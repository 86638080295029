import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";
import * as actions from "../../../_redux/users/usersActions";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Input } from "../../../../../../_metronic/_partials/controls";
import { useSnackbar } from "notistack";

export function UserBanDialog({ id, show, onHide }) {
  const { command } = useParams();

  const { enqueueSnackbar } = useSnackbar();
  const notifyWithError = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      preventDuplicate: true,
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    });
  };

  // Customers Redux state
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.users.actionsLoading }),
    shallowEqual
  );

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  const banUser = (values) => {
    // server request for deleting customer by id
    dispatch(actions.banUser(values.id, values.weeks))
      .then(() => {
        // // refresh list after deletion
        // dispatch(actions.fetchUsers(usersUIProps.queryParams));
        // // clear selections list
        // usersUIProps.setIds([]);
        // closing delete modal
        onHide();
      })
      .catch((err) => {
        notifyWithError(err.message);
      });
  };

  // ValidationSchema
  const userBanSchema = useMemo(
    () =>
      Yup.object().shape({
        weeks: Yup.number()
          .required("Required")
          .test("Is valid", "Must be greater than 0!", (value) => value >= 1),
      }),
    []
  );

  return (
    <Formik
      enableReinitialize={true}
      initialValues={{ id: id, weeks: 1 }}
      validationSchema={userBanSchema}
      onSubmit={(values) => {
        banUser(values);
      }}
    >
      {({ handleSubmit }) => (
        <Modal
          show={show}
          onHide={onHide}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          {/*begin::Loading*/}
          {isLoading && <ModalProgressBar />}
          {/*end::Loading*/}
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              User {command}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form className="form form-label-right">
              <div className="form-group row">
                <div className="col-lg-4">
                  <Field
                    type="number"
                    step="1"
                    min="1"
                    name="weeks"
                    component={Input}
                    placeholder="Weeks"
                    label="Weeks*"
                  />
                </div>
              </div>
            </Form>
            {!isLoading && <span>Are you sure to {command} this user ?</span>}
            {isLoading && <span>User is being {command}ed...</span>}
          </Modal.Body>
          <Modal.Footer>
            <div>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="button"
                onClick={handleSubmit}
                className="btn btn-danger btn-elevate"
              >
                Ban
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </Formik>
  );
}
