import React, { useMemo } from "react";
import AsyncSelect from "react-select/async";
import _ from "lodash";
import { findRequirements } from "../../../../../Requirements/_redux/requirements/requirementsCrud";

export const RequirementAsyncSelect = ({
  value,
  isError,
  onChange,
  // defaultInputValue,
}) => {
  // Save Search and Debounce
  const asyncProps = useMemo(() => {
    function asyncDebounce(func, wait) {
      const debounced = _.debounce((resolve, reject, args) => {
        func(...args)
          .then(resolve)
          .catch(reject);
      }, wait);
      return (...args) =>
        new Promise((resolve, reject) => {
          debounced(resolve, reject, args);
        });
    }
    const searchRequirement = async (searchText) => {
      return findRequirements({
        filter: { name: searchText },
      });
    };
    var debounced = asyncDebounce(searchRequirement, 500);
    return {
      searchRequirement,
      debounced,
    };
  }, []);

  const loadOptions = async (searchText, loadedOptions, temp) => {
    const {
      data: { requirements: nodes },
    } = await asyncProps.debounced(searchText);
    return nodes;
  };

  return (
    <>
      <AsyncSelect
        styles={{
          container: () => ({
            border: isError && "1px solid #F64E60",
          }),
        }}
        value={value}
        defaultOptions
        isMulti
        loadOptions={loadOptions}
        onChange={onChange}
        getOptionValue={(option) => {
          return option.id;
        }}
        getOptionLabel={(option) => {
          return `${option.name}`;
        }}
        placeholder="Search for requirements"
        noOptionsMessage={() => "No requirement found"}
      />
    </>
  );
};
