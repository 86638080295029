import { createSlice } from "@reduxjs/toolkit";

const initialRefundRequestState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  refundRequestForEdit: undefined,
  lastError: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const refundRequestSlice = createSlice({
  name: "refundRequest",
  initialState: initialRefundRequestState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    refundRequestFetched: (state, action) => {
      state.actionsLoading = false;
      state.refundRequestForEdit = action.payload.refundRequestForEdit;
      state.error = null;
    },
    refundRequestsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    refundRequestUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.refundRequest.id) {
          return action.payload.refundRequest;
        }
        return entity;
      });
    },
  },
});
