import { gql } from "@apollo/client";

const EVENT_RECURRING_CREATE = gql`
  mutation(
    $class_id: Int!
    $studio_id: Int!
    $instructor_id: Int!
    $start_time: TimeOnly!
    $end_time: TimeOnly!
    $has_studio: Boolean!
    $has_virtual: Boolean!
    $participant_limit: Int!
    $accept_punch_card: Boolean!
    $day_of_week: DayOfWeek!
    $start_date: Date
    $end_date: Date
  ) {
    event_recurring {
      create(
        input: {
          class_id: $class_id
          studio_id: $studio_id
          instructor_id: $instructor_id
          start_time: $start_time
          end_time: $end_time
          participant_limit: $participant_limit
          has_studio: $has_studio
          has_virtual: $has_virtual
          accept_punch_card: $accept_punch_card
          day_of_week: $day_of_week
          start_date: $start_date
          end_date: $end_date
        }
      ) {
        id
        class_id
        studio_id
        instructor_id
        day_of_week
        start_time
        end_time
        participant_limit
        has_studio
        has_virtual
        accept_punch_card
        start_date
        end_date
        class {
          id
          name
        }
        studio {
          id
          name
          address
        }
        instructor {
          id
          name
          image_link
        }
      }
    }
  }
`;

const EVENT_RECURRING_UPDATE = gql`
  mutation(
    $id: Int!
    $studio_id: Int!
    $class_id: Int!
    $instructor_id: Int!
    $start_time: TimeOnly!
    $end_time: TimeOnly!
    $participant_limit: Int!
    $has_studio: Boolean!
    $has_virtual: Boolean!
    $accept_punch_card: Boolean!
    $day_of_week: DayOfWeek!
    $start_date: Date
    $end_date: Date
  ) {
    event_recurring {
      update(
        id: $id
        input: {
          class_id: $class_id
          studio_id: $studio_id
          instructor_id: $instructor_id
          start_time: $start_time
          end_time: $end_time
          participant_limit: $participant_limit
          has_studio: $has_studio
          has_virtual: $has_virtual
          accept_punch_card: $accept_punch_card
          day_of_week: $day_of_week
          start_date: $start_date
          end_date: $end_date
        }
      ) {
        id
        class_id
        studio_id
        instructor_id
        day_of_week
        start_time
        end_time
        participant_limit
        has_studio
        has_virtual
        accept_punch_card
        start_date
        end_date
        class {
          id
          name
        }
        studio {
          id
          name
          address
        }
        instructor {
          id
          name
          image_link
        }
      }
    }
  }
`;

const EVENT_RECURRING_PAGINATE = gql`
  query(
    $page: Int
    $limit: Int
    $class_ids: [Int!]
    $studio_ids: [Int!]
    $instructor_ids: [Int!]
    $day_of_weeks: [DayOfWeek]
    $start_time: TimeOnly
    $end_time: TimeOnly
    $has_studio: Boolean
    $has_virtual: Boolean
    $accept_punch_card: Boolean
    $sort_order: SortOrder
    $sort_by: EventRecurringSortableField
    $comparables: [EventRecurringComparable]
  ) {
    event_recurrings(
      page: $page
      limit: $limit
      filter: {
        class_ids: $class_ids
        studio_ids: $studio_ids
        instructor_ids: $instructor_ids
        day_of_weeks: $day_of_weeks
        start_time: $start_time
        end_time: $end_time
        has_studio: $has_studio
        has_virtual: $has_virtual
        accept_punch_card: $accept_punch_card
        sort_order: $sort_order
        sort_by: $sort_by
        comparables: $comparables
      }
    ) {
      total
      nodes {
        id
        class_id
        studio_id
        instructor_id
        day_of_week
        start_time
        end_time
        participant_limit
        has_studio
        has_virtual
        accept_punch_card
        start_date
        end_date
        class {
          id
          name
        }
        studio {
          id
          name
          address
        }
        instructor {
          id
          name
          image_link
        }
      }
    }
  }
`;

const EVENT_RECURRING_BY_ID = gql`
  query EVENT_RECURRING_BY_ID($id: Int!) {
    event_recurring(id: $id) {
      id
      class_id
      studio_id
      instructor_id
      day_of_week
      start_time
      end_time
      participant_limit
      has_studio
      has_virtual
      accept_punch_card
      start_date
      end_date
      class {
        id
        name
      }
      studio {
        id
        name
        address
      }
      instructor {
        id
        name
        image_link
      }
      has_recurring_generated_future_events
    }
  }
`;

const EVENT_RECURRING_DELETE = gql`
  mutation($id: Int!) {
    event_recurring {
      delete(id: $id)
    }
  }
`;

const EVENT_RECURRING_CHECK_GENERATED = gql`
  query EVENT_RECURRING_CHECK_GENERATED($id: Int!) {
    has_recurring_generated_future_events(event_recurring_id: $id)
  }
`;

export {
  EVENT_RECURRING_CREATE,
  EVENT_RECURRING_UPDATE,
  EVENT_RECURRING_PAGINATE,
  EVENT_RECURRING_BY_ID,
  EVENT_RECURRING_DELETE,
  EVENT_RECURRING_CHECK_GENERATED,
};
