import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./InstructorsUIHelpers";

const InstructorsUIContext = createContext();

export const useInstructorsUIContext = () => {
  return useContext(InstructorsUIContext);
};

export const InstructorsUIConsumer = InstructorsUIContext.Consumer;

export const InstructorsUIProvider = ({ instructorsUIEvents, children }) => {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      return isEqual(prevQueryParams, nextQueryParams)
        ? prevQueryParams
        : nextQueryParams;
    });
  }, []);

  const initInstructor = {
    id: undefined,
    user_id: undefined,
    name: "",
    about: "",
    avatar: null,
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initInstructor,
    newInstructorButtonClick: instructorsUIEvents.newInstructorButtonClick,
    openEditInstructorPage: instructorsUIEvents.openEditInstructorPage,
    openDeleteInstructorDialog: instructorsUIEvents.openDeleteInstructorDialog,
  };

  return (
    <InstructorsUIContext.Provider value={value}>
      {children}
    </InstructorsUIContext.Provider>
  );
};
