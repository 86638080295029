import React from "react";
import { Route, Switch } from "react-router-dom";
import { NewsLoadingDialog } from "./news-loading-dialog/NewsLoadingDialog";
import { NewsUIProvider } from "./NewsUIContext";
import { NewsCard } from "./NewsCard";
import { NewsEdit } from "./news-edit/NewsEdit";

export const NewsPage = ({ history }) => {
  const newsUIEvents = {
    newNewsButtonClick: () => {
      history.push("/news/new");
    },
    openEditNewsPage: (id) => {
      history.push(`/news/${id}/edit`);
    },
    openDeleteNewsDialog: (id) => {
      history.push(`/news/${id}/delete`);
    },
  };

  return (
    <NewsUIProvider newsUIEvents={newsUIEvents}>
      <NewsLoadingDialog />

      <Switch>
        <Route path="/news/new">
          {({ match }) => {
            return match != null && <NewsEdit />;
          }}
        </Route>

        <Route path="/news/:id/edit">
          {({ match }) => {
            return match != null && <NewsEdit />;
          }}
        </Route>

        <Route path="/news">
          {({ match }) => {
            return match != null && <NewsCard />;
          }}
        </Route>
      </Switch>
    </NewsUIProvider>
  );
};
