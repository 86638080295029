import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./UserContactsUIHelpers";

const userContactsUIContext = createContext();

export const useUserContactsUIContext = () => {
  return useContext(userContactsUIContext);
};

export const InstructorsUIConsumer = userContactsUIContext.Consumer;

export const UserContactUIProvider = ({ userContactUIEvents, children }) => {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      return isEqual(prevQueryParams, nextQueryParams)
        ? prevQueryParams
        : nextQueryParams;
    });
  }, []);

  const initUserContact = {
    id: undefined,
    name: "",
    class: null,
    location: null,
    instructor: null,
    day_of_week: { value: "MONDAY", label: "Monday" },
    start_time: "",
    end_time: "",
    participant_limit: 1,
    // participant_waiting_limit: 0,
    has_studio: false,
    has_virtual: false,
    accept_punch_card: false,
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initUserContact,
    openDetailUserContactPage: userContactUIEvents.openDetailUserContactPage,
    basePath: userContactUIEvents.basePath,
  };

  return (
    <userContactsUIContext.Provider value={value}>
      {children}
    </userContactsUIContext.Provider>
  );
};
