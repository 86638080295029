import { gql } from "@apollo/client";

const CREDIT_HISTORIES = gql`
  query creditHistories(
    $page: Int
    $limit: Int
    $start_date: String
    $end_date: String
    $user_id: Int
    $type: UserCreditHistoryType
    $sort_by: UserCreditHistorySortableField
    $sort_order: SortOrder
  ) {
    credit_histories(
      page: $page
      limit: $limit
      filter: {
        start_date: $start_date
        end_date: $end_date
        user_id: $user_id
        type: $type
        sort_by: $sort_by
        sort_order: $sort_order
      }
    ) {
      total
      nodes {
        id
        user_id
        credit_type_id
        credit
        type
        payment_status
        reason
        created_at
        updated_at
        user {
          id
          email
          first_name
          last_name
        }
        credit_type {
          id
          name
          credit
          price
        }
        credit_type_id
        paynow_payment_history_id
        stripe_payment_history {
          id
          stripe_identifier
          receipt_url
        }
      }
    }
  }
`;

const CREDIT_HISTORY = gql`
  query getCreditHistoryByID($id: Int!) {
    credit_history(id: $id) {
      id
      user_id
      credit_type_id
      credit
      type
      payment_status
      created_at
      updated_at
      user {
        id
        email
        first_name
        last_name
      }
      credit_type {
        id
        name
        credit
        price
      }
      paynow_payment_history_id
      paynow_payment_history {
        id
        qr_string
        expired_at
        created_at
      }
      stripe_payment_history {
        stripe_identifier
        receipt_url
        charge_status
        description
        payment_method
      }
    }
  }
`;

export { CREDIT_HISTORIES, CREDIT_HISTORY };
