import { gql } from "@apollo/client";

const EVENT_CREATE = gql`
  mutation(
    $class_id: Int!
    $studio_id: Int!
    $instructor_id: Int!
    $date: Date!
    $start_time: TimeOnly!
    $end_time: TimeOnly!
    $note_html: String
    $participant_limit: Int!
    $participant_count: Int!
    $studio_price: Float
    $studio_credit: Float
    $virtual_price: Float
    $virtual_credit: Float
    $zoom_url: String
    $has_studio: Boolean!
    $has_virtual: Boolean!
    $accept_punch_card: Boolean!
    $is_special_class: Boolean!
  ) {
    event {
      create(
        input: {
          class_id: $class_id
          studio_id: $studio_id
          instructor_id: $instructor_id
          date: $date
          start_time: $start_time
          end_time: $end_time
          note_html: $note_html
          participant_limit: $participant_limit
          participant_count: $participant_count
          studio_price: $studio_price
          studio_credit: $studio_credit
          virtual_price: $virtual_price
          virtual_credit: $virtual_credit
          zoom_url: $zoom_url
          has_studio: $has_studio
          has_virtual: $has_virtual
          accept_punch_card: $accept_punch_card
          is_special_class: $is_special_class
        }
      ) {
        id
        class_id
        studio_id
        instructor_id
        date
        start_time
        end_time
        note_html
        participant_limit
        participant_count
        studio_price
        studio_credit
        virtual_price
        virtual_credit
        zoom_url
        zoom_pass_code
        has_studio
        has_virtual
        accept_punch_card
        is_special_class
        status
        event_recurring_id
        updated_at
        class {
          name
        }
        instructor {
          name
          image_link
        }
        studio {
          id
          name
          address
        }
      }
    }
  }
`;

const EVENT_PAGINATE = gql`
  query(
    $page: Int
    $limit: Int
    $class_ids: [Int!]
    $studio_ids: [Int!]
    $instructor_ids: [Int!]
    $start_date: String
    $end_date: String
    $start_time: TimeOnly
    $end_time: TimeOnly
    $participant_limit: Int
    $has_studio: Boolean
    $has_virtual: Boolean
    $accept_punch_card: Boolean
    $is_special_class: Boolean
    $status: [EventStatus]
    $sort_by: EventSortableField
    $sort_order: SortOrder
  ) {
    events(
      page: $page
      limit: $limit
      filter: {
        class_ids: $class_ids
        studio_ids: $studio_ids
        instructor_ids: $instructor_ids
        start_date: $start_date
        end_date: $end_date
        start_time: $start_time
        end_time: $end_time
        participant_limit: $participant_limit
        has_studio: $has_studio
        has_virtual: $has_virtual
        accept_punch_card: $accept_punch_card
        is_special_class: $is_special_class
        status: $status
        sort_by: $sort_by
        sort_order: $sort_order
      }
    ) {
      total
      nodes {
        id
        class_id
        studio_id
        instructor_id
        date
        start_time
        end_time
        participant_limit
        participant_count
        studio_price
        studio_credit
        virtual_price
        virtual_credit
        zoom_url
        zoom_pass_code
        has_studio
        has_virtual
        accept_punch_card
        is_special_class
        status
        event_recurring_id
        updated_at
        class {
          name
        }
        instructor {
          name
          image_link
        }
        studio {
          id
          name
          address
        }
      }
    }
  }
`;

const EVENT_UPDATE = gql`
  mutation(
    $id: Int!
    $class_id: Int!
    $studio_id: Int!
    $instructor_id: Int!
    $date: Date!
    $start_time: TimeOnly!
    $end_time: TimeOnly!
    $note_html: String
    $participant_limit: Int!
    $participant_count: Int!
    $studio_price: Float
    $studio_credit: Float
    $virtual_price: Float
    $virtual_credit: Float
    $zoom_url: String
    $has_studio: Boolean!
    $has_virtual: Boolean!
    $accept_punch_card: Boolean!
    $is_special_class: Boolean!
  ) {
    event {
      update(
        id: $id
        input: {
          class_id: $class_id
          studio_id: $studio_id
          instructor_id: $instructor_id
          date: $date
          start_time: $start_time
          end_time: $end_time
          note_html: $note_html
          participant_limit: $participant_limit
          participant_count: $participant_count
          studio_price: $studio_price
          studio_credit: $studio_credit
          virtual_price: $virtual_price
          virtual_credit: $virtual_credit
          zoom_url: $zoom_url
          has_studio: $has_studio
          has_virtual: $has_virtual
          accept_punch_card: $accept_punch_card
          is_special_class: $is_special_class
        }
      ) {
        id
        class_id
        studio_id
        instructor_id
        date
        start_time
        end_time
        note_html
        participant_limit
        participant_count
        studio_price
        studio_credit
        virtual_price
        virtual_credit
        zoom_url
        zoom_pass_code
        has_studio
        has_virtual
        accept_punch_card
        is_special_class
        status
        event_recurring_id
        updated_at
        class {
          name
        }
        instructor {
          name
          image_link
        }
        studio {
          id
          name
          address
        }
      }
    }
  }
`;

const EVENT_BY_ID = gql`
  query($id: Int!) {
    event(id: $id) {
      id
      class_id
      studio_id
      instructor_id
      date
      start_time
      end_time
      note_html
      participant_limit
      participant_count
      studio_price
      studio_credit
      virtual_price
      virtual_credit
      zoom_url
      zoom_pass_code
      has_studio
      has_virtual
      accept_punch_card
      is_special_class
      status
      event_recurring_id
      updated_at
      class {
        id
        name
        studio_price
        studio_credit
        virtual_price
        virtual_credit
      }
      studio {
        id
        name
        address
      }
      instructor {
        id
        name
        image_link
      }
      event_recurring_id
    }
  }
`;

const EVENT_DELETE = gql`
  mutation($id: Int!) {
    event {
      delete(id: $id)
    }
  }
`;

const EVENT_CANCEL = gql`
  mutation cancelEvent($id: Int!) {
    event {
      cancel(id: $id) {
        id
      }
    }
  }
`;

const DETECT_EVENT = gql`
  query DETECT_EVENT(
    $id: Int
    $class_id: Int!
    $studio_id: Int!
    $instructor_id: Int!
    $date: Date!
    $start_time: TimeOnly!
    $end_time: TimeOnly!
    $participant_limit: Int!
    $participant_count: Int!
    $studio_price: Float
    $studio_credit: Float
    $virtual_price: Float
    $virtual_credit: Float
    $zoom_url: String
    $has_studio: Boolean!
    $has_virtual: Boolean!
    $accept_punch_card: Boolean!
    $is_special_class: Boolean!
  ) {
    detect_event_conflict(
      id: $id
      input: {
        class_id: $class_id
        studio_id: $studio_id
        instructor_id: $instructor_id
        date: $date
        start_time: $start_time
        end_time: $end_time
        participant_limit: $participant_limit
        participant_count: $participant_count
        studio_price: $studio_price
        studio_credit: $studio_credit
        virtual_price: $virtual_price
        virtual_credit: $virtual_credit
        zoom_url: $zoom_url
        has_studio: $has_studio
        has_virtual: $has_virtual
        accept_punch_card: $accept_punch_card
        is_special_class: $is_special_class
      }
    ) {
      instructor_schedule {
        id
        class_id
        studio_id
        instructor_id
        date
        start_time
        end_time
        participant_limit
        participant_count
        studio_price
        studio_credit
        virtual_price
        virtual_credit
        zoom_url
        zoom_pass_code
        has_studio
        has_virtual
        accept_punch_card
        is_special_class
        status
        event_recurring_id
        class {
          name
        }
        instructor {
          name
          image_link
        }
        studio {
          id
          name
          address
        }
      }
      studio_usage {
        id
        class_id
        studio_id
        instructor_id
        date
        start_time
        end_time
        participant_limit
        participant_count
        studio_price
        studio_credit
        virtual_price
        virtual_credit
        zoom_url
        zoom_pass_code
        has_studio
        has_virtual
        accept_punch_card
        is_special_class
        status
        class {
          name
        }
        instructor {
          name
          image_link
        }
        studio {
          id
          name
          address
        }
      }
    }
  }
`;

export {
  EVENT_CREATE,
  EVENT_UPDATE,
  EVENT_PAGINATE,
  EVENT_BY_ID,
  EVENT_DELETE,
  EVENT_CANCEL,
  DETECT_EVENT,
};
