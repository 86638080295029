import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control", "hide-spinner"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function InputPlusMinus({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors, setFieldValue }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  type = "text",
  step = 1,
  precision = 2,
  ...props
}) {
  const onPlus = () => {
    setFieldValue(
      field.name,
      +(parseFloat(field.value) + parseFloat(step)).toFixed(precision)
    );
  };

  const onMinus = () => {
    setFieldValue(
      field.name,
      +(parseFloat(field.value) - parseFloat(step)).toFixed(precision)
    );
  };

  return (
    <>
      {label && <label>{label}</label>}
      <div class="input-group">
        <button type="button" className="btn btn-secondary" onClick={onMinus}>
          <FontAwesomeIcon icon={faMinus} />
        </button>
        <input
          type={type}
          className={getFieldCSSClasses(
            touched[field.name],
            errors[field.name]
          )}
          {...field}
          {...props}
        />
        <button type="button" className="btn btn-secondary" onClick={onPlus}>
          <FontAwesomeIcon icon={faPlus} />
        </button>
        {withFeedbackLabel && (
          <FieldFeedbackLabel
            error={errors[field.name]}
            touched={touched[field.name]}
            label={label}
            type={type}
            customFeedbackLabel={customFeedbackLabel}
          />
        )}
      </div>
    </>
  );
}
