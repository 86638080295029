/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";

export function HeaderMenu({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };

  const CustomMenuItem = ({ label, link, hasBullet }) => (
    <li className={`menu-item menu-item-rel ${getMenuItemActive(link)}`}>
      <NavLink className="menu-link" to={link}>
        {hasBullet && (
          <i className="menu-bullet menu-bullet-dot">
            <span />
          </i>
        )}
        <span className="menu-text">{label}</span>
        {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
      </NavLink>
    </li>
  );

  const CustomNestedMenuItem = ({ children, link, label, right }) => {
    return (
      <li
        data-menu-toggle={layoutProps.menuDesktopToggle}
        aria-haspopup="true"
        className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(
          link
        )}`}>
        <NavLink className="menu-link menu-toggle" to={link}>
          <span className="menu-text">{label}</span>
          <i className="menu-arrow"></i>
        </NavLink>
        <div
          className={`menu-submenu menu-submenu-classic menu-submenu-${
            right ? "right" : "left"
          }`}>
          <ul className="menu-subnav">{children}</ul>
        </div>
      </li>
    );
  };

  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}>
      {/*begin::Header Nav*/}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <CustomMenuItem label="Dashboard" link="/dashboard" />
        {/*end::1 Level*/}

        {/*Classic submenu*/}
        {/*begin::1 Level*/}
        <CustomNestedMenuItem link="/custom" label="Menus">
          <CustomNestedMenuItem right={true} link="/clients" label="Clients">
            <CustomMenuItem
              hasBullet={true}
              link="/clients/contacts"
              label="Clients"
            />
            <CustomMenuItem
              hasBullet={true}
              link="/refund-requests"
              label="Refund Requests"
            />
          </CustomNestedMenuItem>
          <CustomMenuItem link="/users" label="Users" />
          <CustomMenuItem link="/credit-types" label="Credit Types" />
          <CustomMenuItem link="/instructors" label="Instructors" />
          <CustomMenuItem link="/requirements" label="Requirements" />
          <CustomNestedMenuItem right={true} link="/studio" label="Studio">
            <CustomMenuItem
              hasBullet={true}
              link="/studio/list"
              label="Studios"
            />
            <CustomMenuItem
              hasBullet={true}
              link="/studio/images"
              label="Images / Gallery"
            />
          </CustomNestedMenuItem>
          <CustomNestedMenuItem
            right={true}
            link="/purchase-histories"
            label="Purchase Histories">
            <CustomMenuItem
              hasBullet={true}
              link="/purchase-histories/credit-histories"
              label="Credit Histories"
            />
            <CustomMenuItem
              hasBullet={true}
              link="/purchase-histories/virtual-unlimited-histories"
              label="Virtual Unlimited Histories"
            />
          </CustomNestedMenuItem>
          <CustomNestedMenuItem right={true} link="/event" label="Event">
            <CustomMenuItem
              hasBullet={true}
              link="/event/list"
              label="Events"
            />
            <CustomMenuItem
              hasBullet={true}
              link="/event/recurrings"
              label="Recurrings"
            />
            <CustomMenuItem
              hasBullet={true}
              link="/event/bookings"
              label="Bookings"
            />
          </CustomNestedMenuItem>
          <CustomMenuItem link="/locations" label="Locations" />
          <CustomMenuItem link="/news" label="News" />
          <CustomMenuItem link="/classes" label="Classes" />
          <CustomNestedMenuItem
            right={true}
            link="/config"
            label="Configuration">
            {/* <CustomMenuItem
              hasBullet={true}
              link="/config/builder"
              label="Layout Builder"
            /> */}
            <CustomMenuItem
              hasBullet={true}
              link="/config/setting"
              label="Setting"
            />
          </CustomNestedMenuItem>
        </CustomNestedMenuItem>
        {/*end::1 Level*/}
      </ul>
      {/*end::Header Nav*/}
    </div>
  );
}
