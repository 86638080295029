import { gql } from "@apollo/client";

const CLASS_CREATE = gql`
  mutation(
    $name: String!
    $color_hex: String!
    $about: String!
    $target_audience: String
    $benefits: String
    $how_it_works: String
    $studio_price: Float!
    $studio_credit: Float!
    $virtual_price: Float!
    $virtual_credit: Float!
    $youtube_url: String
    $image: Upload
    $requirement_ids: [Int!]
  ) {
    class {
      create(
        input: {
          name: $name
          color_hex: $color_hex
          about: $about
          target_audience: $target_audience
          benefits: $benefits
          how_it_works: $how_it_works
          studio_price: $studio_price
          studio_credit: $studio_credit
          virtual_price: $virtual_price
          virtual_credit: $virtual_credit
          youtube_url: $youtube_url
          image: $image
        }
        requirement_ids: $requirement_ids
      ) {
        id
        name
        color_hex
        about
        target_audience
        benefits
        how_it_works
        studio_price
        studio_credit
        virtual_price
        virtual_credit
        youtube_url
        image_path
        image_link
        updated_at
      }
    }
  }
`;

const CLASS_UPDATE = gql`
  mutation(
    $id: Int!
    $name: String!
    $color_hex: String!
    $about: String!
    $target_audience: String
    $benefits: String
    $how_it_works: String
    $studio_price: Float!
    $studio_credit: Float!
    $virtual_price: Float!
    $virtual_credit: Float!
    $youtube_url: String
    $image: Upload
    $requirement_ids: [Int!]
  ) {
    class {
      update(
        id: $id
        input: {
          name: $name
          color_hex: $color_hex
          about: $about
          target_audience: $target_audience
          benefits: $benefits
          how_it_works: $how_it_works
          studio_price: $studio_price
          studio_credit: $studio_credit
          virtual_price: $virtual_price
          virtual_credit: $virtual_credit
          youtube_url: $youtube_url
          image: $image
        }
        requirement_ids: $requirement_ids
      ) {
        id
        name
        color_hex
        about
        target_audience
        benefits
        how_it_works
        studio_price
        studio_credit
        virtual_price
        virtual_credit
        youtube_url
        image_path
        image_link
        updated_at
      }
    }
  }
`;

const CLASS_PAGINATE = gql`
  query(
    $page: Int
    $limit: Int
    $name: String
    $sort_by: ClassSortableField
    $sort_order: SortOrder
  ) {
    classes(
      page: $page
      limit: $limit
      filter: { name: $name, sort_by: $sort_by, sort_order: $sort_order }
    ) {
      total
      nodes {
        id
        name
        color_hex
        about
        target_audience
        benefits
        how_it_works
        studio_price
        studio_credit
        virtual_price
        virtual_credit
        youtube_url
        image_link
        updated_at
      }
    }
  }
`;

const CLASS_BY_ID = gql`
  query($id: Int!) {
    class(id: $id) {
      id
      name
      color_hex
      about
      target_audience
      benefits
      how_it_works
      studio_price
      studio_credit
      virtual_price
      virtual_credit
      youtube_url
      image_link
      updated_at
      requirements {
        class_id
        requirement_id
        requirement {
          id
          name
        }
      }
    }
  }
`;

const CLASS_DELETE = gql`
  mutation($id: Int!) {
    class {
      delete(id: $id)
    }
  }
`;

export {
  CLASS_CREATE,
  CLASS_UPDATE,
  CLASS_PAGINATE,
  CLASS_BY_ID,
  CLASS_DELETE,
};
