import React, { useMemo, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import * as actions from "../../../_redux/creditTypes/creditTypesActions";
import moment from "moment";
import BootstrapTable from "react-bootstrap-table-next";
import {
  // getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import { useCreditTypesUIContext } from "../CreditTypesUIContext";
import * as uiHelpers from "../CreditTypesUIHelpers";
import * as columnFormatters from "./column-formatters";

export const CreditTypesTable = () => {
  const creditTypesUIContext = useCreditTypesUIContext();
  const creditTypesUIProps = useMemo(() => {
    return {
      ids: creditTypesUIContext.ids,
      setIds: creditTypesUIContext.setIds,
      queryParams: creditTypesUIContext.queryParams,
      setQueryParams: creditTypesUIContext.setQueryParams,
      openEditCreditTypePage: creditTypesUIContext.openEditCreditTypePage,
      openDeleteCreditTypeDialog:
        creditTypesUIContext.openDeleteCreditTypeDialog,
    };
  }, [creditTypesUIContext]);

  const { currentState } = useSelector(
    (state) => ({ currentState: state.credit_types }),
    shallowEqual
  );
  const { entities } = currentState;

  const dispatch = useDispatch();
  useEffect(() => {
    creditTypesUIProps.setIds([]);
    dispatch(actions.fetchCreditTypes(creditTypesUIProps.queryParams));

    // eslint-disable-next-line
  }, [creditTypesUIProps.queryParams, dispatch]);

  const columns = [
    // {
    //   dataField: "id",
    //   text: "ID",
    //   sort: false,
    //   headerStyle: () => {
    //     return { width: "100px" };
    //   },
    // },
    {
      dataField: "name",
      text: "Name",
      sort: false,
    },
    {
      dataField: "credit",
      text: "Credit",
      sort: false,
      headerStyle: () => ({
        textAlign: "center",
      }),
      style: () => ({
        textAlign: "center",
      }),
      formatter: columnFormatters.FixedDecimalFormatter,
    },
    {
      dataField: "price",
      text: "Price",
      sort: false,
      headerStyle: () => ({
        textAlign: "center",
      }),
      style: () => ({
        textAlign: "center",
      }),
      formatter: columnFormatters.FixedDecimalFormatter,
    },
    {
      dataField: "updated_at",
      text: "Updated At",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD HH:mm:ss"): "-",
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditCreditTypePage: creditTypesUIProps.openEditCreditTypePage,
        openDeleteCreditTypeDialog:
          creditTypesUIProps.openDeleteCreditTypeDialog,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  return (
    <>
      <BootstrapTable
        wrapperClasses="table-responsive"
        bordered={false}
        classes="table table-head-custom table-vertical-center overflow-hidden"
        bootstrap4
        remote
        keyField="id"
        data={entities === null ? [] : entities}
        columns={columns}
        defaultSorted={uiHelpers.defaultSorted}
        onTableChange={getHandlerTableChange(creditTypesUIProps.setQueryParams)}
        // selectRow={getSelectRow({
        //   entities,
        //   ids: creditTypesUIProps.ids,
        //   setIds: creditTypesUIProps.setIds,
        // })}
        // {...paginationTableProps}
      >
        <PleaseWaitMessage entities={entities} />
        <NoRecordsFoundMessage entities={entities} />
      </BootstrapTable>
    </>
  );
};
