import React from "react";
import { Route, Switch } from "react-router-dom";
import { LocationsUIProvider } from "./LocationsUIContext";
import { LocationsCard } from "./LocationsCard";
import { LocationsLoadingDialog } from "./locations-loading-dialog/LocationsLoadingDialog";
import { LocationEdit } from "./location-edit/LocationEdit";

export function LocationsPage({ history }) {
  const basePath = "/locations";

  const locationsUIEvents = {
    newLocationButtonClick: () => {
      history.push(`${basePath}/new`);
    },
    openEditLocationPage: (id) => {
      history.push(`${basePath}/${id}/edit`);
    },
    openDeleteLocationDialog: (id) => {
      history.push(`${basePath}/${id}/delete`);
    },
  };

  return (
    <LocationsUIProvider locationsUIEvents={locationsUIEvents}>
      <LocationsLoadingDialog />
      <Switch>
        <Route path={`${basePath}/new`}>
          {({ match }) => {
            return match != null && <LocationEdit />;
          }}
        </Route>

        <Route path={`${basePath}/:id/edit`}>
          {({ match }) => {
            return match != null && <LocationEdit />;
          }}
        </Route>

        <Route path={`${basePath}`}>
          {({ match }) => {
            return match != null && <LocationsCard />;
          }}
        </Route>
      </Switch>
    </LocationsUIProvider>
  );
}
