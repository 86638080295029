import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./ClassesUIHelpers.js";

const ClassesUIContext = createContext();

export const useClassesUIContext = () => {
  return useContext(ClassesUIContext);
};

export const ClassesUIConsumer = ClassesUIContext.Consumer;

export const ClassesUIProvider = ({ classesUIEvents, children }) => {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      return isEqual(prevQueryParams, nextQueryParams)
        ? prevQueryParams
        : nextQueryParams;
    });
  }, []);

  const initClass = {
    id: undefined,
    name: "",
    about: "",
    target_audience: null,
    benefits: null,
    how_it_works: null,
    studio_price: 0.0,
    studio_credit: 0.0,
    virtual_price: 0.0,
    virtual_credit: 0.0,
    youtube_url: null,
    image: null,
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initClass,
    newClassButtonClick: classesUIEvents.newClassButtonClick,
    openEditClassDialog: classesUIEvents.openEditClassDialog,
    openDeleteClassDialog: classesUIEvents.openDeleteClassDialog,
  };

  return (
    <ClassesUIContext.Provider value={value}>
      {children}
    </ClassesUIContext.Provider>
  );
};
