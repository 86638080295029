import {
  USERS_PAGINATE,
  USER_BY_ID,
  USER_CREATE,
  USER_UPDATE_CREDIT,
  USER_UPDATE,
  BAN_USER,
  UNBAN_USER,
} from "../../../../../graphql/index";
import { apolloClient } from "../../../../apollo/client";
import moment from "moment";

// CREATE, add new user to the server
export function createUser(user) {
  return apolloClient.mutate({
    mutation: USER_CREATE,
    variables: {
      email: user.email?.trim(),
      password: user.password,
      first_name: user.first_name?.trim(),
      last_name: user.last_name?.trim(),
      birth_date: user.birth_date,
      birth_month: user.birth_month,
      phone_number: user.phone_number?.trim(),
      accept_marketing: user.accept_marketing,
      accept_notification: user.accept_notification,
      // is_admin: user.role === "admin",
      is_admin: user.is_admin,
      avatar: user.avatar,
    },
  });
}

// Update User
export function updateUser(user) {
  return apolloClient.mutate({
    mutation: USER_UPDATE,
    variables: {
      id: user.id,
      first_name: user.first_name?.trim(),
      last_name: user.last_name?.trim(),
      email: user.email?.trim(),
      birth_date: user.birth_date,
      birth_month: user.birth_month,
      phone_number: user.phone_number?.trim(),
      credit_expired_at:
        user.credit_expired_at instanceof moment
          ? user.credit_expired_at.format("YYYY-MM-DD HH:mm:ss")
          : user.credit_expired_at,
      accept_marketing: user.accept_marketing,
      accept_notification: user.accept_notification,
      is_admin: user.is_admin,
      image_path: user.image_path,
      avatar: user.avatar,
    },
  });
}

export function getAllUsers() {
  return apolloClient.query({
    query: USERS_PAGINATE,
  });
}

export function getUserById(userId) {
  return apolloClient.query({
    query: USER_BY_ID,
    variables: {
      id: userId,
    },
  });
}

export function findUsers(queryParams) {
  return apolloClient.query({
    query: USERS_PAGINATE,
    variables: {
      page: queryParams.pageNumber,
      limit: queryParams.pageSize,
      sort_by: queryParams.sortField?.toUpperCase(),
      sort_order: queryParams.sortOrder?.toUpperCase(),
      phrase:
        queryParams.filter.phrase === "" ? null : queryParams.filter.phrase,
      is_admin:
        queryParams.filter.is_admin === null
          ? null
          : queryParams.filter.is_admin === ""
          ? null
          : queryParams.filter.is_admin === "admin",
      is_instructor:
        queryParams.filter.is_instructor != null
          ? queryParams.filter.is_instructor
          : null,
    },
  });
}

export function updateUserCreditAmount(user) {
  return apolloClient.mutate({
    mutation: USER_UPDATE_CREDIT,
    variables: {
      id: user.id,
      credit: parseInt(user.credit),
      reason: user.reason,
    },
  });
}

export function banUser(id, weeks) {
  return apolloClient.mutate({
    mutation: BAN_USER,
    variables: {
      id,
      weeks,
    },
  });
}

export function unbanUser(id) {
  return apolloClient.mutate({
    mutation: UNBAN_USER,
    variables: {
      id,
    },
  });
}
