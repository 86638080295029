import React, { useMemo } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  Card,
  CardHeader,
  Input,
} from "../../../../../../_metronic/_partials/controls";
import { RequirementAsyncSelect } from "./form-extras";
import ModalCropper from "../../../../../../utils/ModalCropper";
import AsteriskLabel from "../../../../../../utils/Asterisk";
import { LimitDecimalPlaces } from "../../../../../../utils/input";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const SUPPORTED_FORMATS = ["image/png", "image/jpg", "image/jpeg"];

function checkIfFilesAreCorrectType(file) {
  if (SUPPORTED_FORMATS.includes(file.type)) {
    return true;
  }
  return false;
}

export function ClassEditForm({
  saveClass,
  isEditing,
  classData,
  actionsLoading,
  onHide,
}) {
  // Validation schema
  const ClassEditSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string()
          .trim()
          .required("Required"),
        color_hex: Yup.string()
          .trim()
          .required("Required"),
        about: Yup.string()
          .trim()
          .required("Required"),
        studio_price: Yup.number()
          .required("Required")
          .test(
            "Is valid",
            "Must be greater than equal 0!",
            (value) => value >= 0
          ),
        studio_credit: Yup.number()
          .required("Required")
          .test(
            "Is valid",
            "Must be greater than equal 0!",
            (value) => value >= 0
          ),
        virtual_price: Yup.number()
          .required("Required")
          .test(
            "Is valid",
            "Must be greater than equal 0!",
            (value) => value >= 0
          ),
        virtual_credit: Yup.number()
          .required("Required")
          .test(
            "Is valid",
            "Must be greater than equal 0!",
            (value) => value >= 0
          ),
        youtube_url: Yup.string()
          .nullable()
          .matches(
            /^(https?:\/\/)?((www\.)?youtube\.com|youtu\.be)\/.+$/,
            "Must be a valid YouTube URL"
          ),
        image: Yup.mixed().test(
          "file-type",
          "Invalid Image file type",
          (value) => {
            if (value === null) {
              return isEditing;
            }
            return checkIfFilesAreCorrectType(value);
          }
        ),
      }),
    [isEditing]
  );

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...classData,
          image: null,
          preview_image_link: classData.image_link
            ? classData.image_link
            : null,
          requirements: classData.requirements
            ? classData.requirements.map(
                (requirement) => requirement.requirement
              )
            : [],
          backup_image: null,
        }}
        validationSchema={ClassEditSchema}
        onSubmit={(values) => {
          saveClass(values);
        }}
      >
        {({ handleSubmit, values, errors, setFieldValue }) => (
          <>
            {actionsLoading && (
              <div className="overlay-layer bg-transparent">
                <div className="spinner spinner-lg spinner-success" />
              </div>
            )}
            <Form className="form form-label-right row">
              <div className="col-xl-6">
                <Card className="px-2">
                  <CardHeader title={"General"} />
                  <div className="form-group flex flex-wrap pt-4">
                    <div className="col-xl-12">
                      <Field
                        name="name"
                        component={Input}
                        placeholder="Name"
                        label={<AsteriskLabel word="Name" />}
                      />
                    </div>
                    <div className="col-xl-6 mb-4">
                      <Field
                        name="color_hex"
                        component={Input}
                        type="color"
                        placeholder="Color"
                        label={<AsteriskLabel word="Color" />}
                      />
                    </div>
                    <div className="col-xl-12 pt-4">
                      <label>Requirements</label>
                      <RequirementAsyncSelect
                        value={values.requirements}
                        onChange={(options) => {
                          setFieldValue(
                            "requirements",
                            options === null ? [] : [...options]
                          );
                        }}
                      />
                    </div>
                    <div className="col-xl-12 pt-4">
                      <Field
                        name="youtube_url"
                        type="text"
                        component={Input}
                        placeholder="Youtube embedded URL"
                        label="Youtube embedded URL"
                      />
                    </div>
                    <div className="col-xl-12 pt-4">
                      <label>{<AsteriskLabel word="Headline Image" />}</label>
                      <br />
                      <ModalCropper
                        onSaved={(blob, file) => {
                          setFieldValue("image", file);
                        }}
                        initialValue={values.image_link}
                        aspectRatio={4 / 3}
                        width={400}
                        height={300}
                        cropperHeight={"300px"}
                        cropperWidth={"400px"}
                        modalTitle={"Headline Image"}
                      />
                      <ErrorMessage name={"image"}>
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                  </div>
                </Card>

                <Card className="px-2">
                  <div className="form-group row px-4 pt-4">
                    <div className="col-xl-12">
                      <label>Target Audience</label>
                      <ReactQuill
                        theme="snow"
                        modules={{
                          toolbar: [
                            ["bold", "italic", "underline", "strike"],
                            [{ list: "bullet" }],
                          ],
                        }}
                        value={values.target_audience || ""}
                        onChange={(content) => {
                          if (content === "<p><br></p>") {
                            setFieldValue("target_audience", null);
                          } else {
                            setFieldValue("target_audience", content);
                          }
                        }}
                      />
                      <ErrorMessage
                        className="text-danger"
                        name="target_audience"
                        component="div"
                      />
                    </div>
                  </div>
                </Card>

                <Card className="px-2">
                  <div className="form-group row px-4 pt-4">
                    <div className="col-xl-12">
                      <label>How it Works</label>
                      <ReactQuill
                        theme="snow"
                        modules={{
                          toolbar: [
                            ["bold", "italic", "underline", "strike"],
                            [{ list: "bullet" }],
                          ],
                        }}
                        value={values.how_it_works || ""}
                        onChange={(content) => {
                          if (content === "<p><br></p>") {
                            setFieldValue("how_it_works", null);
                          } else {
                            setFieldValue("how_it_works", content);
                          }
                        }}
                      />
                      <ErrorMessage
                        className="text-danger"
                        name="how_it_works"
                        component="div"
                      />
                    </div>
                  </div>
                </Card>
              </div>
              <div className="col-xl-6">
                <Card className="px-2">
                  <CardHeader title={"Pricing"} />
                  <div className="form-group row px-4 pt-4">
                    <div className="col-lg-6">
                      <Field
                        name="studio_price"
                        type="number"
                        component={Input}
                        step="0.01"
                        min="0.00"
                        onChange={(e) => {
                          setFieldValue(
                            "studio_price",
                            LimitDecimalPlaces(e.target.value, 2)
                          );
                        }}
                        placeholder="Studio Price"
                        label={<AsteriskLabel word="Studio Price" />}
                      />
                    </div>
                    <div className="col-lg-6">
                      <Field
                        name="studio_credit"
                        type="number"
                        component={Input}
                        step="0.01"
                        min="0.00"
                        onChange={(e) => {
                          setFieldValue(
                            "studio_credit",
                            LimitDecimalPlaces(e.target.value, 2)
                          );
                        }}
                        placeholder="Studio Credit"
                        label={<AsteriskLabel word="Studio Credit" />}
                      />
                    </div>
                  </div>
                  <div className="form-group row px-4 pt-4">
                    <div className="col-lg-6">
                      <Field
                        name="virtual_price"
                        type="number"
                        component={Input}
                        step="0.01"
                        min="0.00"
                        onChange={(e) => {
                          setFieldValue(
                            "virtual_price",
                            LimitDecimalPlaces(e.target.value, 2)
                          );
                        }}
                        placeholder="Virtual Price"
                        label={<AsteriskLabel word="Virtual Price" />}
                      />
                    </div>
                    <div className="col-lg-6">
                      <Field
                        name="virtual_credit"
                        type="number"
                        component={Input}
                        step="0.01"
                        min="0.00"
                        onChange={(e) => {
                          setFieldValue(
                            "virtual_credit",
                            LimitDecimalPlaces(e.target.value, 2)
                          );
                        }}
                        placeholder="Virtual Credit"
                        label={<AsteriskLabel word="Virtual Credit" />}
                      />
                    </div>
                  </div>
                </Card>
                <Card className="px-2">
                  <div className="form-group row px-4 pt-4">
                    <div className="col-xl-12">
                      <label>{<AsteriskLabel word="About" />}</label>
                      <ReactQuill
                        theme="snow"
                        modules={{
                          toolbar: [
                            ["bold", "italic", "underline", "strike"],
                            [{ list: "bullet" }],
                          ],
                        }}
                        value={values.about}
                        onChange={(val) => {
                          setFieldValue("about", val);
                        }}
                      />
                      <ErrorMessage
                        className="text-danger"
                        name="about"
                        component="div"
                      />
                    </div>
                  </div>
                </Card>

                <Card className="px-2">
                  <div className="form-group row px-4 pt-4">
                    <div className="col-xl-12">
                      <label>Benefits</label>
                      <ReactQuill
                        theme="snow"
                        modules={{
                          toolbar: [
                            ["bold", "italic", "underline", "strike"],
                            [{ list: "bullet" }],
                          ],
                        }}
                        value={values.benefits || ""}
                        onChange={(content) => {
                          if (content === "<p><br></p>") {
                            setFieldValue("benefits", null);
                          } else {
                            setFieldValue("benefits", content);
                          }
                        }}
                      />
                      <ErrorMessage
                        className="text-danger"
                        name="benefits"
                        component="div"
                      />
                    </div>
                  </div>
                </Card>
              </div>
            </Form>
            <div className="row">
              <div className="col-xl-12">
                <Card className="_card-toolbar-start">
                  <button
                    type="submit"
                    onClick={() => handleSubmit()}
                    className="btn btn-primary btn-elevate"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    onClick={onHide}
                    className="btn btn-light btn-elevate"
                  >
                    Cancel
                  </button>
                  <> </>
                </Card>
              </div>
            </div>
          </>
        )}
      </Formik>
    </>
  );
}
