import React, { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { snackActions } from "../../../../../../utils/SnackbarUtils";
import * as actions from "../../../_redux/instructors/instructorsActions";
import { useInstructorsUIContext } from "../InstructorsUIContext";
// import { InstructorEditPageHeader } from "./InstructorEditPageHeader";
import { InstructorEditForm } from "./InstructorEditForm";

export const InstructorEditPage = ({ id, onHide }) => {
  const instructorsUIContext = useInstructorsUIContext();
  const instructorsUIProps = useMemo(() => {
    return {
      initInstructor: instructorsUIContext.initInstructor,
    };
  }, [instructorsUIContext]);

  const dispatch = useDispatch();
  const { actionsLoading, instructorForEdit } = useSelector((state) => {
    return {
      actionsLoading: state.instructors.actionsLoading,
      instructorForEdit: state.instructors.instructorForEdit,
    };
  }, shallowEqual);

  useEffect(() => {
    dispatch(actions.fetchInstructor(id));
  }, [id, dispatch]);

  const saveInstructor = (instructor) => {
    if (!id) {
      dispatch(actions.createInstructor(instructor))
        .then(() => onHide())
        .catch((err) => snackActions.error(err.message));
    } else {
      dispatch(actions.updateInstructor(instructor))
        .then(() => onHide())
        .catch((err) => {
          snackActions.error(err.message);
        });
    }
  };

  return (
    <>
      {id !== undefined ? (
        instructorForEdit &&
        !actionsLoading && (
          <InstructorEditForm
            saveInstructor={saveInstructor}
            isEditing={true}
            actionsLoading={actionsLoading}
            instructor={instructorForEdit}
            onHide={onHide}
          />
        )
      ) : (
        <InstructorEditForm
          saveInstructor={saveInstructor}
          isEditing={false}
          actionsLoading={actionsLoading}
          instructor={instructorsUIProps.initInstructor}
          onHide={onHide}
        />
      )}
    </>
  );
};
