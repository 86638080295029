import { createSlice } from "@reduxjs/toolkit";

const initialStudiosState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  studioForEdit: undefined,
  lastError: null,

  studioImages: [],
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const studiosSlice = createSlice({
  name: "studios",
  initialState: initialStudiosState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },

    // getnewsById
    studioFetched: (state, action) => {
      state.actionsLoading = false;
      state.studioForEdit = action.payload.studioForEdit;
      state.error = null;
    },

    // getNews
    studiosFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },

    // createnews
    studioCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.studio);
    },

    // update news
    studioUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map((entity) => {
        if (entity.id === action.payload.studio.id) {
          return action.payload.studio;
        }
        return entity;
      });
    },

    // delete news
    studioDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        (entity) => entity.id !== action.payload.id
      );
    },

    // delete studio image
    studioImageDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.studioImages = state.studioImages.filter(
        (entity) => entity.id !== action.payload.id
      );
    },
  },
});
