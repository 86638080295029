import React, { useMemo } from "react";
import { Formik } from "formik";
import { debounce, isEqual } from "lodash";
import { useClassesUIContext } from "../ClassesUIContext";

const prepareFilter = (queryParams, values) => {
  const { name } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};
  filter.name = name;

  newQueryParams.filter = filter;
  return newQueryParams;
};

export function ClassesFilter({ listLoading }) {
  const classesUIContext = useClassesUIContext();
  const classesUIProps = useMemo(() => {
    return {
      queryParams: classesUIContext.queryParams,
      setQueryParams: classesUIContext.setQueryParams,
    };
  }, [classesUIContext]);

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(classesUIProps.queryParams, values);
    if (!isEqual(newQueryParams, classesUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      classesUIProps.setQueryParams(newQueryParams);
    }
  };
  const debouncedApplyFilter = debounce(applyFilter, 500);

  return (
    <>
      <Formik
        initialValues={{
          name: "",
        }}
        onSubmit={(values) => {
          debouncedApplyFilter(values);
        }}>
        {({ values, handleSubmit, setFieldValue }) => {
          return (
            <form onSubmit={handleSubmit} className="form form-label-right">
              <div className="form-group row">
                <div className="col-lg-2">
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    value={values.name}
                    onChange={(e) => {
                      setFieldValue("name", e.target.value);
                      handleSubmit();
                    }}
                    placeholder="Search"
                  />
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </>
  );
}
