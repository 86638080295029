import React, { useEffect, useMemo } from "react";
import * as actions from "../../_redux/refundRequestActions";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../_metronic/_helpers";
import * as uiHelpers from "../RefundRequestUIHelpers";
import * as columnFormatters from "./column-formatters";
import { useRefundRequestUIContext } from "../RefundRequestUIContext";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { Pagination } from "../../../../../_metronic/_partials/controls";
import { DateTimeColumnFormatter } from "../../../../../utils/DateTimeColumnFormatter";

export const RefundRequestTable = () => {
  // Users UI Context
  const refundRequestUIContext = useRefundRequestUIContext();
  const refundRequestUIProps = useMemo(() => {
    return {
      ids: refundRequestUIContext.ids,
      setIds: refundRequestUIContext.setIds,
      queryParams: refundRequestUIContext.queryParams,
      setQueryParams: refundRequestUIContext.setQueryParams,
      toRefundRequestDetailPage:
        refundRequestUIContext.toRefundRequestDetailPage,
    };
  }, [refundRequestUIContext]);

  // Get Current State of Users from Store
  const { currentState } = useSelector(
    (state) => ({ currentState: state.refundRequest }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;

  // Users Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      refundRequestUIProps.setIds([]);
      dispatch(actions.fetchRefundRequests(refundRequestUIProps.queryParams));
    }, 300);

    return () => clearTimeout(delayDebounceFn);

    // eslint-disable-next-line
  }, [refundRequestUIProps.queryParams, dispatch]);

  const columns = [
    {
      dataField: "is_read",
      text: "",
      formatter: columnFormatters.IdReadColumnFormatter,
    },
    {
      dataField: "id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "name",
      text: "User's Name",
      sort: false,
    },
    {
      dataField: "email",
      text: "User's Email",
      sort: false,
    },
    {
      dataField: "phone_number",
      text: "Phone Number",
      sort: false,
    },
    {
      dataField: "created_at",
      text: "Date of Filling",
      formatter: columnFormatters.TransactionDateColumnFormatter,
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "updated_at",
      text: "Updated At",
      sort: false,
      sortCaret: sortCaret,
      formatter: DateTimeColumnFormatter,
      style: () => ({
        minWidth: "150px",
      }),
    },
    {
      dataField: "payment_method",
      text: "Payment Method",
      sort: false,
      sortCaret: sortCaret,
      formatter: columnFormatters.PaymentMethodFormatter,
    },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: refundRequestUIProps.queryParams.pageSize,
    page: refundRequestUIProps.queryParams.pageNumber,
  };

  const tableRowEvents = {
    onClick: (e, row, rowIndex) => {
      refundRequestUIProps.toRefundRequestDetailPage(row.id);
    },
    onMouseEnter: (e, row, rowIndex) => {
      // console.log(`enter on row with index: ${rowIndex}`);
    },
  };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                bordered={false}
                remote
                keyField="id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  refundRequestUIProps.setQueryParams
                )}
                rowStyle={{
                  cursor: "pointer",
                }}
                rowEvents={tableRowEvents}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
};
