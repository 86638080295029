export const defaultSorted = [{ dataField: "created_at", order: "desc" }];
export const sizePerPageList = [
  { text: "3", value: 3 },
  { text: "5", value: 5 },
  { text: "10", value: 10 },
];

export const initialFilter = {
  filter: {
    start_date: "",
    end_date: "",
    phrase: "",
    type: { value: "", label: "All" },
  },
  sortOrder: "desc", // asc||desc
  sortField: "created_at",
  pageNumber: 1,
  pageSize: 10,
};
