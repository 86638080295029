import React from "react";

export const Avatar = ({ size, image_link }) => {
  return (
    <div
      style={{
        width: size,
        height: size,
        minWidth: size,
        minHeight: size,
        backgroundImage: `url(${image_link})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        borderRadius: "50%",
      }}
    />
  );
};
