import { REFUND_REQUEST, REFUND_REQUESTS } from "../../../../graphql";
import { toSnackUpperCase } from "../../../../utils/case";
import { apolloClient } from "../../../apollo/client";

export function findRefundRequests(queryParams) {
  return apolloClient.query({
    query: REFUND_REQUESTS,
    variables: {
      page: queryParams.pageNumber,
      limit: queryParams.pageSize,
      start_date: queryParams.filter.start_date,
      end_date: queryParams.filter.end_date,
      phrase: queryParams.filter.phrase,
      event_ids: queryParams.filter.event_ids
        ? queryParams.filter.event_ids.map((e) => parseInt(e.id))
        : null,
      payment_methods: queryParams.filter.payment_methods
        ? queryParams.filter.payment_methods.map((e) => {
            return toSnackUpperCase(e.id);
          })
        : null,
      sort_by: queryParams.sortField.toUpperCase(),
      sort_order: queryParams.sortOrder.toUpperCase(),
    },
  });
}

export function findRefundRequest(id) {
  return apolloClient.query({
    query: REFUND_REQUEST,
    variables: {
      id: id,
    },
  });
}
