import React, { useMemo } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  Card,
  CardHeader,
  Input,
} from "../../../../../_metronic/_partials/controls";
import ModalCropper from "../../../../../utils/ModalCropper";
import AsteriskLabel from "../../../../../utils/Asterisk";

const SUPPORTED_FORMATS = ["image/png", "image/jpg", "image/jpeg"];

export function checkIfFilesAreCorrectType(file) {
  if (SUPPORTED_FORMATS.includes(file.type)) {
    return true;
  }
  return false;
}

export function NewsEditForm({ news, saveNews, isEditing, onHide }) {
  // validation schema
  const NewsEditSchema = useMemo(
    () =>
      Yup.object().shape({
        title: Yup.string()
          .trim()
          .max(150, "Maximum 150 symbols")
          .required("Title is required"),
        image: !isEditing
          ? Yup.mixed().test(
              "file-type",
              "Invalid Image file type",
              (value) => {
                if (value === null) {
                  return false;
                }
                return checkIfFilesAreCorrectType(value);
              }
            )
          : Yup.mixed().test(
              "file-type",
              "Invalid Image file type",
              (value) => {
                if (value === null) {
                  return true;
                }
                return checkIfFilesAreCorrectType(value);
              }
            ),
        body_html: Yup.string()
          .min(150, "Minimum 150 characters")
          .required("Content is required"),
      }),
    //eslint-disable-next-line
    []
  );

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...news,
          image_link: news.image_link ? news.image_link : null,
          backup_image: null,
        }}
        validationSchema={NewsEditSchema}
        onSubmit={(values) => {
          saveNews(values);
        }}
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <>
            <Form className="form form-label-right row">
              <div className="col-lg-6">
                <Card className="px-2">
                  <CardHeader title={"General"} />
                  <div className="form-group flex flex-wrap pt-4">
                    <div className="col-lg-12 mb-4">
                      <Field
                        name="title"
                        component={Input}
                        placeholder="Title"
                        label={<AsteriskLabel word="Title" />}
                        rows={5}
                      />
                    </div>
                    <div className="col-lg-12 mb-4">
                      <label>{<AsteriskLabel word="Headline Image" />}</label>
                      <br />
                      <ModalCropper
                        onSaved={(blob, file) => {
                          setFieldValue("image", file);
                        }}
                        initialValue={values.image_link}
                        aspectRatio={3 / 4}
                        width={300}
                        height={400}
                        cropperHeight={"400px"}
                        cropperWidth={"300px"}
                        modalTitle={"News Image"}
                      />
                      <ErrorMessage name={"image"}>
                        {(msg) => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                  </div>
                </Card>
              </div>

              <div className="col-lg-6">
                <Card className="px-2">
                  <CardHeader title={"Additional Information"} />
                  <div className="form-group flex flex-wrap pt-4">
                    <div className="col-lg-12 mb-4">
                      <label>{<AsteriskLabel word="News Content" />}</label>
                      <ReactQuill
                        theme="snow"
                        value={values.body_html}
                        onChange={(val) => {
                          setFieldValue("body_html", val);
                        }}
                      />
                      <ErrorMessage
                        className="text-danger"
                        name="body_html"
                        component="div"
                      />
                    </div>
                  </div>
                </Card>
              </div>
            </Form>

            <Card className="_card-toolbar-start">
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
            </Card>
          </>
        )}
      </Formik>
    </>
  );
}
