import React, { useMemo, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import * as actions from "../../../_redux/locations/locationsActions";
import BootstrapTable from "react-bootstrap-table-next";
import moment from "moment";
import {
  // getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import { useLocationsUIContext } from "../LocationsUIContext";
import * as uiHelpers from "../LocationsUIHelpers";
import * as columnFormatters from "./column-formatters";

export const LocationsTable = () => {
  const locationsUIContext = useLocationsUIContext();
  const locationsUIProps = useMemo(() => {
    return {
      ids: locationsUIContext.ids,
      setIds: locationsUIContext.setIds,
      queryParams: locationsUIContext.queryParams,
      setQueryParams: locationsUIContext.setQueryParams,
      openEditLocationPage: locationsUIContext.openEditLocationPage,
      openDeleteLocationDialog: locationsUIContext.openDeleteLocationDialog,
    };
  }, [locationsUIContext]);

  const { currentState } = useSelector(
    (state) => ({ currentState: state.locations }),
    shallowEqual
  );
  const { entities } = currentState;

  const dispatch = useDispatch();
  useEffect(() => {
    locationsUIProps.setIds([]);
    dispatch(actions.fetchLocations(locationsUIProps.queryParams));

    // eslint-disable-next-line
  }, [locationsUIProps.queryParams, dispatch]);

  const columns = [
    // {
    //   dataField: "id",
    //   text: "ID",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   headerSortingClasses,
    //   headerStyle: () => {
    //     return { width: "100px" };
    //   },
    // },
    {
      dataField: "name",
      text: "Name",
      sort: false,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "building_name",
      text: "Building Name",
      sort: false,
      sortCaret: sortCaret,
    },
    {
      dataField: "street_name",
      text: "Street Name",
      sort: false,
      sortCaret: sortCaret,
    },
    {
      dataField: "block_no",
      text: "Block No",
      sort: false,
      sortCaret: sortCaret,
    },
    {
      dataField: "unit_no",
      text: "Unit No",
      sort: false,
      sortCaret: sortCaret,
    },
    {
      dataField: "level_no",
      text: "Level No",
      sort: false,
      sortCaret: sortCaret,
    },
    {
      dataField: "region",
      text: "Region",
      formatter: columnFormatters.RegionColumnFormatter,
    },
    {
      dataField: "updated_at",
      text: "Updated At",
      sort: false,
      formatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD HH:mm:ss") : "-",
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditLocationPage: locationsUIProps.openEditLocationPage,
        openDeleteLocationDialog: locationsUIProps.openDeleteLocationDialog,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  return (
    <>
      <BootstrapTable
        wrapperClasses="table-responsive"
        bordered={false}
        classes="table table-head-custom table-vertical-center overflow-hidden"
        bootstrap4
        remote
        keyField="id"
        data={entities === null ? [] : entities}
        columns={columns}
        defaultSorted={uiHelpers.defaultSorted}
        onTableChange={getHandlerTableChange(locationsUIProps.setQueryParams)}
        // selectRow={getSelectRow({
        //   entities,
        //   ids: locationsUIProps.ids,
        //   setIds: locationsUIProps.setIds,
        // })}
        // {...paginationTableProps}
      >
        <PleaseWaitMessage entities={entities} />
        <NoRecordsFoundMessage entities={entities} />
      </BootstrapTable>
    </>
  );
};
