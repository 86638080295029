import React from "react";

export const EllipsisColumnFormatter = (cell, row) => {
  return (
    <div
      style={{ textOverflow: "ellipsis" }}
      dangerouslySetInnerHTML={{
        __html: cell,
      }}></div>
  );
};
