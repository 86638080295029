import {
  EVENT_CREATE,
  EVENT_PAGINATE,
  EVENT_BY_ID,
  EVENT_UPDATE,
  EVENT_DELETE,
  EVENT_CANCEL,
} from "../../../../../../graphql";
import { apolloClient } from "../../../../../apollo/client";
import moment from "moment";

export const createEvent = (event) => {
  return apolloClient.mutate({
    mutation: EVENT_CREATE,
    variables: {
      class_id: event.class.id,
      studio_id: event.studio.id,
      instructor_id: event.instructor.id,
      date: event.date,
      start_time:
        event.start_time + (event.start_time.length === 5 ? ":00" : ""),
      end_time: event.end_time + (event.end_time.length === 5 ? ":00" : ""),
      note_html: event.note_html !== "" ? event.note_html : null,
      participant_limit: event.participant_limit,
      participant_count: event.participant_count,
      studio_price: event.has_studio ? event.studio_price : null,
      studio_credit: event.has_studio ? event.studio_credit : null,
      virtual_price: event.has_virtual ? event.virtual_price : null,
      virtual_credit: event.has_virtual ? event.virtual_credit : null,
      zoom_url: event.zoom_url === "" ? null : event.zoom_url,
      zoom_pass_code: event.zoom_pass_code === "" ? null : event.zoom_pass_code,
      accept_punch_card: event.accept_punch_card,
      is_special_class: event.is_special_class,
      has_studio: event.has_studio,
      has_virtual: event.has_virtual,
    },
  });
};

export const updateEvent = (event) => {
  return apolloClient.mutate({
    mutation: EVENT_UPDATE,
    variables: {
      id: event.id,
      class_id: event.class.id,
      studio_id: event.studio.id,
      instructor_id: event.instructor.id,
      date: event.date,
      start_time:
        event.start_time + (event.start_time.length === 5 ? ":00" : ""),
      end_time: event.end_time + (event.end_time.length === 5 ? ":00" : ""),
      note_html: event.note_html !== "" ? event.note_html : null,
      participant_limit: event.participant_limit,
      participant_count: event.participant_count,
      studio_price: event.has_studio ? event.studio_price : null,
      studio_credit: event.has_studio ? event.studio_credit : null,
      virtual_price: event.has_virtual ? event.virtual_price : null,
      virtual_credit: event.has_virtual ? event.virtual_credit : null,
      zoom_url: event.zoom_url === "" ? null : event.zoom_url,
      accept_punch_card: event.accept_punch_card,
      is_special_class: event.is_special_class,
      has_studio: event.has_studio,
      has_virtual: event.has_virtual,
    },
  });
};

export const getEventById = (eventId) => {
  return apolloClient.query({
    query: EVENT_BY_ID,
    variables: {
      id: eventId,
    },
  });
};

export const getAllEvents = () => {
  return apolloClient.query({
    query: EVENT_PAGINATE,
  });
};

export const findEvents = (queryParams) => {
  return apolloClient.query({
    query: EVENT_PAGINATE,
    variables: {
      page: queryParams.pageNumber,
      limit: queryParams.pageSize,
      sort_by: queryParams.sortField?.toUpperCase(),
      sort_order: queryParams.sortOrder?.toUpperCase(),
      class_ids:
        queryParams.filter.classes && queryParams.filter.classes.length > 0
          ? queryParams.filter.classes.map((item) => parseInt(item.id))
          : null,
      studio_ids:
        queryParams.filter.studios && queryParams.filter.studios.length > 0
          ? queryParams.filter.studios.map((item) => parseInt(item.id))
          : null,
      instructor_ids:
        queryParams.filter.instructors &&
        queryParams.filter.instructors.length > 0
          ? queryParams.filter.instructors.map((item) => parseInt(item.id))
          : null,
      status:
        queryParams.filter.status && queryParams.filter.status.length > 0
          ? queryParams.filter.status.map((item) => item.name)
          : null,
      start_date:
        queryParams.filter.start_date === ""
          ? null
          : moment(queryParams.filter.start_date).format("YYYY-MM-DD"),
      end_date:
        queryParams.filter.end_date === ""
          ? null
          : moment(queryParams.filter.end_date).format("YYYY-MM-DD"),
      start_time:
        queryParams.filter.start_time === ""
          ? null
          : queryParams.filter.start_time,
      end_time:
        queryParams.filter.end_time === "" ? null : queryParams.filter.end_time,
      participant_limit: queryParams.filter.participant_limit,
      // participant_waiting_limit: queryParams.filter.participant_waiting_limit,
      has_studio:
        queryParams.filter.has_studio.value === ""
          ? null
          : queryParams.filter.has_studio.value === "true",
      has_virtual:
        queryParams.filter.has_virtual.value === ""
          ? null
          : queryParams.filter.has_virtual.value === "true",
      accept_punch_card:
        queryParams.filter.accept_punch_card.value === ""
          ? null
          : queryParams.filter.accept_punch_card.value === "true",
      is_special_class:
        queryParams.filter.is_special_class.value === ""
          ? null
          : queryParams.filter.is_special_class.value === "true",
    },
  });
};

export const deleteEvent = (eventId) => {
  return apolloClient.mutate({
    mutation: EVENT_DELETE,
    variables: {
      id: eventId,
    },
  });
};

export const cancelEvent = (eventId) => {
  return apolloClient.mutate({
    mutation: EVENT_CANCEL,
    variables: {
      id: eventId,
    },
  });
};
