export const defaultSorted = [{ dataField: "updated_at", order: "desc" }];
export const sizePerPageList = [
  { text: "3", value: 3 },
  { text: "5", value: 5 },
  { text: "10", value: 10 },
];

export const initialFilter = {
  filter: {
    phrase: null,
    start_date: null,
    end_date: null,
  },
  pageNumber: 1,
  pageSize: 10,
  sortOrder: "desc", // asc||desc
  sortField: "updated_at",
};
