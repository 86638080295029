import {
  CREDIT_TYPE_PAGINATE,
  CREDIT_TYPE_GET_BY_ID,
  CREDIT_TYPE_CREATE,
  CREDIT_TYPE_UPDATE,
  CREDIT_TYPE_DELETE,
} from "../../../../../graphql";
import { apolloClient } from "../../../../apollo/client";

export const createCreditType = (creditType) => {
  return apolloClient.mutate({
    mutation: CREDIT_TYPE_CREATE,
    variables: {
      name: creditType.name,
      credit: creditType.credit,
      price: creditType.price,
    },
  });
};

export const updateCreditType = (creditType) => {
  return apolloClient.mutate({
    mutation: CREDIT_TYPE_UPDATE,
    variables: {
      id: creditType.id,
      name: creditType.name,
      credit: creditType.credit,
      price: creditType.price,
    },
  });
};

export const getCreditTypeById = (creditTypeId) => {
  return apolloClient.query({
    query: CREDIT_TYPE_GET_BY_ID,
    variables: {
      id: creditTypeId,
    },
  });
};

export const getAllCreditTypes = () => {
  return apolloClient.query({
    query: CREDIT_TYPE_PAGINATE,
  });
};

export const findCreditTypes = (queryParams) => {
  return apolloClient.query({
    query: CREDIT_TYPE_PAGINATE,
  });
};

export const deleteCreditType = (creditTypeId) => {
  return apolloClient.mutate({
    mutation: CREDIT_TYPE_DELETE,
    variables: {
      id: creditTypeId,
    },
  });
};
