import * as requestFromServer from "./locationsCrud";
import { locationsSlice, callTypes } from "./locationsSlice";

const { actions } = locationsSlice;

export const createLocation = (location) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createLocation(location)
    .then((response) => {
      const {
        data: {
          location: { create },
        },
      } = response;
      dispatch(actions.locationCreated({ location: create }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      throw error;
    });
};

export const updateLocation = (location) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateLocation(location)
    .then((response) => {
      const {
        data: {
          location: { update },
        },
      } = response;
      dispatch(actions.locationUpdated({ location: update }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      throw error;
    });
};

export const fetchLocations = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findLocations(queryParams)
    .then((response) => {
      const {
        data: { locations },
      } = response;
      dispatch(
        actions.locationsFetched({
          totalCount: locations.length,
          entities: locations,
        })
      );
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchLocationsAll = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllLocations()
    .then((response) => {
      const {
        data: {
          locations: { total, nodes },
        },
      } = response;
      dispatch(
        actions.locationsFetched({
          totalCount: total,
          entities: nodes,
        })
      );
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchLocation = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.locationFetched({ locationForEdit: undefined }));
  }
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getLocationById(id)
    .then((response) => {
      const {
        data: { location },
      } = response;
      dispatch(
        actions.locationFetched({
          locationForEdit: {
            ...location,
            long_location: `${location.name || ""} ${location.street_name ||
              ""} ${location.block_no || ""}#${location.level_no ||
              ""}-${location.unit_no || ""},${location.building_name ||
              ""} Singapore ${location.postal_code || ""}`,
          },
        })
      );
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteLocation = (id) => (dispatch) => {
  if (!id) {
    return;
  }
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteLocation(id)
    .then((_) => {
      dispatch(actions.locationDeleted({ id: id }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      throw error;
    });
};
