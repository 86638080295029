import React from "react";
import { Modal } from "react-bootstrap";
import { UserContactDetailContactUs } from "./user-contact-detail-contact-us/UserContactDetailContactUs";
import { UserContactDetailCompanyContractRequest } from "./user-contact-detail-company-contract-request/UserContactDetailCompanyContractRequest";
import { UserContactDetailRentStudio } from "./user-contact-detail-rent-studio/UserContactDetailRentStudio";
import { UserContactDetailVacancy } from "./user-contact-detail-vacancy/UserContactDetailVacancy";
import { UserContactDetailPartyContractRequest } from "./user-contact-detail-party-contract-request/UserContactDetailPartyContractRequest";

export const UserContactDetail = ({ actionsLoading, userContactForEdit }) => {
  return (
    <>
      <Modal.Body className="overlay overlay-block cursor-default">
        {actionsLoading ? (
          <div className="overlay-layer bg-transparent">
            <div className="spinner spinner-lg spinner-success"></div>
          </div>
        ) : (
          userContactForEdit &&
          {
            CONTACT_US: (
              <UserContactDetailContactUs
                userContactForEdit={userContactForEdit}
              />
            ),
            COMPANY_CONTRACT_REQUEST: (
              <UserContactDetailCompanyContractRequest
                userContactForEdit={userContactForEdit}
              />
            ),
            RENT_STUDIO: (
              <UserContactDetailRentStudio
                userContactForEdit={userContactForEdit}
              />
            ),
            VACANCY: (
              <UserContactDetailVacancy
                userContactForEdit={userContactForEdit}
              />
            ),
            PARTY_CONTRACT_REQUEST: (
              <UserContactDetailPartyContractRequest
                userContactForEdit={userContactForEdit}
              />
            ),
          }[userContactForEdit.type]
          // <>
          //   <div className="row">
          //     <div className="col-lg-4">
          //       <h5 className="font-bold">User Details</h5>
          //       <h6>Name</h6>
          //       <p>{userContactForEdit.name}</p>
          //       <h6>Email</h6>
          //       <p>{userContactForEdit.email}</p>
          //       <h6>Phone Number</h6>
          //       <p>{userContactForEdit.phone_number || "-"}</p>
          //     </div>
          //     <div className="col-lg-4">
          //       <h5 className="font-bold">Details</h5>
          //       <h6>Type</h6>
          //       <p>{toTitleCaseSpace(userContactForEdit.type)}</p>
          //       <h6>Number of Sessions</h6>
          //       <p>{userContactForEdit.number_of_sessions || "-"}</p>
          //       <h6>Sound System</h6>
          //       <p>
          //         {userContactForEdit.has_sound_system ? (
          //           <i className="fa-solid fa-check"></i>
          //         ) : (
          //           <i className="fa-solid fa-x"></i>
          //         )}
          //       </p>
          //       <h6>Participant Count</h6>
          //       <p>{userContactForEdit.participant_count || "-"}</p>
          //     </div>
          //     <div className="col-lg-4">
          //       <h5 className="font-bold">Preferred</h5>
          //       <h6>DateTime</h6>
          //       <p>
          //         {userContactForEdit.preferred_datetime
          //           ? moment(userContactForEdit.preferred_datetime).format(
          //               "YYYY-MMM-DD HH:MM"
          //             )
          //           : "-"}
          //       </p>
          //       <h6>Day of Week </h6>
          //       <p>
          //         {userContactForEdit.preferred_day_of_week
          //           ? toTitleCaseSpace(
          //               userContactForEdit.preferred_day_of_week
          //             )
          //           : "-"}
          //       </p>
          //       <h6>Start Date </h6>
          //       <p>
          //         {userContactForEdit.start_date
          //           ? moment(userContactForEdit.start_date).format(
          //               "YYYY-MMM-DD HH:MM"
          //             )
          //           : "-"}
          //       </p>
          //     </div>
          //   </div>
          //   <div className="row">
          //     <div className="col=lg-12">
          //       <h6>Message</h6>
          //       <p> {userContactForEdit.message || "-"}</p>
          //     </div>
          //   </div>
          //   <div className="row">
          //     <div className="col=lg-4">
          //       <h6>Attachment</h6>
          //       {userContactForEdit.attachment_link ? (
          //         <img src={userContactForEdit.attachment_link} />
          //       ) : (
          //         "-"
          //       )}
          //     </div>
          //   </div>
          // </>
        )}
      </Modal.Body>
    </>
  );
};
