import React, { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { snackActions } from "../../../../../../utils/SnackbarUtils";
import * as actions from "../../../_redux/creditTypes/creditTypesActions";
import { useCreditTypesUIContext } from "../CreditTypesUIContext";
import { CreditTypeEditForm } from "./CreditTypeEditForm";

export const CreditTypeEdit = () => {
  const creditTypesUIContext = useCreditTypesUIContext();
  const creditTypesUIProps = useMemo(() => {
    return {
      initCreditType: creditTypesUIContext.initCreditType
    };
  }, [creditTypesUIContext]);

  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const { actionsLoading, creditTypeForEdit } = useSelector(state => {
    return {
      actionsLoading: state.credit_types.actionsLoading,
      creditTypeForEdit: state.credit_types.creditTypeForEdit
    };
  }, shallowEqual);

  useEffect(() => {
    dispatch(actions.fetchCreditType(id));
  }, [id, dispatch]);

  const saveCreditType = credit_type => {
    if (!id) {
      dispatch(actions.createCreditType(credit_type))
        .then(() => backToCreditTypeList())
        .catch(err => snackActions.error(err.message));
    } else {
      dispatch(actions.updateCreditType(credit_type))
        .then(() => backToCreditTypeList())
        .catch(err => snackActions.error(err.message));
    }
  };

  const backToCreditTypeList = () => {
    history.push(`/credit-types`);
  };

  return (
    <>
      <CreditTypeEditForm
        saveCreditType={saveCreditType}
        isEditing={id !== null && id !== undefined}
        actionsLoading={actionsLoading}
        credit_type={creditTypeForEdit || creditTypesUIProps.initCreditType}
        onHide={backToCreditTypeList}
      />
    </>
  );
};
