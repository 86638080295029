import React, { useMemo, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import * as actions from "../../../_redux/userContacts/userContactsActions";
import BootstrapTable from "react-bootstrap-table-next";
import {
  // getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { useUserContactsUIContext } from "../UserContactsUIContext";
import * as uiHelpers from "../UserContactsUIHelpers";
import * as columnFormatters from "./column-formatters";
import { DateTimeColumnFormatter } from "../../../../../../utils/DateTimeColumnFormatter";

export const UserContactsTable = () => {
  const userContactsUIContext = useUserContactsUIContext();
  const userContactsUIProps = useMemo(() => {
    return {
      ids: userContactsUIContext.ids,
      setIds: userContactsUIContext.setIds,
      queryParams: userContactsUIContext.queryParams,
      setQueryParams: userContactsUIContext.setQueryParams,
      openDetailUserContactPage:
        userContactsUIContext.openDetailUserContactPage,
    };
  }, [userContactsUIContext]);

  const { currentState } = useSelector(
    (state) => ({ currentState: state.user_contacts }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;

  const dispatch = useDispatch();
  useEffect(() => {
    userContactsUIProps.setIds([]);
    dispatch(actions.fetchUserContacts(userContactsUIProps.queryParams));

    // eslint-disable-next-line
  }, [userContactsUIProps.queryParams, dispatch]);

  const columns = [
    {
      dataField: "is_read",
      text: "",
      formatter: columnFormatters.IdReadColumnFormatter,
    },
    {
      dataField: "name",
      text: "Name",
      sort: false,
      style: () => ({
        minWidth: "150px",
      }),
    },
    {
      dataField: "email",
      text: "Email",
      sort: false,
    },
    {
      dataField: "phone_number",
      text: "Phone Number",
      sort: false,
    },
    {
      dataField: "type",
      text: "Type",
      sort: false,
      formatter: columnFormatters.CapitalizeColumnFormatter,
      style: () => ({
        minWidth: "100px",
      }),
    },
    {
      dataField: "created_at",
      text: "Created At",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: DateTimeColumnFormatter,
      style: () => ({
        minWidth: "150px",
      }),
    },
    {
      dataField: "updated_at",
      text: "Updated At",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: DateTimeColumnFormatter,
      style: () => ({
        minWidth: "150px",
      }),
    },
  ];

  // Table Pagination Properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: userContactsUIProps.queryParams.pageSize,
    page: userContactsUIProps.queryParams.pageNumber,
  };

  const tableRowEvents = {
    onClick: (e, row) => {
      userContactsUIProps.openDetailUserContactPage(row.id);
    },
  };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  userContactsUIProps.setQueryParams
                )}
                rowEvents={tableRowEvents}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
};
