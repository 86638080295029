import {
  LOCATION_GET_ALL,
  LOCATION_GET_BY_ID,
  LOCATION_CREATE,
  LOCATION_UPDATE,
  LOCATION_DELETE,
} from "../../../../../graphql";
import { apolloClient } from "../../../../apollo/client";

export const createLocation = (location) => {
  return apolloClient.mutate({
    mutation: LOCATION_CREATE,
    variables: {
      name: location.name,
      building_name:
        location.building_name !== "" ? location.building_name : null,
      street_name: location.street_name,
      block_no: location.block_no,
      unit_no: location.unit_no !== "" ? location.unit_no : null,
      level_no: location.level_no !== "" ? location.level_no : null,
      postal_code: location.postal_code,
      longitude: location.longitude,
      latitude: location.latitude,
      notes_html: location.notes_html !== "" ? location.notes_html : null,
      region: location.region,
    },
  });
};

export const updateLocation = (location) => {
  return apolloClient.mutate({
    mutation: LOCATION_UPDATE,
    variables: {
      id: location.id,
      name: location.name,
      building_name:
        location.building_name !== "" ? location.building_name : null,
      street_name: location.street_name,
      block_no: location.block_no,
      unit_no: location.unit_no !== "" ? location.unit_no : null,
      level_no: location.level_no !== "" ? location.level_no : null,
      postal_code: location.postal_code,
      longitude: location.longitude,
      latitude: location.latitude,
      notes_html: location.notes_html !== "" ? location.notes_html : null,
      region: location.region,
    },
  });
};

export const getLocationById = (locationId) => {
  return apolloClient.query({
    query: LOCATION_GET_BY_ID,
    variables: {
      id: locationId,
    },
  });
};

export const getAllLocations = () => {
  return apolloClient.query({
    query: LOCATION_GET_ALL,
  });
};

export const findLocations = (queryParams) => {
  return apolloClient.query({
    query: LOCATION_GET_ALL,
    variables: {
      phrase:
        queryParams.filter.phrase === "" ? null : queryParams.filter.phrase,
    },
  });
};

export const deleteLocation = (locationId) => {
  return apolloClient.mutate({
    mutation: LOCATION_DELETE,
    variables: {
      id: locationId,
    },
  });
};
