import React from "react";
import { Route, Switch } from "react-router-dom";
import { InstructorsUIProvider } from "./InstructorsUIContext";
import { InstructorsCard } from "./InstructorsCard";
import { InstructorsLoadingDialog } from "./instructors-loading-dialog/InstructorsLoadingDialog";
import { InstructorEditPage } from "./instructor-edit-dialog/InstructorEditPage";
import { InstructorDeleteDialog } from "./instructor-delete-dialog/InstructorDeleteDialog";

export function InstructorsPage({ history }) {
  const basePath = '/instructors';
  const instructorsUIEvents = {
    newInstructorButtonClick: () => {
      history.push(`${basePath}/new`);
    },
    openEditInstructorPage: (id) => {
      history.push(`${basePath}/${id}/edit`);
    },
    openDeleteInstructorDialog: (id) => {
      history.push(`${basePath}/${id}/delete`);
    },
  };

  return (
    <InstructorsUIProvider instructorsUIEvents={instructorsUIEvents}>
      <InstructorsLoadingDialog />
      <Switch>
        <Route path={`${basePath}/new`}>
          {({ history, match }) => match != null && (
            <InstructorEditPage
              onHide={() => {
                history.push(basePath);
              }}
            />
          )}
        </Route>
        <Route path={`${basePath}/:id/edit`}>
          {({ history, match }) => match != null &&  (
            <InstructorEditPage
              id={match && match.params.id}
              onHide={() => {
                history.push(basePath);
              }}
            />
          )}
        </Route>
        <Route path={basePath}>
              {({match}) => match != null && <InstructorsCard />}
        </Route>
      </Switch>

        <Route path={`/instructors/:id/delete`}>
          {({ history, match }) =>  match != null && (
            <InstructorDeleteDialog
              show={match != null}
              id={match && match.params.id}
              onHide={() => {
                history.push(basePath);
              }}
            />
          )}
        </Route>
    </InstructorsUIProvider>
  );
}
