import React, { useMemo } from "react";
import { Formik } from "formik";
import { isEqual } from "lodash";
import { useNewsUIContext } from "../NewsUIContext";
import { TextField } from "@material-ui/core";

const prepareFilter = (queryParams, values) => {
  const { searchText, startDate, endDate } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};

  // searching filter
  if (searchText) {
    filter.phrase = searchText;
  }

  if(startDate) {
    filter.start_date = startDate;
  }

  if(endDate) {
    filter.end_date = endDate;
  }

  newQueryParams.filter = filter;
  return newQueryParams;
};

export const NewsFilter = () => {

  const newsUIContext = useNewsUIContext();
  const newsUIProps = useMemo(() => {
    return {
      queryParams: newsUIContext.queryParams,
      setQueryParams: newsUIContext.setQueryParams,
    };
  }, [newsUIContext]);

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(newsUIProps.queryParams, values);
    if (!isEqual(newQueryParams, newsUIProps.queryParams)) {
      newsUIProps.setQueryParams(newQueryParams);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          searchText: newsUIProps.queryParams.filter.phrase,
          startDate: newsUIProps.queryParams.filter.start_date,
          endDate: newsUIProps.queryParams.filter.end_date,
        }}
        onSubmit={(values) => {
          applyFilter(values);
        }}
      >
        {({
          values,
          handleSubmit,
          handleBlur,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">
              <div className="col-lg-6">
                <input
                  type="text"
                  className="form-control"
                  name="searchText"
                  placeholder="Search"
                  onBlur={handleBlur}
                  value={values.searchText}
                  onChange={(e) => {
                    setFieldValue("searchText", e.target.value);
                    handleSubmit();
                  }}
                />
              </div>
              <div className="col-lg-3">
                <TextField
                  id="date"
                  name="startDate"
                  label="Start Date"
                  type="date"  
                  style={ { width: "100%" } } 
                  value={values.startDate}           
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={(e) => {
                    setFieldValue("startDate", e.target.value);
                    handleSubmit();
                  }}
                />
              </div>
              <div className="col-lg-3">
                <TextField
                  id="date"
                  name="endDate"
                  label="End Date"
                  value={values.endDate}
                  style={ { width: "100%" } }
                  type="date"                 
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={(e) => {
                    setFieldValue("endDate", e.target.value);
                    handleSubmit();
                  }}
                />
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}