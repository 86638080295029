import * as requestFromServer from "./classesCrud";
import { classesSlice, callTypes } from "./classesSlice";

const { actions } = classesSlice;

export const createClass = (classData) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createClass(classData)
    .then((response) => {
      const {
        data: {
          class: { create },
        },
      } = response;
      dispatch(actions.classCreated({ class: create }));
    })
    .catch((error) => {
      dispatch(actions.catchErrors({ error, callType: callTypes.action }));
    });
};

export const updateClass = (classData) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateClass(classData)
    .then((response) => {
      const {
        data: {
          class: { update },
        },
      } = response;
      dispatch(actions.classUpdated({ class: update }));
    })
    .catch((error) => {
      dispatch(actions.catchErrors({ error, callType: callTypes.action }));
    });
};

export const fetchClasses = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findClasses(queryParams)
    .then((response) => {
      const { total, nodes } = response.data.classes;
      dispatch(actions.classesFetched({ totalCount: total, entities: nodes }));
    })
    .catch((error) => {
      dispatch(actions.catchErrors({ error, callType: callTypes.list }));
    });
};

export const fetchClass = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.classFetched({ classForEdit: undefined }));
  }
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getClassById(id)
    .then((response) => {
      const {
        data: { class: classData },
      } = response;
      dispatch(actions.classFetched({ classForEdit: classData }));
    })
    .catch((error) => {
      dispatch(actions.catchErrors({ error, callType: callTypes.action }));
    });
};

export const fetchClassesAll = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllClasses()
    .then((response) => {
      const { total, nodes } = response.data.classes;
      dispatch(actions.classesFetched({ totalCount: total, entities: nodes }));
    })
    .catch((error) => {
      dispatch(actions.catchErrors({ error, callType: callTypes.list }));
    });
};

export const deleteClass = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteClass(id)
    .then((_) => {
      dispatch(actions.classDeleted({ id: id }));
    })
    .catch((error) => {
      dispatch(actions.catchErrors({ error, callType: callTypes.action }));
    });
};
