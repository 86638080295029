import React, { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { snackActions } from "../../../../../../utils/SnackbarUtils";
import * as actions from "../../../_redux/locations/locationsActions";
import { useLocationsUIContext } from "../LocationsUIContext";
import { LocationEditForm } from "./LocationEditForm";

export const LocationEdit = () => {
  const locationsUIContext = useLocationsUIContext();
  const locationsUIProps = useMemo(() => {
    return {
      initLocation: locationsUIContext.initLocation
    };
  }, [locationsUIContext]);

  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const { actionsLoading, locationForEdit } = useSelector(state => {
    return {
      actionsLoading: state.locations.actionsLoading,
      locationForEdit: state.locations.locationForEdit
    };
  }, shallowEqual);

  useEffect(() => {
    dispatch(actions.fetchLocation(id));
  }, [id, dispatch]);

  const saveLocation = location => {
    if (!id) {
      dispatch(actions.createLocation(location))
        .then(() => backToLocationList())
        .catch(err => snackActions.error(err.message));
    } else {
      dispatch(actions.updateLocation(location))
        .then(() => backToLocationList())
        .catch(err => snackActions.error(err.message));
    }
  };

  const backToLocationList = () => {
    history.push(`/locations`);
  };

  return (
    <>
      <LocationEditForm
        saveLocation={saveLocation}
        isEditing={id !== null && id !== undefined}
        actionsLoading={actionsLoading}
        location={locationForEdit || locationsUIProps.initLocation}
        onHide={backToLocationList}
      />
    </>
  );
};
