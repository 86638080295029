import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./CreditTypesUIHelpers";

const creditTypesUIContext = createContext();

export const useCreditTypesUIContext = () => {
  return useContext(creditTypesUIContext);
};

export const CreditTypesUIConsumer = creditTypesUIContext.Consumer;

export const CreditTypesUIProvider = ({ creditTypesUIEvents, children }) => {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      return isEqual(prevQueryParams, nextQueryParams)
        ? prevQueryParams
        : nextQueryParams;
    });
  }, []);

  const initCreditType = {
    id: undefined,
    name: "",
    credit: 1,
    price: 1,
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initCreditType,
    newCreditTypeButtonClick: creditTypesUIEvents.newCreditTypeButtonClick,
    openEditCreditTypePage: creditTypesUIEvents.openEditCreditTypePage,
    openDeleteCreditTypeDialog: creditTypesUIEvents.openDeleteCreditTypeDialog,
  };

  return (
    <creditTypesUIContext.Provider value={value}>
      {children}
    </creditTypesUIContext.Provider>
  );
};
