import React from "react";
import { Route, Switch } from "react-router-dom";
import { CreditTypesUIProvider } from "./CreditTypesUIContext";
import { CreditTypesCard } from "./CreditTypesCard";
import { CreditTypesLoadingDialog } from "./credit-types-loading-dialog/CreditTypesLoadingDialog";
import { CreditTypeEdit } from "./credit-type-edit/CreditTypeEdit";

export function CreditTypesPage({ history }) {
  const basePath = "/credit-types";

  const creditTypesUIEvents = {
    newCreditTypeButtonClick: () => {
      history.push(`${basePath}/new`);
    },
    openEditCreditTypePage: (id) => {
      history.push(`${basePath}/${id}/edit`);
    },
    openDeleteCreditTypeDialog: (id) => {
      history.push(`${basePath}/${id}/delete`);
    },
  };

  return (
    <CreditTypesUIProvider creditTypesUIEvents={creditTypesUIEvents}>
      <CreditTypesLoadingDialog />
      <Switch>
        <Route path={`${basePath}/new`}>
          {({ match }) => {
            return match != null && <CreditTypeEdit />;
          }}
        </Route>

        <Route path={`${basePath}/:id/edit`}>
          {({ match }) => {
            return match != null && <CreditTypeEdit />;
          }}
        </Route>

        <Route path={`${basePath}`}>
          {({ match }) => {
            return match != null && <CreditTypesCard />;
          }}
        </Route>
      </Switch>
    </CreditTypesUIProvider>
  );
}
