import {
  USER_CONTACT_PAGINATE,
  USER_CONTACT_BY_ID,
} from "../../../../../graphql";
import { apolloClient } from "../../../../apollo/client";
import moment from "moment";

export const getUserContactById = (eventRecurringId) => {
  return apolloClient
    .query({
      query: USER_CONTACT_BY_ID,
      variables: {
        id: eventRecurringId,
      },
    })
    .then((res) => {
      apolloClient.writeQuery({
        query: USER_CONTACT_BY_ID,
        data: {
          user_contact: { ...res.data.user_contact, is_read: true },
        },
        variables: {
          id: eventRecurringId,
        },
      });

      return res;
    });
};

export const getAllUserContacts = () => {
  return apolloClient.query({
    query: USER_CONTACT_PAGINATE,
  });
};

export const findUserContacts = (queryParams) => {
  return apolloClient.query({
    query: USER_CONTACT_PAGINATE,
    variables: {
      page: queryParams.pageNumber,
      limit: queryParams.pageSize,
      sort_by: queryParams.sortField?.toUpperCase(),
      sort_order: queryParams.sortOrder?.toUpperCase(),
      phrase:
        queryParams.filter.phrase === "" ? null : queryParams.filter.phrase,
      type:
        queryParams.filter.type.value === ""
          ? null
          : queryParams.filter.type.value,
      start_date:
        queryParams.filter.start_date === ""
          ? null
          : queryParams.filter.start_date !== null
          ? moment(queryParams.filter.start_date).format("YYYY-MM-DD")
          : queryParams.filter.start_date,
      end_date:
        queryParams.filter.end_date === ""
          ? null
          : queryParams.filter.end_date !== null
          ? moment(queryParams.filter.end_date).format("YYYY-MM-DD")
          : queryParams.filter.end_date,
    },
  });
};
