import React, { useMemo } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  Card,
  CardHeader,
  Input
} from "../../../../../../_metronic/_partials/controls";
import RequirementIconPicker from "../requirement-icon-picker/RequirementsIconPicker";
import AsteriskLabel from "../../../../../../utils/Asterisk";

export const RequirementEditForm = ({
  saveRequirement,
  requirement,
  actionsLoading,
  onHide,
  isEditing
}) => {
  // validation schema
  const RequirementEditSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string()
          .trim()
          .max(100, "Maximum 100 symbols")
          .matches(/^[0-9a-zA-Z ]*$/, "Only AlphaNumeric and Space")
          .required("Name is required"),
        image_path: Yup.string()
          .trim()
          .required("Please Select Icon")
      }),
    //eslint-disable-next-line
    []
  );

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...requirement
        }}
        validationSchema={RequirementEditSchema}
        onSubmit={values => {
          saveRequirement(values);
        }}
      >
        {({ handleSubmit, setFieldValue, values }) => (
          <>
            {actionsLoading && (
              <div className="overlay-layer bg-transparent">
                <div className="spinner spinner-lg spinner-success" />
              </div>
            )}
            <Form className="form form-label-right row">
              <div className="col-lg-6">
                <Card className="px-2">
                  <CardHeader title={"General"} />
                  <div className="form-group flex flex-wrap pt-4">
                    {/* Name */}
                    <div className="col-lg-12 mb-4">
                      <Field
                        name="name"
                        component={Input}
                        placeholder="Name"
                        label={<AsteriskLabel word="Name"/>}
                      />
                    </div>
                    {/* Icon Picker */}
                    <div className="col-lg-12 mb-4">
                      <label>{<AsteriskLabel word="Display Icon"/>}</label>
                      <RequirementIconPicker
                        value={values.image_path}
                        name="image_path"
                        onChange={option => {
                          setFieldValue("image_path", option.value);
                        }}
                      />
                      <ErrorMessage name={"image_path"}>
                        {msg => <div className="text-danger">{msg}</div>}
                      </ErrorMessage>
                    </div>
                  </div>
                </Card>
              </div>
            </Form>
            <div className="row">
              <div className="col-xl-6">
                <Card className="_card-toolbar-start">
                  <button
                    type="submit"
                    onClick={() => handleSubmit()}
                    className="btn btn-primary btn-elevate"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    onClick={onHide}
                    className="btn btn-light btn-elevate"
                  >
                    Cancel
                  </button>
                </Card>
              </div>
            </div>
          </>
        )}
      </Formik>
    </>
  );
};
