export const defaultSorted = [{ dataField: "event", order: "desc" }];
export const sizePerPageList = [
  { text: "3", value: 3 },
  { text: "5", value: 5 },
  { text: "10", value: 10 },
];

export const initialFilter = {
  filter: {
    classes: [],
    studios: [],
    instructors: [],
    start_date: "",
    end_date: "",
    start_time: "",
    end_time: "",
    participant_limit: null,
    has_studio: { value: "", label: "All" },
    has_virtual: { value: "", label: "All" },
    accept_punch_card: { value: "", label: "All" },
    is_special_class: { value: "", label: "All" },
    status: [{ name: "ACTIVE", label: "Active" }],
  },
  sortOrder: "asc", // asc||desc
  sortField: "date",
  pageNumber: 1,
  pageSize: 10,
  is_admin: "",
};
