import { gql } from "@apollo/client";

const INSTRUCTOR_CREATE = gql`
  mutation($user_id: Int!, $name: String!, $about: String!, $avatar: Upload) {
    instructor {
      create(
        input: {
          user_id: $user_id
          name: $name
          about: $about
          avatar: $avatar
        }
      ) {
        id
        user_id
        name
        about
        created_at
        updated_at
        image_link
      }
    }
  }
`;

const INSTRUCTORS_PAGINATE = gql`
  query(
    $page: Int
    $limit: Int
    $name: String
    $sort_by: InstructorSortableField
    $sort_order: SortOrder
  ) {
    instructors(
      page: $page
      limit: $limit
      filter: { name: $name, sort_by: $sort_by, sort_order: $sort_order }
    ) {
      total
      nodes {
        id
        user_id
        name
        about
        created_at
        updated_at
        image_link
      }
    }
  }
`;

const INSTRUCTOR_UPDATE = gql`
  mutation(
    $id: Int!
    $user_id: Int!
    $name: String!
    $about: String!
    $avatar: Upload
  ) {
    instructor {
      update(
        id: $id
        input: {
          user_id: $user_id
          name: $name
          about: $about
          avatar: $avatar
        }
      ) {
        id
        user_id
        name
        about
        created_at
        updated_at
        image_link
      }
    }
  }
`;

const INSTRUCTOR_BY_ID = gql`
  query($id: Int!) {
    instructor(id: $id) {
      id
      user_id
      name
      about
      created_at
      updated_at
      image_link
      user {
        id
        first_name
        last_name
      }
    }
  }
`;

const INSTRUCTOR_DELETE = gql`
  mutation($id: Int!) {
    instructor {
      delete(id: $id)
    }
  }
`;

export {
  INSTRUCTOR_CREATE,
  INSTRUCTORS_PAGINATE,
  INSTRUCTOR_UPDATE,
  INSTRUCTOR_BY_ID,
  INSTRUCTOR_DELETE,
};
