import React, { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { snackActions } from "../../../../../../utils/SnackbarUtils";
import * as actions from "../../../_redux/users/usersActions";
import { useUsersUIContext } from "../UsersUIContext";
import { UserEditForm } from "./UserEditForm";

export function UserEdit() {
  // Users UI Context
  const usersUIContext = useUsersUIContext();
  const usersUIProps = useMemo(() => {
    return {
      initUser: usersUIContext.initUser
    };
  }, [usersUIContext]);

  const { id } = useParams();
  const history = useHistory();

  // Users Redux State
  const dispatch = useDispatch();
  const { actionsLoading, userForEdit } = useSelector(
    state => ({
      actionsLoading: state.users.actionsLoading,
      userForEdit: state.users.userForEdit
    }),
    shallowEqual
  );

  useEffect(() => {
    //server call get user by id
    dispatch(actions.fetchUser(id));
  }, [id, dispatch]);

  // server request to save user
  const saveUser = user => {
    if (!id) {
      // create user
      dispatch(actions.createUser(user))
        .then(() => backToUserlist())
        .catch(err => {
          snackActions.error(err.message);
        });
    } else {
      // update user
      dispatch(actions.updateUser(user))
        .then(() => backToUserlist())
        .catch(err => {
          snackActions.error(err.message);
        });
    }
  };

  const backToUserlist = () => {
    history.push(`/users`);
  };

  return (
    <UserEditForm
      saveUser={saveUser}
      isEditing={id !== null && id !== undefined}
      actionsLoading={actionsLoading}
      user={userForEdit || usersUIProps.initUser}
      onHide={backToUserlist}
    />
  );
}
