import React from "react";
import { Box } from "@material-ui/core";

export function IdReadColumnFormatter(cellContent, row) {
  return (
    <Box
      sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Box
        sx={{
          width: "8px",
          height: "8px",
          borderRadius: "50%",
          background: !cellContent ? "orange" : "transparent",
        }}></Box>
    </Box>
  );
}
