import React, { useMemo, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  ModalProgressBar,
} from "../../../../../_metronic/_partials/controls";
import { useRefundRequestUIContext } from "../RefundRequestUIContext";
import * as actions from "../../_redux/refundRequestActions";
import { useDispatch, useSelector } from "react-redux";
import { RefundRequestDetail } from "./RefundRequestDetail";
// import { RefundRequestDetail } from "./RefundRequestDetail";

export const RefundRequestDetailCard = () => {
  const { id } = useParams();
  const refundRequestUIContext = useRefundRequestUIContext();
  const refundRequestUIProps = useMemo(() => {
    return {
      ids: refundRequestUIContext.ids,
      toRefundRequestsPage: refundRequestUIContext.toRefundRequestsPage,
    };
  }, [refundRequestUIContext]);

  const { actionsLoading, refundRequestForEdit } = useSelector((state) => {
    return {
      actionsLoading: state.refundRequest.actionsLoading,
      refundRequestForEdit: state.refundRequest.refundRequestForEdit,
    };
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.fetchRefundRequest(id));
  }, [id, dispatch]);

  return (
    <Card>
      {actionsLoading && <ModalProgressBar />}
      <CardHeader title="Refund Request Detail">
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={refundRequestUIProps.toRefundRequestsPage}
            className="btn btn-light">
            <i className="fa fa-arrow-left"></i>
            Back
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <RefundRequestDetail
          refundRequestForEdit={refundRequestForEdit}
          actionsLoading={actionsLoading}
        />
      </CardBody>
    </Card>
  );
};
