import React, { useMemo } from "react";
import AsyncSelect from "react-select/async";
import _ from "lodash";
import { findUsers } from "../../../../../Users/_redux/users/usersCrud";

export const UserAsyncSelect = ({
  value,
  isError,
  onChange,
  isEdit,
  currentUser,
}) => {
  const asyncProps = useMemo(() => {
    function asyncDebounce(func, wait) {
      const debounced = _.debounce((resolve, reject, args) => {
        func(...args)
          .then(resolve)
          .catch(reject);
      }, wait);
      return (...args) =>
        new Promise((resolve, reject) => {
          debounced(resolve, reject, args);
        });
    }
    const searchUsers = async (searchText) => {
      return findUsers({
        pageSize: 10,
        pageNumber: 1,
        filter: { phrase: searchText, is_admin: null, is_instructor: false },
      });
    };
    var debounced = asyncDebounce(searchUsers, 500);
    return {
      searchUsers,
      debounced,
    };
  }, []);

  const loadOptions = async (searchText, loadedOptions, temp) => {
    const {
      data: {
        users: { nodes },
      },
    } = await asyncProps.debounced(searchText);

    if (isEdit) {
      if (
        (currentUser.first_name + currentUser.last_name || "").includes(
          searchText
        ) ||
        (currentUser.first_name + " " + currentUser.last_name || "").includes(
          searchText
        )
      ) {
        nodes.push(currentUser);
      }
    }

    return nodes;
  };

  return (
    <>
      <AsyncSelect
        styles={{
          container: () => ({
            border: isError && "1px solid #F64E60",
          }),
        }}
        value={value}
        defaultOptions
        // defaultValue={value}
        // defaultOptions={
        //   defaultValue ? [defaultValue, ...loadOptions()] : loadOptions()
        // }
        loadOptions={loadOptions}
        onChange={onChange}
        getOptionValue={(option) => option.id}
        getOptionLabel={(option) =>
          `${option.first_name} ${option.last_name || ""} `
        }
        placeholder="Search for an user"
        noOptionsMessage={() => "No users found"}
      />
    </>
  );
};
