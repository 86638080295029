import * as requestFromServer from "./requirementsCrud";
import { requirementsSlice, callTypes } from "./requirementsSlice";

const { actions } = requirementsSlice;

export const fetchRequirement = id => dispatch => {
  if (!id) {
    return dispatch(
      actions.requirementFetched({ requirementForEdit: undefined })
    );
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .findRequirement(id)
    .then(response => {
      const {
        data: { requirement }
      } = response;

      dispatch(
        actions.requirementFetched({
          requirementForEdit: {
            ...requirement,
            image: null
          }
        })
      );
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchRequirements = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return requestFromServer
    .findRequirements(queryParams)
    .then(response => {
      const {
        data: { requirements }
      } = response;

      dispatch(
        actions.requirementsFetched({
          totalCount: requirements.length,
          entities: requirements
        })
      );
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const createRequirement = requirement => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .createRequirement(requirement)
    .then(response => {
      const {
        data: {
          requirement: { create }
        }
      } = response;

      dispatch(actions.requirementCreated({ requirement: create }));
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      throw error;
    });
};

export const updateRequirement = requirement => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .updateRequirement(requirement)
    .then(response => {
      const {
        data: {
          requirement: { update }
        }
      } = response;

      dispatch(actions.requirementUpdated({ requirement: update }));
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      throw error;
    });
};

export const deleteRequirement = id => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .deleteRequirement(id)
    .then(response => {
      const {
        data: {
          requirement: { delete: id }
        }
      } = response;

      dispatch(actions.requirementDeleted({ id: id }));
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      throw error;
    });
};
