import { gql } from "@apollo/client";

const EVENT_BOOKING_CREATE = gql`
  mutation(
    $event_id: Int!
    $class_type: ClassType!
    $user_name: String
    $user_email: String
    $payment_method: PaymentMethod!
  ) {
    book_event(
      input: {
        event_id: $event_id
        class_type: $class_type
        user_name: $user_name
        user_email: $user_email
        payment_method: $payment_method
      }
    ) {
      qr
      details {
        ... on EventBooking {
          id
          event_id
          user_id
          user_name
          user_email
          class_type
          price
          credit
          payment_method
          payment_reference
          payment_status
          status
          user {
            id
            email
            first_name
            last_name
          }
          event {
            date
            class {
              id
              name
            }
          }
        }
      }
    }
  }
`;

const EVENT_BOOKING_GET_BY_ID = gql`
  query($id: Int!) {
    event_booking(id: $id) {
      id
      event_id
      user_id
      user_name
      user_email
      class_type
      price
      credit
      payment_method
      payment_reference
      payment_status
      status
      paynow_payment_history {
        id
        qr_string
        expired_at
      }
      stripe_payment_history {
        stripe_identifier
        receipt_url
        charge_status
        description
        payment_method
      }
      user {
        id
        email
        first_name
        last_name
      }
      event {
        id
        date
        start_time
        end_time
        studio_price
        studio_credit
        virtual_price
        virtual_credit
        zoom_url
        class {
          id
          name
        }
        instructor {
          id
          name
        }
      }
    }
  }
`;

const EVENT_BOOKING_PAGINATE = gql`
  query(
    $page: Int
    $limit: Int
    $event_id: Int
    $user_id: Int
    $user_name: String
    $user_email: String
    $class_type: ClassType
    $payment_method: PaymentMethod
    $payment_status: PaymentStatus
    $status: [EventBookingStatus!]
    $start_date: String
    $end_date: String
    $sort_by: EventBookingSortableField
    $sort_order: SortOrder
  ) {
    events_bookings(
      page: $page
      limit: $limit
      filter: {
        event_id: $event_id
        user_id: $user_id
        user_name: $user_name
        user_email: $user_email
        class_type: $class_type
        payment_method: $payment_method
        payment_status: $payment_status
        status: $status
        start_date: $start_date
        end_date: $end_date
        sort_by: $sort_by
        sort_order: $sort_order
      }
    ) {
      total
      nodes {
        id
        event_id
        user_id
        user_name
        user_email
        class_type
        price
        credit
        payment_method
        payment_reference
        payment_status
        status
        created_at
        user {
          id
          email
          first_name
          last_name
        }
        event {
          date
          class {
            id
            name
          }
        }
      }
    }
  }
`;

const EVENT_BOOKING_MARK_REFUNDED = gql`
  mutation EVENT_BOOKING_MARK_REFUNDED($booking_id: Int!) {
    mark_as_refunded(booking_id: $booking_id) {
      id
      event_id
      user_id
      user_name
      user_email
      class_type
      price
      credit
      payment_method
      payment_reference
      payment_status
      status
      created_at
      user {
        id
        email
        first_name
        last_name
      }
      event {
        date
        class {
          id
          name
        }
      }
    }
  }
`;

export {
  EVENT_BOOKING_CREATE,
  EVENT_BOOKING_GET_BY_ID,
  EVENT_BOOKING_PAGINATE,
  EVENT_BOOKING_MARK_REFUNDED,
};
