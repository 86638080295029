import React, { useMemo } from "react";
import { Formik } from "formik";
import { isEqual, debounce } from "lodash";
import { useUsersUIContext } from "../UsersUIContext";

const prepareFilter = (queryParams, values) => {
  const newQueryParams = { ...queryParams };
  const filter = { ...values };

  newQueryParams.filter = filter;
  return newQueryParams;
};

export function UsersFilter({ listLoading }) {
  const usersUIContext = useUsersUIContext();
  const usersUIProps = useMemo(() => {
    return {
      queryParams: usersUIContext.queryParams,
      setQueryParams: usersUIContext.setQueryParams,
    };
  }, [usersUIContext]);

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(usersUIProps.queryParams, values);
    if (!isEqual(newQueryParams, usersUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      usersUIProps.setQueryParams(newQueryParams);
    }
  };
  const debouncedApplyFilter = debounce(applyFilter, 500);

  return (
    <>
      <Formik
        initialValues={{
          ...usersUIProps.queryParams.filter,
        }}
        onSubmit={(values) => {
          debouncedApplyFilter(values);
        }}>
        {({
          values,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
        }) => (
          <form onSubmit={handleSubmit} className="form form-label-right">
            <div className="form-group row">
              <div className="col-lg-2">
                <select
                  className="form-control"
                  name="is_admin"
                  placeholder="Filter by Role"
                  onChange={(e) => {
                    const { value } = e.target;
                    setFieldValue("is_admin", value);
                    handleSubmit();
                  }}
                  onBlur={handleBlur}
                  value={values.is_admin}>
                  <option value={""}>All</option>
                  <option value={"user"}>User</option>
                  <option value={"admin"}>Admin</option>
                </select>
              </div>
              <div className="col-lg-2">
                <input
                  name="phrase"
                  placeholder="Search"
                  className="form-control"
                  value={values.phrase}
                  onChange={(e) => {
                    setFieldValue("phrase", e.target.value);
                    handleSubmit();
                  }}
                />
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
}
