import React, { useMemo } from "react";
import { Formik } from "formik";
import { debounce, isEqual } from "lodash";
import { useRefundRequestUIContext } from "../RefundRequestUIContext";
import { TextField } from "@material-ui/core";
import Select from "react-select";
import { AVAILABLE_PAYMENT_METHODS } from "../RefundRequestUIHelpers";
import { EventAsyncSelect } from "../utils/event-async-select";

const prepareFilter = (queryParams, values) => {
  const { startDate, endDate, paymentMethods, eventIds, searchText } = values;
  const newQueryParams = { ...queryParams };
  const filter = {};

  if (startDate) {
    filter.start_date = startDate;
  }

  if (endDate) {
    filter.end_date = endDate;
  }

  if (paymentMethods && paymentMethods.length > 0) {
    filter.payment_methods = paymentMethods;
  }

  if (eventIds && eventIds.length > 0) {
    filter.event_ids = eventIds;
  }

  if (searchText) {
    filter.phrase = searchText;
  }

  newQueryParams.filter = filter;
  return newQueryParams;
};

export function RefundRequestFilter({ listLoading }) {
  const refundRequestUIContext = useRefundRequestUIContext();
  const refundRequestUIProps = useMemo(() => {
    return {
      queryParams: refundRequestUIContext.queryParams,
      setQueryParams: refundRequestUIContext.setQueryParams,
    };
  }, [refundRequestUIContext]);

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(
      refundRequestUIProps.queryParams,
      values
    );
    if (!isEqual(newQueryParams, refundRequestUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      refundRequestUIProps.setQueryParams(newQueryParams);
    }
  };
  const debouncedApplyFilter = debounce(applyFilter, 300);

  return (
    <>
      <Formik
        initialValues={{
          startDate: refundRequestUIProps.queryParams.filter.start_date,
          endDate: refundRequestUIProps.queryParams.filter.end_date,
          paymentMethods:
            refundRequestUIProps.queryParams.filter.payment_methods,
          eventIds: refundRequestUIProps.queryParams.filter.event_ids,
          searchText: refundRequestUIProps.queryParams.filter.phrase,
        }}
        onSubmit={(values) => {
          debouncedApplyFilter(values);
        }}>
        {({ values, handleSubmit, setFieldValue, handleBlur }) => {
          return (
            <form onSubmit={handleSubmit} className="form form-label-right">
              <div className="row mb-3">
                <div className="col-lg-3">
                  <input
                    type="text"
                    className="form-control"
                    name="searchText"
                    placeholder="Search"
                    onBlur={handleBlur}
                    value={values.searchText}
                    onChange={(e) => {
                      setFieldValue("searchText", e.target.value);
                      handleSubmit();
                    }}
                  />
                </div>
              </div>
              <div className="form-group row align-items-center">
                <div className="col-lg-2">
                  <TextField
                    id="date"
                    name="startDate"
                    label="Start Date"
                    type="date"
                    value={values.startDate}
                    onBlur={handleBlur}
                    style={{ width: "100%" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      setFieldValue("startDate", e.target.value);
                      handleSubmit();
                    }}
                  />
                </div>
                <div className="col-lg-2">
                  <TextField
                    id="date"
                    name="endDate"
                    label="End Date"
                    type="date"
                    onBlur={handleBlur}
                    value={values.endDate}
                    style={{ width: "100%" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    onChange={(e) => {
                      setFieldValue("endDate", e.target.value);
                      handleSubmit();
                    }}
                  />
                </div>
                <div className="col-lg-3">
                  <Select
                    size="small"
                    closeMenuOnSelect={false}
                    isMulti={true}
                    onBlur={handleBlur}
                    style={{ width: "100%" }}
                    name="paymentMethods"
                    value={values.paymentMethods}
                    onChange={(options) => {
                      setFieldValue(
                        "paymentMethods",
                        options === null ? [] : [...options]
                      );
                      handleSubmit();
                    }}
                    options={AVAILABLE_PAYMENT_METHODS}
                    getOptionValue={(option) => option.id}
                    getOptionLabel={(option) => `${option.name}`}
                    placeholder="Search Payment Methods"
                    noOptionsMessage={() => "No Payment Methods found"}
                  />
                </div>
                <div className="col-lg-5">
                  <EventAsyncSelect
                    isMulti={true}
                    value={values.eventIds}
                    onChange={(options) => {
                      setFieldValue(
                        "eventIds",
                        options === null ? [] : [...options]
                      );
                      handleSubmit();
                    }}
                  />
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </>
  );
}
