import React, { useEffect, useMemo } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from "moment";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import * as actions from "../../../../Users/_redux/users/usersActions";
import BootstrapTable from "react-bootstrap-table-next";
import {
  // getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import * as uiHelpers from "../UsersUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { useUsersUIContext } from "../UsersUIContext";
// import cellEditFactory from "react-bootstrap-table2-editor";
// import { useHistory } from "react-router-dom";

export function UsersTable() {
  // Users UI Context
  const usersUIContext = useUsersUIContext();
  const usersUIProps = useMemo(() => {
    return {
      ids: usersUIContext.ids,
      setIds: usersUIContext.setIds,
      queryParams: usersUIContext.queryParams,
      setQueryParams: usersUIContext.setQueryParams,
      openEditUserPage: usersUIContext.openEditUserPage,
      openBanUserDialog: usersUIContext.openBanUserDialog,
      openEditUserCreditDialog: usersUIContext.openEditUserCreditDialog,
    };
  }, [usersUIContext]);

  // Get Current State of Users from Store
  const { currentState } = useSelector(
    (state) => ({ currentState: state.users }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;
  // const history = useHistory();

  // Users Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    usersUIProps.setIds([]);

    dispatch(actions.fetchUsers(usersUIProps.queryParams));

    // eslint-disable-next-line
  }, [usersUIProps.queryParams, dispatch]);

  const columns = [
    // {
    //   dataField: "id",
    //   text: "ID",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   headerSortingClasses,
    //   editable: false,
    // },
    {
      dataField: "image_path",
      text: "Avatar",
      sort: false,
      sortCaret: sortCaret,
      formatter: columnFormatters.ImagePathColumnFormatter,
      editable: false,
    },
    {
      dataField: "email",
      text: "Email",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      editable: false,
    },
    {
      dataField: "first_name",
      text: "First Name",
      sort: false,
      sortCaret: sortCaret,
      editable: false,
    },
    {
      dataField: "last_name",
      text: "Last Name",
      sort: false,
      sortCaret: sortCaret,
      editable: false,
    },
    {
      dataField: "phone_number",
      text: "Phone Number",
      sort: false,
      sortCaret: sortCaret,
      editable: false,
    },
    {
      dataField: "credit",
      text: "Credit",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "credit_expired_at",
      text: "Credit Expired",
      sortCaret: sortCaret,
      formatter: (cell) =>
        cell ? moment(cell).format("YYYY-MM-DD HH:mm:ss") : "-",
      editable: false,
    },
    {
      dataField: "is_admin",
      text: "Admin",
      sortCaret: sortCaret,
      formatter: columnFormatters.IsAdminColumnFormatter,
      classes: "text-center",
      headerClasses: "text-center",
      editable: false,
    },
    {
      dataField: "is_banned",
      text: "Banned",
      sortCaret: sortCaret,
      formatter: columnFormatters.IsBannedColumnFormatter,
      classes: "text-center",
      headerClasses: "text-center",
      editable: false,
    },
    {
      dataField: "unbanned_at",
      text: "Banned Until",
      sortCaret: sortCaret,
      formatter: (cell) =>
        cell ? moment(cell).format("YYYY-MM-DD HH:mm:ss") : "-",
      editable: false,
    },
    {
      dataField: "updated_at",
      text: "Updated At",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      classes: "text-nowrap",
      formatter: (cell) =>
        cell ? moment(cell).format("YYYY-MM-DD HH:mm:ss") : "-",
      editable: false,
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditUserPage: usersUIProps.openEditUserPage,
        openBanUserDialog: usersUIProps.openBanUserDialog,
        openEditUserCreditDialog: usersUIProps.openEditUserCreditDialog,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "200px",
      },
      editable: false,
    },
  ];

  // Table Pagination Properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: usersUIProps.queryParams.pageSize,
    page: usersUIProps.queryParams.pageNumber,
  };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                // cellEdit={cellEditFactory({
                //   mode: "click",
                //   blurToSave: true,
                //   beforeSaveCell: (oldValue, newValue, row, column) => {
                //     if (oldValue !== parseInt(newValue)) {
                //       history.push(
                //         `/users/credit/${row.id}/edit?newAmount=${newValue}&oldAmount=${oldValue}`
                //       );
                //       return true;
                //     }
                //     return false;
                //   },
                // })}
                onTableChange={getHandlerTableChange(
                  usersUIProps.setQueryParams
                )}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
