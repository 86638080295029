import React, { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/classes/classesActions";
import { useClassesUIContext } from "../ClassesUIContext";
import { ClassEditForm } from "./ClassEditForm";

export const ClassEditPage = ({ id, onHide }) => {
  const classesUIContext = useClassesUIContext();
  const classesUIProps = useMemo(() => {
    return {
      initClass: classesUIContext.initClass
    };
  }, [classesUIContext]);

  const dispatch = useDispatch();
  const { actionsLoading, classForEdit } = useSelector(state => {
    return {
      actionsLoading: state.classes.actionsLoading,
      classForEdit: state.classes.classForEdit
    };
  }, shallowEqual);

  useEffect(() => {
    dispatch(actions.fetchClass(id));
  }, [id, dispatch]);

  const saveClass = classData => {
    if (!id) {
      dispatch(actions.createClass(classData)).then(() => onHide());
    } else {
      dispatch(actions.updateClass(classData)).then(() => onHide());
    }
  };

  return (
    <ClassEditForm
      saveClass={saveClass}
      isEditing={id !== null && id !== undefined}
      actionsLoading={actionsLoading}
      classData={classForEdit || classesUIProps.initClass}
      onHide={onHide}
    />
  );
};
