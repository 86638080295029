import { gql } from "@apollo/client";

const CREDIT_TYPE_CREATE = gql`
  mutation($name: String!, $credit: Float!, $price: Float!) {
    credit_type {
      create(input: { name: $name, credit: $credit, price: $price }) {
        id
        name
        credit
        price
        created_at
        updated_at
      }
    }
  }
`;

const CREDIT_TYPE_UPDATE = gql`
  mutation($id: Int!, $name: String!, $credit: Float!, $price: Float!) {
    credit_type {
      update(id: $id, input: { name: $name, credit: $credit, price: $price }) {
        id
        name
        credit
        price
        created_at
        updated_at
      }
    }
  }
`;

const CREDIT_TYPE_PAGINATE = gql`
  query {
    credit_types {
      id
      name
      credit
      price
      created_at
      updated_at
    }
  }
`;

const CREDIT_TYPE_GET_BY_ID = gql`
  query($id: Int!) {
    credit_type(id: $id) {
      id
      name
      credit
      price
      created_at
      updated_at
    }
  }
`;

const CREDIT_TYPE_DELETE = gql`
  mutation($id: Int!) {
    credit_type {
      delete(id: $id)
    }
  }
`;

export {
  CREDIT_TYPE_CREATE,
  CREDIT_TYPE_UPDATE,
  CREDIT_TYPE_PAGINATE,
  CREDIT_TYPE_GET_BY_ID,
  CREDIT_TYPE_DELETE,
};
