import {
  NEWS,
  NEWS_LIST,
  CREATE_NEWS,
  UPDATE_NEWS,
  DELETE_NEWS
} from "../../../../graphql";
import { apolloClient } from "../../../apollo/client";


export function createNews(news) {
  return apolloClient.mutate({
    mutation: CREATE_NEWS,
    variables: {
      body_html: news.body_html,
      title: news.title.trim(),
      image: news.image,
    }
  })
}

export function updateNews(news) {
  return apolloClient.mutate({
    mutation: UPDATE_NEWS,
    variables: {
      id: news.id,
      title: news.title.trim(),
      body_html: news.body_html,
      image: news.image,
    }
  })
}

export function deleteNews(id) {
  return apolloClient.mutate({
    mutation: DELETE_NEWS,
    variables: {
      id,
    }
  })
}

export function findNewsList(queryParams) {
  return apolloClient.query({
    query: NEWS_LIST,
    variables: {
      sort_by: queryParams.sortField.toUpperCase(),
      sort_order: queryParams.sortOrder.toUpperCase(),
      phrase: queryParams.filter.phrase,
      page: queryParams.pageNumber,
      limit: queryParams.pageSize,
      start_date: queryParams.filter.start_date,
      end_date: queryParams.filter.end_date,
    }
  })
}

export function findNews(id) {
  return apolloClient.query({
    query: NEWS,
    variables: {
      id: id
    }
  })
}

