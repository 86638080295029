import React, { useMemo } from "react";
import { Route } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { NewsDeleteDialog } from "./news-delete-dialog/NewsDeleteDialog";
import { NewsFilter } from "./news-filter/NewsFilter";
import { NewsTable } from "./news-table/NewsTable";
import { useNewsUIContext } from "./NewsUIContext";

export const NewsCard = () => {
  const newsUIContext = useNewsUIContext();
  const newsUIProps = useMemo(() => {
    return {
      ids: newsUIContext.ids,
      newNewsButtonClick: newsUIContext.newNewsButtonClick,
    };
  }, [newsUIContext]);

  return (
    <Card>
      <Route exact path="/news/:id/delete">
        {({ history, match }) => {
          return (
            match != null && (
              <NewsDeleteDialog
                show={match != null}
                id={match && match.params.id}
                onHide={() => {
                  history.push("/news");
                }}
              />
            )
          );
        }}
      </Route>
      <CardHeader title="News List">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={newsUIProps.newNewsButtonClick}>
            Publish News
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <NewsFilter />
        <NewsTable />
      </CardBody>
    </Card>
  );
};
