import React from "react";
import { Route, Switch } from "react-router-dom";
import { ClassesUIProvider } from "./ClassesUIContext";
import { ClassesLoadingDialog } from "./classes-loading-dialog/ClassesLoadingDialog";
import { ClassEditPage } from "./classes-edit-page/ClassEditPage";
import { ClassesCard } from "./ClassesCard";
import { ClassDeleteDialog } from "./classes-delete-dialog/ClassesDeleteDialog";

export const ClassesPage = ({ history }) => {
  const basePath = "/classes";

  const classesUIEvents = {
    newClassButtonClick: () => {
      history.push(`${basePath}/new`);
    },
    openEditClassDialog: (id) => {
      history.push(`${basePath}/${id}/edit`);
    },
    openDeleteClassDialog: (id) => {
      history.push(`${basePath}/${id}/delete`);
    },
  };

  return (
    <ClassesUIProvider classesUIEvents={classesUIEvents}>
      <ClassesLoadingDialog />
      <Switch>
          <Route path={`${basePath}/new`}>
            {({ history, match }) => match != null && (
              <ClassEditPage
                onHide={() => {
                  history.push(basePath);
                }}
              />
            )}
          </Route>
          <Route path={`${basePath}/:id/edit`}>
            {({ history, match }) => match != null &&(
              <ClassEditPage
                id={match && match.params.id}
                onHide={() => {
                  history.push(basePath);
                }}
              />
            )}
          </Route>
          <Route path={``}>
            {({match}) => match != null && (
              <ClassesCard />
            )}
          </Route>
      </Switch>
      <Route path={`${basePath}/:id/delete`}>
        {({ history, match }) => (
          <ClassDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push(basePath);
            }}
          />
        )}
      </Route>
    </ClassesUIProvider>
  );
};
