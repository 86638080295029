import React, { useMemo } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { InputPlusMinus } from "../../../../../../_metronic/_partials/controls";
import AsteriskLabel from "../../../../../../utils/Asterisk";

export function UserEditCreditForm({
  updateCredit,
  isEditing,
  user,
  actionsLoading,
  onHide,
}) {
  // Validation schema
  const UserEditSchema = useMemo(
    () =>
      Yup.object().shape({
        credit: Yup.number()
          .required("Required")
          .test("Is valid", "Not equals 0", (value) => value !== 0),
        reason: Yup.string()
          .trim()
          .required("Required"),
      }),
    //eslint-disable-next-line
    [isEditing]
  );

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{ ...user, credit: 0, reason: "" }}
        validationSchema={UserEditSchema}
        onSubmit={(values) => {
          updateCredit(values);
        }}
      >
        {({ handleSubmit, setFieldValue }) => (
          <>
            <Modal.Body className="overlay overlay-block cursor-default">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                {/* Credit */}
                <div className="form-group row">
                  <div className="col-lg-12">
                    <Field
                      type="number"
                      name="credit"
                      component={InputPlusMinus}
                      step="1"
                      precision="2"
                      placeholder="Credit"
                      label={<AsteriskLabel word="Credit" />}
                    />
                  </div>
                </div>
                {/* Reason */}
                <div className="form-group row" style={{ padding: "0 15px" }}>
                  <label>
                    <AsteriskLabel word="Reason" />
                  </label>
                  <textarea
                    className="form-control"
                    rows="6"
                    style={{ width: "100%" }}
                    onChange={(e) => {
                      setFieldValue("reason", e.target.value);
                    }}
                  ></textarea>
                  <ErrorMessage name={"reason"}>
                    {(msg) => <div className="text-danger">{msg}</div>}
                  </ErrorMessage>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
