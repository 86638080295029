import React, { useMemo } from "react";
import { components } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconsMap } from "./Icons";
import { options } from "./Options";
import AsyncSelect from "react-select/async";
import _ from "lodash";
import { Box } from "@material-ui/core";

// Array.prototype.inArray = function(comparer) {
//   for (var i = 0; i < this.length; i++) {
//     if (comparer(this[i])) return true;
//   }
//   return false;
// };

// Array.prototype.pushIfNotExist = function(element, comparer) {
//   if (!this.inArray(comparer)) {
//     this.push(element);
//   }
// };

// var options = [
//   {
//     value: "sports_shoes.svg",
//     label: "Sports Shoes",
//     icon: "sports_shoes.svg",
//     is_fa: false,
//   },
//   {
//     value: "sports_wear.svg",
//     label: "Sports Wear",
//     icon: "sports_wear.svg",
//     is_fa: false,
//   },
//   {
//     value: "swim_suit.svg",
//     label: "Swim Suit",
//     icon: "swim_suit.svg",
//     is_fa: false,
//   },
//   {
//     value: "yoga_mat.svg",
//     label: "Yoga Mat",
//     icon: "yoga_mat.svg",
//     is_fa: false,
//   },
// ];

// var counter = 0;
// for (let [key, value] of Object.entries(faIcon)) {
//   if (counter >= 2) {
//     const label = value.iconName.replace(/-/g, " ");
//     console.log(label, value.iconName);
//     const element = {
//       value: value.iconName,
//       label: toTitleCase(label),
//       icon: value.iconName,
//       is_fa: true,
//     };

//     options.pushIfNotExist(element, function(e) {
//       return e.icon === element.icon && e.value === element.value;
//     });
//     // options.push();
//   }

//   console.log(options);
//   counter++;
// }

const { Option, SingleValue } = components;

const IconSingleValue = (props) => {
  const isFontAwesome = props.data.is_fa;

  return !isFontAwesome ? (
    <SingleValue {...props}>
      <img
        src={require("../../../../../../../public/media/svg/1fiesta/icons/" +
          props.data.icon)}
        style={{
          height: 30,
          width: 30,
          marginRight: "10px",
        }}
        alt={props.data.label}
      />

      {props.data.label}
    </SingleValue>
  ) : (
    <SingleValue {...props}>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
        }}>
        <FontAwesomeIcon
          style={{
            height: 30,
            width: 30,
            marginRight: "10px",
          }}
          color="#dc3545"
          icon={IconsMap[props.data.icon]}
        />

        <Box>{props.data.label}</Box>
      </Box>
    </SingleValue>
  );
};

const IconOption = (props) => {
  const isFontAwesome = props.data.is_fa;

  return !isFontAwesome ? (
    <Option {...props}>
      <img
        src={require("../../../../../../../public/media/svg/1fiesta/icons/" +
          props.data.icon)}
        style={{ width: 30, height: 30, marginRight: "10px" }}
        alt={props.data.label}
      />
      &nbsp;
      {props.data.label}
    </Option>
  ) : (
    <Option {...props}>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
        }}>
        <FontAwesomeIcon
          style={{
            height: 30,
            width: 30,
            marginRight: "10px",
          }}
          color="#dc3545"
          icon={IconsMap[props.data.icon]}
        />

        <Box>{props.data.label}</Box>
      </Box>
    </Option>
  );
};

const RequirementIconPicker = ({ onChange, name, value }) => {
  // Save Search and Debounce
  const asyncProps = useMemo(() => {
    function asyncDebounce(func, wait) {
      const debounced = _.debounce((resolve, reject, args) => {
        func(...args)
          .then(resolve)
          .catch(reject);
      }, wait);
      return (...args) =>
        new Promise((resolve, reject) => {
          debounced(resolve, reject, args);
        });
    }
    const searchIcons = async (searchText) => {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          const filtered = options.filter((option) => {
            return option.label
              .toLowerCase()
              .includes(searchText.toLowerCase());
          });
          resolve(filtered);
        }, 300);
      });
    };
    var debounced = asyncDebounce(searchIcons, 300);
    return {
      searchIcons,
      debounced,
    };
  }, []);

  const loadOptions = async (searchText, loadedOptions, temp) => {
    const result = await asyncProps.debounced(searchText);
    return result.slice(0, 100);
  };

  return (
    <>
      <AsyncSelect
        value={
          value != null
            ? options.filter((option) => {
                return value === option.icon;
              })
            : null
        }
        name={name}
        defaultOptions
        loadOptions={loadOptions}
        onChange={onChange}
        placeholder="Search for an icons"
        noOptionsMessage={() => "No icons found"}
        components={{ Option: IconOption, SingleValue: IconSingleValue }}
      />

      {/* <Select
        name={name}
        value={
          value != null
            ? options.filter((option) => {
                return value === option.icon;
              })
            : null
        }
        options={options}
        components={{ Option: IconOption, SingleValue: IconSingleValue }}
        onChange={onChange}
      /> */}
    </>
  );
};

export default RequirementIconPicker;
