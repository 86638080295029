import React, { useRef, useEffect, useState } from "react";
import {
  shallowEqual,
  useSelector,
  // connect,
  useDispatch,
} from "react-redux";
import { LayoutSplashScreen } from "../../../../_metronic/layout";
import { actions } from "./authRedux";
import {
  // getNewTokenByRefreshToken,
  getUserByToken,
} from "./authCrud";

function AuthInit(props) {
  const didRequest = useRef(false);
  const { logout, fulfillUser } = actions;
  const dispatch = useDispatch();
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const { authToken } = useSelector(
    ({ auth }) => ({
      authToken: auth.authToken,
      refreshToken: auth.refreshToken,
    }),
    shallowEqual
  );

  // We should request user by authToken before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const {
            data: { me: user },
          } = await getUserByToken();
          dispatch(fulfillUser(user));
        }
      } catch (error) {
        dispatch(logout());
      } finally {
        setShowSplashScreen(false);
      }

      return () => (didRequest.current = true);
    };

    if (authToken) {
      requestUser();
    } else {
      dispatch(fulfillUser(undefined));
      setShowSplashScreen(false);
    }
    // eslint-disable-next-line
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>;
}

export default AuthInit;
