import React from "react";
import { Route, Switch } from "react-router-dom";
import { UsersLoadingDialog } from "./users-loading-dialog.js/UsersLoadingDialog";
import { UsersUIProvider } from "./UsersUIContext";
import { UsersCard } from "./UsersCard";
import { UserEdit } from "./user-edit/UserEdit";

export function UsersPage({ history }) {
  const usersUIEvents = {
    newUserButtonClick: () => {
      history.push("/users/new");
    },
    openEditUserPage: (id) => {
      history.push(`/users/${id}/edit`);
    },
    openDeleteUserDialog: (id) => {
      history.push(`/users/${id}/delete`);
    },
    openDeleteUsersDialog: (id) => {
      history.push("/users/deleteUsers");
    },
    openFetchUsersDialog: () => {
      history.push("/users/fetch");
    },
    openUpdateUsersStatusDialog: () => {
      history.push("/users/updateStatus");
    },
    openBanUserDialog: (id, command) => {
      history.push(`/users/${id}/${command}`);
    },
    openEditUserCreditDialog: (id) => {
      history.push(`/users/${id}/credit`);
    },
  };

  return (
    <UsersUIProvider usersUIEvents={usersUIEvents}>
      <UsersLoadingDialog />
      <Switch>
        <Route path={`/users/new`}>
          {({ match }) => {
            return match != null && <UserEdit />;
          }}
        </Route>

        <Route path={`/users/:id/edit`}>
          {({ match }) => {
            return match != null && <UserEdit />;
          }}
        </Route>

        <Route path={`/users`}>
          {({ match }) => {
            return match != null && <UsersCard />;
          }}
        </Route>
      </Switch>
    </UsersUIProvider>
  );
}
