import React from "react";

export function RegionColumnFormatter(_, row) {
  return (
    <>
      <span>
        {row.region.charAt(0).toUpperCase() + row.region.slice(1).toLowerCase()}
      </span>
    </>
  );
}
