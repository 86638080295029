import { Typography } from "@material-ui/core";
import React from "react";
import { Modal } from "react-bootstrap";
import {
  PaymentStatusColumnFormatter,
  TransactionDateColumnFormatter,
  UserEmailColumnFormatter,
} from "../refund-request-table/column-formatters";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import moment from "moment";
import { EventBookingStatusColumnFormatter } from "../refund-request-table/column-formatters/EventBookingStatusColumnFormatter";

export const RefundRequestDetail = ({
  refundRequestForEdit,
  actionsLoading,
}) => {
  return (
    <>
      <Modal.Body className="overlay overlay-block cursor-default">
        {actionsLoading ? (
          <div className="overlay-layer bg-transparent">
            <div className="spinner spinner-lg spinner-success" />
          </div>
        ) : (
          refundRequestForEdit && (
            <>
              <div className="row">
                <div className="col-lg-4">
                  <Typography variant="h6">Date of Filling: </Typography>
                  <Typography variant="p">
                    {TransactionDateColumnFormatter(null, refundRequestForEdit)}
                  </Typography>
                  <div className="row mt-10">
                    <div className="col-lg-6">
                      <Typography variant="h6">Payment Method</Typography>
                      <img
                        alt="stripe"
                        src={toAbsoluteUrl(
                          refundRequestForEdit.payment_method === "STRIPE"
                            ? "/media/svg/payments/stripe_small.svg"
                            : "/media/svg/payments/paynow_small.svg"
                        )}
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <h6>User Detail: </h6>
                  <Typography variant="p">
                    <b>Name: </b> <div>{refundRequestForEdit.name}</div>
                    <b>Email: </b>{" "}
                    {UserEmailColumnFormatter(null, refundRequestForEdit)}
                    <b>Phone: </b>{" "}
                    <div>{refundRequestForEdit.phone_number}</div>
                  </Typography>
                </div>
                {refundRequestForEdit.potential_booking && (
                  <div className="col-lg-4">
                    {refundRequestForEdit.potential_booking
                      .paynow_payment_history && (
                      <>
                        <Typography variant="p">
                          QR Expiration:{" "}
                          {moment(
                            refundRequestForEdit.potential_booking
                              .paynow_payment_history.expired_at
                          ).format("YYYY-MM-DD HH:mm:ss")}
                        </Typography>
                        <img
                          alt="qr"
                          src={
                            refundRequestForEdit.potential_booking
                              .paynow_payment_history.qr_string
                          }
                          width="250px"
                        />
                      </>
                    )}
                  </div>
                )}
              </div>

              {refundRequestForEdit.potential_booking ? (
                <>
                  <h6>Potential Booking: </h6>
                  <Card>
                    <CardHeader title="Booking Detail"></CardHeader>
                    <CardBody>
                      <div className="row">
                        <div className="col-lg-4">
                          <h6>User Detail: </h6>
                          <Typography variant="p">
                            <b>Name: </b>{" "}
                            <div>
                              {refundRequestForEdit.potential_booking.user_name}
                            </div>
                            <b>Email: </b>{" "}
                            <div>
                              {
                                refundRequestForEdit.potential_booking
                                  .user_email
                              }
                            </div>
                          </Typography>
                        </div>
                        <div className="col-lg-6">
                          <h3>
                            {refundRequestForEdit.potential_booking && (
                              <>
                                {EventBookingStatusColumnFormatter(
                                  null,
                                  refundRequestForEdit.potential_booking
                                )}{" "}
                                {PaymentStatusColumnFormatter(
                                  null,
                                  refundRequestForEdit.potential_booking
                                )}
                              </>
                            )}
                          </h3>
                          <Typography variant="h6">
                            Booked Class Type:{" "}
                          </Typography>
                          <Typography>
                            {refundRequestForEdit.potential_booking.class_type}
                          </Typography>
                          <Typography variant="h6">
                            Amount: $
                            {refundRequestForEdit.potential_booking.price}
                          </Typography>
                        </div>
                        <div className="col-lg-2">
                          <Typography variant="h6">Payment Method</Typography>
                          <img
                            alt="stripe"
                            src={toAbsoluteUrl(
                              refundRequestForEdit.potential_booking
                                .payment_method === "STRIPE"
                                ? "/media/svg/payments/stripe_small.svg"
                                : "/media/svg/payments/paynow_small.svg"
                            )}
                            style={{ width: "100%" }}
                          />
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </>
              ) : (
                <div className="row">
                  <h6>Potential Booking: </h6>
                  <span>
                    &nbsp; - (No matched booking to this refund Request)
                  </span>
                </div>
              )}

              <br />

              {refundRequestForEdit.event && (
                <>
                  <h6>Event: </h6>
                  <div className="row">
                    <div className="col-lg-5">
                      <img
                        alt=""
                        src={refundRequestForEdit.event.class.image_link}
                        width="100%"
                      />
                    </div>
                    <div className="col-lg-7">
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              <b>Date</b>
                            </td>
                            <td>&nbsp;:&nbsp;</td>
                            <td>{refundRequestForEdit.event.date}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Time</b>
                            </td>
                            <td>&nbsp;:&nbsp;</td>
                            <td>{`${refundRequestForEdit.event.start_time} - ${refundRequestForEdit.event.end_time}`}</td>
                          </tr>
                          <tr>
                            <td>
                              <b>Class Type</b>
                            </td>
                            <td>&nbsp;:&nbsp;</td>
                            <td>{`${refundRequestForEdit.event.class.name}`}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              )}
            </>
          )
        )}
      </Modal.Body>
    </>
  );
};
