import { gql } from "@apollo/client"

const REQUIREMENTS = gql`
  query requirements (
    $sort_by: RequirementSortableField
    $sort_order: SortOrder
    $name: String
  ) {
    requirements(filter: {
      sort_by: $sort_by
      sort_order: $sort_order
      name: $name
    }) {
      id
      name
      created_at
      updated_at
      image_path
      image_link
    }
  }
`

const REQUIREMENT = gql`
  query requirement ($id: Int!) {
    requirement(id: $id) {
      id
      name
      created_at
      updated_at
      image_path
      image_link
    }
  }
`

const REQUIREMENT_CREATE = gql`
  mutation createRequirement (
    $name: String!
    # $image: Upload
    $image_path: String!
  ) {
    requirement {
      create(
        input: {
          name: $name
          # image: $image
          image_path: $image_path
        }
      ) {
        id
        name
        created_at
        updated_at
        image_path
        image_link
      }
    }
  }
`

const REQUIREMENT_UPDATE = gql`
  mutation updateRequirement (
    $id: Int!
    $name: String!
    # $image: Upload
    $image_path: String!
  ) {
    requirement {
      update(
        id: $id
        input: {
          name: $name
          # image: $image
          image_path: $image_path
        }
      ) {
        id
        name
        created_at
        updated_at
        image_path
        image_link
      }
    }
  }
`

const REQUIREMENT_DELETE = gql`
  mutation deleteRequirement (
    $id: Int!
  ) { 
    requirement {
      delete(id: $id)
    }
  }
`

export {
  REQUIREMENTS,
  REQUIREMENT_CREATE,
  REQUIREMENT_UPDATE,
  REQUIREMENT_DELETE,
  REQUIREMENT
}