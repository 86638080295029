import { gql } from "@apollo/client";

const LOGIN = gql`
  mutation($email: String!, $password: String!) {
    user {
      login(input: { email: $email, password: $password }) {
        token_type
        token
        refresh_token
        user {
          email
          is_admin
        }
      }
    }
  }
`;

const GET_NEW_TOKEN = gql`
  mutation getnewToken($refresh_token: String!) {
    user {
      refresh_access_token(refresh_token: $refresh_token) {
        token_type
        refresh_token
        token
      }
    }
  }
`;

export { LOGIN, GET_NEW_TOKEN };
