import React, { useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { LoadingDialog } from "../../../../../../_metronic/_partials/controls";

export const LocationsLoadingDialog = () => {
  const { isLoading } = useSelector(
    (state) => ({
      isLoading: state.locations.listLoading,
    }),
    shallowEqual
  );

  useEffect(() => {}, [isLoading]);
  return <LoadingDialog isLoading={isLoading} text="Loading..." />;
};
