import React, { useMemo } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Card,
  CardHeader,
  Input,
} from "../../../../../../_metronic/_partials/controls";
import AsteriskLabel from "../../../../../../utils/Asterisk";
import { LimitDecimalPlaces } from "../../../../../../utils/input";

export function CreditTypeEditForm({
  saveCreditType,
  isEditing,
  credit_type,
  actionsLoading,
  onHide,
}) {
  // Validation schema
  const CreditTypeEditSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string()
          .trim()
          .required("Name is required"),
        credit: Yup.number().min(1, "Credit must be greater than 0"),
        price: Yup.number().min(1, "Price must be greater than 0"),
      }),
    //eslint-disable-next-line
    [isEditing]
  );

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...credit_type,
        }}
        validationSchema={CreditTypeEditSchema}
        onSubmit={(values) => {
          saveCreditType(values);
        }}
      >
        {({ handleSubmit, values, errors, setFieldValue }) => (
          <>
            {actionsLoading && (
              <div className="overlay-layer bg-transparent">
                <div className="spinner spinner-lg spinner-success" />
              </div>
            )}
            <Form className="form form-label-right row">
              <div className="col-lg-6">
                <Card className="px-2">
                  <CardHeader title={"General"} />
                  <div className="form-group flex flex-wrap pt-4">
                    {/* Name */}
                    <div className="col-lg-12 mb-4">
                      <Field
                        name="name"
                        component={Input}
                        placeholder="Name"
                        label={<AsteriskLabel word="Name" />}
                      />
                    </div>
                    <div className="col-lg-12 mb-4">
                      <Field
                        name="credit"
                        type="number"
                        min="0"
                        step="0.01"
                        component={Input}
                        onChange={(e) => {
                          setFieldValue(
                            "credit",
                            LimitDecimalPlaces(e.target.value, 2)
                          );
                        }}
                        placeholder="Credit"
                        label={<AsteriskLabel word="Credits" />}
                      />
                    </div>
                    <div className="col-lg-12 mb-4">
                      <Field
                        name="price"
                        type="number"
                        min="0"
                        step="0.01"
                        component={Input}
                        onChange={(e) => {
                          setFieldValue(
                            "price",
                            LimitDecimalPlaces(e.target.value, 2)
                          );
                        }}
                        placeholder="Price"
                        label={<AsteriskLabel word="Price" />}
                      />
                    </div>
                  </div>
                </Card>
              </div>
            </Form>
            <Card className="_card-toolbar-start">
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
            </Card>
          </>
        )}
      </Formik>
    </>
  );
}
