import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { customersSlice } from "../app/modules/ECommerce/_redux/customers/customersSlice";
import { productsSlice } from "../app/modules/ECommerce/_redux/products/productsSlice";
import { remarksSlice } from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
import { specificationsSlice } from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";
import { usersSlice } from "../app/modules/Users/_redux/users/usersSlice";
import { instructorsSlice } from "../app/modules/Instructors/_redux/instructors/instructorsSlice";
import { requirementsSlice } from "../app/modules/Requirements/_redux/requirements/requirementsSlice";
import { locationsSlice } from "../app/modules/Locations/_redux/locations/locationsSlice";
import { newsSlice } from "../app/modules/News/_redux/newsSlice";
import { classesSlice } from "../app/modules/Class/_redux/classes/classesSlice";
import { eventsSlice } from "../app/modules/Event/List/_redux/events/eventsSlice";
import { eventRecurringsSlice } from "../app/modules/Event/EventRecurring/_redux/eventRecurrings/eventRecurringsSlice";
import { eventBookingsSlice } from "../app/modules/Event/EventBooking/_redux/eventBookings/eventBookingsSlice";
import { studiosSlice } from "../app/modules/Studio/List/_redux/studiosSlice";
import { studioImagesSlice } from "../app/modules/Studio/Gallery/_redux/studioImagesSlice";
import { creditHistorySlice } from "../app/modules/PurchaseHistory/CreditHistory/_redux/creditHistorySlice";
import { settingSlice } from "../app/pages/config/Setting/_redux/settingSlice";
import { virtualUnlimitedHistorySlice } from "../app/modules/PurchaseHistory/VirtualUnlimitedHistory/_redux/virtualUnlimitedHistorySlice";
import { userContactsSlice } from "../app/modules/UserContact/_redux/userContacts/userContactsSlice";
import { refundRequestSlice } from "../app/modules/RefundRequest/_redux/refundRequestSlice";
import { creditTypesSlice } from "../app/modules/CreditType/_redux/creditTypes/creditTypesSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  customers: customersSlice.reducer,
  products: productsSlice.reducer,
  remarks: remarksSlice.reducer,
  specifications: specificationsSlice.reducer,
  users: usersSlice.reducer,
  instructors: instructorsSlice.reducer,
  requirements: requirementsSlice.reducer,
  locations: locationsSlice.reducer,
  news: newsSlice.reducer,
  classes: classesSlice.reducer,
  studios: studiosSlice.reducer,
  events: eventsSlice.reducer,
  studioImages: studioImagesSlice.reducer,
  event_recurrings: eventRecurringsSlice.reducer,
  creditHistory: creditHistorySlice.reducer,
  event_bookings: eventBookingsSlice.reducer,
  setting: settingSlice.reducer,
  virtualUnlimitedHistory: virtualUnlimitedHistorySlice.reducer,
  user_contacts: userContactsSlice.reducer,
  refundRequest: refundRequestSlice.reducer,
  credit_types: creditTypesSlice.reducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
