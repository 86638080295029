export const datas = [
  {
    path: "/dashboard",
    name: "Dashboard",
    faIconClass: "fas fa-house",
  },
  {
    path: "",
    name: "Event",
    faIconClass: "fas fa-calendar-alt",
    nested: [
      {
        path: "/event/list",
        name: "Event",
        hasBullet: true,
      },
      {
        path: "/event/recurrings",
        name: "Event Recurring",
        hasBullet: true,
      },
    ],
  },
  {
    path: "/event/bookings",
    name: "Booking",
    faIconClass: "fas fa-check-circle",
  },
  {
    path: "/clients/refund-requests",
    name: "Refund Request",
    faIconClass: "fas fa-exchange-alt",
  },

  {
    path: "/purchase-histories/virtual-unlimited-histories",
    name: "Virtual Unlimited",
    faIconClass: "fas fa-money-bill-wave",
  },
  {
    path: "",
    name: "User Management",
    faIconClass: "fas fa-users",
    nested: [
      {
        path: "/users",
        name: "User",
        hasBullet: true,
      },
      {
        path: "/purchase-histories/credit-histories",
        name: "Credit History",
        hasBullet: true,
      },
    ],
  },
  {
    path: "/instructors",
    name: "Instructor",
    faIconClass: "fas fa-chalkboard-teacher",
  },
  {
    path: "",
    name: "Class",
    faIconClass: "fas fa-graduation-cap",
    nested: [
      {
        path: "/classes",
        name: "Class",
        hasBullet: true,
      },
      {
        path: "/requirements",
        name: "Class Requirement",
        hasBullet: true,
      },
    ],
  },
  {
    path: "/studios",
    name: "Studio",
    faIconClass: "fas fa-building",
  },
  {
    path: "/clients/contacts",
    name: "Contact Us",
    faIconClass: "fas fa-address-book",
  },
  {
    path: "/news",
    name: "News",
    faIconClass: "fas fa-newspaper",
  },
  {
    path: "",
    name: "Settings",
    faIconClass: "fas fa-cogs",
    nested: [
      {
        path: "/credit-types",
        name: "Credit Type",
        hasBullet: true,
      },
      {
        path: "/config/setting",
        name: "Setting",
        hasBullet: true,
      },
    ],
  },
];
