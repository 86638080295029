import React from "react";
import { Box } from "@material-ui/core";

export function PaymentMethodFormatter(cell, row) {
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
      }}>
      <img
        alt=""
        width={50}
        src={
          row.payment_method === "STRIPE"
            ? "/media/svg/payments/stripe_small.svg"
            : "/media/svg/payments/paynow_small.svg"
        }
      />
    </Box>
  );
}
