import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import { RefundRequestFilter } from "./refund-request-filter/RefundRequestFilter";
import { RefundRequestTable } from "./refund-request-table/RefundRequestTable";

export const RefundRequestCard = () => {
  return (
    <Card>
      <CardHeader title="Refund Requests">
        <CardHeaderToolbar></CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <RefundRequestFilter />
        <RefundRequestTable />
      </CardBody>
    </Card>
  );
};
