import { gql } from "@apollo/client";

const ME = gql`
  query getMe {
    me {
      id
      first_name
      last_name
      email
      image_path
      phone_number
      phone_number_verified_at
      is_admin
    }
  }
`;

const USER_UPDATE_CREDIT = gql`
  mutation($id: Int!, $credit: Float!, $reason: String!) {
    user {
      update_user_credit(id: $id, credit: $credit, reason: $reason) {
        id
        email
        email_verified_at
        first_name
        last_name
        phone_number
        phone_number_verified_at
        credit
        created_at
        accept_marketing
        accept_notification
        image_link
        image_path
        is_admin
        is_banned
        unbanned_at
        updated_at
      }
    }
  }
`;

const USERS_PAGINATE = gql`
  query(
    $page: Int
    $limit: Int
    $sort_by: UserSortableField
    $sort_order: SortOrder
    $email_verified_at: Date
    $phone_number_verified_at: Date
    $is_admin: Boolean
    $phrase: String
    $is_instructor: Boolean
  ) {
    users(
      page: $page
      limit: $limit
      filter: {
        sort_by: $sort_by
        sort_order: $sort_order
        email_verified_at: $email_verified_at
        phone_number_verified_at: $phone_number_verified_at
        is_admin: $is_admin
        phrase: $phrase
        is_instructor: $is_instructor
      }
    ) {
      total
      nodes {
        id
        email
        email_verified_at
        first_name
        last_name
        phone_number
        phone_number_verified_at
        credit
        credit_expired_at
        created_at
        accept_marketing
        accept_notification
        image_link
        image_path
        is_admin
        is_banned
        unbanned_at
        updated_at
      }
    }
  }
`;

const USER_BY_ID = gql`
  query($id: Int!) {
    user(id: $id) {
      id
      email
      email_verified_at
      first_name
      last_name
      birth_date
      birth_month
      phone_number
      phone_number_verified_at
      credit
      credit_expired_at
      created_at
      updated_at
      image_link
      image_path
      accept_marketing
      accept_notification
      virtual_unlimited_until
      is_admin
      is_banned
      unbanned_at
      updated_at
    }
  }
`;

const USER_CREATE = gql`
  mutation(
    $email: String!
    $password: String!
    $first_name: String!
    $last_name: String
    $birth_date: Int!
    $birth_month: Int!
    $phone_number: String!
    $accept_marketing: Boolean!
    $accept_notification: Boolean!
    $is_admin: Boolean!
    $image_path: String
    $avatar: Upload
  ) {
    user {
      create_user(
        input: {
          email: $email
          password: $password
          first_name: $first_name
          last_name: $last_name
          birth_date: $birth_date
          birth_month: $birth_month
          phone_number: $phone_number
          accept_marketing: $accept_marketing
          accept_notification: $accept_notification
          image_path: $image_path
          avatar: $avatar
        }
        is_admin: $is_admin
      ) {
        id
        email
        email_verified_at
        first_name
        last_name
        phone_number
        phone_number_verified_at
        credit
        created_at
        accept_marketing
        accept_notification
        image_link
        image_path
        is_admin
        is_banned
        unbanned_at
        updated_at
      }
    }
  }
`;

const USER_UPDATE = gql`
  mutation(
    $id: Int!
    $email: String!
    $first_name: String!
    $last_name: String
    $birth_date: Int!
    $birth_month: Int!
    $phone_number: String!
    $credit_expired_at: Time!
    $accept_marketing: Boolean!
    $accept_notification: Boolean!
    $is_admin: Boolean
    $image_path: String
    $avatar: Upload
  ) {
    user {
      update_user(
        id: $id
        input: {
          email: $email
          first_name: $first_name
          last_name: $last_name
          birth_date: $birth_date
          birth_month: $birth_month
          phone_number: $phone_number
          credit_expired_at: $credit_expired_at
          accept_marketing: $accept_marketing
          accept_notification: $accept_notification
          image_path: $image_path
          avatar: $avatar
        }
        is_admin: $is_admin
      ) {
        id
        email
        email_verified_at
        first_name
        last_name
        phone_number
        phone_number_verified_at
        credit
        credit_expired_at
        accept_marketing
        accept_notification
        image_link
        image_path
        is_admin
        is_banned
        unbanned_at
        created_at
        updated_at
      }
    }
  }
`;

// const USER_CREDIT_UPDATE = gql`
//   mutation updateUserCredit($id: Int!, $credit: Float!) {
//     user {
//       update_user_credit(id: $id, credit: $credit) {
//         id
//         email
//         email_verified_at
//         first_name
//         last_name
//         birth_date
//         birth_month
//         phone_number
//         phone_number_verified_at
//         credit
//         credit_expired_at
//         created_at
//         updated_at
//         image_link
//         image_path
//         accept_marketing
//         accept_notification
//         virtual_unlimited_until
//         is_admin
//         is_banned
// unbanned_at
//       }
//     }
//   }
// `;

const BAN_USER = gql`
  mutation BAN_USER($id: Int!, $weeks: Int!) {
    user {
      ban_user(id: $id, weeks: $weeks) {
        id
        email
        email_verified_at
        first_name
        last_name
        phone_number
        phone_number_verified_at
        credit
        created_at
        accept_marketing
        accept_notification
        image_link
        image_path
        is_admin
        is_banned
        unbanned_at
        updated_at
      }
    }
  }
`;

const UNBAN_USER = gql`
  mutation UNBAN_USER($id: Int!) {
    user {
      unban_user(id: $id) {
        id
        email
        email_verified_at
        first_name
        last_name
        phone_number
        phone_number_verified_at
        credit
        created_at
        accept_marketing
        accept_notification
        image_link
        image_path
        is_admin
        is_banned
        unbanned_at
        updated_at
      }
    }
  }
`;

export {
  ME,
  USERS_PAGINATE,
  USER_UPDATE_CREDIT,
  USER_BY_ID,
  USER_CREATE,
  USER_UPDATE,
  // USER_CREDIT_UPDATE,
  BAN_USER,
  UNBAN_USER,
};
