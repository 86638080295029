import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../../_metronic/_partials/controls";
import { UserContactsTable } from "./user-contacts-table/UserContactsTable";
import { UserContactsFilter } from "./user-contacts-filter/UserContactsFilter";

export const UserContactsCard = () => {
  return (
    <Card>
      <CardHeader title="User Contact List"></CardHeader>
      <CardBody>
        <UserContactsFilter />
        <UserContactsTable />
      </CardBody>
    </Card>
  );
};
