import React, { useEffect, useMemo } from "react";
import * as actions from "../../../_redux/requirements/requirementsActions";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import moment from "moment";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import * as uiHelpers from "../RequirementsUIHelpers";
import * as columnFormatters from "./column-formatters";
import { useRequirementsUIContext } from "../RequirementsUIContext";
import BootstrapTable from "react-bootstrap-table-next";
import { DateTimeColumnFormatter } from "../../../../../../utils/DateTimeColumnFormatter";

export const RequirementsTable = () => {
  // Users UI Context
  const requirementsUIContext = useRequirementsUIContext();
  const requirementsUIProps = useMemo(() => {
    return {
      ids: requirementsUIContext.ids,
      setIds: requirementsUIContext.setIds,
      queryParams: requirementsUIContext.queryParams,
      setQueryParams: requirementsUIContext.setQueryParams,
      openEditRequirementDialog:
        requirementsUIContext.openEditRequirementDialog,
      openDeleteRequirementDialog:
        requirementsUIContext.openDeleteRequirementDialog,
    };
  }, [requirementsUIContext]);

  // Get Current State of Users from Store
  const { currentState } = useSelector(
    (state) => ({ currentState: state.requirements }),
    shallowEqual
  );
  const { entities } = currentState;

  // Users Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      requirementsUIProps.setIds([]);
      dispatch(actions.fetchRequirements(requirementsUIProps.queryParams));
    }, 500);

    return () => clearTimeout(delayDebounceFn);

    // eslint-disable-next-line
  }, [requirementsUIProps.queryParams, dispatch]);

  const columns = [
    // {
    //   dataField: "id",
    //   text: "ID",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   headerSortingClasses,
    // },
    {
      dataField: "image_path",
      text: "Display Icon",
      sort: false,
      sortCaret: sortCaret,
      formatter: columnFormatters.ImagePathColumnFormatter,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "created_at",
      text: "Created At",
      sort: false,
      sortCaret: sortCaret,
      formatter: DateTimeColumnFormatter,
      style: () => ({
        minWidth: "150px",
      }),
    },
    {
      dataField: "updated_at",
      text: "Updated At",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      classes: "text-nowrap",
      formatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD HH:mm:ss"): "-",
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditRequirementDialog:
          requirementsUIProps.openEditRequirementDialog,
        openDeleteRequirementDialog:
          requirementsUIProps.openDeleteRequirementDialog,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  return (
    <>
      <BootstrapTable
        wrapperClasses="table-responsive"
        bordered={false}
        classes="table table-head-custom table-vertical-center overflow-hidden"
        bootstrap4
        remote
        keyField="id"
        data={entities === null ? [] : entities}
        columns={columns}
        defaultSorted={uiHelpers.defaultSorted}
        onTableChange={getHandlerTableChange(
          requirementsUIProps.setQueryParams
        )}>
        <PleaseWaitMessage entities={entities} />
        <NoRecordsFoundMessage entities={entities} />
      </BootstrapTable>
    </>
  );
};
