import { gql } from "@apollo/client";

const LOCATION_GET_BY_ID = gql`
  query($id: Int!) {
    location(id: $id) {
      id
      name
      building_name
      street_name
      block_no
      unit_no
      level_no
      postal_code
      longitude
      latitude
      notes_html
      region
      updated_at
    }
  }
`;

const LOCATION_GET_ALL = gql`
  query($phrase: String) {
    locations(phrase: $phrase) {
      id
      name
      building_name
      street_name
      block_no
      unit_no
      level_no
      postal_code
      longitude
      latitude
      notes_html
      region
      updated_at
    }
  }
`;

const LOCATION_CREATE = gql`
  mutation(
    $name: String!
    $building_name: String
    $street_name: String!
    $block_no: String!
    $unit_no: String
    $level_no: String
    $postal_code: String!
    $longitude: Float!
    $latitude: Float!
    $notes_html: String
    $region: Region!
  ) {
    location {
      create(
        input: {
          name: $name
          building_name: $building_name
          street_name: $street_name
          block_no: $block_no
          unit_no: $unit_no
          level_no: $level_no
          postal_code: $postal_code
          longitude: $longitude
          latitude: $latitude
          notes_html: $notes_html
          region: $region
        }
      ) {
        id
        name
        building_name
        street_name
        block_no
        unit_no
        level_no
        postal_code
        longitude
        latitude
        notes_html
        region
        updated_at
      }
    }
  }
`;

const LOCATION_UPDATE = gql`
  mutation(
    $id: Int!
    $name: String!
    $building_name: String
    $street_name: String!
    $block_no: String!
    $unit_no: String
    $level_no: String
    $postal_code: String!
    $longitude: Float!
    $latitude: Float!
    $notes_html: String
    $region: Region!
  ) {
    location {
      update(
        id: $id
        input: {
          name: $name
          building_name: $building_name
          street_name: $street_name
          block_no: $block_no
          unit_no: $unit_no
          level_no: $level_no
          postal_code: $postal_code
          longitude: $longitude
          latitude: $latitude
          notes_html: $notes_html
          region: $region
        }
      ) {
        id
        name
        building_name
        street_name
        block_no
        unit_no
        level_no
        postal_code
        longitude
        latitude
        notes_html
        region
        updated_at
      }
    }
  }
`;

const LOCATION_DELETE = gql`
  mutation($id: Int!) {
    location {
      delete(id: $id)
    }
  }
`;

export {
  LOCATION_GET_ALL,
  LOCATION_GET_BY_ID,
  LOCATION_CREATE,
  LOCATION_UPDATE,
  LOCATION_DELETE,
};
