import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";

export function ActionsColumnFormatter(cell, row, rowIndex, formatExtraData) {
  return (
    <>
      <button
        type="button"
        title="Edit customer"
        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
        onClick={() => formatExtraData.openEditRequirementDialog(row.id)}>
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG
            title="Edit customer"
            src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
          />
        </span>
      </button>

      <button
        type="button"
        title="Delete requirement"
        className="btn btn-icon btn-light btn-hover-danger btn-sm"
        onClick={() => formatExtraData.openDeleteRequirementDialog(row.id)}>
        <span className="svg-icon svg-icon-md svg-icon-danger">
          <SVG title="Delete requirement" src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
        </span>
      </button>
    </>
  );
}
