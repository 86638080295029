import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";

export function ActionsColumnFormatter(
  cellContent,
  row,
  rowIndex,
  { openEditClassDialog, openDeleteClassDialog }
) {
  return (
    <>
      <button
        type="button"
        title="Edit Class"
        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
        onClick={() => openEditClassDialog(row.id)}>
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG
            title="Edit Class"
            src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
          />
        </span>
      </button>
      <> </>

      <button
        type="button"
        title="Delete Class"
        className="btn btn-icon btn-light btn-hover-danger btn-sm"
        onClick={() => openDeleteClassDialog(row.id)}>
        <span className="svg-icon svg-icon-md svg-icon-danger">
        <SVG 
          title="Delete Class"
          src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} 
        />
        </span>
      </button>
    </>
  );
}
