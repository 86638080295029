import React, { useMemo, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import moment from "moment";
import * as actions from "../../../_redux/instructors/instructorsActions";
import BootstrapTable from "react-bootstrap-table-next";
import {
  // getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { useInstructorsUIContext } from "../InstructorsUIContext";
import * as uiHelpers from "../InstructorsUIHelpers";
import * as columnFormatters from "./column-formatters";

export const InstructorsTable = () => {
  const instructorsUIContext = useInstructorsUIContext();
  const instructorsUIProps = useMemo(() => {
    return {
      ids: instructorsUIContext.ids,
      setIds: instructorsUIContext.setIds,
      queryParams: instructorsUIContext.queryParams,
      setQueryParams: instructorsUIContext.setQueryParams,
      openEditInstructorPage: instructorsUIContext.openEditInstructorPage,
      openDeleteInstructorDialog:
        instructorsUIContext.openDeleteInstructorDialog,
    };
  }, [instructorsUIContext]);

  const { currentState } = useSelector(
    (state) => ({ currentState: state.instructors }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;

  const dispatch = useDispatch();
  useEffect(() => {
    instructorsUIProps.setIds([]);
    dispatch(actions.fetchInstructors(instructorsUIProps.queryParams));

    // eslint-disable-next-line
  }, [instructorsUIProps.queryParams, dispatch]);

  const columns = [
    // {
    //   dataField: "id",
    //   text: "ID",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   headerSortingClasses,
    //   headerStyle: () => {
    //     return { width: "100px" };
    //   },
    // },
    {
      dataField: "image_link",
      text: "Avatar",
      sort: false,
      sortCaret: sortCaret,
      formatter: columnFormatters.ImagePathColumnFormatter,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      style: () => ({
        minWidth: "160px",
      }),
    },
    {
      dataField: "about",
      text: "About",
      sort: false,
      sortCaret: sortCaret,
      formatter: columnFormatters.EllipsisColumnFormatter,
    },
    {
      dataField: "updated_at",
      text: "Updated At",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      classes: "text-nowrap",
      formatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD HH:mm:ss") : "-",
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditInstructorPage: instructorsUIProps.openEditInstructorPage,
        openDeleteInstructorDialog:
          instructorsUIProps.openDeleteInstructorDialog,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  // Table Pagination Properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: instructorsUIProps.queryParams.pageSize,
    page: instructorsUIProps.queryParams.pageNumber,
  };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}>
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  instructorsUIProps.setQueryParams
                )}
                // selectRow={getSelectRow({
                //   entities,
                //   ids: instructorsUIProps.ids,
                //   setIds: instructorsUIProps.setIds,
                // })}
                {...paginationTableProps}>
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
};
