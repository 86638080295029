import * as requestFromServer from "./creditTypesCrud";
import { creditTypesSlice, callTypes } from "./creditTypesSlice";

const { actions } = creditTypesSlice;

export const createCreditType = (credit_type) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createCreditType(credit_type)
    .then((response) => {
      const {
        data: {
          credit_type: { create },
        },
      } = response;
      dispatch(actions.creditTypeCreated({ credit_type: create }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateCreditType = (credit_type) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateCreditType(credit_type)
    .then((response) => {
      const {
        data: {
          credit_type: { update },
        },
      } = response;
      dispatch(actions.creditTypeUpdated({ credit_type: update }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchCreditTypes = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findCreditTypes(queryParams)
    .then((response) => {
      const {
        data: { credit_types },
      } = response;
      dispatch(
        actions.creditTypesFetched({
          totalCount: credit_types.length,
          entities: credit_types,
        })
      );
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchCreditTypesAll = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllCreditTypes()
    .then((response) => {
      const {
        data: {
          credit_types: { total, nodes },
        },
      } = response;
      dispatch(
        actions.creditTypesFetched({
          totalCount: total,
          entities: nodes,
        })
      );
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchCreditType = (id) => (dispatch) => {
  if (!id) {
    return dispatch(
      actions.creditTypeFetched({ creditTypeForEdit: undefined })
    );
  }
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getCreditTypeById(id)
    .then((response) => {
      const {
        data: { credit_type },
      } = response;
      dispatch(
        actions.creditTypeFetched({
          creditTypeForEdit: {
            ...credit_type,
          },
        })
      );
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteCreditType = (id) => (dispatch) => {
  if (!id) {
    return;
  }
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteCreditType(id)
    .then((_) => {
      dispatch(actions.creditTypeDeleted({ id: id }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      throw error;
    });
};
