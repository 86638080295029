import { createSlice } from "@reduxjs/toolkit";

const initialClassesState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [],
  classForEdit: undefined,
  lastError: null,
};

export const callTypes = {
  list: "list",
  action: "action",
};

export const classesSlice = createSlice({
  name: "classes",
  initialState: initialClassesState,
  reducers: {
    catchErrors: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if ((action.payload.callType = callTypes.list)) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    classFetched: (state, action) => {
      state.actionsLoading = false;
      state.classForEdit = action.payload.classForEdit;
      state.error = null;
    },
    classesFetched: (state, action) => {
      const { entities, totalCount } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.totalCount = totalCount;
      state.entities = entities;
    },
    classCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.class);
    },
    classUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.map((entity) =>
        entity.id === action.payload.class.id ? action.payload.class : entity
      );
    },
    classDeleted: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.filter((entity) => entity.id !== action.payload.id);
    },
  },
});
