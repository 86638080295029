import * as requestFromServer from "./refundRequestCrud";
import { refundRequestSlice, callTypes } from "./refundRequestSlice";

const { actions } = refundRequestSlice;

export const fetchRefundRequests = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return requestFromServer
    .findRefundRequests(queryParams)
    .then((response) => {
      const {
        data: {
          refund_requests: { total, nodes },
        },
      } = response;

      dispatch(
        actions.refundRequestsFetched({
          totalCount: total,
          entities: nodes,
        })
      );
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchRefundRequest = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .findRefundRequest(id)
    .then((response) => {
      const {
        data: {
          refund_request
        },
      } = response;

      dispatch(
        actions.refundRequestFetched({
          refundRequestForEdit: refund_request
        })
      );
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};