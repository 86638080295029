import React, { useMemo } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  Card,
  CardHeader,
  Input,
} from "../../../../../../_metronic/_partials/controls";
import { UserAsyncSelect } from "./form-extras";
import ModalCropper from "../../../../../../utils/ModalCropper";
import AsteriskLabel from "../../../../../../utils/Asterisk";

export function InstructorEditForm({
  saveInstructor,
  isEditing,
  instructor,
  actionsLoading,
  onHide,
}) {
  // Validation schema
  const InstructorEditSchema = useMemo(
    () =>
      isEditing
        ? Yup.object().shape({
            user_id: Yup.string().required("User is required"),
            name: Yup.string()
              .trim()
              .max(50, "Maximum 50 symbols")
              .required("Name is required"),
            about: Yup.string()
              .trim()
              .required("About is required"),
          })
        : Yup.object().shape({
            name: Yup.string()
              .trim()
              .max(50, "Maximum 50 symbols")
              .required("Name is required"),
            about: Yup.string().required("About is required"),
          }),
    [isEditing]
  );

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...instructor,
          selected_val: instructor.user,
          backup_avatar: null,
        }}
        validationSchema={InstructorEditSchema}
        validate={(values) => {
          const errors = {};
          if (!values.user_id) {
            errors.user_id = "Required";
          }
          if (!values.about) {
            errors.about = "Required";
          }
          return errors;
        }}
        onSubmit={(values) => {
          saveInstructor(values);
        }}
      >
        {({ handleSubmit, values, errors, setFieldValue }) => {
          return (
            <>
              <div className="overlay overlay-block cursor-default">
                {actionsLoading && (
                  <div className="overlay-layer bg-transparent">
                    <div className="spinner spinner-lg spinner-success" />
                  </div>
                )}
                <Form className="form form-label-right row">
                  <div className="col-lg-6">
                    <Card className="px-2">
                      <CardHeader title={"General"} />
                      <div className="form-group flex flex-wrap pt-4">
                        {/* User */}
                        <div className="col-lg-12 mb-4">
                          <label>{<AsteriskLabel word="User" />}</label>
                          {isEditing ? (
                            instructor.id && (
                              <>
                                <UserAsyncSelect
                                  isEdit={isEditing}
                                  currentUser={instructor.user}
                                  value={
                                    values.selected_val
                                      ? {
                                          id: values.selected_val.id,
                                          first_name:
                                            values.selected_val.first_name,
                                          last_name:
                                            values.selected_val.last_name || "",
                                        }
                                      : null
                                  }
                                  isError={errors.user_id}
                                  onChange={(option) => {
                                    setFieldValue("user_id", option.id);
                                    setFieldValue("selected_val", option);
                                  }}
                                />
                              </>
                            )
                          ) : (
                            <UserAsyncSelect
                              value={
                                values.selected_val
                                  ? {
                                      id: values.selected_val.id,
                                      first_name:
                                        values.selected_val.first_name,
                                      last_name:
                                        values.selected_val.last_name || "",
                                    }
                                  : null
                              }
                              isError={errors.user_id}
                              onChange={(option) => {
                                setFieldValue("user_id", option.id);
                                setFieldValue("selected_val", option);
                              }}
                            />
                          )}

                          <ErrorMessage
                            className="text-danger"
                            name="user_id"
                            component="div"
                          />
                        </div>
                        {/* Name */}
                        <div className="col-lg-12 mb-4">
                          <Field
                            name="name"
                            component={Input}
                            placeholder="Name"
                            label={<AsteriskLabel word="Name" />}
                          />
                        </div>
                        {/* Login */}
                        <div className="col-lg-12 mb-4">
                          <label>{<AsteriskLabel word="Avatar Image" />}</label>
                          <br />
                          <ModalCropper
                            onSaved={(blob, file) => {
                              setFieldValue("avatar", file);
                            }}
                            initialValue={values.image_link}
                            aspectRatio={3 / 4}
                            width={300}
                            height={400}
                            cropperHeight={"400px"}
                            cropperWidth={"300px"}
                            modalTitle={"Avatar Image"}
                          />
                        </div>
                      </div>
                    </Card>
                  </div>
                  <div className="col-lg-6">
                    <Card className="px-2">
                      <CardHeader title={"Additional Information"} />
                      <div className="form-group flex flex-wrap pt-4">
                        <div className="col-lg-12 mb-4">
                          <label>{<AsteriskLabel word="About" />}</label>
                          <ReactQuill
                            theme="snow"
                            value={values?.about}
                            onChange={(val) => {
                              setFieldValue("about", val);
                            }}
                          />
                          <ErrorMessage
                            className="text-danger"
                            name="about"
                            component="div"
                          />
                        </div>
                      </div>
                    </Card>
                  </div>
                </Form>
              </div>
              <Card className="_card-toolbar-start">
                <button
                  type="submit"
                  onClick={() => handleSubmit()}
                  className="btn btn-primary btn-elevate"
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={onHide}
                  className="btn btn-light btn-elevate"
                >
                  Cancel
                </button>
              </Card>
            </>
          );
        }}
      </Formik>
    </>
  );
}
