import { gql } from "@apollo/client";

const STUDIOS = gql`
  query studios(
    $comparables: [StudioComparable]
    $phrase: String
    $is_rentable: Boolean
    $sort_by: StudioSortableField
    $sort_order: SortOrder
  ) {
    studios(
      filter: {
        comparables: $comparables
        phrase: $phrase
        is_rentable: $is_rentable
        sort_by: $sort_by
        sort_order: $sort_order
      }
    ) {
      id
      name
      color_hex
      address
      area_sqft
      normal_rate
      peak_hour_rate
      is_rentable
      created_at
      updated_at
      images {
        id
        position
        created_at
        image_path
        image_link
      }
    }
  }
`;

const STUDIO = gql`
  query studio($id: Int!) {
    studio(id: $id) {
      id
      name
      color_hex
      address
      region
      latitude
      longitude
      area_sqft
      normal_rate
      peak_hour_rate
      description_html
      is_rentable
      created_at
      updated_at
      images {
        id
        position
        created_at
        image_path
        image_link
      }
    }
  }
`;

const CREATE_STUDIO = gql`
  mutation createStudio(
    $name: String!
    $color_hex: String!
    $address: String!
    $region: Region!
    $latitude: Float!
    $longitude: Float!
    $area_sqft: Float
    $normal_rate: Float
    $peak_hour_rate: Float
    $is_rentable: Boolean!
    $studio_images: [StudioImageInput!]!
    $description_html: String
  ) {
    studio {
      create(
        input: {
          name: $name
          color_hex: $color_hex
          address: $address
          region: $region
          latitude: $latitude
          longitude: $longitude
          area_sqft: $area_sqft
          normal_rate: $normal_rate
          peak_hour_rate: $peak_hour_rate
          is_rentable: $is_rentable
          studio_images: $studio_images
          description_html: $description_html
        }
      ) {
        id
        name
        color_hex
        address
        region
        latitude
        longitude
        area_sqft
        normal_rate
        peak_hour_rate
        description_html
        is_rentable
        created_at
        updated_at
      }
    }
  }
`;

const UPDATE_STUDIO = gql`
  mutation updateStudio(
    $id: Int!
    $name: String!
    $color_hex: String!
    $address: String!
    $region: Region!
    $latitude: Float!
    $longitude: Float!
    $area_sqft: Float
    $normal_rate: Float
    $peak_hour_rate: Float
    $is_rentable: Boolean!
    $studio_images: [StudioImageInput!]!
    $description_html: String
  ) {
    studio {
      update(
        input: {
          name: $name
          color_hex: $color_hex
          address: $address
          region: $region
          latitude: $latitude
          longitude: $longitude
          area_sqft: $area_sqft
          normal_rate: $normal_rate
          peak_hour_rate: $peak_hour_rate
          is_rentable: $is_rentable
          studio_images: $studio_images
          description_html: $description_html
        }
        id: $id
      ) {
        id
        name
        color_hex
        address
        region
        latitude
        longitude
        area_sqft
        normal_rate
        peak_hour_rate
        description_html
        is_rentable
        created_at
        updated_at
      }
    }
  }
`;

const DELETE_STUDIO = gql`
  mutation deleteStudio($id: Int!) {
    studio {
      delete(id: $id)
    }
  }
`;

const STUDIOS_IMAGES = gql`
  query studiosImages($phrase: String) {
    studios(filter: { phrase: $phrase }) {
      id
      name
      location {
        id
        building_name
        street_name
        block_no
        unit_no
        level_no
        postal_code
      }
      images {
        id
        position
        created_at
        image_link
      }
      is_rentable
    }
  }
`;

const STUDIOS_PREVIEW = gql`
  query studiosPreview($phrase: String) {
    studios(filter: { phrase: $phrase }) {
      id
      name
      is_rentable
    }
  }
`;

const STUDIO_IMAGES = gql`
  query studiosImages($id: Int!) {
    studio(id: $id) {
      id
      images {
        id
        position
        created_at
        image_link
        studio_id
      }
    }
  }
`;

const CREATE_STUDIO_IMAGE = gql`
  mutation createStudioImage(
    $studio_id: Int!
    $position: Int!
    $image: Upload!
  ) {
    studio {
      image {
        create(
          input: { studio_id: $studio_id, position: $position, image: $image }
        ) {
          id
          position
          image_link
          created_at
        }
      }
    }
  }
`;

const UDPATE_STUDIO_IMAGE = gql`
  mutation updateStudioImage($id: Int!, $position: Int, $image: Upload) {
    studio {
      image {
        update(id: $id, position: $position, new_image: $image) {
          id
          position
          image_link
          created_at
          updated_at
        }
      }
    }
  }
`;

const DELETE_STUDIO_IMAGE = gql`
  mutation deleteStudioImage($id: Int!) {
    studio {
      image {
        delete(id: $id)
      }
    }
  }
`;

const STUDIO_IMAGE = gql`
  query getStudioImage($id: Int!) {
    studio_image(id: $id) {
      id
      position
      image_link
      created_at
    }
  }
`;

const STUDIO_IMAGE_UPLOAD = gql`
  mutation studioImageUpload($image: Upload!) {
    studio {
      image_upload(image: $image) {
        image_path
        image_link
      }
    }
  }
`;

export {
  STUDIOS,
  CREATE_STUDIO,
  UPDATE_STUDIO,
  DELETE_STUDIO,
  STUDIO,
  STUDIOS_IMAGES,
  STUDIOS_PREVIEW,
  STUDIO_IMAGES,
  CREATE_STUDIO_IMAGE,
  UDPATE_STUDIO_IMAGE,
  STUDIO_IMAGE,
  DELETE_STUDIO_IMAGE,
  STUDIO_IMAGE_UPLOAD,
};
