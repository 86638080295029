import axios from "axios";
import { ME, LOGIN, GET_NEW_TOKEN } from "../../../../graphql/index";
import { apolloClient } from "../../../apollo/client";

export const LOGIN_URL = `${process.env.REACT_APP_API_URL}/auth/login`;
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const ME_URL = `${process.env.REACT_APP_API_URL}/auth/me`;

export function login(email, password) {
  return apolloClient.mutate({
    mutation: LOGIN,
    variables: {
      email: email,
      password: password,
    },
  }).then((response) => {
    const { user } = response.data.user.login;
    if(!user.is_admin) {
      throw new Error("User is not admin");
    }
    return response;
  });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  return apolloClient.query({
    query: ME,
    onError: (error) => {
      throw error;
    },
  });
}

export function getNewTokenByRefreshToken(refreshToken) {
  return apolloClient.mutate({
    mutation: GET_NEW_TOKEN,
    variables: {
      refresh_token: refreshToken,
    },
    onError: (error) => {
      throw error;
    },
  });
}
