import React, { createContext, useContext, useState, useCallback } from "react";
import {  isEqual, isFunction } from "lodash";
import { initialFilter } from "./RequirementsUIHelpers";
import { useSnackbar } from "notistack";

const RequirementsUIContext = createContext();

export function useRequirementsUIContext() {
  return useContext(RequirementsUIContext);
}

export const RequirementsUIConsumer = RequirementsUIContext.Consumer;

export const RequirementsUIProvider = ({ requirementsUIEvents, children }) => {
  const { enqueueSnackbar } = useSnackbar()
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }
      return nextQueryParams;
    });
  }, []);

  const initRequirement = {
    id: undefined,
    name: "",
    // image: null,
    image_path: "",
  };

  const notifyWithError = (message) => {
    enqueueSnackbar(message, {
      variant: "error",
      preventDuplicate: true,
      anchorOrigin: {
        vertical: "top",
        horizontal: "center",
      },
    });
  }

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initRequirement,
    notifyWithError,
    newRequirementButtonClick: requirementsUIEvents.newRequirementButtonClick,
    openEditRequirementDialog: requirementsUIEvents.openEditRequirementDialog,
    openDeleteRequirementDialog: requirementsUIEvents.openDeleteRequirementDialog,
  }

  return (
    <RequirementsUIContext.Provider value={value}>
      {children} 
    </RequirementsUIContext.Provider>
  );
}