import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { useInstructorsUIContext } from "./InstructorsUIContext";
import { InstructorsTable } from "./instructors-table/InstructorsTable";
import { InstructorsFilter } from "./instructors-filter/InstructorsFilter";

export const InstructorsCard = () => {
  const instructorsUIContext = useInstructorsUIContext();
  const instructorsUIProps = useMemo(() => {
    return {
      ids: instructorsUIContext.ids,
      newInstructorButtonClick: instructorsUIContext.newInstructorButtonClick,
    };
  }, [instructorsUIContext]);

  return (
    <Card>
      <CardHeader title="Instructors List">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={instructorsUIProps.newInstructorButtonClick}>
            New Instructors
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <InstructorsFilter />
        <InstructorsTable />
      </CardBody>
    </Card>
  );
};
