import React, { useEffect, useRef, useState } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Modal } from "react-bootstrap";

const ModalCropper = ({
  onHide: _onHide,
  initialValue,
  modalTitle,
  cropperHeight,
  cropperWidth,
  width,
  height,
  aspectRatio,
  onSaved: _onSaved,
}) => {
  const [show, setShow] = useState(false);
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const inputRef = useRef(null);
  const cropperRef = useRef(null);

  useEffect(() => {
    setPreview(initialValue);
  }, [initialValue]);

  const handleInputClick = async (e) => {
    inputRef.current.value = "";
  };

  const handleInputChange = (e) => {
    if (e.currentTarget.files.length > 0) {
      setShow(true);
      setImage(e.currentTarget.files[0]);
    } else {
      setShow(false);
    }
  };

  const onSave = (e) => {
    const imageElem = cropperRef?.current;
    const cropper = imageElem?.cropper;

    cropper
      .getCroppedCanvas({
        width: width,
        height: height,
        fillColor: "#fff",
        imageSmoothingEnabled: true,
        imageSmoothingQuality: "high",
      })
      .toBlob(
        (blob) => {
          var file = new File([blob], "avatar.jpeg", { type: "image/jpeg" });
          setPreview(URL.createObjectURL(blob));
          _onSaved(blob, file);
          // Hide Modal
          onHide();
        },
        "image/jpeg",
        1
      );
  };

  const onHide = () => {
    if (_onHide && typeof _onHide === "function") {
      _onHide();
    }

    // Hide Modal and reset Cropper image
    setShow(false);
    setImage(null);
  };

  // const clearImage = () => {
  //   setPreview(null);
  //   _onSaved("", null);
  // };

  return (
    <>
      <input
        type="file"
        style={{ display: "none" }}
        ref={inputRef}
        onClick={handleInputClick}
        onChange={handleInputChange}
      />
      <div className="_cropper-preview-container">
        <div
          className="_cropper-preview"
          style={{
            width: width,
            height: height,
            backgroundImage: preview ? `url(${preview})` : "",
          }}></div>
        <div
          className="_cropper-preview-edit-btn"
          onClick={() => inputRef.current.click()}>
          <i className="fa-solid fa-image" />
        </div>
        {/* <div className="_cropper-preview-delete-btn" onClick={clearImage}>
          <i className="fa-solid fa-x" />
        </div> */}
      </div>
      <Modal show={show} onHide={onHide}>
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Cropper
            src={image ? URL.createObjectURL(image) : initialValue}
            style={{ width: cropperWidth, height: cropperHeight }}
            aspectRatio={aspectRatio}
            guides={true}
            ref={cropperRef}
            viewMode={3}
            dragMode="move"
            cropBoxMovable={false}
            cropBoxResizable={false}
            background={true}
            checkCrossOrigin={true}
            checkOrientation={false}
            autoCropArea={1}
          />
        </Modal.Body>
        <Modal.Footer>
          <button onClick={onHide} className="btn btn-light">
            Close
          </button>
          <button onClick={onSave} className="btn btn-primary">
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalCropper;
