import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./UsersUIHelpers";

const UsersUIContext = createContext();

export function useUsersUIContext() {
  return useContext(UsersUIContext);
}

export const UsersUIConsumer = UsersUIContext.Consumer;

export function UsersUIProvider({ usersUIEvents, children }) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }
      return nextQueryParams;
    });
  }, []);

  const initUser = {
    id: undefined,
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    birth_date: 1,
    birth_month: 1,
    phone_number: "",
    // role: "",
    is_admin: false,
    accept_marketing: true,
    accept_notification: true,
    avatar: undefined
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initUser,
    newUserButtonClick: usersUIEvents.newUserButtonClick,
    openEditUserPage: usersUIEvents.openEditUserPage,
    openBanUserDialog: usersUIEvents.openBanUserDialog,
    openEditUserCreditDialog: usersUIEvents.openEditUserCreditDialog
  };

  return (
    <UsersUIContext.Provider value={value}>{children} </UsersUIContext.Provider>
  );
}
