import React from "react";
import moment from "moment";
import _ from "lodash";
import { DateRangePicker } from "materialui-daterange-picker";

export const CustomDateRangePicker = ({ onChange }) => {
  const [open, setOpen] = React.useState(false);
  const [dateRange, setDateRange] = React.useState({});

  const toggle = () => setOpen((prev) => !prev);
  const _onChange = (...params) => {
    if (onChange) {
      onChange(...params);
    }
  };

  const formatDate = () => {
    if (_.isEqual(dateRange, {})) return "";
    return (
      moment(dateRange.startDate).format("YYYY-MM-DD") +
      " - " +
      moment(dateRange.endDate).format("YYYY-MM-DD")
    );
  };

  return (
    <>
      <label>Start Date - End Date</label>
      <input
        className="form-control"
        label="Start/End Date"
        type="text"
        value={formatDate(dateRange)}
        onClick={() => toggle()}
        readOnly
      />
      {!_.isEqual(dateRange, {}) && (
        <button
          className="button-text-only text-danger"
          onClick={() => {
            setDateRange({});
            _onChange("", "");
          }}>
          CLEAR
        </button>
      )}
      <DateRangePicker
        open={open}
        toggle={toggle}
        onChange={(range) => {
          setDateRange(range);
          _onChange(range.startDate, range.endDate);
        }}
        wrapperClassName="date-range-picker-wrapper"
      />
    </>
  );
};
