import React from "react";

export const BooleanColumnFormatter = (cell, _) => {
  return (
    <div className="d-flex justify-content-center">
      {cell ? (
        <i style={{ color: "#0fe50f" }} className="fa-solid fa-check"></i>
      ) : (
        <i style={{ color: "red" }} className="fa-solid fa-x"></i>
      )}
    </div>
  );
};
