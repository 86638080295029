export const defaultSorted = [
  { dataField: "id", order: "desc" },
  { dataField: "created_at", order: "desc" },
];
export const sizePerPageList = [
  { text: "3", value: 3 },
  { text: "5", value: 5 },
  { text: "10", value: 10 },
];

export const initialFilter = {
  filter: {
    start_date: null,
    end_date: null,
    payment_methods: null,
    phrase: null,
    event_ids: null,
  },
  pageNumber: 1,
  pageSize: 10,
  sortOrder: "desc", // asc||desc
  sortField: "id",
};

export const AVAILABLE_PAYMENT_METHODS = [
  {
    id: "STRIPE",
    name: "Stripe",
  },
  {
    id: "PAY_NOW",
    name: "Pay Now",
  },
];

export const AVAILABLE_PAYMENT_STATUSES = ["Unpaid", "Paid", "Refunded"];
export const AVAILABLE_EVENT_BOOKING_STATUS = ["Pending", "Processing", "Canceled", "Completed", "Refund"]
