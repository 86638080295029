import React, { useMemo } from "react";
import { Formik, Field } from "formik";
import { debounce, isEqual } from "lodash";
import { useUserContactsUIContext } from "../UserContactsUIContext";
import ReactSelect from "react-select";
import { Input } from "../../../../../../_metronic/_partials/controls";
import { CustomDateRangePicker } from "../utils";

const prepareFilter = (queryParams, values) => {
  const newQueryParams = { ...queryParams };

  newQueryParams.filter = { ...values };
  return newQueryParams;
};

export function UserContactsFilter({ listLoading }) {
  const userContactsUIContext = useUserContactsUIContext();
  const userContactsUIProps = useMemo(() => {
    return {
      queryParams: userContactsUIContext.queryParams,
      setQueryParams: userContactsUIContext.setQueryParams,
    };
  }, [userContactsUIContext]);

  const applyFilter = (values) => {
    const newQueryParams = prepareFilter(
      userContactsUIProps.queryParams,
      values
    );
    if (!isEqual(newQueryParams, userContactsUIProps.queryParams)) {
      newQueryParams.pageNumber = 1;
      // update list by queryParams
      userContactsUIProps.setQueryParams(newQueryParams);
    }
  };
  const debouncedApplyFilter = debounce(applyFilter, 500);

  return (
    <>
      <Formik
        initialValues={{ ...userContactsUIProps.queryParams.filter }}
        onSubmit={(values) => {
          debouncedApplyFilter(values);
        }}>
        {({ values, handleSubmit, setFieldValue }) => {
          return (
            <form onSubmit={handleSubmit} className="form form-label-right">
              <div className="form-group row">
                <div className="col-lg-4">
                  <Field
                    name="phrase"
                    label="Search"
                    placeholder="Search"
                    component={Input}
                    type="text"
                    className="form-control"
                    value={values.phrase}
                    onChange={(e) => {
                      setFieldValue("phrase", e.target.value);
                      handleSubmit();
                    }}
                  />
                </div>
                <div className="col-lg-4">
                  <label>Type</label>
                  <ReactSelect
                    name="type"
                    value={values.type}
                    options={[
                      {
                        value: "",
                        label: "All",
                      },
                      {
                        value: "CONTACT_US",
                        label: "Contact Us",
                      },
                      {
                        value: "COMPANY_CONTRACT_REQUEST",
                        label: "Company Contract Request",
                      },
                      {
                        value: "PARTY_CONTRACT_REQUEST",
                        label: "Party Contract Request",
                      },
                      {
                        value: "RENT_STUDIO",
                        label: "Rent Studio",
                      },
                      {
                        value: "VACANCY",
                        label: "Vacancy",
                      },
                    ]}
                    onChange={(option) => {
                      setFieldValue("type", option);
                      handleSubmit();
                    }}
                  />
                </div>
                <div className="col-lg-4">
                  <CustomDateRangePicker
                    onChange={(startDate, endDate) => {
                      setFieldValue("start_date", startDate);
                      setFieldValue("end_date", endDate);
                      handleSubmit();
                    }}
                  />
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </>
  );
}
