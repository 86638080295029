import React, { useMemo, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import * as actions from "../../../_redux/classes/classesActions";
import moment from "moment";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { useClassesUIContext } from "../ClassesUIContext";
import * as uiHelpers from "../ClassesUIHelpers";
import * as columnFormatters from "./column-formatters";

export const ClassesTable = () => {
  const classesUIContext = useClassesUIContext();
  const classesUIProps = useMemo(() => {
    return {
      ids: classesUIContext.ids,
      setIds: classesUIContext.setIds,
      queryParams: classesUIContext.queryParams,
      setQueryParams: classesUIContext.setQueryParams,
      openEditClassDialog: classesUIContext.openEditClassDialog,
      openDeleteClassDialog: classesUIContext.openDeleteClassDialog,
    };
    //eslint-disable-next-line
  }, [classesUIContext]);

  const { currentState } = useSelector(
    (state) => ({ currentState: state.classes }),
    shallowEqual
  );

  const { totalCount, entities, listLoading } = currentState;

  const dispatch = useDispatch();
  useEffect(() => {
    classesUIProps.setIds([]);
    dispatch(actions.fetchClasses(classesUIProps.queryParams));
    //eslint-disable-next-line
  }, [classesUIProps.queryParams, dispatch]);

  const columns = [
    // {
    //   dataField: "id",
    //   text: "ID",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   headerSortingClasses,
    // },
    {
      dataField: "image_link",
      text: "Headline Image",
      sort: false,
      sortCaret: sortCaret,
      formatter: columnFormatters.ImagePathColumnFormatter,
    },
    {
      dataField: "name",
      text: "Name",
      sort: true,
      sortCaret: sortCaret,
      style: function callback(cell, row, rowIndex, colIndex) {
        return {
          color: row.color_hex,
        };
      },
    },
    {
      dataField: "studio_price",
      text: "Studio Price",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      classes: "text-center",
      headerClasses: "text-center",
      formatter: columnFormatters.PriceFormatter,
    },
    {
      dataField: "studio_credit",
      text: "Studio Credit",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      classes: "text-center",
      headerClasses: "text-center",
      formatter: columnFormatters.FixedDecimalFormatter,
    },
    {
      dataField: "virtual_price",
      text: "Virtual Price",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      classes: "text-center",
      headerClasses: "text-center",
      formatter: columnFormatters.PriceFormatter,
    },
    {
      dataField: "virtual_credit",
      text: "Virtual Credit",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      classes: "text-center",
      headerClasses: "text-center",
      formatter: columnFormatters.FixedDecimalFormatter,
    },
    {
      dataField: "updated_at",
      text: "Updated At",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      classes: "text-nowrap",
      formatter: (cell) =>
        cell ? moment(cell).format("YYYY-MM-DD HH:mm:ss") : "-",
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditClassDialog: classesUIProps.openEditClassDialog,
        openDeleteClassDialog: classesUIProps.openDeleteClassDialog,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  // Table Pagination Properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: classesUIProps.queryParams.pageSize,
    page: classesUIProps.queryParams.pageNumber,
  };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                remote
                keyField="id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  classesUIProps.setQueryParams
                )}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
};
