import React, { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/requirements/requirementsActions";
import { useRequirementsUIContext } from "../RequirementsUIContext";
import { RequirementEditForm } from "./RequirementEditForm";
import { snackActions } from "../../../../../../utils/SnackbarUtils";
import { useHistory, useParams } from "react-router-dom";

export const RequirementEdit = () => {
  const requirementsUIContext = useRequirementsUIContext();
  const requirementsUIProps = useMemo(() => {
    return {
      initRequirement: requirementsUIContext.initRequirement
    };
  }, [requirementsUIContext]);

  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const { actionsLoading, requirementForEdit } = useSelector(
    state => ({
      actionsLoading: state.requirements.actionsLoading,
      requirementForEdit: state.requirements.requirementForEdit
    }),
    shallowEqual
  );

  useEffect(() => {
    //server call get requirement by id
    dispatch(actions.fetchRequirement(id));
  }, [id, dispatch]);

  // server request to save requirement
  const saveRequirement = requirement => {
    if (!id) {
      // create user
      dispatch(actions.createRequirement(requirement))
        .then(() => backToRequirementList())
        .catch(err => snackActions.error(err.message));
    } else {
      // update user
      dispatch(actions.updateRequirement(requirement))
        .then(() => backToRequirementList())
        .catch(err => snackActions.error(err.message));
    }
  };

  const backToRequirementList = () => {
    history.push(`/requirements`);
  };

  return (
    <>
      <RequirementEditForm
        saveRequirement={saveRequirement}
        isEditing={id !== undefined}
        actionsLoading={actionsLoading}
        requirement={requirementForEdit || requirementsUIProps.initRequirement}
        onHide={backToRequirementList}
      />
    </>
  );
};
