import * as requestFromServer from "./instructorsCrud";
import { instructorsSlice, callTypes } from "./instructorsSlice";

const { actions } = instructorsSlice;

export const createInstructor = (instructor) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createInstructor(instructor)
    .then((response) => {
      const {
        data: {
          instructor: { create },
        },
      } = response;
      dispatch(actions.instructorCreated({ instructor: create }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      throw error;
    });
};

export const updateInstructor = (instructor) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateInstructor(instructor)
    .then((response) => {
      const {
        data: {
          instructor: { update },
        },
      } = response;
      dispatch(actions.instructorUpdated({ instructor: update }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      throw error;
    });
};

export const fetchInstructors = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findInstructors(queryParams)
    .then((response) => {
      const {
        data: {
          instructors: { total, nodes },
        },
      } = response;
      dispatch(
        actions.instructorsFetched({
          totalCount: total,
          entities: nodes,
        })
      );
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchInstructorsAll = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllInstructors()
    .then((response) => {
      const {
        data: {
          instructors: { total, nodes },
        },
      } = response;
      dispatch(
        actions.instructorsFetched({
          totalCount: total,
          entities: nodes,
        })
      );
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchInstructor = (id) => (dispatch) => {
  if (!id) {
    return dispatch(
      actions.instructorFetched({ instructorForEdit: undefined })
    );
  }
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getInstructorById(id)
    .then((response) => {
      const {
        data: { instructor },
      } = response;
      dispatch(actions.instructorFetched({ instructorForEdit: instructor }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteInstructor = (id) => (dispatch) => {
  if (!id) {
    return;
  }
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteInstructor(id)
    .then((_) => {
      dispatch(actions.instructorDeleted({ id: id }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
