/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import { datas } from "./AsideMenuData";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  const CustomMenuItem = ({ item }) => (
    <li
      className={`menu-item ${getMenuItemActive(item.path, false)}`}
      aria-haspopup="true"
    >
      <NavLink className="menu-link" to={item.path}>
        {item.hasBullet && (
          <i className="menu-bullet menu-bullet-dot">
            <span />
          </i>
        )}
        {item.faIconClass && (
          <span className="svg-icon menu-icon">
            <i className={item.faIconClass}></i>
          </span>
        )}
        <span className="menu-text">{item.name}</span>
      </NavLink>
    </li>
  );

  const CustomNestedMenuItem = ({ item }) => {
    if (item.nested) {
      return (
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            item,
            true
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to={item.path}>
            {item.hasBullet && (
              <i className="menu-bullet menu-bullet-dot">
                <span />
              </i>
            )}
            {item.faIconClass && (
              <span className="svg-icon menu-icon">
                <i className={item.faIconClass}></i>
              </span>
            )}
            <span className="menu-text">{item.name}</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">{item.name}</span>
                </span>
              </li>
              {item.nested.map((subItem, index) => (
                <CustomNestedMenuItem
                  key={`${subItem.path}-${index}`}
                  item={subItem}
                />
              ))}
            </ul>
          </div>
        </li>
      );
    } else {
      return <CustomMenuItem item={item} />;
    }
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        {datas.map((item, index) => (
          <CustomNestedMenuItem key={`${item.path}-${index}`} item={item} />
        ))}
        {/*end::1 Level*/}
      </ul>
    </>
  );
}
