import React from "react";
import { Route, Switch } from "react-router-dom";
import { RequirementEdit } from "./requirement-edit/RequirementEdit";
import { RequirementsLoadingDialog } from "./requirements-loading-dialog/RequirementsLoadingDialog";
import { RequirementsCard } from "./RequirementsCard";
import { RequirementsUIProvider } from "./RequirementsUIContext";

export const RequirementsPage = ({ history }) => {
  const requirementsUIEvents = {
    newRequirementButtonClick: () => {
      history.push("/requirements/new");
    },
    openEditRequirementDialog: (id) => {
      history.push(`/requirements/${id}/edit`);
    },
    openDeleteRequirementDialog: (id) => {
      history.push(`/requirements/${id}/delete`);
    }
  }

  return (
    <RequirementsUIProvider requirementsUIEvents={requirementsUIEvents}>
      <RequirementsLoadingDialog />
      {/* <Route path="/requirements/new">
        {({ history, match }) => (
          <RequirementEditDialog
            show={match != null}
            onHide={() => {
              history.push("/requirements");
            }}
          />
        )}
      </Route>
      <Route path="/requirements/:id/edit">
        {({ history, match }) => (
          <RequirementEditDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/requirements");
            }}
          />
        )}
      </Route>
      
      <RequirementsCard /> */}

      <Switch>
        <Route path={`/requirements/new`}>
          {({ match }) => {
            return match != null && <RequirementEdit />;
          }}
        </Route>

        <Route path={`/requirements/:id/edit`}>
          {({ match }) => {
            return match != null && <RequirementEdit />;
          }}
        </Route>

        <Route path={`/requirements`}>
          {({ match }) => {
            return match != null && <RequirementsCard />;
          }}
        </Route>
      </Switch>
    </RequirementsUIProvider>
  )
}