import React, { useEffect, useMemo } from "react";
import * as actions from "../../_redux/newsActions";
import moment from "moment";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import {
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../_metronic/_helpers";
import * as uiHelpers from "../NewsUIHelpers";
import * as columnFormatters from "./column-formatters";
import { useNewsUIContext } from "../NewsUIContext";
import BootstrapTable from "react-bootstrap-table-next";
import { Pagination } from "../../../../../_metronic/_partials/controls";

export const NewsTable = () => {
  const newsUIContext = useNewsUIContext();
  const newsUIProps = useMemo(() => {
    return {
      ids: newsUIContext.ids,
      setIds: newsUIContext.setIds,
      queryParams: newsUIContext.queryParams,
      setQueryParams: newsUIContext.setQueryParams,
      openEditNewsPage: newsUIContext.openEditNewsPage,
      openDeleteNewsDialog: newsUIContext.openDeleteNewsDialog,
    };
  }, [newsUIContext]);

  const { currentState } = useSelector(
    (state) => ({ currentState: state.news }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;

  // Users Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      newsUIProps.setIds([]);
      dispatch(actions.fetchNewsList(newsUIContext.queryParams));
    }, 500);

    return () => clearTimeout(delayDebounceFn);

    // eslint-disable-next-line
  }, [newsUIContext.queryParams, dispatch]);

  const columns = [
    // {
    //   dataField: "id",
    //   text: "ID",
    //   sort: true,
    //   sortCaret: sortCaret,
    //   headerSortingClasses,
    // },
    {
      dataField: "image_link",
      text: "Headline Image",
      sort: false,
      sortCaret: sortCaret,
      formatter: columnFormatters.ImagePathColumnFormatter,
    },
    {
      dataField: "title",
      text: "Title",
      sort: false,
      sortCaret: sortCaret,
    },
    {
      dataField: "updated_at",
      text: "Updated At",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      classes: "text-nowrap",
      formatter: (cell) => cell ? moment(cell).format("YYYY-MM-DD HH:mm:ss") : "-",
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openEditNewsPage: newsUIProps.openEditNewsPage,
        openDeleteNewsDialog: newsUIProps.openDeleteNewsDialog,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: newsUIProps.queryParams.pageSize,
    page: newsUIProps.queryParams.pageNumber,
  };

  const tableRowEvents = {
    onClick: (e, row, rowIndex) => {
      // console.log(`clicked on row with index: ${rowIndex}`);
    },
    onMouseEnter: (e, row, rowIndex) => {
      // console.log(`enter on row with index: ${rowIndex}`);
    },
  };

  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center overflow-hidden"
                bootstrap4
                bordered={false}
                remote
                keyField="id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  newsUIProps.setQueryParams
                )}
                rowStyle={{
                  cursor: "pointer",
                }}
                rowEvents={tableRowEvents}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
};
