import { gql } from "@apollo/client";

const NEWS_LIST = gql`
  query getNewsList(
    $page: Int
    $limit: Int
    $start_date: Date
    $end_date: Date
    $sort_by: NewsSortableField
    $sort_order: SortOrder
    $phrase: String
  ) {
    news_list(
      filter: {
        start_date: $start_date
        end_date: $end_date
        phrase: $phrase
        sort_by: $sort_by
        sort_order: $sort_order
      }
      page: $page
      limit: $limit
    ) {
      total
      nodes {
        id
        title
        body_html
        created_at
        updated_at
        image_link
      }
    }
  }
`;

const NEWS = gql`
  query news($id: Int!) {
    news(id: $id) {
      id
      title
      body_html
      created_at
      updated_at
      image_link
    }
  }
`;

const CREATE_NEWS = gql`
  mutation createNews($title: String!, $body_html: String!, $image: Upload) {
    news {
      create(input: { title: $title, body_html: $body_html, image: $image }) {
        id
        title
        body_html
        created_at
        updated_at
        image_link
      }
    }
  }
`;

const UPDATE_NEWS = gql`
  mutation updateNews(
    $id: Int!
    $title: String!
    $body_html: String!
    $image: Upload
  ) {
    news {
      update(
        id: $id
        input: { title: $title, body_html: $body_html, image: $image }
      ) {
        id
        title
        body_html
        created_at
        updated_at
        image_link
      }
    }
  }
`;

const DELETE_NEWS = gql`
  mutation deleteNews($id: Int!) {
    news {
      delete(id: $id)
    }
  }
`;

export { NEWS_LIST, NEWS, CREATE_NEWS, UPDATE_NEWS, DELETE_NEWS };
