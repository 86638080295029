import React, { useMemo } from "react";
import AsyncSelect from "react-select/async";
import _ from "lodash";
import { findEvents } from "../../../Event/List/_redux/events/eventsCrud";
import { initialFilter } from "../../../Event/List/pages/events/EventsUIHelpers";

export const EventAsyncSelect = ({
  isMulti = false,
  value,
  isError,
  onChange,
}) => {
  // Save Search and Debounce
  const asyncProps = useMemo(() => {
    function asyncDebounce(func, wait) {
      const debounced = _.debounce((resolve, reject, args) => {
        func(...args)
          .then(resolve)
          .catch(reject);
      }, wait);
      return (...args) =>
        new Promise((resolve, reject) => {
          debounced(resolve, reject, args);
        });
    }
    const searchEvents = async () => {
      return findEvents({
        pageSize: null,
        pageNumber: null,
        sortField: null,
        sortOrder: null,
        filter: {
          ...initialFilter.filter,
          status: [
            {
              name: "ACTIVE",
            },
          ],
        },
      });
    };
    var debounced = asyncDebounce(searchEvents, 500);
    return {
      searchEvents,
      debounced,
    };
  }, []);

  const loadOptions = async (searchText, loadedOptions, temp) => {
    const {
      data: {
        events: { nodes },
      },
    } = await asyncProps.debounced();

    // filter the search key word
    const filteredEvents = nodes.filter((event) => {
      return event.class.name.toLowerCase().includes(searchText.toLowerCase());
    });

    return filteredEvents;
  };

  return (
    <>
      <AsyncSelect
        styles={{
          container: () => ({
            border: isError && "1px solid #F64E60",
          }),
        }}
        value={value}
        isMulti={isMulti}
        defaultOptions
        loadOptions={loadOptions}
        onChange={onChange}
        getOptionValue={(option) => option.id}
        getOptionLabel={(option) => `${option.id} ${option.class.name}`}
        placeholder="Search for active events"
        noOptionsMessage={() => "No events found"}
      />
    </>
  );
};
