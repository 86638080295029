import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { useClassesUIContext } from "./ClassesUIContext";
import { ClassesTable } from "./classes-table/ClassesTable";
import { ClassesFilter } from "./classes-filter/ClassesFilter";

export const ClassesCard = () => {
  const classesUIContext = useClassesUIContext();
  const classesUIProps = useMemo(() => {
    return {
      ids: classesUIContext.ids,
      newClassButtonClick: classesUIContext.newClassButtonClick,
    };
  }, [classesUIContext]);

  return (
    <Card>
      <CardHeader title="Classes List">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={classesUIProps.newClassButtonClick}>
            New Class
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <ClassesFilter />
        <ClassesTable />
      </CardBody>
    </Card>
  );
};
