import { gql } from "@apollo/client";

const USER_CONTACT_PAGINATE = gql`
  query USER_CONTACT_PAGINATE(
    $page: Int
    $limit: Int
    $type: ContactUsType
    $phrase: String
    $sort_by: ContactUsSortableField
    $sort_order: SortOrder
    $start_date: Date
    $end_date: Date
  ) {
    user_contacts(
      page: $page
      limit: $limit
      filter: {
        type: $type
        phrase: $phrase
        sort_by: $sort_by
        sort_order: $sort_order
        start_date: $start_date
        end_date: $end_date
      }
    ) {
      total
      nodes {
        id
        email
        type
        name
        phone_number
        message
        preferred_datetime
        preferred_day_of_week
        preferred_time
        preferred_start_date
        number_of_sessions
        has_sound_system
        participant_count
        attachment_link
        is_read
        created_at
        updated_at
      }
    }
  }
`;

const USER_CONTACT_BY_ID = gql`
  query USER_CONTACT_BY_ID($id: Int!) {
    user_contact(id: $id) {
      id
      email
      type
      name
      phone_number
      message
      location
      preferred_datetime
      preferred_day_of_week
      preferred_time
      preferred_start_date
      number_of_sessions
      has_sound_system
      participant_count
      attachment_link
      is_read
      studio {
        name
      }
      class {
        name
      }
      created_at
      updated_at
    }
  }
`;

export { USER_CONTACT_PAGINATE, USER_CONTACT_BY_ID };
