import React from "react";
import { Badge } from "react-bootstrap";
import { AVAILABLE_PAYMENT_STATUSES } from "../../RefundRequestUIHelpers";
import { toSnackUpperCase } from "../../../../../../utils/case";

const colors = ["danger", "success", "primary"];

export function PaymentStatusColumnFormatter(cell, row) {
  let index = 0;

  //eslint-disable-next-line
  AVAILABLE_PAYMENT_STATUSES.map((paymentStatus) => {
    if (toSnackUpperCase(paymentStatus) === row.payment_status) {
      index = AVAILABLE_PAYMENT_STATUSES.indexOf(paymentStatus);
    }
  });

  return (
    <Badge variant={colors[index]}>{AVAILABLE_PAYMENT_STATUSES[index]}</Badge>
  );
}
