import {
  REQUIREMENTS,
  REQUIREMENT_CREATE,
  REQUIREMENT_UPDATE,
  REQUIREMENT_DELETE,
  REQUIREMENT,
} from "../../../../../graphql";
import { apolloClient } from "../../../../apollo/client";

export function createRequirement(requirement) {
  return apolloClient.mutate({
    mutation: REQUIREMENT_CREATE,
    variables: {
      name: requirement.name.trim(),
      // image: requirement.image,
      image_path: requirement.image_path,
    },
  });
}

export function updateRequirement(requirement) {
  return apolloClient.mutate({
    mutation: REQUIREMENT_UPDATE,
    variables: {
      id: requirement.id,
      name: requirement.name.trim(),
      // image: requirement.image,
      image_path: requirement.image_path,
    },
  });
}

export function deleteRequirement(id) {
  return apolloClient.mutate({
    mutation: REQUIREMENT_DELETE,
    variables: {
      id,
    },
  });
}

export function findRequirements(queryParams) {
  return apolloClient.query({
    query: REQUIREMENTS,
    variables: {
      sort_by: queryParams.sortField?.toUpperCase(),
      sort_order: queryParams.sortOrder?.toUpperCase(),
      name: queryParams.filter.name,
    },
  });
}

export function findRequirement(id) {
  return apolloClient.query({
    query: REQUIREMENT,
    variables: {
      id: id,
    },
  });
}
