import React from "react";
import Datetime from "react-datetime";
import { FieldFeedbackLabel } from "./FieldFeedbackLabel";
import "react-datetime/css/react-datetime.css";

const getFieldCSSClasses = (touched, errors) => {
  const classes = ["form-control"];
  if (touched && errors) {
    classes.push("is-invalid");
  }

  if (touched && !errors) {
    classes.push("is-valid");
  }

  return classes.join(" ");
};

export function DateTimeField({
  field,
  form: { touched, errors, setFieldValue, setFieldTouched },
  label,
  withFeedbackLabel = true,
  customFeedbackLabel,
  ...props
}) {
  return (
    <>
      {label && <label>{label}</label>}
      <Datetime
        inputProps={{
          className: getFieldCSSClasses(
            touched[field.name],
            errors[field.name]
          ),
        }}
        dateFormat="YYYY-MM-DD"
        timeFormat="HH:mm:ss"
        name={field.name}
        value={field.value}
        onChange={(value) => setFieldValue(field.name, value)}
        onClose={() => setFieldTouched(field.name, true)}
      />
      {withFeedbackLabel && (
        <FieldFeedbackLabel
          error={errors[field.name]}
          touched={touched[field.name]}
          label={label}
          type={props.type || "text"}
          customFeedbackLabel={customFeedbackLabel}
        />
      )}
    </>
  );
}
