import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { useCreditTypesUIContext } from "./CreditTypesUIContext";
import { CreditTypesTable } from "./credit-types-table/CreditTypesTable";
import { CreditTypeDeleteDialog } from "./credit-type-delete-dialog/CreditTypeDeleteDialog";
import { Route } from "react-router-dom";
// import { LocationFilter } from "./locations-filter/LocationFilter";

export const CreditTypesCard = () => {

  const basePath = "/credit-types";
  const creditTypesUIContext = useCreditTypesUIContext();
  const creditTypesUIProps = useMemo(() => {
    return {
      ids: creditTypesUIContext.ids,
      newCreditTypeButtonClick: creditTypesUIContext.newCreditTypeButtonClick,
    };
  }, [creditTypesUIContext]);

  return (
    <Card>
      <Route path={`${basePath}/:id/delete`}>
        {({ history, match }) => (
          <CreditTypeDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push(basePath);
            }}
          />
        )}
      </Route>
      <CardHeader title="Credit Type List">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={creditTypesUIProps.newCreditTypeButtonClick}
          >
            New Credit Type
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {/* <CreditTypesTable /> */}
        <CreditTypesTable />
      </CardBody>
    </Card>
  );
};
