/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { PersistGate } from "redux-persist/integration/react";
import { AuthInit } from "./modules/Auth";
import { Routes } from "../app/Routes";
import ApolloWrapper from "./apollo/client";
import { I18nProvider } from "../_metronic/i18n";
import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";
import { SnackbarUtilsConfigurator } from "../utils/SnackbarUtils";
import "./App.css";

// TODO: remove location
export default function App({ store, persistor, basename }) {
  return (
    /* Provide Redux store */
    <SnackbarProvider maxSnack={3}>
      <SnackbarUtilsConfigurator />
      <Provider store={store}>
        <ApolloWrapper store={store}>
          {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
          <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
            {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
            <React.Suspense fallback={<LayoutSplashScreen />}>
              {/* Override `basename` (e.g: `homepage` in `package.json`) */}
              <BrowserRouter basename={basename}>
                {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
                <MaterialThemeProvider>
                  {/* Provide `react-intl` context synchronized with Redux state.  */}
                  <I18nProvider>
                    {/* Render routes with provided `Layout`. */}
                    <AuthInit>
                      <Routes />
                    </AuthInit>
                  </I18nProvider>
                </MaterialThemeProvider>
              </BrowserRouter>
            </React.Suspense>
          </PersistGate>
        </ApolloWrapper>
      </Provider>
    </SnackbarProvider>
  );
}
