import React from "react";

const Switch = ({checked, onChange, ...rest}) => {
    return(
        <span className="switch switch-sm switch-icon">
            <label>
                <input type="checkbox" checked={checked} onChange={onChange} {...rest}/>
                <span></span>
            </label>
        </span>
    );
};

export default Switch;
