import React from "react"

const AsteriskLabel = ({ word }) => {
  return (
    <>
      {word}{" "}
      <span
        style={{
          color: "red",
        }}
      >
        *
      </span>
    </>
  );
};

export default AsteriskLabel;
