import React from "react";
import { Switch, Route } from "react-router-dom";
import { UserContactUIProvider } from "./UserContactsUIContext";
import { UserContactsCard } from "./UserContactsCard";
import { UserContactsLoadingDialog } from "./user-contacts-loading-dialog/UserContactsLoadingDialog";
import { UserContactDetailCard } from "./user-contact-detail/UserContactDetailCard";

export function UserContactsPage({ history }) {
  const basePath = "/clients/contacts";

  const userContactUIEvents = {
    openDetailUserContactPage: (id) => {
      history.push(`${basePath}/${id}`);
    },
    basePath: () => {
      history.push(basePath);
    },
  };

  return (
    <UserContactUIProvider userContactUIEvents={userContactUIEvents}>
      <UserContactsLoadingDialog />
      <Switch>
        <Route path={`${basePath}/:id`}>
          {({ match }) =>
            match != null && <UserContactDetailCard id={match.params.id} />
          }
        </Route>
        <Route exact path={basePath}>
          {({ match }) => match != null && <UserContactsCard />}
        </Route>
      </Switch>
    </UserContactUIProvider>
  );
}
