import { gql } from "@apollo/client";

const REFUND_REQUESTS = gql`
  query REFUND_REQUESTS(
    $page: Int
    $limit: Int
    $event_ids: [Int]
    $phrase: String
    $payment_methods: [PaymentMethod]
    $sort_by: RefundRequestSortableField
    $sort_order: SortOrder
    $start_date: Date
    $end_date: Date
  ) {
    refund_requests(
      page: $page
      limit: $limit
      filter: {
        event_ids: $event_ids
        phrase: $phrase
        payment_methods: $payment_methods
        sort_by: $sort_by
        start_date: $start_date
        end_date: $end_date
        sort_order: $sort_order
      }
    ) {
      total
      nodes {
        id
        event_id
        email
        phone_number
        name
        payment_method
        created_at
        potential_booking {
          id
          user_name
          user_email
          event_id
        }
        event {
          id
          start_time
          end_time
          date
          studio_price
          studio_credit
          virtual_price
          virtual_credit
          has_studio
          has_virtual
          accept_punch_card
          is_special_class
          status
          created_at
          class {
            id
            name
            image_link
          }
          studio {
            id
            name
            location {
              id
              name
              building_name
              street_name
              block_no
              unit_no
              level_no
              postal_code
              longitude
              latitude
              region
            }
          }
          instructor {
            id
            name
            image_link
          }
        }
        is_read
      }
    }
  }
`;

const REFUND_REQUEST = gql`
  query REFUND_REQUEST($id: Int!) {
    refund_request(id: $id) {
      id
      event_id
      email
      phone_number
      name
      payment_method
      created_at

      potential_booking {
        id
        user_name
        user_email
        payment_method
        event_id
        class_type
        price
        payment_status
        paynow_payment_history {
          qr_string
          expired_at
        }
      }
      event {
        id
        start_time
        end_time
        date
        studio_price
        studio_credit
        virtual_price
        virtual_credit
        has_studio
        has_virtual
        accept_punch_card
        is_special_class
        status
        created_at
        class {
          id
          name
          image_link
        }
        studio {
          id
          name
          location {
            id
            name
            building_name
            street_name
            block_no
            unit_no
            level_no
            postal_code
            longitude
            latitude
            region
          }
        }
        instructor {
          id
          name
          image_link
        }
      }
      is_read
    }
  }
`;

export { REFUND_REQUESTS, REFUND_REQUEST };
