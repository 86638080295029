import React from "react";
import { Route, Switch } from "react-router-dom";
import { RefundRequestLoadingDialog } from "./refund-request-loading-dialog/RefundRequestLoadingDialog";
import { RefundRequestUIProvider } from "./RefundRequestUIContext";
import { RefundRequestCard } from "./RefundRequestCard";
import { RefundRequestDetailCard } from "./refund-request-detail/RefundRequestDetailCard";

export const RefundRequestPage = ({ history }) => {
  const basePath = "/clients/refund-requests"

  const refundRequestUIEvents = {
    toRefundRequestDetailPage: (id) => {
      history.push(`${basePath}/${id}`);
    },
    toRefundRequestsPage: () => {
      history.push(`${basePath}`);
    },
  };

  return (
    <RefundRequestUIProvider refundRequestUIEvents={refundRequestUIEvents}>
      <RefundRequestLoadingDialog />

      <Switch>
        <Route path={`${basePath}/:id`}>
          {({ match }) => {
            return match != null && <RefundRequestDetailCard />;
          }}
        </Route>

        <Route path={`${basePath}`}>
          {({ match }) => {
            return match != null && <RefundRequestCard />;
          }}
        </Route>
      </Switch>
    </RefundRequestUIProvider>
  );
};
