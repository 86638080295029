import React from "react";
import { Badge } from "react-bootstrap";
import { AVAILABLE_EVENT_BOOKING_STATUS } from "../../RefundRequestUIHelpers";
import { toSnackUpperCase } from "../../../../../../utils/case";

const colors = ["info", "warning", "secondary", "success", "primary"];

export function EventBookingStatusColumnFormatter(cell, row) {
  let index = 0;

  //eslint-disable-next-line
  AVAILABLE_EVENT_BOOKING_STATUS.map((eventBookingStatus) => {
    if (toSnackUpperCase(eventBookingStatus) === row.status) {
      index = AVAILABLE_EVENT_BOOKING_STATUS.indexOf(eventBookingStatus);
    }
  });

  return (
    <Badge variant={colors[index]}>
      {AVAILABLE_EVENT_BOOKING_STATUS[index]}
    </Badge>
  );
}
