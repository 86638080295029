import * as requestFromServer from "./newsCrud";
import { newsSlice, callTypes } from "./newsSlice";

const { actions } = newsSlice;

export const fetchNews = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.newsFetched({ newsForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .findNews(id)
    .then((response) => {
      const {
        data: { news },
      } = response;

      dispatch(
        actions.newsFetched({
          newsForEdit: {
            ...news,
            image: null,
          },
        })
      );
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchNewsList = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));

  return requestFromServer
    .findNewsList(queryParams)
    .then((response) => {
      const {
        data: {
          news_list: { total, nodes },
        },
      } = response;

      dispatch(
        actions.newsListFetched({
          totalCount: total,
          entities: nodes,
        })
      );
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const createNews = (news) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .createNews(news)
    .then((response) => {
      const {
        data: {
          news: { create },
        },
      } = response;

      dispatch(actions.newsCreated({ news: create }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      throw error;
    });
};

export const updateNews = (news) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .updateNews(news)
    .then((response) => {
      const {
        data: {
          news: { update },
        },
      } = response;

      dispatch(actions.newsUpdated({ news: update }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      throw error;
    });
};

export const deleteNews = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .deleteNews(id)
    .then((response) => {
      const {
        data: {
          requirement: { delete: id },
        },
      } = response;

      dispatch(actions.newsDeleted({ id: id }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
