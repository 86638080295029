import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { snackActions } from "../../../../../../utils/SnackbarUtils";
import * as actions from "../../../_redux/users/usersActions";
import { UserEditCreditDialogHeader } from "./UserEditCreditDialogHeader";
import { UserEditCreditForm } from "./UserEditCreditForm";

export function UserEditCreditDialog({ id, show, onHide }) {
  // Users Redux State
  const dispatch = useDispatch();
  const { actionsLoading, userForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.users.actionsLoading,
      userForEdit: state.users.userForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    //server call get user by id
    dispatch(actions.fetchUser(id));
  }, [id, dispatch]);

  // server request to save user
  const updateCredit = (user) => {
    dispatch(actions.updateUserCredit(user))
      .then(() => onHide())
      .catch((err) => {
        snackActions.error(err.graphQLErrors[0].message);
      });
  };

  return (
    <Modal
      size="lg"
      show={show}
      onHide={onHide}
      aria-labelledby="user-edit-dialog-modal-lg">
      <UserEditCreditDialogHeader id={id} />
      <UserEditCreditForm
        updateCredit={updateCredit}
        isEditing={id !== null}
        actionsLoading={actionsLoading}
        user={userForEdit}
        onHide={onHide}
      />
    </Modal>
  );
}
