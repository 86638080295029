import {
  CLASS_CREATE,
  CLASS_PAGINATE,
  CLASS_BY_ID,
  CLASS_DELETE,
  CLASS_UPDATE,
} from "../../../../../graphql";
import { apolloClient } from "../../../../apollo/client";

export const createClass = (classData) => {
  return apolloClient.mutate({
    mutation: CLASS_CREATE,
    variables: {
      name: classData.name,
      color_hex: classData.color_hex,
      about: classData.about,
      target_audience:
        classData.target_audience !== "" ? classData.target_audience : null,
      benefits: classData.benefits,
      how_it_works: classData.how_it_works,
      studio_price: classData.studio_price,
      studio_credit: classData.studio_credit,
      virtual_price: classData.virtual_price,
      virtual_credit: classData.virtual_credit,
      youtube_url: classData.youtube_url !== "" ? classData.youtube_url : null,
      image: classData.image,
      requirement_ids: classData.requirements
        ? classData.requirements.map((item) => item.id)
        : null,
    },
  });
};

export const updateClass = (classData) => {
  return apolloClient.mutate({
    mutation: CLASS_UPDATE,
    variables: {
      id: classData.id,
      name: classData.name,
      color_hex: classData.color_hex,
      about: classData.about,
      target_audience:
        classData.target_audience !== "" ? classData.target_audience : null,
      benefits: classData.benefits,
      how_it_works: classData.how_it_works,
      studio_price: classData.studio_price,
      studio_credit: classData.studio_credit,
      virtual_price: classData.virtual_price,
      virtual_credit: classData.virtual_credit,
      youtube_url: classData.youtube_url !== "" ? classData.youtube_url : null,
      image: classData.image,
      requirement_ids: classData.requirements
        ? classData.requirements.map((item) => item.id)
        : null,
    },
  });
};

export const getClassById = (classId) => {
  return apolloClient.query({
    query: CLASS_BY_ID,
    variables: {
      id: classId,
    },
  });
};

export const getAllClasses = () => {
  return apolloClient.query({
    query: CLASS_PAGINATE,
  });
};

export const findClasses = (queryParams) => {
  return apolloClient.query({
    query: CLASS_PAGINATE,
    variables: {
      page: queryParams.pageNumber,
      limit: queryParams.pageSize,
      name: queryParams.filter.name === "" ? null : queryParams.filter.name,
      sort_by: queryParams.sortField?.toUpperCase(),
      sort_order: queryParams.sortOrder?.toUpperCase(),
    },
  });
};

export const deleteClass = (classId) => {
  return apolloClient.mutate({
    mutation: CLASS_DELETE,
    variables: {
      id: classId,
    },
  });
};
