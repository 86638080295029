import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { useSnackbar } from "notistack";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/locations/locationsActions";
import { useLocationsUIContext } from "../LocationsUIContext";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";

export function LocationDeleteDialog({ id, show, onHide }) {
  const { enqueueSnackbar } = useSnackbar();

  const locationsUIContext = useLocationsUIContext();
  const locationsUIProps = useMemo(() => {
    return {
      setIds: locationsUIContext.setIds,
      queryParams: locationsUIContext.queryParams,
    };
  }, [locationsUIContext]);

  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.customers.actionsLoading }),
    shallowEqual
  );

  useEffect(() => {
    if (!id) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  const deleteLocation = () => {
    dispatch(actions.deleteLocation(id))
      .then((temp) => {
        // refresh list after deletion
        dispatch(actions.fetchLocations(locationsUIProps.queryParams)).then(
          () => {
            locationsUIProps.setIds([]);
            onHide();
          }
        );
      })
      .catch((error) => {
        enqueueSnackbar(error.graphQLErrors[0].message, {
          variant: "error",
        });
      });
  };

  return (
    <Modal show={show} onHide={onHide}>
      {/*begin::Loading*/}
      {isLoading && <ModalProgressBar />}
      {/*end::Loading*/}
      <Modal.Header closeButton>
        <Modal.Title>Location Delete</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isLoading && (
          <span>Are you sure to permanently delete this location?</span>
        )}
        {isLoading && <span>Location are deleting...</span>}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate">
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={deleteLocation}
            className="btn btn-danger btn-elevate">
            Delete
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
