import * as requestFromServer from "./userContactsCrud";
import { userContactsSlice, callTypes } from "./userContactsSlice";

const { actions } = userContactsSlice;

export const fetchUserContacts = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findUserContacts(queryParams)
    .then((response) => {
      const {
        data: {
          user_contacts: { total, nodes },
        },
      } = response;
      dispatch(
        actions.userContactsFetched({
          totalCount: total,
          entities: nodes,
        })
      );
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchUserContactsAll = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllUserContacts()
    .then((response) => {
      const {
        data: {
          user_contacts: { total, nodes },
        },
      } = response;
      dispatch(
        actions.userContactsFetched({
          totalCount: total,
          entities: nodes,
        })
      );
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchUserContact = (id) => (dispatch) => {
  if (!id) {
    return dispatch(
      actions.userContactFetched({ userContactForEdit: undefined })
    );
  }
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getUserContactById(id)
    .then((response) => {
      const {
        data: { user_contact },
      } = response;
      dispatch(
        actions.userContactFetched({
          userContactForEdit: {
            ...user_contact,
          },
        })
      );
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
