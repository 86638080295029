import React from "react";
import { IconsMap } from "../../requirement-icon-picker/Icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function ImagePathColumnFormatter(cell, row) {
  if (!row.image_path) return <div></div>;

  let icon;

  try {
    icon = require("../../../../../../../../public/media/svg/1fiesta/icons/" +
      row.image_path);

    return (
      <div
        style={{
          borderRadius: "50%",
          width: "30px",
          height: "30px",
        }}
      >
        <img
          alt=""
          src={icon}
          style={{
            objectFit: "contain",
            width: "100%",
            height: "100%",
          }}
        ></img>
      </div>
    );
  } catch (e) {
    icon = IconsMap[row.image_path];

    return (
      <div
        style={{
          borderRadius: "50%",
          width: "30px",
          height: "30px",
        }}
      >
        <FontAwesomeIcon
          style={{
            height: 30,
            width: 30,
            marginRight: "10px",
          }}
          color="#dc3545"
          icon={IconsMap[row.image_path]}
        />
      </div>
    );
  }
}
