import React, { useMemo } from "react";
import { Route } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { RequirementDeleteDialog } from "./requirement-delete-dialog/RequirementDeleteDialog";
import { RequirementFilter } from "./requirements-filter/RequirementsFilter";
import { RequirementsTable } from "./requirements-table/RequirementsTable";
import { useRequirementsUIContext } from "./RequirementsUIContext";

export const  RequirementsCard = () => {
  const requirementsUIContext = useRequirementsUIContext();
  const requirementsUIProps = useMemo(() => {
    return {
      ids: requirementsUIContext.ids,
      newRequirementButtonClick: requirementsUIContext.newRequirementButtonClick,
    };
  }, [requirementsUIContext]);

  return (
    <Card>
      <Route path="/requirements/:id/delete">
        {({ history, match }) => (
          <RequirementDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push("/requirements");
            }}
          />
        )}
      </Route>
      <CardHeader title="Requirement List">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={requirementsUIProps.newRequirementButtonClick}
          >
            New Requirement
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <RequirementFilter />
        {/* {usersUIProps.ids.length > 0 && <UsersGrouping />} */}
        <RequirementsTable />
      </CardBody>
    </Card>
  );
}

