import { gql } from "@apollo/client";

const SETTINGS = gql`
  query settings {
    settings {
      id
      key
      value
      unit
      description
    }
  }
`;

const SETTING = gql`
  query setting($id: Int!) {
    setting(id: $id) {
      id
      key
      value
      unit
      description
      created_at
      updated_at
    }
  }
`;

const UPDATE_SETTING = gql`
  mutation updateSetting($id: Int!, $value: String!) {
    settings {
      update(id: $id, value: $value) {
        id
        key
        value
        unit
        description
        created_at
        updated_at
      }
    }
  }
`;

export { SETTINGS, SETTING, UPDATE_SETTING };
