import {
  INSTRUCTOR_CREATE,
  INSTRUCTORS_PAGINATE,
  INSTRUCTOR_BY_ID,
  INSTRUCTOR_UPDATE,
  INSTRUCTOR_DELETE,
} from "../../../../../graphql";
import { apolloClient } from "../../../../apollo/client";

export const createInstructor = (instructor) => {
  return apolloClient.mutate({
    mutation: INSTRUCTOR_CREATE,
    variables: {
      user_id: instructor.user_id,
      name: instructor.name,
      about: instructor.about,
      // image_path: instructor.image_path,
      avatar: instructor.avatar,
    },
  });
};

export const updateInstructor = (instructor) => {
  return apolloClient.mutate({
    mutation: INSTRUCTOR_UPDATE,
    variables: {
      id: instructor.id,
      user_id: instructor.user_id,
      name: instructor.name,
      about: instructor.about,
      avatar: instructor.avatar,
    },
  });
};

export const getInstructorById = (instructorId) => {
  return apolloClient.query({
    query: INSTRUCTOR_BY_ID,
    variables: {
      id: instructorId,
    },
  });
};

export const getAllInstructors = () => {
  return apolloClient.query({
    query: INSTRUCTORS_PAGINATE,
  });
};

export const findInstructors = (queryParams) => {
  return apolloClient.query({
    query: INSTRUCTORS_PAGINATE,
    variables: {
      page: queryParams.pageNumber,
      limit: queryParams.pageSize,
      name: queryParams.filter.name === "" ? null : queryParams.filter.name,
      sort_by: queryParams.sortField?.toUpperCase(),
      sort_order: queryParams.sortOrder?.toUpperCase(),
    },
  });
};

export const deleteInstructor = (instructorId) => {
  return apolloClient.mutate({
    mutation: INSTRUCTOR_DELETE,
    variables: {
      id: instructorId,
    },
  });
};
