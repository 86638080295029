import React, { useState, useMemo } from "react";
import { Map, Marker, TileLayer } from "react-leaflet";
import { Icon } from "leaflet";

export const LeafletMap = ({ initialPosition, handlePositionChange }) => {
  const [position, setPosition] = useState(initialPosition);
  const centerPosition = useMemo(
    () => initialPosition,

    //eslint-disable-next-line
    []
  );

  const _handlePositionChange = (lat, lng) => {
    if (handlePositionChange) {
      handlePositionChange(lat, lng);
    }
  };

  const onClickUpdatePosition = (e) => {
    if (e.latlng) {
      const { lat, lng } = e.latlng;
      setPosition([lat, lng]);
      _handlePositionChange(lat, lng);
    } else {
      setPosition(initialPosition);
      _handlePositionChange(initialPosition[0], initialPosition[1]);
    }
  };

  return (
    <Map
      center={centerPosition || [1.28, 103.84]}
      zoom={13}
      style={{ width: "100%", height: "100%" }}
      onClick={onClickUpdatePosition}>
      <TileLayer
        noWrap={true}
        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.osm.org/{z}/{x}/{y}.png"
      />
      {position && (
        <Marker
          position={position}
          icon={
            new Icon({
              iconUrl: "/media/svg/icons/Map/Marker1.svg",
              iconSize: [40, 40],
              iconAnchor: [12, 41],
            })
          }></Marker>
      )}
    </Map>
  );
};
