import React from "react";
import { toTitleCaseSpace } from "../../../../../../../utils/case";
import moment from "moment";

export const UserContactDetailRentStudio = ({ userContactForEdit }) => {
  return (
    <>
      <div className="row">
        <div className="col-lg-4">
          <h5 className="font-bold">User Details</h5>
          <h6>Name</h6>
          <p>{userContactForEdit.name}</p>
          <h6>Email</h6>
          <p>{userContactForEdit.email}</p>
          <h6>Phone Number</h6>
          <p>{userContactForEdit.phone_number || "-"}</p>
          <h6>Type</h6>
          <p>{toTitleCaseSpace(userContactForEdit.type)}</p>
        </div>

        <div className="col-lg-4">
          <h5 className="font-bold">Preferred</h5>
          <h6>Time</h6>
          <p>
            {userContactForEdit.preferred_datetime
              ? moment(userContactForEdit.preferred_datetime).format(
                  "ddd, D MMM YYYY HH:mm"
                )
              : "-"}
          </p>
          <h6>Location</h6>
          <p>{userContactForEdit.studio.name || "-"}</p>
        </div>
        <div className="col-lg-4">
          <h5 className="font-bold">Details</h5>
          <h6>Rent Purpose</h6>
          <p> {userContactForEdit.message || "-"}</p>
        </div>
      </div>
    </>
  );
};
