import React from "react";
import { toTitleCaseSpace } from "../../../../../../../utils/case";

export const UserContactDetailContactUs = ({ userContactForEdit }) => {
  return (
    <>
      <div className="row">
        <div className="col-lg-4">
          <h5 className="font-bold">User Details</h5>
          <h6>Name</h6>
          <p>{userContactForEdit.name || "-"}</p>
          <h6>Email</h6>
          <p>{userContactForEdit.email || "-"}</p>
          <h6>Type</h6>
          <p>{toTitleCaseSpace(userContactForEdit.type)}</p>
        </div>
        <div className="col-lg-4">
          <h5 className="font-bold">Details</h5>
          <h6>Message</h6>
          <p>{userContactForEdit.message || "-"}</p>
        </div>
      </div>
    </>
  );
};
