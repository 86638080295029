import React, { useMemo, useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { LeafletMap } from "./form-extras/leaflet";
import {
  Card,
  CardHeader,
  Input,
  Select
} from "../../../../../../_metronic/_partials/controls";
import AsteriskLabel from "../../../../../../utils/Asterisk";

export function LocationEditForm({
  saveLocation,
  isEditing,
  location,
  actionsLoading,
  onHide
}) {
  // Validation schema
  const [showMap, setShowMap] = useState(false);
  const LocationEditSchema = useMemo(
    () =>
      Yup.object().shape(
        {
          name: Yup.string()
            .trim()
            .required("Name is required"),
          street_name: Yup.string()
            .trim()
            .required("Street name is required"),
          building_name: Yup.string().nullable(),
          block_no: Yup.string()
            .trim()
            .required("Block no is required"),
          unit_no: Yup.string().when("level_no", {
            is: level_no => level_no,
            then: Yup.string().required(
              "Unit No is required when level no is specified"
            )
          }),
          level_no: Yup.string().when("unit_no", {
            is: unit_no => unit_no,
            then: Yup.string().required(
              "Level No is required when Unit No is specified"
            )
          }),
          postal_code: Yup.string()
            .trim()
            .required("Postal Code is required")
            .matches(/^[0-9]{6}$/, "Invalid postal code"),
          region: Yup.string()
            .trim()
            .required("Region is required")
        },
        [["level_no", "unit_no"]]
      ),
    //eslint-disable-next-line
    [isEditing]
  );

  useEffect(() => {
    setTimeout(() => {
      setShowMap(true);
    }, 1000);
  }, []);

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          ...location,
          region: isEditing ? location.region : "NORTH"
        }}
        validationSchema={LocationEditSchema}
        validate={values => {
          const errors = {};
          if (!values.longitude || !values.latitude) {
            errors.map = "Required";
          }
          return errors;
        }}
        onSubmit={values => {
          saveLocation(values);
        }}
      >
        {({ handleSubmit, values, errors, setFieldValue }) => (
          <>
            {actionsLoading && (
              <div className="overlay-layer bg-transparent">
                <div className="spinner spinner-lg spinner-success" />
              </div>
            )}
            <Form className="form form-label-right row">
              <div className="col-lg-6">
                <Card className="px-2">
                  <CardHeader title={"General"} />
                  <div className="form-group flex flex-wrap pt-4">
                    {/* Name */}
                    <div className="col-lg-12 mb-4">
                      <Field
                        name="name"
                        component={Input}
                        placeholder="Name"
                        label={<AsteriskLabel word="Name"/>}
                      />
                    </div>
                    {/* Building Name */}
                    <div className="col-lg-12 mb-4">
                      <Field
                        name="building_name"
                        component={Input}
                        placeholder="Building Name"
                        label="Building Name"
                      />
                    </div>
                    <div className="col-lg-12 mb-4">
                      <Field
                        name="street_name"
                        component={Input}
                        placeholder="Street Name"
                        label={<AsteriskLabel word="Street Name"/>}
                      />
                    </div>
                    <div className="col-lg-12 mb-4">
                      <Field
                        name="block_no"
                        component={Input}
                        placeholder="Block no"
                        label={<AsteriskLabel word="Block No"/>}
                      />
                    </div>
                    <div className="col-lg-12 mb-4">
                      <Field
                        name="unit_no"
                        component={Input}
                        placeholder="Unit No"
                        label="Unit No"
                      />
                    </div>
                    <div className="col-lg-12 mb-4">
                      <Field
                        name="postal_code"
                        component={Input}
                        placeholder="******"
                        label={<AsteriskLabel word="Postal Code"/>}
                      />
                    </div>
                    <div className="col-lg-12 mb-4">
                      <Field
                        name="level_no"
                        component={Input}
                        placeholder="Level No"
                        label="Level No"
                      />
                    </div>
                    <div className="col-lg-12 mb-4">
                      <Select
                        name="region"
                        placeholder="Region"
                        label={<AsteriskLabel word="Region"/>}
                      >
                        <option value="NORTH">North</option>
                        <option value="SOUTH">South</option>
                        <option value="WEST">West</option>
                        <option value="EAST">East</option>
                        <option value="CENTRAL">Central</option>
                      </Select>
                    </div>
                  </div>
                </Card>
              </div>
              <div className="col-lg-6">
                <Card className="px-2">
                  <CardHeader title={"Additional Information"} />
                  <div className="form-group flex flex-wrap pt-4">
                    {showMap && (
                      <div className="col-lg-12">
                        <div style={{ height: "300px" }}>
                          <LeafletMap
                            initialPosition={
                              values.latitude && values.longitude
                                ? [values.latitude, values.longitude]
                                : null
                            }
                            handlePositionChange={(lat, lng) => {
                              setFieldValue("latitude", lat);
                              setFieldValue("longitude", lng);
                            }}
                          />
                        </div>
                        {errors.map && (
                          <span classname="text-danger">Required</span>
                        )}
                      </div>
                    )}
                  </div>
                </Card>
              </div>
            </Form>
            <Card className="_card-toolbar-start">
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
            </Card>
          </>
        )}
      </Formik>
    </>
  );
}
