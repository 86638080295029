import React from "react";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../../../../_metronic/_helpers";

export function ActionsColumnFormatter(
  cellContent,
  row,
  rowIndex,
  { openEditUserPage, openBanUserDialog, openEditUserCreditDialog }
) {
  return (
    <>
      <button
        type="button"
        title="Edit User"
        className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
        onClick={(e) => {
          e.stopPropagation();
          openEditUserPage(row.id)
        }}
      >
        <span className="svg-icon svg-icon-md svg-icon-primary">
          <SVG
        title="Edit User"
            src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
          />
        </span>
      </button>

      <button
        type="button"
        title="Edit Credit"
        className="btn btn-icon btn-light btn-hover-warning btn-sm mr-3"
        onClick={(e) => {
          e.stopPropagation();
          openEditUserCreditDialog(row.id);
        }}
      >
        <span
          className="svg-icon svg-icon-md svg-icon-warning"
          title="Edit Credit"
        >
          <SVG
            title="Edit Credit"
            src={toAbsoluteUrl("/media/svg/icons/Shopping/Money.svg")}
          />
        </span>
      </button>

      <button
        type="button"
        title="Toggle Ban User"
        className="btn btn-icon btn-light btn-hover-danger btn-sm"
        onClick={(e) =>{
          e.stopPropagation()
          openBanUserDialog(row.id, row.is_banned ? "unban" : "ban")
        }}
      >
        <span className="svg-icon svg-icon-md svg-icon-danger">
          <SVG
            title="Toggle Ban User"
            src={
              row.is_banned
                ? toAbsoluteUrl("/media/svg/icons/General/Unlock.svg")
                : toAbsoluteUrl("/media/svg/icons/General/Lock.svg")
            }
          />
        </span>
      </button>
    </>
  );
}
