import * as requestFromServer from "./usersCrud";
import { usersSlice, callTypes } from "./usersSlice";

const { actions } = usersSlice;

export const fetchUsers = (queryParams) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findUsers(queryParams)
    .then((response) => {
      const {
        data: {
          users: { total, nodes },
        },
      } = response;
      dispatch(actions.usersFetched({ totalCount: total, entities: nodes }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchUsersAll = () => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .getAllUsers()
    .then((response) => {
      const {
        data: {
          users: { total, nodes },
        },
      } = response;
      dispatch(actions.usersFetched({ totalCount: total, entities: nodes }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchUser = (id) => (dispatch) => {
  if (!id) {
    return dispatch(actions.userFetched({ userForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getUserById(id)
    .then((response) => {
      const {
        data: { user },
      } = response;
      dispatch(
        actions.userFetched({
          userForEdit: {
            ...user,
            date_of_birth: new Date(
              new Date().getFullYear(),
              user.birth_month - 1, // Server month start from 1, Javascript month start from 0
              user.birth_date
            ),
            role: user.is_admin ? "admin" : "user",
          },
        })
      );
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createUser = (userForCreation) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createUser(userForCreation)
    .then((response) => {
      const {
        data: {
          user: { create_user },
        },
      } = response;
      dispatch(actions.userCreated({ user: create_user }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      throw error;
    });
};

export const updateUser = (user) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateUser(user)
    .then((response) => {
      const {
        data: {
          user: { update_user },
        },
      } = response;
      dispatch(actions.userUpdated({ user: update_user }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      throw error;
    });
};

// export const deleteUsers = (ids) => (dispatch) => {
//   dispatch(actions.startCall({callType: callTypes.action}));
//   return requestFromServer
//   .deleteUsers(ids)
//   .then(() => {
//     dispatch(actions.usersDeleted({ids}));
//   }).catch((error) => {
//     dispatch(actions.catchError({error, callType: callTypes.action}));
//   })
// }

export const updateUserCredit = (user) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .updateUserCreditAmount(user)
    .then((response) => {
      const {
        data: {
          user: { update_user_credit },
        },
      } = response;

      dispatch(actions.userUpdated({ user: update_user_credit }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

// export const toggleBanUser = (id) => (dispatch) => {
//   dispatch(actions.startCall({ callType: callTypes.action }));

//   return requestFromServer
//     .toggleBanUser(id)
//     .then((response) => {
//       const {
//         data: {
//           user: { toggle_ban_user },
//         },
//       } = response;

//       dispatch(actions.onToggleUserBanned({ isBanned: toggle_ban_user }));
//     })
//     .catch((error) => {
//       dispatch(actions.catchError({ error, callType: callTypes.action }));
//       throw error;
//     });
// };

export const banUser = (id, weeks) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .banUser(id, weeks)
    .then((response) => {
      const {
        data: {
          user: { ban_user },
        },
      } = response;

      dispatch(actions.userUpdated({ user: ban_user }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      throw error;
    });
};

export const unbanUser = (id) => (dispatch) => {
  dispatch(actions.startCall({ callType: callTypes.action }));

  return requestFromServer
    .unbanUser(id)
    .then((response) => {
      const {
        data: {
          user: { unban_user },
        },
      } = response;

      dispatch(actions.userUpdated({ user: unban_user }));
    })
    .catch((error) => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      throw error;
    });
};
