import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
import { UsersPage } from "./modules/Users/pages/users/UsersPage";
import { InstructorsPage } from "./modules/Instructors/pages/instructors/InstructorsPage";
import { RequirementsPage } from "./modules/Requirements/pages/requirements/RequirementsPage";
import { LocationsPage } from "./modules/Locations/pages/locations/LocationsPage";
import { ClassesPage } from "./modules/Class/pages/classes/ClassesPage";
import { NewsPage } from "./modules/News/pages/NewsPage";
import { UserContactsPage } from "./modules/UserContact/pages/userContacts/UserContactsPage";
import { RefundRequestPage } from "./modules/RefundRequest/pages/RefundRequestPage";
import { CreditTypesPage } from "./modules/CreditType/pages/creditTypes/CreditTypesPage";

const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);
const StudioPage = lazy(() => import("./modules/Studio/StudioPage"));
const ConfigPage = lazy(() => import("./pages/config/ConfigPage"));
const PurchaseHistoryPage = lazy(() =>
  import("./modules/PurchaseHistory/PurchaseHistoryPage")
);
const EventPage = lazy(() => import("./modules/Event/EventPage"));

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        <Redirect exact from="/" to="/dashboard" />
        <Redirect exact from="/clients" to="/clients/contacts" />

        <ContentRoute path="/my-page" component={MyPage} />
        <ContentRoute path="/dashboard" component={DashboardPage} />

        <Route path="/config" component={ConfigPage} />

        <Route path="/user-profile" component={UserProfilepage} />
        <Route path="/studios" component={StudioPage} />
        <Route path="/news" component={NewsPage} />

        <Route path="/users" component={UsersPage} />
        <Route path="/instructors" component={InstructorsPage} />
        <Route path="/requirements" component={RequirementsPage} />

        <Route path="/locations" component={LocationsPage} />
        <Route path="/classes" component={ClassesPage} />
        <Route path="/event" component={EventPage} />

        <Route path="/clients/contacts" component={UserContactsPage} />
        <Route path="/purchase-histories" component={PurchaseHistoryPage} />
        <Route path="/clients/refund-requests" component={RefundRequestPage} />

        <Route path="/credit-types" component={CreditTypesPage} />

        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
