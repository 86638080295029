import React, { useMemo } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Input,
  Card,
  CardHeader,
  DateTimeField,
} from "../../../../../../_metronic/_partials/controls";
import parsePhoneNumber from "libphonenumber-js";
import ModalCropper from "../../../../../../utils/ModalCropper";
import Switch from "../../../../../../utils/Switch";
import AsteriskLabel from "../../../../../../utils/Asterisk";

export function UserEditForm({
  saveUser,
  isEditing,
  user,
  actionsLoading,
  onHide,
}) {
  const days = [31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  // Validation schema
  const UserEditSchema = useMemo(
    () =>
      isEditing
        ? Yup.object().shape({
            first_name: Yup.string()
              .trim()
              .max(50, "Maximum 50 symbols")
              .required("First Name is required"),
            last_name: Yup.string()
              .trim()
              .nullable(true)
              .max(50, "Maximum 50 symbols"),
            email: Yup.string()
              .trim()
              .email("Invalid email")
              .required("Email is required"),
            birth_date: Yup.number().required("Birth Date is Required"),
            birth_month: Yup.number().required("Birth Month is Required"),
            phone_number: Yup.string()
              .required("Phone number is required")
              .trim()
              .test("not valid", "Not a valid Phone Number", function(value) {
                if (!value) return false;
                const phoneLib = parsePhoneNumber(value, "SG");
                if (phoneLib) {
                  return phoneLib.isValid();
                } else {
                  return false;
                }
              }),
            credit_expired_at: Yup.date().required(
              "Credit expired is required"
            ),
          })
        : Yup.object().shape({
            first_name: Yup.string()
              .trim()
              .max(50, "Maximum 50 symbols")
              .required("First Name is required"),
            last_name: Yup.string()
              .trim()
              .nullable(true)
              .max(50, "Maximum 50 symbols"),
            email: Yup.string()
              .trim()
              .email("Invalid email")
              .required("Email is required"),
            birth_date: Yup.number().required("Birth Date is Required"),
            birth_month: Yup.number().required("Birth Month is Required"),
            phone_number: Yup.string()
              .required("Phone number is required")
              .trim()
              .test("not valid", "Not a valid Phone Number", function(value) {
                if (!value) return false;
                const phoneLib = parsePhoneNumber(value, "SG");
                if (phoneLib) {
                  return phoneLib.isValid();
                } else {
                  return false;
                }
              }),
            password: Yup.string()
              .required("Password is required")
              .min(8, "Must be at least 8 characters")
              .matches(
                /(?=.*[$#@!%^&*()_+=-]).{8,}/,
                "Must contain at least one special character"
              ),
          }),
    [isEditing]
  );

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{ ...user }}
        validationSchema={UserEditSchema}
        onSubmit={(values) => {
          saveUser(values);
        }}
      >
        {({ handleSubmit, setFieldValue, values }) => {
          return (
            <>
              <div className="overlay overlay-block cursor-default">
                {actionsLoading && (
                  <div className="overlay-layer bg-transparent">
                    <div className="spinner spinner-lg spinner-success" />
                  </div>
                )}
                <Form className="form form-label-right row">
                  <div className="col-xl-7">
                    <Card className="px-2">
                      <CardHeader title={"General"} />
                      <div className="form-group row pt-4 px-4">
                        <div className="col-xl-6">
                          <Field
                            type="email"
                            name="email"
                            component={Input}
                            placeholder="Email"
                            label={<AsteriskLabel word="Email" />}
                          />
                        </div>
                        <div className="col-xl-6">
                          <Field
                            type="phone"
                            name="phone_number"
                            component={Input}
                            placeholder="+65XXXXXXXX"
                            label={<AsteriskLabel word="Phone Number" />}
                          />
                        </div>
                      </div>
                      <div className="form-group row px-4">
                        <div className="col-xl-6">
                          <Field
                            name="first_name"
                            component={Input}
                            placeholder="First Name"
                            label={<AsteriskLabel word="First Name" />}
                          />
                        </div>

                        <div className="col-xl-6">
                          <Field
                            name="last_name"
                            component={Input}
                            placeholder="Last Name"
                            label={"Last Name"}
                          />
                        </div>
                      </div>

                      <div className="form-group row px-4">
                        {!isEditing && (
                          <div className="col-xl-6">
                            <Field
                              type="password"
                              name="password"
                              component={Input}
                              placeholder="Password"
                              label={
                                isEditing ? (
                                  "Password"
                                ) : (
                                  <AsteriskLabel word="Password" />
                                )
                              }
                            />
                          </div>
                        )}
                        {/* Date of birth */}
                        <div className="col-xl-6">
                          <div className="row">
                            <div className="col-xl-6">
                              <label htmlFor="birth_date">
                                <AsteriskLabel word="Birth Date" />
                              </label>
                              <select
                                name="birth_date"
                                id="birth_date"
                                className="form-control"
                                value={values.birth_date}
                                onChange={(e) => {
                                  setFieldValue("birth_date", e.target.value);
                                }}
                              >
                                {Array.from(
                                  { length: days[values.birth_month - 1] },
                                  (_, i) => i + 1
                                ).map((v) => {
                                  return (
                                    <option key={`_birth_date-${v}`} value={v}>
                                      {v}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                            <div className="col-xl-6">
                              <label htmlFor="birth_month">
                                {<AsteriskLabel word="Birth Month" />}
                              </label>
                              <select
                                className="form-control"
                                id="birth_month"
                                name="birth_month"
                                value={values.birth_month}
                                onChange={(e) => {
                                  setFieldValue("birth_month", e.target.value);
                                }}
                              >
                                {Object.entries({
                                  January: 1,
                                  February: 2,
                                  March: 3,
                                  April: 4,
                                  May: 5,
                                  June: 6,
                                  July: 7,
                                  August: 8,
                                  September: 9,
                                  October: 10,
                                  November: 11,
                                  December: 12,
                                }).map(([key, val]) => (
                                  <option
                                    key={`_birth_month-${val}`}
                                    value={val}
                                  >
                                    {key}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        {/* Credit Expired */}
                        {isEditing && (
                          <div className="col-xl-6">
                            <Field
                              name="credit_expired_at"
                              component={DateTimeField}
                              label={<AsteriskLabel word="Credit Expired At" />}
                            />
                          </div>
                        )}
                      </div>
                      <div className="form-group row px-4">
                        <div className="col-xl-6">
                          <div className="form-group row">
                            <div className="col-xl-12">
                              Admin
                              <Switch
                                name="is_admin"
                                checked={values.is_admin}
                                onChange={(e) => {
                                  setFieldValue("is_admin", e.target.checked);
                                }}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-xl-12">
                              Accept Marketing
                              <Switch
                                name="accept_marketing"
                                checked={values.accept_marketing}
                                onChange={(e) => {
                                  setFieldValue(
                                    "accept_marketing",
                                    e.target.checked
                                  );
                                }}
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-xl-12">
                              Accept Notification
                              <Switch
                                name="accept_notification"
                                checked={values.accept_notification}
                                onChange={(e) => {
                                  setFieldValue(
                                    "accept_notification",
                                    e.target.checked
                                  );
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group row px-4">
                        <div className="col-xl-4">
                          <label>Avatar Image</label>
                          <br />
                          <ModalCropper
                            onSaved={(blob, file) => {
                              setFieldValue("avatar", file);
                            }}
                            initialValue={values.image_link}
                            aspectRatio={1 / 1}
                            width={300}
                            height={300}
                            cropperWidth={"300px"}
                            cropperHeight={"300px"}
                            modalTitle={"Avatar Image"}
                          />
                        </div>
                      </div>
                    </Card>
                  </div>
                </Form>
              </div>
              <div className="row">
                <div className="col-xl-7">
                  <Card className="_card-toolbar-start">
                    <button
                      type="submit"
                      onClick={() => handleSubmit()}
                      className="btn btn-primary btn-elevate"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      onClick={onHide}
                      className="btn btn-light btn-elevate mr-auto"
                    >
                      Cancel
                    </button>
                  </Card>
                </div>
              </div>
            </>
          );
        }}
      </Formik>
    </>
  );
}
