import React, { createContext, useContext, useState, useCallback } from "react";
import { isEqual, isFunction } from "lodash";
import { initialFilter } from "./LocationsUIHelpers";

const LocationsUIContext = createContext();

export const useLocationsUIContext = () => {
  return useContext(LocationsUIContext);
};

export const LocationsUIConsumer = LocationsUIContext.Consumer;

export const LocationsUIProvider = ({ locationsUIEvents, children }) => {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback((nextQueryParams) => {
    setQueryParamsBase((prevQueryParams) => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      return isEqual(prevQueryParams, nextQueryParams)
        ? prevQueryParams
        : nextQueryParams;
    });
  }, []);

  const initLocation = {
    id: undefined,
    name: "",
    building_name: "",
    street_name: "",
    block_no: "",
    unit_no: "",
    level_no: "",
    postal_code: "",
    longitude: "",
    latitude: "",
    notes_html: null,
    region: "", // NORTH | SOUTH | EAST | WEST | CENTRAL
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initLocation,
    newLocationButtonClick: locationsUIEvents.newLocationButtonClick,
    openEditLocationPage: locationsUIEvents.openEditLocationPage,
    openDeleteLocationDialog: locationsUIEvents.openDeleteLocationDialog,
  };

  return (
    <LocationsUIContext.Provider value={value}>
      {children}
    </LocationsUIContext.Provider>
  );
};
