import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import * as actions from "../../_redux/newsActions";
import { NewsEditForm } from "./NewsEditForm";
import { useHistory, useParams } from "react-router-dom";
import { snackActions } from "../../../../../utils/SnackbarUtils";

const initNews = {
  id: undefined,
  title: "",
  body_html: "",
  image: null,
};

export function NewsEdit() {
  const { id } = useParams();
  const history = useHistory();

  const dispatch = useDispatch();

  const { actionsLoading, newsForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.news.actionsLoading,
      newsForEdit: state.news.newsForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchNews(id));
  }, [id, dispatch]);

  const saveNews = (values) => {
    if (!id) {
      dispatch(actions.createNews(values))
        .then(() => backToNewsList())
        .catch((err) => snackActions.error(err.message));
    } else {
      dispatch(actions.updateNews(values))
        .then(() => backToNewsList())
        .catch((err) => snackActions.error(err.message));
    }
  };

  const backToNewsList = () => {
    history.push(`/news`);
  };

  return (
    <>
      <NewsEditForm
        actionsLoading={actionsLoading}
        news={newsForEdit || initNews}
        saveNews={saveNews}
        isEditing={id !== undefined}
        onHide={backToNewsList}
      />
    </>
  );
}
