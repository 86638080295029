import { gql } from "@apollo/client";

const VIRTUAL_UNLIMITED_HISTORIES = gql`
  query virtualUnlimitedHistories(
    $page: Int
    $limit: Int
    $user_id: Int
    $payment_method: PaymentMethod
    $payment_status: PaymentStatus
    $start_date: String
    $end_date: String
    $sort_by: UserVirtualUnlimitedHistorySortableField
    $sort_order: SortOrder
  ) {
    virtual_unlimited_histories(
      page: $page
      limit: $limit
      filter: {
        user_id: $user_id
        payment_method: $payment_method
        payment_status: $payment_status
        start_date: $start_date
        end_date: $end_date
        sort_by: $sort_by
        sort_order: $sort_order
      }
    ) {
      total
      nodes {
        id
        user_id
        price
        payment_method
        payment_status
        created_at
        paynow_payment_history_id
        stripe_payment_history {
          id
          stripe_identifier
        }
        user {
          id
          email
          first_name
          last_name
        }
        paynow_payment_history {
          id
          qr_string
          expired_at
        }
      }
    }
  }
`;

const VIRTUAL_UNLIMITED_HISTORY = gql`
  query virtualUnlimitedHistory($id: Int!) {
    virtual_unlimited_history(id: $id) {
      id
      user_id
      price
      payment_method
      payment_status
      created_at
      paynow_payment_history_id
      user {
        id
        email
        first_name
        last_name
      }
      paynow_payment_history {
        id
        qr_string
        expired_at
      }
      stripe_payment_history {
        stripe_identifier
        receipt_url
        charge_status
        description
        payment_method
      }
    }
  }
`;

export { VIRTUAL_UNLIMITED_HISTORIES, VIRTUAL_UNLIMITED_HISTORY };
