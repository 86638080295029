import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { UsersTable } from "./users-table/UsersTable";
import { useUsersUIContext } from "./UsersUIContext";
import { UsersFilter } from "./users-filter/UsersFilter";
import { Route } from "react-router-dom";
import { UserEditCreditDialog } from "./user-edit-credit-dialog/UserEditCreditDialog";
import { UsersUnbanDialog } from "./users-unban-dialog/UsersUnbanDialog";
import { UserCreditEditDialog } from "./user-credit-edit-dialog/UserCreditEditDialog";
import { UserBanDialog } from "./user-ban-dialog/UserBanDialog";

export function UsersCard() {
  const usersUIContext = useUsersUIContext();
  const usersUIProps = useMemo(() => {
    return {
      ids: usersUIContext.ids,
      newUserButtonClick: usersUIContext.newUserButtonClick,
    };
  }, [usersUIContext]);

  return (
    <Card>
      <Route path="/users/credit/:id/edit">
        {({ history, match }) => (
          <UserCreditEditDialog
            id={match && match.params.id}
            show={match != null}
            onHide={() => {
              history.push("/users");
            }}
          />
        )}
      </Route>

      <Route path="/users/:id/ban">
        {({ history, match }) => (
          <UserBanDialog
            id={match && match.params.id}
            show={match != null}
            onHide={() => {
              history.push("/users");
            }}
          />
        )}
      </Route>

      <Route path="/users/:id/unban">
        {({ history, match }) => (
          <UsersUnbanDialog
            id={match && match.params.id}
            show={match != null}
            onHide={() => {
              history.push("/users");
            }}
          />
        )}
      </Route>

      <Route path="/users/:id/credit">
        {({ history, match }) => (
          <UserEditCreditDialog
            id={match && match.params.id}
            show={match != null}
            onHide={() => {
              history.push("/users");
            }}
          />
        )}
      </Route>
      <CardHeader title="Users List">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={usersUIProps.newUserButtonClick}
          >
            New User
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <UsersFilter />
        {/* {usersUIProps.ids.length > 0 && <UsersGrouping />} */}
        <UsersTable />
      </CardBody>
    </Card>
  );
}
