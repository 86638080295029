export function toSnackUpperCase(str) {
  let uppercase = str.toUpperCase();
  return uppercase.split(" ").join("_");
}

export function toTitleCase(str) {
  const splitted = str.split("_");
  return splitted
    .map((word) => word.charAt(0).toUpperCase() + word.substr(1).toLowerCase())
    .join(" ");
}

export function upperSnackCaseToTittleCase(str) {
  return toTitleCase(str.split("_").join(" "));
}

export function toTitleCaseSpace(str) {
  return toTitleCase(str);
}
