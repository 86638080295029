import React from "react";
import { toTitleCaseSpace } from "../../../../../../../utils/case";

export const UserContactDetailVacancy = ({ userContactForEdit }) => {
  return (
    <>
      <div className="row">
        <div className="col-lg-4">
          <h5 className="font-bold">User Details</h5>
          <h6>Name</h6>
          <p>{userContactForEdit.name}</p>
          <h6>Email</h6>
          <p>{userContactForEdit.email}</p>
          <h6>Phone Number</h6>
          <p>{userContactForEdit.phone_number || "-"}</p>
          <h6>Type</h6>
          <p>{toTitleCaseSpace(userContactForEdit.type)}</p>
        </div>
        <div className="col-lg-4">
          <h5 className="font-bold">Details</h5>
          <h6>Why interested working in 1Fiesta</h6>
          <p> {userContactForEdit.message || "-"}</p>
          <h6>Attachment</h6>
          {userContactForEdit.attachment_link ? (
            <>
              <a
                class="btn btn-success mr-2"
                href={userContactForEdit.attachment_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                Preview
              </a>
              <a
                className="btn btn-primary"
                href={userContactForEdit.attachment_link}
                download="attachment"
              >
                Download
              </a>
            </>
          ) : (
            "-"
          )}
        </div>
      </div>
    </>
  );
};
