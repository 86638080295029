import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "./authCrud";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetUser: "[Set User] Action",
  UserRedirect: "[Redirect User] Action",
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  refreshToken: undefined,
};

export const reducer = persistReducer(
  { storage, key: "v726-demo1-auth", whitelist: ["authToken", "refreshToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { token, refresh_token } = action.payload;

        return {
          authToken: token,
          refreshToken: refresh_token,
          user: undefined,
        };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.SetUser: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.UserRedirect: {
        const { user } = action.payload;
        return { ...state, user };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (payload) => ({ type: actionTypes.Login, payload: payload }),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (payload) => ({
    type: actionTypes.UserRequested,
    payload: payload,
  }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),
  userRedirect: (user) => ({ type: actionTypes.UserRedirect, payload: { user } }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga(param) {
    yield put(actions.requestUser(param.payload));
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested(param) {
    const {
      data: { me: user },
    } = yield getUserByToken();

    yield put(actions.fulfillUser({
      ...user,
      ...param.payload
    }));
  });

  yield takeLatest(actionTypes.UserLoaded, function* userRedirectSaga(param) {
    yield put(actions.userRedirect(param.payload.user));
  });

}
