import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../_metronic/_partials/controls";
import { useLocationsUIContext } from "./LocationsUIContext";
import { LocationsTable } from "./locations-table/LocationsTable";
import { Route } from "react-router-dom";
import { LocationDeleteDialog } from "./location-delete-dialog/LocationDeleteDialog";
// import { LocationFilter } from "./locations-filter/LocationFilter";

export const LocationsCard = () => {
  const basePath = "/locations";
  const locationsUIContext = useLocationsUIContext();
  const locationsUIProps = useMemo(() => {
    return {
      ids: locationsUIContext.ids,
      newLocationButtonClick: locationsUIContext.newLocationButtonClick,
    };
  }, [locationsUIContext]);

  return (
    <Card>
      <Route path={`${basePath}/:id/delete`}>
        {({ history, match }) => (
          <LocationDeleteDialog
            show={match != null}
            id={match && match.params.id}
            onHide={() => {
              history.push(basePath);
            }}
          />
        )}
      </Route>
      <CardHeader title="Locations List">
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-primary"
            onClick={locationsUIProps.newLocationButtonClick}>
            New Location
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {/* <InstructorsFilter /> */}
        <LocationsTable />
      </CardBody>
    </Card>
  );
};
