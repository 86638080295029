import React, { useMemo, useEffect } from "react";
import * as actions from "../../../_redux/userContacts/userContactsActions";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  ModalProgressBar,
} from "../../../../../../_metronic/_partials/controls";
import { useUserContactsUIContext } from "../UserContactsUIContext";
import { UserContactDetail } from "./UserContactDetail";

export const UserContactDetailCard = ({ id }) => {
  const userContactsUIContext = useUserContactsUIContext();
  const userContactsUIProps = useMemo(() => {
    return {
      basePath: userContactsUIContext.basePath,
    };
  }, [userContactsUIContext]);

  const dispatch = useDispatch();
  const { actionsLoading, userContactForEdit } = useSelector((state) => {
    return {
      actionsLoading: state.user_contacts.actionsLoading,
      userContactForEdit: state.user_contacts.userContactForEdit,
    };
  });

  useEffect(() => {
    dispatch(actions.fetchUserContact(id));
  }, [id, dispatch]);

  return (
    <Card>
      {actionsLoading && <ModalProgressBar />}
      <CardHeader title="User Contact Detail">
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={userContactsUIProps.basePath}
            className="btn btn-light">
            <i className="fa fa-arrow-left"></i>
            Back
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <UserContactDetail
          userContactForEdit={userContactForEdit}
          actionsLoading={actionsLoading}
        />
      </CardBody>
    </Card>
  );
};
