import React from "react";

export function ImagePathColumnFormatter(cell, row) {
  if (!row.image_link) return <div></div>;
  return (
    <img
      alt=""
      style={{ objectFit: "cover", width: "100px" }}
      src={row.image_link}
    />
  );
}
