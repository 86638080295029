import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { ModalProgressBar } from "../../../../../../_metronic/_partials/controls";

export function UserEditCreditDialogHeader({ id }) {
  const { userForEdit, actionsLoading } = useSelector(
    (state) => ({
      userForEdit: state.users.userForEdit,
      actionsLoading: state.users.actionsLoading,
    }),
    shallowEqual
  );

  const [title, setTitle] = useState("");

  useEffect(() => {
    let _title = id ? "" : "New User";
    if (userForEdit && id) {
      _title = `Edit user '${userForEdit.first_name} ${userForEdit.last_name || ""}' credit`;
    }

    setTitle(_title);
    //eslint-disable-next-line
  }, [userForEdit, actionsLoading]);

  return (
    <>
      {actionsLoading && <ModalProgressBar />}
      <Modal.Header closeButton>
        <Modal.Title id="user-edit-modal-size-title-lg">{title} </Modal.Title>
      </Modal.Header>
    </>
  );
}
