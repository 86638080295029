import React from "react";
import {
  ApolloClient,
  ApolloProvider,
  from,
  InMemoryCache,
} from "@apollo/client";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "@apollo/client/link/context";
import store from "../../redux/store";
import { onError } from "@apollo/client/link/error";
import { getNewTokenByRefreshToken } from "../modules/Auth/_redux/authCrud";
import { actions } from "../modules/Auth/_redux/authRedux";
import { snackActions } from "../../utils/SnackbarUtils";

const authLink = setContext(() => {
  const {
    auth: { authToken },
  } = store.getState();

  return {
    headers: {
      Authorization: authToken ? `Bearer ${authToken}` : "",
    },
  };
});

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    const previousPath = window.location.pathname;

    const { login, logout } = actions;

    if (networkError) {
      // redirect to no connection page
    }

    if (graphQLErrors) {
      graphQLErrors.forEach(
        async ({ message, locations, path, extensions }) => {
          if (extensions) {
            switch (extensions.code) {
              case "UNAUTHORIZED":
                const {
                  auth: { refreshToken },
                } = store.getState();

                const { data } = await getNewTokenByRefreshToken(refreshToken);
                store.dispatch(
                  login({
                    token: data.user.refresh_access_token.token,
                    refresh_token: data.user.refresh_access_token.refresh_token,
                    is_retrieve_new_token: true,
                    previousPath: previousPath,
                  })
                );

                // Modify the operation context with a new token
                const oldHeaders = operation.getContext().headers;
                operation.setContext({
                  headers: {
                    ...oldHeaders,
                    authorization: `Bearer ${data.user.refresh_access_token.token}`,
                  },
                });

                // Retry the request, returning the new observable
                return forward(operation);
              case "INVALID_REFRESH_TOKEN":
                store.dispatch(logout());
                break;
              case "BANNED":
                store.dispatch(logout());
                snackActions.error(message);
                break;
              default:
            }
          }
        }
      );
    }
  }
);

const httpLink = createUploadLink({
  uri: `${process.env.REACT_APP_GRAPHQL_ENDPOINT}`,
});

export const apolloClient = new ApolloClient({
  link: from([errorLink, authLink.concat(httpLink)]),
  cache: new InMemoryCache({
    typePolicies: "no-cache",
  }),
  defaultOptions: {
    // watchQuery: {
    //   fetchPolicy: 'no-cache',
    //   errorPolicy: 'ignore',
    // },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  },
});

const ApolloWrapper = ({ children }) => {
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default ApolloWrapper;
