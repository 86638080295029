export const options = [
  {
    value: "sports_shoes.svg",
    label: "Sports Shoes",
    icon: "sports_shoes.svg",
    is_fa: false,
  },
  {
    value: "sports_wear.svg",
    label: "Sports Wear",
    icon: "sports_wear.svg",
    is_fa: false,
  },
  {
    value: "swim_suit.svg",
    label: "Swim Suit",
    icon: "swim_suit.svg",
    is_fa: false,
  },
  {
    value: "yoga_mat.svg",
    label: "Yoga Mat",
    icon: "yoga_mat.svg",
    is_fa: false,
  },
  {
    value: "0",
    label: "0",
    icon: "0",
    is_fa: true,
  },
  {
    value: "1",
    label: "1",
    icon: "1",
    is_fa: true,
  },
  {
    value: "2",
    label: "2",
    icon: "2",
    is_fa: true,
  },
  {
    value: "3",
    label: "3",
    icon: "3",
    is_fa: true,
  },
  {
    value: "4",
    label: "4",
    icon: "4",
    is_fa: true,
  },
  {
    value: "5",
    label: "5",
    icon: "5",
    is_fa: true,
  },
  {
    value: "6",
    label: "6",
    icon: "6",
    is_fa: true,
  },
  {
    value: "7",
    label: "7",
    icon: "7",
    is_fa: true,
  },
  {
    value: "8",
    label: "8",
    icon: "8",
    is_fa: true,
  },
  {
    value: "9",
    label: "9",
    icon: "9",
    is_fa: true,
  },
  {
    value: "a",
    label: "A",
    icon: "a",
    is_fa: true,
  },
  {
    value: "address-book",
    label: "Address book",
    icon: "address-book",
    is_fa: true,
  },
  {
    value: "address-card",
    label: "Address card",
    icon: "address-card",
    is_fa: true,
  },
  {
    value: "align-center",
    label: "Align center",
    icon: "align-center",
    is_fa: true,
  },
  {
    value: "align-justify",
    label: "Align justify",
    icon: "align-justify",
    is_fa: true,
  },
  {
    value: "align-left",
    label: "Align left",
    icon: "align-left",
    is_fa: true,
  },
  {
    value: "align-right",
    label: "Align right",
    icon: "align-right",
    is_fa: true,
  },
  {
    value: "anchor",
    label: "Anchor",
    icon: "anchor",
    is_fa: true,
  },
  {
    value: "anchor-circle-check",
    label: "Anchor circle check",
    icon: "anchor-circle-check",
    is_fa: true,
  },
  {
    value: "anchor-circle-exclamation",
    label: "Anchor circle exclamation",
    icon: "anchor-circle-exclamation",
    is_fa: true,
  },
  {
    value: "anchor-circle-xmark",
    label: "Anchor circle xmark",
    icon: "anchor-circle-xmark",
    is_fa: true,
  },
  {
    value: "anchor-lock",
    label: "Anchor lock",
    icon: "anchor-lock",
    is_fa: true,
  },
  {
    value: "angle-down",
    label: "Angle down",
    icon: "angle-down",
    is_fa: true,
  },
  {
    value: "angle-left",
    label: "Angle left",
    icon: "angle-left",
    is_fa: true,
  },
  {
    value: "angle-right",
    label: "Angle right",
    icon: "angle-right",
    is_fa: true,
  },
  {
    value: "angle-up",
    label: "Angle up",
    icon: "angle-up",
    is_fa: true,
  },
  {
    value: "angles-down",
    label: "Angles down",
    icon: "angles-down",
    is_fa: true,
  },
  {
    value: "angles-left",
    label: "Angles left",
    icon: "angles-left",
    is_fa: true,
  },
  {
    value: "angles-right",
    label: "Angles right",
    icon: "angles-right",
    is_fa: true,
  },
  {
    value: "angles-up",
    label: "Angles up",
    icon: "angles-up",
    is_fa: true,
  },
  {
    value: "ankh",
    label: "Ankh",
    icon: "ankh",
    is_fa: true,
  },
  {
    value: "apple-whole",
    label: "Apple whole",
    icon: "apple-whole",
    is_fa: true,
  },
  {
    value: "archway",
    label: "Archway",
    icon: "archway",
    is_fa: true,
  },
  {
    value: "arrow-down",
    label: "Arrow down",
    icon: "arrow-down",
    is_fa: true,
  },
  {
    value: "arrow-down-1-9",
    label: "Arrow down 1 9",
    icon: "arrow-down-1-9",
    is_fa: true,
  },
  {
    value: "arrow-down-9-1",
    label: "Arrow down 9 1",
    icon: "arrow-down-9-1",
    is_fa: true,
  },
  {
    value: "arrow-down-a-z",
    label: "Arrow down a z",
    icon: "arrow-down-a-z",
    is_fa: true,
  },
  {
    value: "arrow-down-long",
    label: "Arrow down long",
    icon: "arrow-down-long",
    is_fa: true,
  },
  {
    value: "arrow-down-short-wide",
    label: "Arrow down short wide",
    icon: "arrow-down-short-wide",
    is_fa: true,
  },
  {
    value: "arrow-down-up-across-line",
    label: "Arrow down up across line",
    icon: "arrow-down-up-across-line",
    is_fa: true,
  },
  {
    value: "arrow-down-up-lock",
    label: "Arrow down up lock",
    icon: "arrow-down-up-lock",
    is_fa: true,
  },
  {
    value: "arrow-down-wide-short",
    label: "Arrow down wide short",
    icon: "arrow-down-wide-short",
    is_fa: true,
  },
  {
    value: "arrow-down-z-a",
    label: "Arrow down z a",
    icon: "arrow-down-z-a",
    is_fa: true,
  },
  {
    value: "arrow-left",
    label: "Arrow left",
    icon: "arrow-left",
    is_fa: true,
  },
  {
    value: "arrow-left-long",
    label: "Arrow left long",
    icon: "arrow-left-long",
    is_fa: true,
  },
  {
    value: "arrow-pointer",
    label: "Arrow pointer",
    icon: "arrow-pointer",
    is_fa: true,
  },
  {
    value: "arrow-right",
    label: "Arrow right",
    icon: "arrow-right",
    is_fa: true,
  },
  {
    value: "arrow-right-arrow-left",
    label: "Arrow right arrow left",
    icon: "arrow-right-arrow-left",
    is_fa: true,
  },
  {
    value: "arrow-right-from-bracket",
    label: "Arrow right from bracket",
    icon: "arrow-right-from-bracket",
    is_fa: true,
  },
  {
    value: "arrow-right-long",
    label: "Arrow right long",
    icon: "arrow-right-long",
    is_fa: true,
  },
  {
    value: "arrow-right-to-bracket",
    label: "Arrow right to bracket",
    icon: "arrow-right-to-bracket",
    is_fa: true,
  },
  {
    value: "arrow-right-to-city",
    label: "Arrow right to city",
    icon: "arrow-right-to-city",
    is_fa: true,
  },
  {
    value: "arrow-rotate-left",
    label: "Arrow rotate left",
    icon: "arrow-rotate-left",
    is_fa: true,
  },
  {
    value: "arrow-rotate-right",
    label: "Arrow rotate right",
    icon: "arrow-rotate-right",
    is_fa: true,
  },
  {
    value: "arrow-trend-down",
    label: "Arrow trend down",
    icon: "arrow-trend-down",
    is_fa: true,
  },
  {
    value: "arrow-trend-up",
    label: "Arrow trend up",
    icon: "arrow-trend-up",
    is_fa: true,
  },
  {
    value: "arrow-turn-down",
    label: "Arrow turn down",
    icon: "arrow-turn-down",
    is_fa: true,
  },
  {
    value: "arrow-turn-up",
    label: "Arrow turn up",
    icon: "arrow-turn-up",
    is_fa: true,
  },
  {
    value: "arrow-up",
    label: "Arrow up",
    icon: "arrow-up",
    is_fa: true,
  },
  {
    value: "arrow-up-1-9",
    label: "Arrow up 1 9",
    icon: "arrow-up-1-9",
    is_fa: true,
  },
  {
    value: "arrow-up-9-1",
    label: "Arrow up 9 1",
    icon: "arrow-up-9-1",
    is_fa: true,
  },
  {
    value: "arrow-up-a-z",
    label: "Arrow up a z",
    icon: "arrow-up-a-z",
    is_fa: true,
  },
  {
    value: "arrow-up-from-bracket",
    label: "Arrow up from bracket",
    icon: "arrow-up-from-bracket",
    is_fa: true,
  },
  {
    value: "arrow-up-from-ground-water",
    label: "Arrow up from ground water",
    icon: "arrow-up-from-ground-water",
    is_fa: true,
  },
  {
    value: "arrow-up-from-water-pump",
    label: "Arrow up from water pump",
    icon: "arrow-up-from-water-pump",
    is_fa: true,
  },
  {
    value: "arrow-up-long",
    label: "Arrow up long",
    icon: "arrow-up-long",
    is_fa: true,
  },
  {
    value: "arrow-up-right-dots",
    label: "Arrow up right dots",
    icon: "arrow-up-right-dots",
    is_fa: true,
  },
  {
    value: "arrow-up-right-from-square",
    label: "Arrow up right from square",
    icon: "arrow-up-right-from-square",
    is_fa: true,
  },
  {
    value: "arrow-up-short-wide",
    label: "Arrow up short wide",
    icon: "arrow-up-short-wide",
    is_fa: true,
  },
  {
    value: "arrow-up-wide-short",
    label: "Arrow up wide short",
    icon: "arrow-up-wide-short",
    is_fa: true,
  },
  {
    value: "arrow-up-z-a",
    label: "Arrow up z a",
    icon: "arrow-up-z-a",
    is_fa: true,
  },
  {
    value: "arrows-down-to-line",
    label: "Arrows down to line",
    icon: "arrows-down-to-line",
    is_fa: true,
  },
  {
    value: "arrows-down-to-people",
    label: "Arrows down to people",
    icon: "arrows-down-to-people",
    is_fa: true,
  },
  {
    value: "arrows-left-right",
    label: "Arrows left right",
    icon: "arrows-left-right",
    is_fa: true,
  },
  {
    value: "arrows-left-right-to-line",
    label: "Arrows left right to line",
    icon: "arrows-left-right-to-line",
    is_fa: true,
  },
  {
    value: "arrows-rotate",
    label: "Arrows rotate",
    icon: "arrows-rotate",
    is_fa: true,
  },
  {
    value: "arrows-spin",
    label: "Arrows spin",
    icon: "arrows-spin",
    is_fa: true,
  },
  {
    value: "arrows-split-up-and-left",
    label: "Arrows split up and left",
    icon: "arrows-split-up-and-left",
    is_fa: true,
  },
  {
    value: "arrows-to-circle",
    label: "Arrows to circle",
    icon: "arrows-to-circle",
    is_fa: true,
  },
  {
    value: "arrows-to-dot",
    label: "Arrows to dot",
    icon: "arrows-to-dot",
    is_fa: true,
  },
  {
    value: "arrows-to-eye",
    label: "Arrows to eye",
    icon: "arrows-to-eye",
    is_fa: true,
  },
  {
    value: "arrows-turn-right",
    label: "Arrows turn right",
    icon: "arrows-turn-right",
    is_fa: true,
  },
  {
    value: "arrows-turn-to-dots",
    label: "Arrows turn to dots",
    icon: "arrows-turn-to-dots",
    is_fa: true,
  },
  {
    value: "arrows-up-down",
    label: "Arrows up down",
    icon: "arrows-up-down",
    is_fa: true,
  },
  {
    value: "arrows-up-down-left-right",
    label: "Arrows up down left right",
    icon: "arrows-up-down-left-right",
    is_fa: true,
  },
  {
    value: "arrows-up-to-line",
    label: "Arrows up to line",
    icon: "arrows-up-to-line",
    is_fa: true,
  },
  {
    value: "asterisk",
    label: "Asterisk",
    icon: "asterisk",
    is_fa: true,
  },
  {
    value: "at",
    label: "At",
    icon: "at",
    is_fa: true,
  },
  {
    value: "atom",
    label: "Atom",
    icon: "atom",
    is_fa: true,
  },
  {
    value: "audio-description",
    label: "Audio description",
    icon: "audio-description",
    is_fa: true,
  },
  {
    value: "austral-sign",
    label: "Austral sign",
    icon: "austral-sign",
    is_fa: true,
  },
  {
    value: "award",
    label: "Award",
    icon: "award",
    is_fa: true,
  },
  {
    value: "b",
    label: "B",
    icon: "b",
    is_fa: true,
  },
  {
    value: "baby",
    label: "Baby",
    icon: "baby",
    is_fa: true,
  },
  {
    value: "baby-carriage",
    label: "Baby carriage",
    icon: "baby-carriage",
    is_fa: true,
  },
  {
    value: "backward",
    label: "Backward",
    icon: "backward",
    is_fa: true,
  },
  {
    value: "backward-fast",
    label: "Backward fast",
    icon: "backward-fast",
    is_fa: true,
  },
  {
    value: "backward-step",
    label: "Backward step",
    icon: "backward-step",
    is_fa: true,
  },
  {
    value: "bacon",
    label: "Bacon",
    icon: "bacon",
    is_fa: true,
  },
  {
    value: "bacteria",
    label: "Bacteria",
    icon: "bacteria",
    is_fa: true,
  },
  {
    value: "bacterium",
    label: "Bacterium",
    icon: "bacterium",
    is_fa: true,
  },
  {
    value: "bag-shopping",
    label: "Bag shopping",
    icon: "bag-shopping",
    is_fa: true,
  },
  {
    value: "bahai",
    label: "Bahai",
    icon: "bahai",
    is_fa: true,
  },
  {
    value: "baht-sign",
    label: "Baht sign",
    icon: "baht-sign",
    is_fa: true,
  },
  {
    value: "ban",
    label: "Ban",
    icon: "ban",
    is_fa: true,
  },
  {
    value: "ban-smoking",
    label: "Ban smoking",
    icon: "ban-smoking",
    is_fa: true,
  },
  {
    value: "bandage",
    label: "Bandage",
    icon: "bandage",
    is_fa: true,
  },
  {
    value: "barcode",
    label: "Barcode",
    icon: "barcode",
    is_fa: true,
  },
  {
    value: "bars",
    label: "Bars",
    icon: "bars",
    is_fa: true,
  },
  {
    value: "bars-progress",
    label: "Bars progress",
    icon: "bars-progress",
    is_fa: true,
  },
  {
    value: "bars-staggered",
    label: "Bars staggered",
    icon: "bars-staggered",
    is_fa: true,
  },
  {
    value: "baseball",
    label: "Baseball",
    icon: "baseball",
    is_fa: true,
  },
  {
    value: "baseball-bat-ball",
    label: "Baseball bat ball",
    icon: "baseball-bat-ball",
    is_fa: true,
  },
  {
    value: "basket-shopping",
    label: "Basket shopping",
    icon: "basket-shopping",
    is_fa: true,
  },
  {
    value: "basketball",
    label: "Basketball",
    icon: "basketball",
    is_fa: true,
  },
  {
    value: "bath",
    label: "Bath",
    icon: "bath",
    is_fa: true,
  },
  {
    value: "battery-empty",
    label: "Battery empty",
    icon: "battery-empty",
    is_fa: true,
  },
  {
    value: "battery-full",
    label: "Battery full",
    icon: "battery-full",
    is_fa: true,
  },
  {
    value: "battery-half",
    label: "Battery half",
    icon: "battery-half",
    is_fa: true,
  },
  {
    value: "battery-quarter",
    label: "Battery quarter",
    icon: "battery-quarter",
    is_fa: true,
  },
  {
    value: "battery-three-quarters",
    label: "Battery three quarters",
    icon: "battery-three-quarters",
    is_fa: true,
  },
  {
    value: "bed",
    label: "Bed",
    icon: "bed",
    is_fa: true,
  },
  {
    value: "bed-pulse",
    label: "Bed pulse",
    icon: "bed-pulse",
    is_fa: true,
  },
  {
    value: "beer-mug-empty",
    label: "Beer mug empty",
    icon: "beer-mug-empty",
    is_fa: true,
  },
  {
    value: "bell",
    label: "Bell",
    icon: "bell",
    is_fa: true,
  },
  {
    value: "bell-concierge",
    label: "Bell concierge",
    icon: "bell-concierge",
    is_fa: true,
  },
  {
    value: "bell-slash",
    label: "Bell slash",
    icon: "bell-slash",
    is_fa: true,
  },
  {
    value: "bezier-curve",
    label: "Bezier curve",
    icon: "bezier-curve",
    is_fa: true,
  },
  {
    value: "bicycle",
    label: "Bicycle",
    icon: "bicycle",
    is_fa: true,
  },
  {
    value: "binoculars",
    label: "Binoculars",
    icon: "binoculars",
    is_fa: true,
  },
  {
    value: "biohazard",
    label: "Biohazard",
    icon: "biohazard",
    is_fa: true,
  },
  {
    value: "bitcoin-sign",
    label: "Bitcoin sign",
    icon: "bitcoin-sign",
    is_fa: true,
  },
  {
    value: "blender",
    label: "Blender",
    icon: "blender",
    is_fa: true,
  },
  {
    value: "blender-phone",
    label: "Blender phone",
    icon: "blender-phone",
    is_fa: true,
  },
  {
    value: "blog",
    label: "Blog",
    icon: "blog",
    is_fa: true,
  },
  {
    value: "bold",
    label: "Bold",
    icon: "bold",
    is_fa: true,
  },
  {
    value: "bolt",
    label: "Bolt",
    icon: "bolt",
    is_fa: true,
  },
  {
    value: "bolt-lightning",
    label: "Bolt lightning",
    icon: "bolt-lightning",
    is_fa: true,
  },
  {
    value: "bomb",
    label: "Bomb",
    icon: "bomb",
    is_fa: true,
  },
  {
    value: "bone",
    label: "Bone",
    icon: "bone",
    is_fa: true,
  },
  {
    value: "bong",
    label: "Bong",
    icon: "bong",
    is_fa: true,
  },
  {
    value: "book",
    label: "Book",
    icon: "book",
    is_fa: true,
  },
  {
    value: "book-atlas",
    label: "Book atlas",
    icon: "book-atlas",
    is_fa: true,
  },
  {
    value: "book-bible",
    label: "Book bible",
    icon: "book-bible",
    is_fa: true,
  },
  {
    value: "book-bookmark",
    label: "Book bookmark",
    icon: "book-bookmark",
    is_fa: true,
  },
  {
    value: "book-journal-whills",
    label: "Book journal whills",
    icon: "book-journal-whills",
    is_fa: true,
  },
  {
    value: "book-medical",
    label: "Book medical",
    icon: "book-medical",
    is_fa: true,
  },
  {
    value: "book-open",
    label: "Book open",
    icon: "book-open",
    is_fa: true,
  },
  {
    value: "book-open-reader",
    label: "Book open reader",
    icon: "book-open-reader",
    is_fa: true,
  },
  {
    value: "book-quran",
    label: "Book quran",
    icon: "book-quran",
    is_fa: true,
  },
  {
    value: "book-skull",
    label: "Book skull",
    icon: "book-skull",
    is_fa: true,
  },
  {
    value: "bookmark",
    label: "Bookmark",
    icon: "bookmark",
    is_fa: true,
  },
  {
    value: "border-all",
    label: "Border all",
    icon: "border-all",
    is_fa: true,
  },
  {
    value: "border-none",
    label: "Border none",
    icon: "border-none",
    is_fa: true,
  },
  {
    value: "border-top-left",
    label: "Border top left",
    icon: "border-top-left",
    is_fa: true,
  },
  {
    value: "bore-hole",
    label: "Bore hole",
    icon: "bore-hole",
    is_fa: true,
  },
  {
    value: "bottle-droplet",
    label: "Bottle droplet",
    icon: "bottle-droplet",
    is_fa: true,
  },
  {
    value: "bottle-water",
    label: "Bottle water",
    icon: "bottle-water",
    is_fa: true,
  },
  {
    value: "bowl-food",
    label: "Bowl food",
    icon: "bowl-food",
    is_fa: true,
  },
  {
    value: "bowl-rice",
    label: "Bowl rice",
    icon: "bowl-rice",
    is_fa: true,
  },
  {
    value: "bowling-ball",
    label: "Bowling ball",
    icon: "bowling-ball",
    is_fa: true,
  },
  {
    value: "box",
    label: "Box",
    icon: "box",
    is_fa: true,
  },
  {
    value: "box-archive",
    label: "Box archive",
    icon: "box-archive",
    is_fa: true,
  },
  {
    value: "box-open",
    label: "Box open",
    icon: "box-open",
    is_fa: true,
  },
  {
    value: "box-tissue",
    label: "Box tissue",
    icon: "box-tissue",
    is_fa: true,
  },
  {
    value: "boxes-packing",
    label: "Boxes packing",
    icon: "boxes-packing",
    is_fa: true,
  },
  {
    value: "boxes-stacked",
    label: "Boxes stacked",
    icon: "boxes-stacked",
    is_fa: true,
  },
  {
    value: "braille",
    label: "Braille",
    icon: "braille",
    is_fa: true,
  },
  {
    value: "brain",
    label: "Brain",
    icon: "brain",
    is_fa: true,
  },
  {
    value: "brazilian-real-sign",
    label: "Brazilian real sign",
    icon: "brazilian-real-sign",
    is_fa: true,
  },
  {
    value: "bread-slice",
    label: "Bread slice",
    icon: "bread-slice",
    is_fa: true,
  },
  {
    value: "bridge",
    label: "Bridge",
    icon: "bridge",
    is_fa: true,
  },
  {
    value: "bridge-circle-check",
    label: "Bridge circle check",
    icon: "bridge-circle-check",
    is_fa: true,
  },
  {
    value: "bridge-circle-exclamation",
    label: "Bridge circle exclamation",
    icon: "bridge-circle-exclamation",
    is_fa: true,
  },
  {
    value: "bridge-circle-xmark",
    label: "Bridge circle xmark",
    icon: "bridge-circle-xmark",
    is_fa: true,
  },
  {
    value: "bridge-lock",
    label: "Bridge lock",
    icon: "bridge-lock",
    is_fa: true,
  },
  {
    value: "bridge-water",
    label: "Bridge water",
    icon: "bridge-water",
    is_fa: true,
  },
  {
    value: "briefcase",
    label: "Briefcase",
    icon: "briefcase",
    is_fa: true,
  },
  {
    value: "briefcase-medical",
    label: "Briefcase medical",
    icon: "briefcase-medical",
    is_fa: true,
  },
  {
    value: "broom",
    label: "Broom",
    icon: "broom",
    is_fa: true,
  },
  {
    value: "broom-ball",
    label: "Broom ball",
    icon: "broom-ball",
    is_fa: true,
  },
  {
    value: "brush",
    label: "Brush",
    icon: "brush",
    is_fa: true,
  },
  {
    value: "bucket",
    label: "Bucket",
    icon: "bucket",
    is_fa: true,
  },
  {
    value: "bug",
    label: "Bug",
    icon: "bug",
    is_fa: true,
  },
  {
    value: "bug-slash",
    label: "Bug slash",
    icon: "bug-slash",
    is_fa: true,
  },
  {
    value: "bugs",
    label: "Bugs",
    icon: "bugs",
    is_fa: true,
  },
  {
    value: "building",
    label: "Building",
    icon: "building",
    is_fa: true,
  },
  {
    value: "building-circle-arrow-right",
    label: "Building circle arrow right",
    icon: "building-circle-arrow-right",
    is_fa: true,
  },
  {
    value: "building-circle-check",
    label: "Building circle check",
    icon: "building-circle-check",
    is_fa: true,
  },
  {
    value: "building-circle-exclamation",
    label: "Building circle exclamation",
    icon: "building-circle-exclamation",
    is_fa: true,
  },
  {
    value: "building-circle-xmark",
    label: "Building circle xmark",
    icon: "building-circle-xmark",
    is_fa: true,
  },
  {
    value: "building-columns",
    label: "Building columns",
    icon: "building-columns",
    is_fa: true,
  },
  {
    value: "building-flag",
    label: "Building flag",
    icon: "building-flag",
    is_fa: true,
  },
  {
    value: "building-lock",
    label: "Building lock",
    icon: "building-lock",
    is_fa: true,
  },
  {
    value: "building-ngo",
    label: "Building ngo",
    icon: "building-ngo",
    is_fa: true,
  },
  {
    value: "building-shield",
    label: "Building shield",
    icon: "building-shield",
    is_fa: true,
  },
  {
    value: "building-un",
    label: "Building un",
    icon: "building-un",
    is_fa: true,
  },
  {
    value: "building-user",
    label: "Building user",
    icon: "building-user",
    is_fa: true,
  },
  {
    value: "building-wheat",
    label: "Building wheat",
    icon: "building-wheat",
    is_fa: true,
  },
  {
    value: "bullhorn",
    label: "Bullhorn",
    icon: "bullhorn",
    is_fa: true,
  },
  {
    value: "bullseye",
    label: "Bullseye",
    icon: "bullseye",
    is_fa: true,
  },
  {
    value: "burger",
    label: "Burger",
    icon: "burger",
    is_fa: true,
  },
  {
    value: "burst",
    label: "Burst",
    icon: "burst",
    is_fa: true,
  },
  {
    value: "bus",
    label: "Bus",
    icon: "bus",
    is_fa: true,
  },
  {
    value: "bus-simple",
    label: "Bus simple",
    icon: "bus-simple",
    is_fa: true,
  },
  {
    value: "business-time",
    label: "Business time",
    icon: "business-time",
    is_fa: true,
  },
  {
    value: "c",
    label: "C",
    icon: "c",
    is_fa: true,
  },
  {
    value: "cake-candles",
    label: "Cake candles",
    icon: "cake-candles",
    is_fa: true,
  },
  {
    value: "calculator",
    label: "Calculator",
    icon: "calculator",
    is_fa: true,
  },
  {
    value: "calendar",
    label: "Calendar",
    icon: "calendar",
    is_fa: true,
  },
  {
    value: "calendar-check",
    label: "Calendar check",
    icon: "calendar-check",
    is_fa: true,
  },
  {
    value: "calendar-day",
    label: "Calendar day",
    icon: "calendar-day",
    is_fa: true,
  },
  {
    value: "calendar-days",
    label: "Calendar days",
    icon: "calendar-days",
    is_fa: true,
  },
  {
    value: "calendar-minus",
    label: "Calendar minus",
    icon: "calendar-minus",
    is_fa: true,
  },
  {
    value: "calendar-plus",
    label: "Calendar plus",
    icon: "calendar-plus",
    is_fa: true,
  },
  {
    value: "calendar-week",
    label: "Calendar week",
    icon: "calendar-week",
    is_fa: true,
  },
  {
    value: "calendar-xmark",
    label: "Calendar xmark",
    icon: "calendar-xmark",
    is_fa: true,
  },
  {
    value: "camera",
    label: "Camera",
    icon: "camera",
    is_fa: true,
  },
  {
    value: "camera-retro",
    label: "Camera retro",
    icon: "camera-retro",
    is_fa: true,
  },
  {
    value: "camera-rotate",
    label: "Camera rotate",
    icon: "camera-rotate",
    is_fa: true,
  },
  {
    value: "campground",
    label: "Campground",
    icon: "campground",
    is_fa: true,
  },
  {
    value: "candy-cane",
    label: "Candy cane",
    icon: "candy-cane",
    is_fa: true,
  },
  {
    value: "cannabis",
    label: "Cannabis",
    icon: "cannabis",
    is_fa: true,
  },
  {
    value: "capsules",
    label: "Capsules",
    icon: "capsules",
    is_fa: true,
  },
  {
    value: "car",
    label: "Car",
    icon: "car",
    is_fa: true,
  },
  {
    value: "car-battery",
    label: "Car battery",
    icon: "car-battery",
    is_fa: true,
  },
  {
    value: "car-burst",
    label: "Car burst",
    icon: "car-burst",
    is_fa: true,
  },
  {
    value: "car-on",
    label: "Car on",
    icon: "car-on",
    is_fa: true,
  },
  {
    value: "car-rear",
    label: "Car rear",
    icon: "car-rear",
    is_fa: true,
  },
  {
    value: "car-side",
    label: "Car side",
    icon: "car-side",
    is_fa: true,
  },
  {
    value: "car-tunnel",
    label: "Car tunnel",
    icon: "car-tunnel",
    is_fa: true,
  },
  {
    value: "caravan",
    label: "Caravan",
    icon: "caravan",
    is_fa: true,
  },
  {
    value: "caret-down",
    label: "Caret down",
    icon: "caret-down",
    is_fa: true,
  },
  {
    value: "caret-left",
    label: "Caret left",
    icon: "caret-left",
    is_fa: true,
  },
  {
    value: "caret-right",
    label: "Caret right",
    icon: "caret-right",
    is_fa: true,
  },
  {
    value: "caret-up",
    label: "Caret up",
    icon: "caret-up",
    is_fa: true,
  },
  {
    value: "carrot",
    label: "Carrot",
    icon: "carrot",
    is_fa: true,
  },
  {
    value: "cart-arrow-down",
    label: "Cart arrow down",
    icon: "cart-arrow-down",
    is_fa: true,
  },
  {
    value: "cart-flatbed",
    label: "Cart flatbed",
    icon: "cart-flatbed",
    is_fa: true,
  },
  {
    value: "cart-flatbed-suitcase",
    label: "Cart flatbed suitcase",
    icon: "cart-flatbed-suitcase",
    is_fa: true,
  },
  {
    value: "cart-plus",
    label: "Cart plus",
    icon: "cart-plus",
    is_fa: true,
  },
  {
    value: "cart-shopping",
    label: "Cart shopping",
    icon: "cart-shopping",
    is_fa: true,
  },
  {
    value: "cash-register",
    label: "Cash register",
    icon: "cash-register",
    is_fa: true,
  },
  {
    value: "cat",
    label: "Cat",
    icon: "cat",
    is_fa: true,
  },
  {
    value: "cedi-sign",
    label: "Cedi sign",
    icon: "cedi-sign",
    is_fa: true,
  },
  {
    value: "cent-sign",
    label: "Cent sign",
    icon: "cent-sign",
    is_fa: true,
  },
  {
    value: "certificate",
    label: "Certificate",
    icon: "certificate",
    is_fa: true,
  },
  {
    value: "chair",
    label: "Chair",
    icon: "chair",
    is_fa: true,
  },
  {
    value: "chalkboard",
    label: "Chalkboard",
    icon: "chalkboard",
    is_fa: true,
  },
  {
    value: "chalkboard-user",
    label: "Chalkboard user",
    icon: "chalkboard-user",
    is_fa: true,
  },
  {
    value: "champagne-glasses",
    label: "Champagne glasses",
    icon: "champagne-glasses",
    is_fa: true,
  },
  {
    value: "charging-station",
    label: "Charging station",
    icon: "charging-station",
    is_fa: true,
  },
  {
    value: "chart-area",
    label: "Chart area",
    icon: "chart-area",
    is_fa: true,
  },
  {
    value: "chart-bar",
    label: "Chart bar",
    icon: "chart-bar",
    is_fa: true,
  },
  {
    value: "chart-column",
    label: "Chart column",
    icon: "chart-column",
    is_fa: true,
  },
  {
    value: "chart-gantt",
    label: "Chart gantt",
    icon: "chart-gantt",
    is_fa: true,
  },
  {
    value: "chart-line",
    label: "Chart line",
    icon: "chart-line",
    is_fa: true,
  },
  {
    value: "chart-pie",
    label: "Chart pie",
    icon: "chart-pie",
    is_fa: true,
  },
  {
    value: "chart-simple",
    label: "Chart simple",
    icon: "chart-simple",
    is_fa: true,
  },
  {
    value: "check",
    label: "Check",
    icon: "check",
    is_fa: true,
  },
  {
    value: "check-double",
    label: "Check double",
    icon: "check-double",
    is_fa: true,
  },
  {
    value: "check-to-slot",
    label: "Check to slot",
    icon: "check-to-slot",
    is_fa: true,
  },
  {
    value: "cheese",
    label: "Cheese",
    icon: "cheese",
    is_fa: true,
  },
  {
    value: "chess",
    label: "Chess",
    icon: "chess",
    is_fa: true,
  },
  {
    value: "chess-bishop",
    label: "Chess bishop",
    icon: "chess-bishop",
    is_fa: true,
  },
  {
    value: "chess-board",
    label: "Chess board",
    icon: "chess-board",
    is_fa: true,
  },
  {
    value: "chess-king",
    label: "Chess king",
    icon: "chess-king",
    is_fa: true,
  },
  {
    value: "chess-knight",
    label: "Chess knight",
    icon: "chess-knight",
    is_fa: true,
  },
  {
    value: "chess-pawn",
    label: "Chess pawn",
    icon: "chess-pawn",
    is_fa: true,
  },
  {
    value: "chess-queen",
    label: "Chess queen",
    icon: "chess-queen",
    is_fa: true,
  },
  {
    value: "chess-rook",
    label: "Chess rook",
    icon: "chess-rook",
    is_fa: true,
  },
  {
    value: "chevron-down",
    label: "Chevron down",
    icon: "chevron-down",
    is_fa: true,
  },
  {
    value: "chevron-left",
    label: "Chevron left",
    icon: "chevron-left",
    is_fa: true,
  },
  {
    value: "chevron-right",
    label: "Chevron right",
    icon: "chevron-right",
    is_fa: true,
  },
  {
    value: "chevron-up",
    label: "Chevron up",
    icon: "chevron-up",
    is_fa: true,
  },
  {
    value: "child",
    label: "Child",
    icon: "child",
    is_fa: true,
  },
  {
    value: "child-dress",
    label: "Child dress",
    icon: "child-dress",
    is_fa: true,
  },
  {
    value: "child-reaching",
    label: "Child reaching",
    icon: "child-reaching",
    is_fa: true,
  },
  {
    value: "child-rifle",
    label: "Child rifle",
    icon: "child-rifle",
    is_fa: true,
  },
  {
    value: "children",
    label: "Children",
    icon: "children",
    is_fa: true,
  },
  {
    value: "church",
    label: "Church",
    icon: "church",
    is_fa: true,
  },
  {
    value: "circle",
    label: "Circle",
    icon: "circle",
    is_fa: true,
  },
  {
    value: "circle-arrow-down",
    label: "Circle arrow down",
    icon: "circle-arrow-down",
    is_fa: true,
  },
  {
    value: "circle-arrow-left",
    label: "Circle arrow left",
    icon: "circle-arrow-left",
    is_fa: true,
  },
  {
    value: "circle-arrow-right",
    label: "Circle arrow right",
    icon: "circle-arrow-right",
    is_fa: true,
  },
  {
    value: "circle-arrow-up",
    label: "Circle arrow up",
    icon: "circle-arrow-up",
    is_fa: true,
  },
  {
    value: "circle-check",
    label: "Circle check",
    icon: "circle-check",
    is_fa: true,
  },
  {
    value: "circle-chevron-down",
    label: "Circle chevron down",
    icon: "circle-chevron-down",
    is_fa: true,
  },
  {
    value: "circle-chevron-left",
    label: "Circle chevron left",
    icon: "circle-chevron-left",
    is_fa: true,
  },
  {
    value: "circle-chevron-right",
    label: "Circle chevron right",
    icon: "circle-chevron-right",
    is_fa: true,
  },
  {
    value: "circle-chevron-up",
    label: "Circle chevron up",
    icon: "circle-chevron-up",
    is_fa: true,
  },
  {
    value: "circle-dollar-to-slot",
    label: "Circle dollar to slot",
    icon: "circle-dollar-to-slot",
    is_fa: true,
  },
  {
    value: "circle-dot",
    label: "Circle dot",
    icon: "circle-dot",
    is_fa: true,
  },
  {
    value: "circle-down",
    label: "Circle down",
    icon: "circle-down",
    is_fa: true,
  },
  {
    value: "circle-exclamation",
    label: "Circle exclamation",
    icon: "circle-exclamation",
    is_fa: true,
  },
  {
    value: "circle-h",
    label: "Circle h",
    icon: "circle-h",
    is_fa: true,
  },
  {
    value: "circle-half-stroke",
    label: "Circle half stroke",
    icon: "circle-half-stroke",
    is_fa: true,
  },
  {
    value: "circle-info",
    label: "Circle info",
    icon: "circle-info",
    is_fa: true,
  },
  {
    value: "circle-left",
    label: "Circle left",
    icon: "circle-left",
    is_fa: true,
  },
  {
    value: "circle-minus",
    label: "Circle minus",
    icon: "circle-minus",
    is_fa: true,
  },
  {
    value: "circle-nodes",
    label: "Circle nodes",
    icon: "circle-nodes",
    is_fa: true,
  },
  {
    value: "circle-notch",
    label: "Circle notch",
    icon: "circle-notch",
    is_fa: true,
  },
  {
    value: "circle-pause",
    label: "Circle pause",
    icon: "circle-pause",
    is_fa: true,
  },
  {
    value: "circle-play",
    label: "Circle play",
    icon: "circle-play",
    is_fa: true,
  },
  {
    value: "circle-plus",
    label: "Circle plus",
    icon: "circle-plus",
    is_fa: true,
  },
  {
    value: "circle-question",
    label: "Circle question",
    icon: "circle-question",
    is_fa: true,
  },
  {
    value: "circle-radiation",
    label: "Circle radiation",
    icon: "circle-radiation",
    is_fa: true,
  },
  {
    value: "circle-right",
    label: "Circle right",
    icon: "circle-right",
    is_fa: true,
  },
  {
    value: "circle-stop",
    label: "Circle stop",
    icon: "circle-stop",
    is_fa: true,
  },
  {
    value: "circle-up",
    label: "Circle up",
    icon: "circle-up",
    is_fa: true,
  },
  {
    value: "circle-user",
    label: "Circle user",
    icon: "circle-user",
    is_fa: true,
  },
  {
    value: "circle-xmark",
    label: "Circle xmark",
    icon: "circle-xmark",
    is_fa: true,
  },
  {
    value: "city",
    label: "City",
    icon: "city",
    is_fa: true,
  },
  {
    value: "clapperboard",
    label: "Clapperboard",
    icon: "clapperboard",
    is_fa: true,
  },
  {
    value: "clipboard",
    label: "Clipboard",
    icon: "clipboard",
    is_fa: true,
  },
  {
    value: "clipboard-check",
    label: "Clipboard check",
    icon: "clipboard-check",
    is_fa: true,
  },
  {
    value: "clipboard-list",
    label: "Clipboard list",
    icon: "clipboard-list",
    is_fa: true,
  },
  {
    value: "clipboard-question",
    label: "Clipboard question",
    icon: "clipboard-question",
    is_fa: true,
  },
  {
    value: "clipboard-user",
    label: "Clipboard user",
    icon: "clipboard-user",
    is_fa: true,
  },
  {
    value: "clock",
    label: "Clock",
    icon: "clock",
    is_fa: true,
  },
  {
    value: "clock-rotate-left",
    label: "Clock rotate left",
    icon: "clock-rotate-left",
    is_fa: true,
  },
  {
    value: "clone",
    label: "Clone",
    icon: "clone",
    is_fa: true,
  },
  {
    value: "closed-captioning",
    label: "Closed captioning",
    icon: "closed-captioning",
    is_fa: true,
  },
  {
    value: "cloud",
    label: "Cloud",
    icon: "cloud",
    is_fa: true,
  },
  {
    value: "cloud-arrow-down",
    label: "Cloud arrow down",
    icon: "cloud-arrow-down",
    is_fa: true,
  },
  {
    value: "cloud-arrow-up",
    label: "Cloud arrow up",
    icon: "cloud-arrow-up",
    is_fa: true,
  },
  {
    value: "cloud-bolt",
    label: "Cloud bolt",
    icon: "cloud-bolt",
    is_fa: true,
  },
  {
    value: "cloud-meatball",
    label: "Cloud meatball",
    icon: "cloud-meatball",
    is_fa: true,
  },
  {
    value: "cloud-moon",
    label: "Cloud moon",
    icon: "cloud-moon",
    is_fa: true,
  },
  {
    value: "cloud-moon-rain",
    label: "Cloud moon rain",
    icon: "cloud-moon-rain",
    is_fa: true,
  },
  {
    value: "cloud-rain",
    label: "Cloud rain",
    icon: "cloud-rain",
    is_fa: true,
  },
  {
    value: "cloud-showers-heavy",
    label: "Cloud showers heavy",
    icon: "cloud-showers-heavy",
    is_fa: true,
  },
  {
    value: "cloud-showers-water",
    label: "Cloud showers water",
    icon: "cloud-showers-water",
    is_fa: true,
  },
  {
    value: "cloud-sun",
    label: "Cloud sun",
    icon: "cloud-sun",
    is_fa: true,
  },
  {
    value: "cloud-sun-rain",
    label: "Cloud sun rain",
    icon: "cloud-sun-rain",
    is_fa: true,
  },
  {
    value: "clover",
    label: "Clover",
    icon: "clover",
    is_fa: true,
  },
  {
    value: "code",
    label: "Code",
    icon: "code",
    is_fa: true,
  },
  {
    value: "code-branch",
    label: "Code branch",
    icon: "code-branch",
    is_fa: true,
  },
  {
    value: "code-commit",
    label: "Code commit",
    icon: "code-commit",
    is_fa: true,
  },
  {
    value: "code-compare",
    label: "Code compare",
    icon: "code-compare",
    is_fa: true,
  },
  {
    value: "code-fork",
    label: "Code fork",
    icon: "code-fork",
    is_fa: true,
  },
  {
    value: "code-merge",
    label: "Code merge",
    icon: "code-merge",
    is_fa: true,
  },
  {
    value: "code-pull-request",
    label: "Code pull request",
    icon: "code-pull-request",
    is_fa: true,
  },
  {
    value: "coins",
    label: "Coins",
    icon: "coins",
    is_fa: true,
  },
  {
    value: "colon-sign",
    label: "Colon sign",
    icon: "colon-sign",
    is_fa: true,
  },
  {
    value: "comment",
    label: "Comment",
    icon: "comment",
    is_fa: true,
  },
  {
    value: "comment-dollar",
    label: "Comment dollar",
    icon: "comment-dollar",
    is_fa: true,
  },
  {
    value: "comment-dots",
    label: "Comment dots",
    icon: "comment-dots",
    is_fa: true,
  },
  {
    value: "comment-medical",
    label: "Comment medical",
    icon: "comment-medical",
    is_fa: true,
  },
  {
    value: "comment-slash",
    label: "Comment slash",
    icon: "comment-slash",
    is_fa: true,
  },
  {
    value: "comment-sms",
    label: "Comment sms",
    icon: "comment-sms",
    is_fa: true,
  },
  {
    value: "comments",
    label: "Comments",
    icon: "comments",
    is_fa: true,
  },
  {
    value: "comments-dollar",
    label: "Comments dollar",
    icon: "comments-dollar",
    is_fa: true,
  },
  {
    value: "compact-disc",
    label: "Compact disc",
    icon: "compact-disc",
    is_fa: true,
  },
  {
    value: "compass",
    label: "Compass",
    icon: "compass",
    is_fa: true,
  },
  {
    value: "compass-drafting",
    label: "Compass drafting",
    icon: "compass-drafting",
    is_fa: true,
  },
  {
    value: "compress",
    label: "Compress",
    icon: "compress",
    is_fa: true,
  },
  {
    value: "computer",
    label: "Computer",
    icon: "computer",
    is_fa: true,
  },
  {
    value: "computer-mouse",
    label: "Computer mouse",
    icon: "computer-mouse",
    is_fa: true,
  },
  {
    value: "cookie",
    label: "Cookie",
    icon: "cookie",
    is_fa: true,
  },
  {
    value: "cookie-bite",
    label: "Cookie bite",
    icon: "cookie-bite",
    is_fa: true,
  },
  {
    value: "copy",
    label: "Copy",
    icon: "copy",
    is_fa: true,
  },
  {
    value: "copyright",
    label: "Copyright",
    icon: "copyright",
    is_fa: true,
  },
  {
    value: "couch",
    label: "Couch",
    icon: "couch",
    is_fa: true,
  },
  {
    value: "cow",
    label: "Cow",
    icon: "cow",
    is_fa: true,
  },
  {
    value: "credit-card",
    label: "Credit card",
    icon: "credit-card",
    is_fa: true,
  },
  {
    value: "crop",
    label: "Crop",
    icon: "crop",
    is_fa: true,
  },
  {
    value: "crop-simple",
    label: "Crop simple",
    icon: "crop-simple",
    is_fa: true,
  },
  {
    value: "cross",
    label: "Cross",
    icon: "cross",
    is_fa: true,
  },
  {
    value: "crosshairs",
    label: "Crosshairs",
    icon: "crosshairs",
    is_fa: true,
  },
  {
    value: "crow",
    label: "Crow",
    icon: "crow",
    is_fa: true,
  },
  {
    value: "crown",
    label: "Crown",
    icon: "crown",
    is_fa: true,
  },
  {
    value: "crutch",
    label: "Crutch",
    icon: "crutch",
    is_fa: true,
  },
  {
    value: "cruzeiro-sign",
    label: "Cruzeiro sign",
    icon: "cruzeiro-sign",
    is_fa: true,
  },
  {
    value: "cube",
    label: "Cube",
    icon: "cube",
    is_fa: true,
  },
  {
    value: "cubes",
    label: "Cubes",
    icon: "cubes",
    is_fa: true,
  },
  {
    value: "cubes-stacked",
    label: "Cubes stacked",
    icon: "cubes-stacked",
    is_fa: true,
  },
  {
    value: "d",
    label: "D",
    icon: "d",
    is_fa: true,
  },
  {
    value: "database",
    label: "Database",
    icon: "database",
    is_fa: true,
  },
  {
    value: "delete-left",
    label: "Delete left",
    icon: "delete-left",
    is_fa: true,
  },
  {
    value: "democrat",
    label: "Democrat",
    icon: "democrat",
    is_fa: true,
  },
  {
    value: "desktop",
    label: "Desktop",
    icon: "desktop",
    is_fa: true,
  },
  {
    value: "dharmachakra",
    label: "Dharmachakra",
    icon: "dharmachakra",
    is_fa: true,
  },
  {
    value: "diagram-next",
    label: "Diagram next",
    icon: "diagram-next",
    is_fa: true,
  },
  {
    value: "diagram-predecessor",
    label: "Diagram predecessor",
    icon: "diagram-predecessor",
    is_fa: true,
  },
  {
    value: "diagram-project",
    label: "Diagram project",
    icon: "diagram-project",
    is_fa: true,
  },
  {
    value: "diagram-successor",
    label: "Diagram successor",
    icon: "diagram-successor",
    is_fa: true,
  },
  {
    value: "diamond",
    label: "Diamond",
    icon: "diamond",
    is_fa: true,
  },
  {
    value: "diamond-turn-right",
    label: "Diamond turn right",
    icon: "diamond-turn-right",
    is_fa: true,
  },
  {
    value: "dice",
    label: "Dice",
    icon: "dice",
    is_fa: true,
  },
  {
    value: "dice-d20",
    label: "Dice d20",
    icon: "dice-d20",
    is_fa: true,
  },
  {
    value: "dice-d6",
    label: "Dice d6",
    icon: "dice-d6",
    is_fa: true,
  },
  {
    value: "dice-five",
    label: "Dice five",
    icon: "dice-five",
    is_fa: true,
  },
  {
    value: "dice-four",
    label: "Dice four",
    icon: "dice-four",
    is_fa: true,
  },
  {
    value: "dice-one",
    label: "Dice one",
    icon: "dice-one",
    is_fa: true,
  },
  {
    value: "dice-six",
    label: "Dice six",
    icon: "dice-six",
    is_fa: true,
  },
  {
    value: "dice-three",
    label: "Dice three",
    icon: "dice-three",
    is_fa: true,
  },
  {
    value: "dice-two",
    label: "Dice two",
    icon: "dice-two",
    is_fa: true,
  },
  {
    value: "disease",
    label: "Disease",
    icon: "disease",
    is_fa: true,
  },
  {
    value: "display",
    label: "Display",
    icon: "display",
    is_fa: true,
  },
  {
    value: "divide",
    label: "Divide",
    icon: "divide",
    is_fa: true,
  },
  {
    value: "dna",
    label: "Dna",
    icon: "dna",
    is_fa: true,
  },
  {
    value: "dog",
    label: "Dog",
    icon: "dog",
    is_fa: true,
  },
  {
    value: "dollar-sign",
    label: "Dollar sign",
    icon: "dollar-sign",
    is_fa: true,
  },
  {
    value: "dolly",
    label: "Dolly",
    icon: "dolly",
    is_fa: true,
  },
  {
    value: "dong-sign",
    label: "Dong sign",
    icon: "dong-sign",
    is_fa: true,
  },
  {
    value: "door-closed",
    label: "Door closed",
    icon: "door-closed",
    is_fa: true,
  },
  {
    value: "door-open",
    label: "Door open",
    icon: "door-open",
    is_fa: true,
  },
  {
    value: "dove",
    label: "Dove",
    icon: "dove",
    is_fa: true,
  },
  {
    value: "down-left-and-up-right-to-center",
    label: "Down left and up right to center",
    icon: "down-left-and-up-right-to-center",
    is_fa: true,
  },
  {
    value: "down-long",
    label: "Down long",
    icon: "down-long",
    is_fa: true,
  },
  {
    value: "download",
    label: "Download",
    icon: "download",
    is_fa: true,
  },
  {
    value: "dragon",
    label: "Dragon",
    icon: "dragon",
    is_fa: true,
  },
  {
    value: "draw-polygon",
    label: "Draw polygon",
    icon: "draw-polygon",
    is_fa: true,
  },
  {
    value: "droplet",
    label: "Droplet",
    icon: "droplet",
    is_fa: true,
  },
  {
    value: "droplet-slash",
    label: "Droplet slash",
    icon: "droplet-slash",
    is_fa: true,
  },
  {
    value: "drum",
    label: "Drum",
    icon: "drum",
    is_fa: true,
  },
  {
    value: "drum-steelpan",
    label: "Drum steelpan",
    icon: "drum-steelpan",
    is_fa: true,
  },
  {
    value: "drumstick-bite",
    label: "Drumstick bite",
    icon: "drumstick-bite",
    is_fa: true,
  },
  {
    value: "dumbbell",
    label: "Dumbbell",
    icon: "dumbbell",
    is_fa: true,
  },
  {
    value: "dumpster",
    label: "Dumpster",
    icon: "dumpster",
    is_fa: true,
  },
  {
    value: "dumpster-fire",
    label: "Dumpster fire",
    icon: "dumpster-fire",
    is_fa: true,
  },
  {
    value: "dungeon",
    label: "Dungeon",
    icon: "dungeon",
    is_fa: true,
  },
  {
    value: "e",
    label: "E",
    icon: "e",
    is_fa: true,
  },
  {
    value: "ear-deaf",
    label: "Ear deaf",
    icon: "ear-deaf",
    is_fa: true,
  },
  {
    value: "ear-listen",
    label: "Ear listen",
    icon: "ear-listen",
    is_fa: true,
  },
  {
    value: "earth-africa",
    label: "Earth africa",
    icon: "earth-africa",
    is_fa: true,
  },
  {
    value: "earth-americas",
    label: "Earth americas",
    icon: "earth-americas",
    is_fa: true,
  },
  {
    value: "earth-asia",
    label: "Earth asia",
    icon: "earth-asia",
    is_fa: true,
  },
  {
    value: "earth-europe",
    label: "Earth europe",
    icon: "earth-europe",
    is_fa: true,
  },
  {
    value: "earth-oceania",
    label: "Earth oceania",
    icon: "earth-oceania",
    is_fa: true,
  },
  {
    value: "egg",
    label: "Egg",
    icon: "egg",
    is_fa: true,
  },
  {
    value: "eject",
    label: "Eject",
    icon: "eject",
    is_fa: true,
  },
  {
    value: "elevator",
    label: "Elevator",
    icon: "elevator",
    is_fa: true,
  },
  {
    value: "ellipsis",
    label: "Ellipsis",
    icon: "ellipsis",
    is_fa: true,
  },
  {
    value: "ellipsis-vertical",
    label: "Ellipsis vertical",
    icon: "ellipsis-vertical",
    is_fa: true,
  },
  {
    value: "envelope",
    label: "Envelope",
    icon: "envelope",
    is_fa: true,
  },
  {
    value: "envelope-circle-check",
    label: "Envelope circle check",
    icon: "envelope-circle-check",
    is_fa: true,
  },
  {
    value: "envelope-open",
    label: "Envelope open",
    icon: "envelope-open",
    is_fa: true,
  },
  {
    value: "envelope-open-text",
    label: "Envelope open text",
    icon: "envelope-open-text",
    is_fa: true,
  },
  {
    value: "envelopes-bulk",
    label: "Envelopes bulk",
    icon: "envelopes-bulk",
    is_fa: true,
  },
  {
    value: "equals",
    label: "Equals",
    icon: "equals",
    is_fa: true,
  },
  {
    value: "eraser",
    label: "Eraser",
    icon: "eraser",
    is_fa: true,
  },
  {
    value: "ethernet",
    label: "Ethernet",
    icon: "ethernet",
    is_fa: true,
  },
  {
    value: "euro-sign",
    label: "Euro sign",
    icon: "euro-sign",
    is_fa: true,
  },
  {
    value: "exclamation",
    label: "Exclamation",
    icon: "exclamation",
    is_fa: true,
  },
  {
    value: "expand",
    label: "Expand",
    icon: "expand",
    is_fa: true,
  },
  {
    value: "explosion",
    label: "Explosion",
    icon: "explosion",
    is_fa: true,
  },
  {
    value: "eye",
    label: "Eye",
    icon: "eye",
    is_fa: true,
  },
  {
    value: "eye-dropper",
    label: "Eye dropper",
    icon: "eye-dropper",
    is_fa: true,
  },
  {
    value: "eye-low-vision",
    label: "Eye low vision",
    icon: "eye-low-vision",
    is_fa: true,
  },
  {
    value: "eye-slash",
    label: "Eye slash",
    icon: "eye-slash",
    is_fa: true,
  },
  {
    value: "f",
    label: "F",
    icon: "f",
    is_fa: true,
  },
  {
    value: "face-angry",
    label: "Face angry",
    icon: "face-angry",
    is_fa: true,
  },
  {
    value: "face-dizzy",
    label: "Face dizzy",
    icon: "face-dizzy",
    is_fa: true,
  },
  {
    value: "face-flushed",
    label: "Face flushed",
    icon: "face-flushed",
    is_fa: true,
  },
  {
    value: "face-frown",
    label: "Face frown",
    icon: "face-frown",
    is_fa: true,
  },
  {
    value: "face-frown-open",
    label: "Face frown open",
    icon: "face-frown-open",
    is_fa: true,
  },
  {
    value: "face-grimace",
    label: "Face grimace",
    icon: "face-grimace",
    is_fa: true,
  },
  {
    value: "face-grin",
    label: "Face grin",
    icon: "face-grin",
    is_fa: true,
  },
  {
    value: "face-grin-beam",
    label: "Face grin beam",
    icon: "face-grin-beam",
    is_fa: true,
  },
  {
    value: "face-grin-beam-sweat",
    label: "Face grin beam sweat",
    icon: "face-grin-beam-sweat",
    is_fa: true,
  },
  {
    value: "face-grin-hearts",
    label: "Face grin hearts",
    icon: "face-grin-hearts",
    is_fa: true,
  },
  {
    value: "face-grin-squint",
    label: "Face grin squint",
    icon: "face-grin-squint",
    is_fa: true,
  },
  {
    value: "face-grin-squint-tears",
    label: "Face grin squint tears",
    icon: "face-grin-squint-tears",
    is_fa: true,
  },
  {
    value: "face-grin-stars",
    label: "Face grin stars",
    icon: "face-grin-stars",
    is_fa: true,
  },
  {
    value: "face-grin-tears",
    label: "Face grin tears",
    icon: "face-grin-tears",
    is_fa: true,
  },
  {
    value: "face-grin-tongue",
    label: "Face grin tongue",
    icon: "face-grin-tongue",
    is_fa: true,
  },
  {
    value: "face-grin-tongue-squint",
    label: "Face grin tongue squint",
    icon: "face-grin-tongue-squint",
    is_fa: true,
  },
  {
    value: "face-grin-tongue-wink",
    label: "Face grin tongue wink",
    icon: "face-grin-tongue-wink",
    is_fa: true,
  },
  {
    value: "face-grin-wide",
    label: "Face grin wide",
    icon: "face-grin-wide",
    is_fa: true,
  },
  {
    value: "face-grin-wink",
    label: "Face grin wink",
    icon: "face-grin-wink",
    is_fa: true,
  },
  {
    value: "face-kiss",
    label: "Face kiss",
    icon: "face-kiss",
    is_fa: true,
  },
  {
    value: "face-kiss-beam",
    label: "Face kiss beam",
    icon: "face-kiss-beam",
    is_fa: true,
  },
  {
    value: "face-kiss-wink-heart",
    label: "Face kiss wink heart",
    icon: "face-kiss-wink-heart",
    is_fa: true,
  },
  {
    value: "face-laugh",
    label: "Face laugh",
    icon: "face-laugh",
    is_fa: true,
  },
  {
    value: "face-laugh-beam",
    label: "Face laugh beam",
    icon: "face-laugh-beam",
    is_fa: true,
  },
  {
    value: "face-laugh-squint",
    label: "Face laugh squint",
    icon: "face-laugh-squint",
    is_fa: true,
  },
  {
    value: "face-laugh-wink",
    label: "Face laugh wink",
    icon: "face-laugh-wink",
    is_fa: true,
  },
  {
    value: "face-meh",
    label: "Face meh",
    icon: "face-meh",
    is_fa: true,
  },
  {
    value: "face-meh-blank",
    label: "Face meh blank",
    icon: "face-meh-blank",
    is_fa: true,
  },
  {
    value: "face-rolling-eyes",
    label: "Face rolling eyes",
    icon: "face-rolling-eyes",
    is_fa: true,
  },
  {
    value: "face-sad-cry",
    label: "Face sad cry",
    icon: "face-sad-cry",
    is_fa: true,
  },
  {
    value: "face-sad-tear",
    label: "Face sad tear",
    icon: "face-sad-tear",
    is_fa: true,
  },
  {
    value: "face-smile",
    label: "Face smile",
    icon: "face-smile",
    is_fa: true,
  },
  {
    value: "face-smile-beam",
    label: "Face smile beam",
    icon: "face-smile-beam",
    is_fa: true,
  },
  {
    value: "face-smile-wink",
    label: "Face smile wink",
    icon: "face-smile-wink",
    is_fa: true,
  },
  {
    value: "face-surprise",
    label: "Face surprise",
    icon: "face-surprise",
    is_fa: true,
  },
  {
    value: "face-tired",
    label: "Face tired",
    icon: "face-tired",
    is_fa: true,
  },
  {
    value: "fan",
    label: "Fan",
    icon: "fan",
    is_fa: true,
  },
  {
    value: "faucet",
    label: "Faucet",
    icon: "faucet",
    is_fa: true,
  },
  {
    value: "faucet-drip",
    label: "Faucet drip",
    icon: "faucet-drip",
    is_fa: true,
  },
  {
    value: "fax",
    label: "Fax",
    icon: "fax",
    is_fa: true,
  },
  {
    value: "feather",
    label: "Feather",
    icon: "feather",
    is_fa: true,
  },
  {
    value: "feather-pointed",
    label: "Feather pointed",
    icon: "feather-pointed",
    is_fa: true,
  },
  {
    value: "ferry",
    label: "Ferry",
    icon: "ferry",
    is_fa: true,
  },
  {
    value: "file",
    label: "File",
    icon: "file",
    is_fa: true,
  },
  {
    value: "file-arrow-down",
    label: "File arrow down",
    icon: "file-arrow-down",
    is_fa: true,
  },
  {
    value: "file-arrow-up",
    label: "File arrow up",
    icon: "file-arrow-up",
    is_fa: true,
  },
  {
    value: "file-audio",
    label: "File audio",
    icon: "file-audio",
    is_fa: true,
  },
  {
    value: "file-circle-check",
    label: "File circle check",
    icon: "file-circle-check",
    is_fa: true,
  },
  {
    value: "file-circle-exclamation",
    label: "File circle exclamation",
    icon: "file-circle-exclamation",
    is_fa: true,
  },
  {
    value: "file-circle-minus",
    label: "File circle minus",
    icon: "file-circle-minus",
    is_fa: true,
  },
  {
    value: "file-circle-plus",
    label: "File circle plus",
    icon: "file-circle-plus",
    is_fa: true,
  },
  {
    value: "file-circle-question",
    label: "File circle question",
    icon: "file-circle-question",
    is_fa: true,
  },
  {
    value: "file-circle-xmark",
    label: "File circle xmark",
    icon: "file-circle-xmark",
    is_fa: true,
  },
  {
    value: "file-code",
    label: "File code",
    icon: "file-code",
    is_fa: true,
  },
  {
    value: "file-contract",
    label: "File contract",
    icon: "file-contract",
    is_fa: true,
  },
  {
    value: "file-csv",
    label: "File csv",
    icon: "file-csv",
    is_fa: true,
  },
  {
    value: "file-excel",
    label: "File excel",
    icon: "file-excel",
    is_fa: true,
  },
  {
    value: "file-export",
    label: "File export",
    icon: "file-export",
    is_fa: true,
  },
  {
    value: "file-image",
    label: "File image",
    icon: "file-image",
    is_fa: true,
  },
  {
    value: "file-import",
    label: "File import",
    icon: "file-import",
    is_fa: true,
  },
  {
    value: "file-invoice",
    label: "File invoice",
    icon: "file-invoice",
    is_fa: true,
  },
  {
    value: "file-invoice-dollar",
    label: "File invoice dollar",
    icon: "file-invoice-dollar",
    is_fa: true,
  },
  {
    value: "file-lines",
    label: "File lines",
    icon: "file-lines",
    is_fa: true,
  },
  {
    value: "file-medical",
    label: "File medical",
    icon: "file-medical",
    is_fa: true,
  },
  {
    value: "file-pdf",
    label: "File pdf",
    icon: "file-pdf",
    is_fa: true,
  },
  {
    value: "file-pen",
    label: "File pen",
    icon: "file-pen",
    is_fa: true,
  },
  {
    value: "file-powerpoint",
    label: "File powerpoint",
    icon: "file-powerpoint",
    is_fa: true,
  },
  {
    value: "file-prescription",
    label: "File prescription",
    icon: "file-prescription",
    is_fa: true,
  },
  {
    value: "file-shield",
    label: "File shield",
    icon: "file-shield",
    is_fa: true,
  },
  {
    value: "file-signature",
    label: "File signature",
    icon: "file-signature",
    is_fa: true,
  },
  {
    value: "file-video",
    label: "File video",
    icon: "file-video",
    is_fa: true,
  },
  {
    value: "file-waveform",
    label: "File waveform",
    icon: "file-waveform",
    is_fa: true,
  },
  {
    value: "file-word",
    label: "File word",
    icon: "file-word",
    is_fa: true,
  },
  {
    value: "file-zipper",
    label: "File zipper",
    icon: "file-zipper",
    is_fa: true,
  },
  {
    value: "fill",
    label: "Fill",
    icon: "fill",
    is_fa: true,
  },
  {
    value: "fill-drip",
    label: "Fill drip",
    icon: "fill-drip",
    is_fa: true,
  },
  {
    value: "film",
    label: "Film",
    icon: "film",
    is_fa: true,
  },
  {
    value: "filter",
    label: "Filter",
    icon: "filter",
    is_fa: true,
  },
  {
    value: "filter-circle-dollar",
    label: "Filter circle dollar",
    icon: "filter-circle-dollar",
    is_fa: true,
  },
  {
    value: "filter-circle-xmark",
    label: "Filter circle xmark",
    icon: "filter-circle-xmark",
    is_fa: true,
  },
  {
    value: "fingerprint",
    label: "Fingerprint",
    icon: "fingerprint",
    is_fa: true,
  },
  {
    value: "fire",
    label: "Fire",
    icon: "fire",
    is_fa: true,
  },
  {
    value: "fire-burner",
    label: "Fire burner",
    icon: "fire-burner",
    is_fa: true,
  },
  {
    value: "fire-extinguisher",
    label: "Fire extinguisher",
    icon: "fire-extinguisher",
    is_fa: true,
  },
  {
    value: "fire-flame-curved",
    label: "Fire flame curved",
    icon: "fire-flame-curved",
    is_fa: true,
  },
  {
    value: "fire-flame-simple",
    label: "Fire flame simple",
    icon: "fire-flame-simple",
    is_fa: true,
  },
  {
    value: "fish",
    label: "Fish",
    icon: "fish",
    is_fa: true,
  },
  {
    value: "fish-fins",
    label: "Fish fins",
    icon: "fish-fins",
    is_fa: true,
  },
  {
    value: "flag",
    label: "Flag",
    icon: "flag",
    is_fa: true,
  },
  {
    value: "flag-checkered",
    label: "Flag checkered",
    icon: "flag-checkered",
    is_fa: true,
  },
  {
    value: "flag-usa",
    label: "Flag usa",
    icon: "flag-usa",
    is_fa: true,
  },
  {
    value: "flask",
    label: "Flask",
    icon: "flask",
    is_fa: true,
  },
  {
    value: "flask-vial",
    label: "Flask vial",
    icon: "flask-vial",
    is_fa: true,
  },
  {
    value: "floppy-disk",
    label: "Floppy disk",
    icon: "floppy-disk",
    is_fa: true,
  },
  {
    value: "florin-sign",
    label: "Florin sign",
    icon: "florin-sign",
    is_fa: true,
  },
  {
    value: "folder",
    label: "Folder",
    icon: "folder",
    is_fa: true,
  },
  {
    value: "folder-closed",
    label: "Folder closed",
    icon: "folder-closed",
    is_fa: true,
  },
  {
    value: "folder-minus",
    label: "Folder minus",
    icon: "folder-minus",
    is_fa: true,
  },
  {
    value: "folder-open",
    label: "Folder open",
    icon: "folder-open",
    is_fa: true,
  },
  {
    value: "folder-plus",
    label: "Folder plus",
    icon: "folder-plus",
    is_fa: true,
  },
  {
    value: "folder-tree",
    label: "Folder tree",
    icon: "folder-tree",
    is_fa: true,
  },
  {
    value: "font",
    label: "Font",
    icon: "font",
    is_fa: true,
  },
  {
    value: "font-awesome",
    label: "Font awesome",
    icon: "font-awesome",
    is_fa: true,
  },
  {
    value: "football",
    label: "Football",
    icon: "football",
    is_fa: true,
  },
  {
    value: "forward",
    label: "Forward",
    icon: "forward",
    is_fa: true,
  },
  {
    value: "forward-fast",
    label: "Forward fast",
    icon: "forward-fast",
    is_fa: true,
  },
  {
    value: "forward-step",
    label: "Forward step",
    icon: "forward-step",
    is_fa: true,
  },
  {
    value: "franc-sign",
    label: "Franc sign",
    icon: "franc-sign",
    is_fa: true,
  },
  {
    value: "frog",
    label: "Frog",
    icon: "frog",
    is_fa: true,
  },
  {
    value: "futbol",
    label: "Futbol",
    icon: "futbol",
    is_fa: true,
  },
  {
    value: "g",
    label: "G",
    icon: "g",
    is_fa: true,
  },
  {
    value: "gamepad",
    label: "Gamepad",
    icon: "gamepad",
    is_fa: true,
  },
  {
    value: "gas-pump",
    label: "Gas pump",
    icon: "gas-pump",
    is_fa: true,
  },
  {
    value: "gauge",
    label: "Gauge",
    icon: "gauge",
    is_fa: true,
  },
  {
    value: "gauge-high",
    label: "Gauge high",
    icon: "gauge-high",
    is_fa: true,
  },
  {
    value: "gauge-simple",
    label: "Gauge simple",
    icon: "gauge-simple",
    is_fa: true,
  },
  {
    value: "gauge-simple-high",
    label: "Gauge simple high",
    icon: "gauge-simple-high",
    is_fa: true,
  },
  {
    value: "gavel",
    label: "Gavel",
    icon: "gavel",
    is_fa: true,
  },
  {
    value: "gear",
    label: "Gear",
    icon: "gear",
    is_fa: true,
  },
  {
    value: "gears",
    label: "Gears",
    icon: "gears",
    is_fa: true,
  },
  {
    value: "gem",
    label: "Gem",
    icon: "gem",
    is_fa: true,
  },
  {
    value: "genderless",
    label: "Genderless",
    icon: "genderless",
    is_fa: true,
  },
  {
    value: "ghost",
    label: "Ghost",
    icon: "ghost",
    is_fa: true,
  },
  {
    value: "gift",
    label: "Gift",
    icon: "gift",
    is_fa: true,
  },
  {
    value: "gifts",
    label: "Gifts",
    icon: "gifts",
    is_fa: true,
  },
  {
    value: "glass-water",
    label: "Glass water",
    icon: "glass-water",
    is_fa: true,
  },
  {
    value: "glass-water-droplet",
    label: "Glass water droplet",
    icon: "glass-water-droplet",
    is_fa: true,
  },
  {
    value: "glasses",
    label: "Glasses",
    icon: "glasses",
    is_fa: true,
  },
  {
    value: "globe",
    label: "Globe",
    icon: "globe",
    is_fa: true,
  },
  {
    value: "golf-ball-tee",
    label: "Golf ball tee",
    icon: "golf-ball-tee",
    is_fa: true,
  },
  {
    value: "gopuram",
    label: "Gopuram",
    icon: "gopuram",
    is_fa: true,
  },
  {
    value: "graduation-cap",
    label: "Graduation cap",
    icon: "graduation-cap",
    is_fa: true,
  },
  {
    value: "greater-than",
    label: "Greater than",
    icon: "greater-than",
    is_fa: true,
  },
  {
    value: "greater-than-equal",
    label: "Greater than equal",
    icon: "greater-than-equal",
    is_fa: true,
  },
  {
    value: "grip",
    label: "Grip",
    icon: "grip",
    is_fa: true,
  },
  {
    value: "grip-lines",
    label: "Grip lines",
    icon: "grip-lines",
    is_fa: true,
  },
  {
    value: "grip-lines-vertical",
    label: "Grip lines vertical",
    icon: "grip-lines-vertical",
    is_fa: true,
  },
  {
    value: "grip-vertical",
    label: "Grip vertical",
    icon: "grip-vertical",
    is_fa: true,
  },
  {
    value: "group-arrows-rotate",
    label: "Group arrows rotate",
    icon: "group-arrows-rotate",
    is_fa: true,
  },
  {
    value: "guarani-sign",
    label: "Guarani sign",
    icon: "guarani-sign",
    is_fa: true,
  },
  {
    value: "guitar",
    label: "Guitar",
    icon: "guitar",
    is_fa: true,
  },
  {
    value: "gun",
    label: "Gun",
    icon: "gun",
    is_fa: true,
  },
  {
    value: "h",
    label: "H",
    icon: "h",
    is_fa: true,
  },
  {
    value: "hammer",
    label: "Hammer",
    icon: "hammer",
    is_fa: true,
  },
  {
    value: "hamsa",
    label: "Hamsa",
    icon: "hamsa",
    is_fa: true,
  },
  {
    value: "hand",
    label: "Hand",
    icon: "hand",
    is_fa: true,
  },
  {
    value: "hand-back-fist",
    label: "Hand back fist",
    icon: "hand-back-fist",
    is_fa: true,
  },
  {
    value: "hand-dots",
    label: "Hand dots",
    icon: "hand-dots",
    is_fa: true,
  },
  {
    value: "hand-fist",
    label: "Hand fist",
    icon: "hand-fist",
    is_fa: true,
  },
  {
    value: "hand-holding",
    label: "Hand holding",
    icon: "hand-holding",
    is_fa: true,
  },
  {
    value: "hand-holding-dollar",
    label: "Hand holding dollar",
    icon: "hand-holding-dollar",
    is_fa: true,
  },
  {
    value: "hand-holding-droplet",
    label: "Hand holding droplet",
    icon: "hand-holding-droplet",
    is_fa: true,
  },
  {
    value: "hand-holding-hand",
    label: "Hand holding hand",
    icon: "hand-holding-hand",
    is_fa: true,
  },
  {
    value: "hand-holding-heart",
    label: "Hand holding heart",
    icon: "hand-holding-heart",
    is_fa: true,
  },
  {
    value: "hand-holding-medical",
    label: "Hand holding medical",
    icon: "hand-holding-medical",
    is_fa: true,
  },
  {
    value: "hand-lizard",
    label: "Hand lizard",
    icon: "hand-lizard",
    is_fa: true,
  },
  {
    value: "hand-middle-finger",
    label: "Hand middle finger",
    icon: "hand-middle-finger",
    is_fa: true,
  },
  {
    value: "hand-peace",
    label: "Hand peace",
    icon: "hand-peace",
    is_fa: true,
  },
  {
    value: "hand-point-down",
    label: "Hand point down",
    icon: "hand-point-down",
    is_fa: true,
  },
  {
    value: "hand-point-left",
    label: "Hand point left",
    icon: "hand-point-left",
    is_fa: true,
  },
  {
    value: "hand-point-right",
    label: "Hand point right",
    icon: "hand-point-right",
    is_fa: true,
  },
  {
    value: "hand-point-up",
    label: "Hand point up",
    icon: "hand-point-up",
    is_fa: true,
  },
  {
    value: "hand-pointer",
    label: "Hand pointer",
    icon: "hand-pointer",
    is_fa: true,
  },
  {
    value: "hand-scissors",
    label: "Hand scissors",
    icon: "hand-scissors",
    is_fa: true,
  },
  {
    value: "hand-sparkles",
    label: "Hand sparkles",
    icon: "hand-sparkles",
    is_fa: true,
  },
  {
    value: "hand-spock",
    label: "Hand spock",
    icon: "hand-spock",
    is_fa: true,
  },
  {
    value: "handcuffs",
    label: "Handcuffs",
    icon: "handcuffs",
    is_fa: true,
  },
  {
    value: "hands",
    label: "Hands",
    icon: "hands",
    is_fa: true,
  },
  {
    value: "hands-asl-interpreting",
    label: "Hands asl interpreting",
    icon: "hands-asl-interpreting",
    is_fa: true,
  },
  {
    value: "hands-bound",
    label: "Hands bound",
    icon: "hands-bound",
    is_fa: true,
  },
  {
    value: "hands-bubbles",
    label: "Hands bubbles",
    icon: "hands-bubbles",
    is_fa: true,
  },
  {
    value: "hands-clapping",
    label: "Hands clapping",
    icon: "hands-clapping",
    is_fa: true,
  },
  {
    value: "hands-holding",
    label: "Hands holding",
    icon: "hands-holding",
    is_fa: true,
  },
  {
    value: "hands-holding-child",
    label: "Hands holding child",
    icon: "hands-holding-child",
    is_fa: true,
  },
  {
    value: "hands-holding-circle",
    label: "Hands holding circle",
    icon: "hands-holding-circle",
    is_fa: true,
  },
  {
    value: "hands-praying",
    label: "Hands praying",
    icon: "hands-praying",
    is_fa: true,
  },
  {
    value: "handshake",
    label: "Handshake",
    icon: "handshake",
    is_fa: true,
  },
  {
    value: "handshake-angle",
    label: "Handshake angle",
    icon: "handshake-angle",
    is_fa: true,
  },
  {
    value: "handshake-simple",
    label: "Handshake simple",
    icon: "handshake-simple",
    is_fa: true,
  },
  {
    value: "handshake-simple-slash",
    label: "Handshake simple slash",
    icon: "handshake-simple-slash",
    is_fa: true,
  },
  {
    value: "handshake-slash",
    label: "Handshake slash",
    icon: "handshake-slash",
    is_fa: true,
  },
  {
    value: "hanukiah",
    label: "Hanukiah",
    icon: "hanukiah",
    is_fa: true,
  },
  {
    value: "hard-drive",
    label: "Hard drive",
    icon: "hard-drive",
    is_fa: true,
  },
  {
    value: "hashtag",
    label: "Hashtag",
    icon: "hashtag",
    is_fa: true,
  },
  {
    value: "hat-cowboy",
    label: "Hat cowboy",
    icon: "hat-cowboy",
    is_fa: true,
  },
  {
    value: "hat-cowboy-side",
    label: "Hat cowboy side",
    icon: "hat-cowboy-side",
    is_fa: true,
  },
  {
    value: "hat-wizard",
    label: "Hat wizard",
    icon: "hat-wizard",
    is_fa: true,
  },
  {
    value: "head-side-cough",
    label: "Head side cough",
    icon: "head-side-cough",
    is_fa: true,
  },
  {
    value: "head-side-cough-slash",
    label: "Head side cough slash",
    icon: "head-side-cough-slash",
    is_fa: true,
  },
  {
    value: "head-side-mask",
    label: "Head side mask",
    icon: "head-side-mask",
    is_fa: true,
  },
  {
    value: "head-side-virus",
    label: "Head side virus",
    icon: "head-side-virus",
    is_fa: true,
  },
  {
    value: "heading",
    label: "Heading",
    icon: "heading",
    is_fa: true,
  },
  {
    value: "headphones",
    label: "Headphones",
    icon: "headphones",
    is_fa: true,
  },
  {
    value: "headphones-simple",
    label: "Headphones simple",
    icon: "headphones-simple",
    is_fa: true,
  },
  {
    value: "headset",
    label: "Headset",
    icon: "headset",
    is_fa: true,
  },
  {
    value: "heart",
    label: "Heart",
    icon: "heart",
    is_fa: true,
  },
  {
    value: "heart-circle-bolt",
    label: "Heart circle bolt",
    icon: "heart-circle-bolt",
    is_fa: true,
  },
  {
    value: "heart-circle-check",
    label: "Heart circle check",
    icon: "heart-circle-check",
    is_fa: true,
  },
  {
    value: "heart-circle-exclamation",
    label: "Heart circle exclamation",
    icon: "heart-circle-exclamation",
    is_fa: true,
  },
  {
    value: "heart-circle-minus",
    label: "Heart circle minus",
    icon: "heart-circle-minus",
    is_fa: true,
  },
  {
    value: "heart-circle-plus",
    label: "Heart circle plus",
    icon: "heart-circle-plus",
    is_fa: true,
  },
  {
    value: "heart-circle-xmark",
    label: "Heart circle xmark",
    icon: "heart-circle-xmark",
    is_fa: true,
  },
  {
    value: "heart-crack",
    label: "Heart crack",
    icon: "heart-crack",
    is_fa: true,
  },
  {
    value: "heart-pulse",
    label: "Heart pulse",
    icon: "heart-pulse",
    is_fa: true,
  },
  {
    value: "helicopter",
    label: "Helicopter",
    icon: "helicopter",
    is_fa: true,
  },
  {
    value: "helicopter-symbol",
    label: "Helicopter symbol",
    icon: "helicopter-symbol",
    is_fa: true,
  },
  {
    value: "helmet-safety",
    label: "Helmet safety",
    icon: "helmet-safety",
    is_fa: true,
  },
  {
    value: "helmet-un",
    label: "Helmet un",
    icon: "helmet-un",
    is_fa: true,
  },
  {
    value: "highlighter",
    label: "Highlighter",
    icon: "highlighter",
    is_fa: true,
  },
  {
    value: "hill-avalanche",
    label: "Hill avalanche",
    icon: "hill-avalanche",
    is_fa: true,
  },
  {
    value: "hill-rockslide",
    label: "Hill rockslide",
    icon: "hill-rockslide",
    is_fa: true,
  },
  {
    value: "hippo",
    label: "Hippo",
    icon: "hippo",
    is_fa: true,
  },
  {
    value: "hockey-puck",
    label: "Hockey puck",
    icon: "hockey-puck",
    is_fa: true,
  },
  {
    value: "holly-berry",
    label: "Holly berry",
    icon: "holly-berry",
    is_fa: true,
  },
  {
    value: "horse",
    label: "Horse",
    icon: "horse",
    is_fa: true,
  },
  {
    value: "horse-head",
    label: "Horse head",
    icon: "horse-head",
    is_fa: true,
  },
  {
    value: "hospital",
    label: "Hospital",
    icon: "hospital",
    is_fa: true,
  },
  {
    value: "hospital-user",
    label: "Hospital user",
    icon: "hospital-user",
    is_fa: true,
  },
  {
    value: "hot-tub-person",
    label: "Hot tub person",
    icon: "hot-tub-person",
    is_fa: true,
  },
  {
    value: "hotdog",
    label: "Hotdog",
    icon: "hotdog",
    is_fa: true,
  },
  {
    value: "hotel",
    label: "Hotel",
    icon: "hotel",
    is_fa: true,
  },
  {
    value: "hourglass",
    label: "Hourglass",
    icon: "hourglass",
    is_fa: true,
  },
  {
    value: "hourglass-empty",
    label: "Hourglass empty",
    icon: "hourglass-empty",
    is_fa: true,
  },
  {
    value: "hourglass-end",
    label: "Hourglass end",
    icon: "hourglass-end",
    is_fa: true,
  },
  {
    value: "hourglass-start",
    label: "Hourglass start",
    icon: "hourglass-start",
    is_fa: true,
  },
  {
    value: "house",
    label: "House",
    icon: "house",
    is_fa: true,
  },
  {
    value: "house-chimney",
    label: "House chimney",
    icon: "house-chimney",
    is_fa: true,
  },
  {
    value: "house-chimney-crack",
    label: "House chimney crack",
    icon: "house-chimney-crack",
    is_fa: true,
  },
  {
    value: "house-chimney-medical",
    label: "House chimney medical",
    icon: "house-chimney-medical",
    is_fa: true,
  },
  {
    value: "house-chimney-user",
    label: "House chimney user",
    icon: "house-chimney-user",
    is_fa: true,
  },
  {
    value: "house-chimney-window",
    label: "House chimney window",
    icon: "house-chimney-window",
    is_fa: true,
  },
  {
    value: "house-circle-check",
    label: "House circle check",
    icon: "house-circle-check",
    is_fa: true,
  },
  {
    value: "house-circle-exclamation",
    label: "House circle exclamation",
    icon: "house-circle-exclamation",
    is_fa: true,
  },
  {
    value: "house-circle-xmark",
    label: "House circle xmark",
    icon: "house-circle-xmark",
    is_fa: true,
  },
  {
    value: "house-crack",
    label: "House crack",
    icon: "house-crack",
    is_fa: true,
  },
  {
    value: "house-fire",
    label: "House fire",
    icon: "house-fire",
    is_fa: true,
  },
  {
    value: "house-flag",
    label: "House flag",
    icon: "house-flag",
    is_fa: true,
  },
  {
    value: "house-flood-water",
    label: "House flood water",
    icon: "house-flood-water",
    is_fa: true,
  },
  {
    value: "house-flood-water-circle-arrow-right",
    label: "House flood water circle arrow right",
    icon: "house-flood-water-circle-arrow-right",
    is_fa: true,
  },
  {
    value: "house-laptop",
    label: "House laptop",
    icon: "house-laptop",
    is_fa: true,
  },
  {
    value: "house-lock",
    label: "House lock",
    icon: "house-lock",
    is_fa: true,
  },
  {
    value: "house-medical",
    label: "House medical",
    icon: "house-medical",
    is_fa: true,
  },
  {
    value: "house-medical-circle-check",
    label: "House medical circle check",
    icon: "house-medical-circle-check",
    is_fa: true,
  },
  {
    value: "house-medical-circle-exclamation",
    label: "House medical circle exclamation",
    icon: "house-medical-circle-exclamation",
    is_fa: true,
  },
  {
    value: "house-medical-circle-xmark",
    label: "House medical circle xmark",
    icon: "house-medical-circle-xmark",
    is_fa: true,
  },
  {
    value: "house-medical-flag",
    label: "House medical flag",
    icon: "house-medical-flag",
    is_fa: true,
  },
  {
    value: "house-signal",
    label: "House signal",
    icon: "house-signal",
    is_fa: true,
  },
  {
    value: "house-tsunami",
    label: "House tsunami",
    icon: "house-tsunami",
    is_fa: true,
  },
  {
    value: "house-user",
    label: "House user",
    icon: "house-user",
    is_fa: true,
  },
  {
    value: "hryvnia-sign",
    label: "Hryvnia sign",
    icon: "hryvnia-sign",
    is_fa: true,
  },
  {
    value: "hurricane",
    label: "Hurricane",
    icon: "hurricane",
    is_fa: true,
  },
  {
    value: "i",
    label: "I",
    icon: "i",
    is_fa: true,
  },
  {
    value: "i-cursor",
    label: "I cursor",
    icon: "i-cursor",
    is_fa: true,
  },
  {
    value: "ice-cream",
    label: "Ice cream",
    icon: "ice-cream",
    is_fa: true,
  },
  {
    value: "icicles",
    label: "Icicles",
    icon: "icicles",
    is_fa: true,
  },
  {
    value: "icons",
    label: "Icons",
    icon: "icons",
    is_fa: true,
  },
  {
    value: "id-badge",
    label: "Id badge",
    icon: "id-badge",
    is_fa: true,
  },
  {
    value: "id-card",
    label: "Id card",
    icon: "id-card",
    is_fa: true,
  },
  {
    value: "id-card-clip",
    label: "Id card clip",
    icon: "id-card-clip",
    is_fa: true,
  },
  {
    value: "igloo",
    label: "Igloo",
    icon: "igloo",
    is_fa: true,
  },
  {
    value: "image",
    label: "Image",
    icon: "image",
    is_fa: true,
  },
  {
    value: "image-portrait",
    label: "Image portrait",
    icon: "image-portrait",
    is_fa: true,
  },
  {
    value: "images",
    label: "Images",
    icon: "images",
    is_fa: true,
  },
  {
    value: "inbox",
    label: "Inbox",
    icon: "inbox",
    is_fa: true,
  },
  {
    value: "indent",
    label: "Indent",
    icon: "indent",
    is_fa: true,
  },
  {
    value: "indian-rupee-sign",
    label: "Indian rupee sign",
    icon: "indian-rupee-sign",
    is_fa: true,
  },
  {
    value: "industry",
    label: "Industry",
    icon: "industry",
    is_fa: true,
  },
  {
    value: "infinity",
    label: "Infinity",
    icon: "infinity",
    is_fa: true,
  },
  {
    value: "info",
    label: "Info",
    icon: "info",
    is_fa: true,
  },
  {
    value: "italic",
    label: "Italic",
    icon: "italic",
    is_fa: true,
  },
  {
    value: "j",
    label: "J",
    icon: "j",
    is_fa: true,
  },
  {
    value: "jar",
    label: "Jar",
    icon: "jar",
    is_fa: true,
  },
  {
    value: "jar-wheat",
    label: "Jar wheat",
    icon: "jar-wheat",
    is_fa: true,
  },
  {
    value: "jedi",
    label: "Jedi",
    icon: "jedi",
    is_fa: true,
  },
  {
    value: "jet-fighter",
    label: "Jet fighter",
    icon: "jet-fighter",
    is_fa: true,
  },
  {
    value: "jet-fighter-up",
    label: "Jet fighter up",
    icon: "jet-fighter-up",
    is_fa: true,
  },
  {
    value: "joint",
    label: "Joint",
    icon: "joint",
    is_fa: true,
  },
  {
    value: "jug-detergent",
    label: "Jug detergent",
    icon: "jug-detergent",
    is_fa: true,
  },
  {
    value: "k",
    label: "K",
    icon: "k",
    is_fa: true,
  },
  {
    value: "kaaba",
    label: "Kaaba",
    icon: "kaaba",
    is_fa: true,
  },
  {
    value: "key",
    label: "Key",
    icon: "key",
    is_fa: true,
  },
  {
    value: "keyboard",
    label: "Keyboard",
    icon: "keyboard",
    is_fa: true,
  },
  {
    value: "khanda",
    label: "Khanda",
    icon: "khanda",
    is_fa: true,
  },
  {
    value: "kip-sign",
    label: "Kip sign",
    icon: "kip-sign",
    is_fa: true,
  },
  {
    value: "kit-medical",
    label: "Kit medical",
    icon: "kit-medical",
    is_fa: true,
  },
  {
    value: "kitchen-set",
    label: "Kitchen set",
    icon: "kitchen-set",
    is_fa: true,
  },
  {
    value: "kiwi-bird",
    label: "Kiwi bird",
    icon: "kiwi-bird",
    is_fa: true,
  },
  {
    value: "l",
    label: "L",
    icon: "l",
    is_fa: true,
  },
  {
    value: "land-mine-on",
    label: "Land mine on",
    icon: "land-mine-on",
    is_fa: true,
  },
  {
    value: "landmark",
    label: "Landmark",
    icon: "landmark",
    is_fa: true,
  },
  {
    value: "landmark-dome",
    label: "Landmark dome",
    icon: "landmark-dome",
    is_fa: true,
  },
  {
    value: "landmark-flag",
    label: "Landmark flag",
    icon: "landmark-flag",
    is_fa: true,
  },
  {
    value: "language",
    label: "Language",
    icon: "language",
    is_fa: true,
  },
  {
    value: "laptop",
    label: "Laptop",
    icon: "laptop",
    is_fa: true,
  },
  {
    value: "laptop-code",
    label: "Laptop code",
    icon: "laptop-code",
    is_fa: true,
  },
  {
    value: "laptop-file",
    label: "Laptop file",
    icon: "laptop-file",
    is_fa: true,
  },
  {
    value: "laptop-medical",
    label: "Laptop medical",
    icon: "laptop-medical",
    is_fa: true,
  },
  {
    value: "lari-sign",
    label: "Lari sign",
    icon: "lari-sign",
    is_fa: true,
  },
  {
    value: "layer-group",
    label: "Layer group",
    icon: "layer-group",
    is_fa: true,
  },
  {
    value: "leaf",
    label: "Leaf",
    icon: "leaf",
    is_fa: true,
  },
  {
    value: "left-long",
    label: "Left long",
    icon: "left-long",
    is_fa: true,
  },
  {
    value: "left-right",
    label: "Left right",
    icon: "left-right",
    is_fa: true,
  },
  {
    value: "lemon",
    label: "Lemon",
    icon: "lemon",
    is_fa: true,
  },
  {
    value: "less-than",
    label: "Less than",
    icon: "less-than",
    is_fa: true,
  },
  {
    value: "less-than-equal",
    label: "Less than equal",
    icon: "less-than-equal",
    is_fa: true,
  },
  {
    value: "life-ring",
    label: "Life ring",
    icon: "life-ring",
    is_fa: true,
  },
  {
    value: "lightbulb",
    label: "Lightbulb",
    icon: "lightbulb",
    is_fa: true,
  },
  {
    value: "lines-leaning",
    label: "Lines leaning",
    icon: "lines-leaning",
    is_fa: true,
  },
  {
    value: "link",
    label: "Link",
    icon: "link",
    is_fa: true,
  },
  {
    value: "link-slash",
    label: "Link slash",
    icon: "link-slash",
    is_fa: true,
  },
  {
    value: "lira-sign",
    label: "Lira sign",
    icon: "lira-sign",
    is_fa: true,
  },
  {
    value: "list",
    label: "List",
    icon: "list",
    is_fa: true,
  },
  {
    value: "list-check",
    label: "List check",
    icon: "list-check",
    is_fa: true,
  },
  {
    value: "list-ol",
    label: "List ol",
    icon: "list-ol",
    is_fa: true,
  },
  {
    value: "list-ul",
    label: "List ul",
    icon: "list-ul",
    is_fa: true,
  },
  {
    value: "litecoin-sign",
    label: "Litecoin sign",
    icon: "litecoin-sign",
    is_fa: true,
  },
  {
    value: "location-arrow",
    label: "Location arrow",
    icon: "location-arrow",
    is_fa: true,
  },
  {
    value: "location-crosshairs",
    label: "Location crosshairs",
    icon: "location-crosshairs",
    is_fa: true,
  },
  {
    value: "location-dot",
    label: "Location dot",
    icon: "location-dot",
    is_fa: true,
  },
  {
    value: "location-pin",
    label: "Location pin",
    icon: "location-pin",
    is_fa: true,
  },
  {
    value: "location-pin-lock",
    label: "Location pin lock",
    icon: "location-pin-lock",
    is_fa: true,
  },
  {
    value: "lock",
    label: "Lock",
    icon: "lock",
    is_fa: true,
  },
  {
    value: "lock-open",
    label: "Lock open",
    icon: "lock-open",
    is_fa: true,
  },
  {
    value: "locust",
    label: "Locust",
    icon: "locust",
    is_fa: true,
  },
  {
    value: "lungs",
    label: "Lungs",
    icon: "lungs",
    is_fa: true,
  },
  {
    value: "lungs-virus",
    label: "Lungs virus",
    icon: "lungs-virus",
    is_fa: true,
  },
  {
    value: "m",
    label: "M",
    icon: "m",
    is_fa: true,
  },
  {
    value: "magnet",
    label: "Magnet",
    icon: "magnet",
    is_fa: true,
  },
  {
    value: "magnifying-glass",
    label: "Magnifying glass",
    icon: "magnifying-glass",
    is_fa: true,
  },
  {
    value: "magnifying-glass-arrow-right",
    label: "Magnifying glass arrow right",
    icon: "magnifying-glass-arrow-right",
    is_fa: true,
  },
  {
    value: "magnifying-glass-chart",
    label: "Magnifying glass chart",
    icon: "magnifying-glass-chart",
    is_fa: true,
  },
  {
    value: "magnifying-glass-dollar",
    label: "Magnifying glass dollar",
    icon: "magnifying-glass-dollar",
    is_fa: true,
  },
  {
    value: "magnifying-glass-location",
    label: "Magnifying glass location",
    icon: "magnifying-glass-location",
    is_fa: true,
  },
  {
    value: "magnifying-glass-minus",
    label: "Magnifying glass minus",
    icon: "magnifying-glass-minus",
    is_fa: true,
  },
  {
    value: "magnifying-glass-plus",
    label: "Magnifying glass plus",
    icon: "magnifying-glass-plus",
    is_fa: true,
  },
  {
    value: "manat-sign",
    label: "Manat sign",
    icon: "manat-sign",
    is_fa: true,
  },
  {
    value: "map",
    label: "Map",
    icon: "map",
    is_fa: true,
  },
  {
    value: "map-location",
    label: "Map location",
    icon: "map-location",
    is_fa: true,
  },
  {
    value: "map-location-dot",
    label: "Map location dot",
    icon: "map-location-dot",
    is_fa: true,
  },
  {
    value: "map-pin",
    label: "Map pin",
    icon: "map-pin",
    is_fa: true,
  },
  {
    value: "marker",
    label: "Marker",
    icon: "marker",
    is_fa: true,
  },
  {
    value: "mars",
    label: "Mars",
    icon: "mars",
    is_fa: true,
  },
  {
    value: "mars-and-venus",
    label: "Mars and venus",
    icon: "mars-and-venus",
    is_fa: true,
  },
  {
    value: "mars-and-venus-burst",
    label: "Mars and venus burst",
    icon: "mars-and-venus-burst",
    is_fa: true,
  },
  {
    value: "mars-double",
    label: "Mars double",
    icon: "mars-double",
    is_fa: true,
  },
  {
    value: "mars-stroke",
    label: "Mars stroke",
    icon: "mars-stroke",
    is_fa: true,
  },
  {
    value: "mars-stroke-right",
    label: "Mars stroke right",
    icon: "mars-stroke-right",
    is_fa: true,
  },
  {
    value: "mars-stroke-up",
    label: "Mars stroke up",
    icon: "mars-stroke-up",
    is_fa: true,
  },
  {
    value: "martini-glass",
    label: "Martini glass",
    icon: "martini-glass",
    is_fa: true,
  },
  {
    value: "martini-glass-citrus",
    label: "Martini glass citrus",
    icon: "martini-glass-citrus",
    is_fa: true,
  },
  {
    value: "martini-glass-empty",
    label: "Martini glass empty",
    icon: "martini-glass-empty",
    is_fa: true,
  },
  {
    value: "mask",
    label: "Mask",
    icon: "mask",
    is_fa: true,
  },
  {
    value: "mask-face",
    label: "Mask face",
    icon: "mask-face",
    is_fa: true,
  },
  {
    value: "mask-ventilator",
    label: "Mask ventilator",
    icon: "mask-ventilator",
    is_fa: true,
  },
  {
    value: "masks-theater",
    label: "Masks theater",
    icon: "masks-theater",
    is_fa: true,
  },
  {
    value: "mattress-pillow",
    label: "Mattress pillow",
    icon: "mattress-pillow",
    is_fa: true,
  },
  {
    value: "maximize",
    label: "Maximize",
    icon: "maximize",
    is_fa: true,
  },
  {
    value: "medal",
    label: "Medal",
    icon: "medal",
    is_fa: true,
  },
  {
    value: "memory",
    label: "Memory",
    icon: "memory",
    is_fa: true,
  },
  {
    value: "menorah",
    label: "Menorah",
    icon: "menorah",
    is_fa: true,
  },
  {
    value: "mercury",
    label: "Mercury",
    icon: "mercury",
    is_fa: true,
  },
  {
    value: "message",
    label: "Message",
    icon: "message",
    is_fa: true,
  },
  {
    value: "meteor",
    label: "Meteor",
    icon: "meteor",
    is_fa: true,
  },
  {
    value: "microchip",
    label: "Microchip",
    icon: "microchip",
    is_fa: true,
  },
  {
    value: "microphone",
    label: "Microphone",
    icon: "microphone",
    is_fa: true,
  },
  {
    value: "microphone-lines",
    label: "Microphone lines",
    icon: "microphone-lines",
    is_fa: true,
  },
  {
    value: "microphone-lines-slash",
    label: "Microphone lines slash",
    icon: "microphone-lines-slash",
    is_fa: true,
  },
  {
    value: "microphone-slash",
    label: "Microphone slash",
    icon: "microphone-slash",
    is_fa: true,
  },
  {
    value: "microscope",
    label: "Microscope",
    icon: "microscope",
    is_fa: true,
  },
  {
    value: "mill-sign",
    label: "Mill sign",
    icon: "mill-sign",
    is_fa: true,
  },
  {
    value: "minimize",
    label: "Minimize",
    icon: "minimize",
    is_fa: true,
  },
  {
    value: "minus",
    label: "Minus",
    icon: "minus",
    is_fa: true,
  },
  {
    value: "mitten",
    label: "Mitten",
    icon: "mitten",
    is_fa: true,
  },
  {
    value: "mobile",
    label: "Mobile",
    icon: "mobile",
    is_fa: true,
  },
  {
    value: "mobile-button",
    label: "Mobile button",
    icon: "mobile-button",
    is_fa: true,
  },
  {
    value: "mobile-retro",
    label: "Mobile retro",
    icon: "mobile-retro",
    is_fa: true,
  },
  {
    value: "mobile-screen",
    label: "Mobile screen",
    icon: "mobile-screen",
    is_fa: true,
  },
  {
    value: "mobile-screen-button",
    label: "Mobile screen button",
    icon: "mobile-screen-button",
    is_fa: true,
  },
  {
    value: "money-bill",
    label: "Money bill",
    icon: "money-bill",
    is_fa: true,
  },
  {
    value: "money-bill-1",
    label: "Money bill 1",
    icon: "money-bill-1",
    is_fa: true,
  },
  {
    value: "money-bill-1-wave",
    label: "Money bill 1 wave",
    icon: "money-bill-1-wave",
    is_fa: true,
  },
  {
    value: "money-bill-transfer",
    label: "Money bill transfer",
    icon: "money-bill-transfer",
    is_fa: true,
  },
  {
    value: "money-bill-trend-up",
    label: "Money bill trend up",
    icon: "money-bill-trend-up",
    is_fa: true,
  },
  {
    value: "money-bill-wave",
    label: "Money bill wave",
    icon: "money-bill-wave",
    is_fa: true,
  },
  {
    value: "money-bill-wheat",
    label: "Money bill wheat",
    icon: "money-bill-wheat",
    is_fa: true,
  },
  {
    value: "money-bills",
    label: "Money bills",
    icon: "money-bills",
    is_fa: true,
  },
  {
    value: "money-check",
    label: "Money check",
    icon: "money-check",
    is_fa: true,
  },
  {
    value: "money-check-dollar",
    label: "Money check dollar",
    icon: "money-check-dollar",
    is_fa: true,
  },
  {
    value: "monument",
    label: "Monument",
    icon: "monument",
    is_fa: true,
  },
  {
    value: "moon",
    label: "Moon",
    icon: "moon",
    is_fa: true,
  },
  {
    value: "mortar-pestle",
    label: "Mortar pestle",
    icon: "mortar-pestle",
    is_fa: true,
  },
  {
    value: "mosque",
    label: "Mosque",
    icon: "mosque",
    is_fa: true,
  },
  {
    value: "mosquito",
    label: "Mosquito",
    icon: "mosquito",
    is_fa: true,
  },
  {
    value: "mosquito-net",
    label: "Mosquito net",
    icon: "mosquito-net",
    is_fa: true,
  },
  {
    value: "motorcycle",
    label: "Motorcycle",
    icon: "motorcycle",
    is_fa: true,
  },
  {
    value: "mound",
    label: "Mound",
    icon: "mound",
    is_fa: true,
  },
  {
    value: "mountain",
    label: "Mountain",
    icon: "mountain",
    is_fa: true,
  },
  {
    value: "mountain-city",
    label: "Mountain city",
    icon: "mountain-city",
    is_fa: true,
  },
  {
    value: "mountain-sun",
    label: "Mountain sun",
    icon: "mountain-sun",
    is_fa: true,
  },
  {
    value: "mug-hot",
    label: "Mug hot",
    icon: "mug-hot",
    is_fa: true,
  },
  {
    value: "mug-saucer",
    label: "Mug saucer",
    icon: "mug-saucer",
    is_fa: true,
  },
  {
    value: "music",
    label: "Music",
    icon: "music",
    is_fa: true,
  },
  {
    value: "n",
    label: "N",
    icon: "n",
    is_fa: true,
  },
  {
    value: "naira-sign",
    label: "Naira sign",
    icon: "naira-sign",
    is_fa: true,
  },
  {
    value: "network-wired",
    label: "Network wired",
    icon: "network-wired",
    is_fa: true,
  },
  {
    value: "neuter",
    label: "Neuter",
    icon: "neuter",
    is_fa: true,
  },
  {
    value: "newspaper",
    label: "Newspaper",
    icon: "newspaper",
    is_fa: true,
  },
  {
    value: "not-equal",
    label: "Not equal",
    icon: "not-equal",
    is_fa: true,
  },
  {
    value: "note-sticky",
    label: "Note sticky",
    icon: "note-sticky",
    is_fa: true,
  },
  {
    value: "notes-medical",
    label: "Notes medical",
    icon: "notes-medical",
    is_fa: true,
  },
  {
    value: "o",
    label: "O",
    icon: "o",
    is_fa: true,
  },
  {
    value: "object-group",
    label: "Object group",
    icon: "object-group",
    is_fa: true,
  },
  {
    value: "object-ungroup",
    label: "Object ungroup",
    icon: "object-ungroup",
    is_fa: true,
  },
  {
    value: "oil-can",
    label: "Oil can",
    icon: "oil-can",
    is_fa: true,
  },
  {
    value: "oil-well",
    label: "Oil well",
    icon: "oil-well",
    is_fa: true,
  },
  {
    value: "om",
    label: "Om",
    icon: "om",
    is_fa: true,
  },
  {
    value: "otter",
    label: "Otter",
    icon: "otter",
    is_fa: true,
  },
  {
    value: "outdent",
    label: "Outdent",
    icon: "outdent",
    is_fa: true,
  },
  {
    value: "p",
    label: "P",
    icon: "p",
    is_fa: true,
  },
  {
    value: "pager",
    label: "Pager",
    icon: "pager",
    is_fa: true,
  },
  {
    value: "paint-roller",
    label: "Paint roller",
    icon: "paint-roller",
    is_fa: true,
  },
  {
    value: "paintbrush",
    label: "Paintbrush",
    icon: "paintbrush",
    is_fa: true,
  },
  {
    value: "palette",
    label: "Palette",
    icon: "palette",
    is_fa: true,
  },
  {
    value: "pallet",
    label: "Pallet",
    icon: "pallet",
    is_fa: true,
  },
  {
    value: "panorama",
    label: "Panorama",
    icon: "panorama",
    is_fa: true,
  },
  {
    value: "paper-plane",
    label: "Paper plane",
    icon: "paper-plane",
    is_fa: true,
  },
  {
    value: "paperclip",
    label: "Paperclip",
    icon: "paperclip",
    is_fa: true,
  },
  {
    value: "parachute-box",
    label: "Parachute box",
    icon: "parachute-box",
    is_fa: true,
  },
  {
    value: "paragraph",
    label: "Paragraph",
    icon: "paragraph",
    is_fa: true,
  },
  {
    value: "passport",
    label: "Passport",
    icon: "passport",
    is_fa: true,
  },
  {
    value: "paste",
    label: "Paste",
    icon: "paste",
    is_fa: true,
  },
  {
    value: "pause",
    label: "Pause",
    icon: "pause",
    is_fa: true,
  },
  {
    value: "paw",
    label: "Paw",
    icon: "paw",
    is_fa: true,
  },
  {
    value: "peace",
    label: "Peace",
    icon: "peace",
    is_fa: true,
  },
  {
    value: "pen",
    label: "Pen",
    icon: "pen",
    is_fa: true,
  },
  {
    value: "pen-clip",
    label: "Pen clip",
    icon: "pen-clip",
    is_fa: true,
  },
  {
    value: "pen-fancy",
    label: "Pen fancy",
    icon: "pen-fancy",
    is_fa: true,
  },
  {
    value: "pen-nib",
    label: "Pen nib",
    icon: "pen-nib",
    is_fa: true,
  },
  {
    value: "pen-ruler",
    label: "Pen ruler",
    icon: "pen-ruler",
    is_fa: true,
  },
  {
    value: "pen-to-square",
    label: "Pen to square",
    icon: "pen-to-square",
    is_fa: true,
  },
  {
    value: "pencil",
    label: "Pencil",
    icon: "pencil",
    is_fa: true,
  },
  {
    value: "people-arrows-left-right",
    label: "People arrows left right",
    icon: "people-arrows-left-right",
    is_fa: true,
  },
  {
    value: "people-carry-box",
    label: "People carry box",
    icon: "people-carry-box",
    is_fa: true,
  },
  {
    value: "people-group",
    label: "People group",
    icon: "people-group",
    is_fa: true,
  },
  {
    value: "people-line",
    label: "People line",
    icon: "people-line",
    is_fa: true,
  },
  {
    value: "people-pulling",
    label: "People pulling",
    icon: "people-pulling",
    is_fa: true,
  },
  {
    value: "people-robbery",
    label: "People robbery",
    icon: "people-robbery",
    is_fa: true,
  },
  {
    value: "people-roof",
    label: "People roof",
    icon: "people-roof",
    is_fa: true,
  },
  {
    value: "pepper-hot",
    label: "Pepper hot",
    icon: "pepper-hot",
    is_fa: true,
  },
  {
    value: "percent",
    label: "Percent",
    icon: "percent",
    is_fa: true,
  },
  {
    value: "person",
    label: "Person",
    icon: "person",
    is_fa: true,
  },
  {
    value: "person-arrow-down-to-line",
    label: "Person arrow down to line",
    icon: "person-arrow-down-to-line",
    is_fa: true,
  },
  {
    value: "person-arrow-up-from-line",
    label: "Person arrow up from line",
    icon: "person-arrow-up-from-line",
    is_fa: true,
  },
  {
    value: "person-biking",
    label: "Person biking",
    icon: "person-biking",
    is_fa: true,
  },
  {
    value: "person-booth",
    label: "Person booth",
    icon: "person-booth",
    is_fa: true,
  },
  {
    value: "person-breastfeeding",
    label: "Person breastfeeding",
    icon: "person-breastfeeding",
    is_fa: true,
  },
  {
    value: "person-burst",
    label: "Person burst",
    icon: "person-burst",
    is_fa: true,
  },
  {
    value: "person-cane",
    label: "Person cane",
    icon: "person-cane",
    is_fa: true,
  },
  {
    value: "person-chalkboard",
    label: "Person chalkboard",
    icon: "person-chalkboard",
    is_fa: true,
  },
  {
    value: "person-circle-check",
    label: "Person circle check",
    icon: "person-circle-check",
    is_fa: true,
  },
  {
    value: "person-circle-exclamation",
    label: "Person circle exclamation",
    icon: "person-circle-exclamation",
    is_fa: true,
  },
  {
    value: "person-circle-minus",
    label: "Person circle minus",
    icon: "person-circle-minus",
    is_fa: true,
  },
  {
    value: "person-circle-plus",
    label: "Person circle plus",
    icon: "person-circle-plus",
    is_fa: true,
  },
  {
    value: "person-circle-question",
    label: "Person circle question",
    icon: "person-circle-question",
    is_fa: true,
  },
  {
    value: "person-circle-xmark",
    label: "Person circle xmark",
    icon: "person-circle-xmark",
    is_fa: true,
  },
  {
    value: "person-digging",
    label: "Person digging",
    icon: "person-digging",
    is_fa: true,
  },
  {
    value: "person-dots-from-line",
    label: "Person dots from line",
    icon: "person-dots-from-line",
    is_fa: true,
  },
  {
    value: "person-dress",
    label: "Person dress",
    icon: "person-dress",
    is_fa: true,
  },
  {
    value: "person-dress-burst",
    label: "Person dress burst",
    icon: "person-dress-burst",
    is_fa: true,
  },
  {
    value: "person-drowning",
    label: "Person drowning",
    icon: "person-drowning",
    is_fa: true,
  },
  {
    value: "person-falling",
    label: "Person falling",
    icon: "person-falling",
    is_fa: true,
  },
  {
    value: "person-falling-burst",
    label: "Person falling burst",
    icon: "person-falling-burst",
    is_fa: true,
  },
  {
    value: "person-half-dress",
    label: "Person half dress",
    icon: "person-half-dress",
    is_fa: true,
  },
  {
    value: "person-harassing",
    label: "Person harassing",
    icon: "person-harassing",
    is_fa: true,
  },
  {
    value: "person-hiking",
    label: "Person hiking",
    icon: "person-hiking",
    is_fa: true,
  },
  {
    value: "person-military-pointing",
    label: "Person military pointing",
    icon: "person-military-pointing",
    is_fa: true,
  },
  {
    value: "person-military-rifle",
    label: "Person military rifle",
    icon: "person-military-rifle",
    is_fa: true,
  },
  {
    value: "person-military-to-person",
    label: "Person military to person",
    icon: "person-military-to-person",
    is_fa: true,
  },
  {
    value: "person-praying",
    label: "Person praying",
    icon: "person-praying",
    is_fa: true,
  },
  {
    value: "person-pregnant",
    label: "Person pregnant",
    icon: "person-pregnant",
    is_fa: true,
  },
  {
    value: "person-rays",
    label: "Person rays",
    icon: "person-rays",
    is_fa: true,
  },
  {
    value: "person-rifle",
    label: "Person rifle",
    icon: "person-rifle",
    is_fa: true,
  },
  {
    value: "person-running",
    label: "Person running",
    icon: "person-running",
    is_fa: true,
  },
  {
    value: "person-shelter",
    label: "Person shelter",
    icon: "person-shelter",
    is_fa: true,
  },
  {
    value: "person-skating",
    label: "Person skating",
    icon: "person-skating",
    is_fa: true,
  },
  {
    value: "person-skiing",
    label: "Person skiing",
    icon: "person-skiing",
    is_fa: true,
  },
  {
    value: "person-skiing-nordic",
    label: "Person skiing nordic",
    icon: "person-skiing-nordic",
    is_fa: true,
  },
  {
    value: "person-snowboarding",
    label: "Person snowboarding",
    icon: "person-snowboarding",
    is_fa: true,
  },
  {
    value: "person-swimming",
    label: "Person swimming",
    icon: "person-swimming",
    is_fa: true,
  },
  {
    value: "person-through-window",
    label: "Person through window",
    icon: "person-through-window",
    is_fa: true,
  },
  {
    value: "person-walking",
    label: "Person walking",
    icon: "person-walking",
    is_fa: true,
  },
  {
    value: "person-walking-arrow-loop-left",
    label: "Person walking arrow loop left",
    icon: "person-walking-arrow-loop-left",
    is_fa: true,
  },
  {
    value: "person-walking-arrow-right",
    label: "Person walking arrow right",
    icon: "person-walking-arrow-right",
    is_fa: true,
  },
  {
    value: "person-walking-dashed-line-arrow-right",
    label: "Person walking dashed line arrow right",
    icon: "person-walking-dashed-line-arrow-right",
    is_fa: true,
  },
  {
    value: "person-walking-luggage",
    label: "Person walking luggage",
    icon: "person-walking-luggage",
    is_fa: true,
  },
  {
    value: "person-walking-with-cane",
    label: "Person walking with cane",
    icon: "person-walking-with-cane",
    is_fa: true,
  },
  {
    value: "peseta-sign",
    label: "Peseta sign",
    icon: "peseta-sign",
    is_fa: true,
  },
  {
    value: "peso-sign",
    label: "Peso sign",
    icon: "peso-sign",
    is_fa: true,
  },
  {
    value: "phone",
    label: "Phone",
    icon: "phone",
    is_fa: true,
  },
  {
    value: "phone-flip",
    label: "Phone flip",
    icon: "phone-flip",
    is_fa: true,
  },
  {
    value: "phone-slash",
    label: "Phone slash",
    icon: "phone-slash",
    is_fa: true,
  },
  {
    value: "phone-volume",
    label: "Phone volume",
    icon: "phone-volume",
    is_fa: true,
  },
  {
    value: "photo-film",
    label: "Photo film",
    icon: "photo-film",
    is_fa: true,
  },
  {
    value: "piggy-bank",
    label: "Piggy bank",
    icon: "piggy-bank",
    is_fa: true,
  },
  {
    value: "pills",
    label: "Pills",
    icon: "pills",
    is_fa: true,
  },
  {
    value: "pizza-slice",
    label: "Pizza slice",
    icon: "pizza-slice",
    is_fa: true,
  },
  {
    value: "place-of-worship",
    label: "Place of worship",
    icon: "place-of-worship",
    is_fa: true,
  },
  {
    value: "plane",
    label: "Plane",
    icon: "plane",
    is_fa: true,
  },
  {
    value: "plane-arrival",
    label: "Plane arrival",
    icon: "plane-arrival",
    is_fa: true,
  },
  {
    value: "plane-circle-check",
    label: "Plane circle check",
    icon: "plane-circle-check",
    is_fa: true,
  },
  {
    value: "plane-circle-exclamation",
    label: "Plane circle exclamation",
    icon: "plane-circle-exclamation",
    is_fa: true,
  },
  {
    value: "plane-circle-xmark",
    label: "Plane circle xmark",
    icon: "plane-circle-xmark",
    is_fa: true,
  },
  {
    value: "plane-departure",
    label: "Plane departure",
    icon: "plane-departure",
    is_fa: true,
  },
  {
    value: "plane-lock",
    label: "Plane lock",
    icon: "plane-lock",
    is_fa: true,
  },
  {
    value: "plane-slash",
    label: "Plane slash",
    icon: "plane-slash",
    is_fa: true,
  },
  {
    value: "plane-up",
    label: "Plane up",
    icon: "plane-up",
    is_fa: true,
  },
  {
    value: "plant-wilt",
    label: "Plant wilt",
    icon: "plant-wilt",
    is_fa: true,
  },
  {
    value: "plate-wheat",
    label: "Plate wheat",
    icon: "plate-wheat",
    is_fa: true,
  },
  {
    value: "play",
    label: "Play",
    icon: "play",
    is_fa: true,
  },
  {
    value: "plug",
    label: "Plug",
    icon: "plug",
    is_fa: true,
  },
  {
    value: "plug-circle-bolt",
    label: "Plug circle bolt",
    icon: "plug-circle-bolt",
    is_fa: true,
  },
  {
    value: "plug-circle-check",
    label: "Plug circle check",
    icon: "plug-circle-check",
    is_fa: true,
  },
  {
    value: "plug-circle-exclamation",
    label: "Plug circle exclamation",
    icon: "plug-circle-exclamation",
    is_fa: true,
  },
  {
    value: "plug-circle-minus",
    label: "Plug circle minus",
    icon: "plug-circle-minus",
    is_fa: true,
  },
  {
    value: "plug-circle-plus",
    label: "Plug circle plus",
    icon: "plug-circle-plus",
    is_fa: true,
  },
  {
    value: "plug-circle-xmark",
    label: "Plug circle xmark",
    icon: "plug-circle-xmark",
    is_fa: true,
  },
  {
    value: "plus",
    label: "Plus",
    icon: "plus",
    is_fa: true,
  },
  {
    value: "plus-minus",
    label: "Plus minus",
    icon: "plus-minus",
    is_fa: true,
  },
  {
    value: "podcast",
    label: "Podcast",
    icon: "podcast",
    is_fa: true,
  },
  {
    value: "poo",
    label: "Poo",
    icon: "poo",
    is_fa: true,
  },
  {
    value: "poo-storm",
    label: "Poo storm",
    icon: "poo-storm",
    is_fa: true,
  },
  {
    value: "poop",
    label: "Poop",
    icon: "poop",
    is_fa: true,
  },
  {
    value: "power-off",
    label: "Power off",
    icon: "power-off",
    is_fa: true,
  },
  {
    value: "prescription",
    label: "Prescription",
    icon: "prescription",
    is_fa: true,
  },
  {
    value: "prescription-bottle",
    label: "Prescription bottle",
    icon: "prescription-bottle",
    is_fa: true,
  },
  {
    value: "prescription-bottle-medical",
    label: "Prescription bottle medical",
    icon: "prescription-bottle-medical",
    is_fa: true,
  },
  {
    value: "print",
    label: "Print",
    icon: "print",
    is_fa: true,
  },
  {
    value: "pump-medical",
    label: "Pump medical",
    icon: "pump-medical",
    is_fa: true,
  },
  {
    value: "pump-soap",
    label: "Pump soap",
    icon: "pump-soap",
    is_fa: true,
  },
  {
    value: "puzzle-piece",
    label: "Puzzle piece",
    icon: "puzzle-piece",
    is_fa: true,
  },
  {
    value: "q",
    label: "Q",
    icon: "q",
    is_fa: true,
  },
  {
    value: "qrcode",
    label: "Qrcode",
    icon: "qrcode",
    is_fa: true,
  },
  {
    value: "question",
    label: "Question",
    icon: "question",
    is_fa: true,
  },
  {
    value: "quote-left",
    label: "Quote left",
    icon: "quote-left",
    is_fa: true,
  },
  {
    value: "quote-right",
    label: "Quote right",
    icon: "quote-right",
    is_fa: true,
  },
  {
    value: "r",
    label: "R",
    icon: "r",
    is_fa: true,
  },
  {
    value: "radiation",
    label: "Radiation",
    icon: "radiation",
    is_fa: true,
  },
  {
    value: "radio",
    label: "Radio",
    icon: "radio",
    is_fa: true,
  },
  {
    value: "rainbow",
    label: "Rainbow",
    icon: "rainbow",
    is_fa: true,
  },
  {
    value: "ranking-star",
    label: "Ranking star",
    icon: "ranking-star",
    is_fa: true,
  },
  {
    value: "receipt",
    label: "Receipt",
    icon: "receipt",
    is_fa: true,
  },
  {
    value: "record-vinyl",
    label: "Record vinyl",
    icon: "record-vinyl",
    is_fa: true,
  },
  {
    value: "rectangle-ad",
    label: "Rectangle ad",
    icon: "rectangle-ad",
    is_fa: true,
  },
  {
    value: "rectangle-list",
    label: "Rectangle list",
    icon: "rectangle-list",
    is_fa: true,
  },
  {
    value: "rectangle-xmark",
    label: "Rectangle xmark",
    icon: "rectangle-xmark",
    is_fa: true,
  },
  {
    value: "recycle",
    label: "Recycle",
    icon: "recycle",
    is_fa: true,
  },
  {
    value: "registered",
    label: "Registered",
    icon: "registered",
    is_fa: true,
  },
  {
    value: "repeat",
    label: "Repeat",
    icon: "repeat",
    is_fa: true,
  },
  {
    value: "reply",
    label: "Reply",
    icon: "reply",
    is_fa: true,
  },
  {
    value: "reply-all",
    label: "Reply all",
    icon: "reply-all",
    is_fa: true,
  },
  {
    value: "republican",
    label: "Republican",
    icon: "republican",
    is_fa: true,
  },
  {
    value: "restroom",
    label: "Restroom",
    icon: "restroom",
    is_fa: true,
  },
  {
    value: "retweet",
    label: "Retweet",
    icon: "retweet",
    is_fa: true,
  },
  {
    value: "ribbon",
    label: "Ribbon",
    icon: "ribbon",
    is_fa: true,
  },
  {
    value: "right-from-bracket",
    label: "Right from bracket",
    icon: "right-from-bracket",
    is_fa: true,
  },
  {
    value: "right-left",
    label: "Right left",
    icon: "right-left",
    is_fa: true,
  },
  {
    value: "right-long",
    label: "Right long",
    icon: "right-long",
    is_fa: true,
  },
  {
    value: "right-to-bracket",
    label: "Right to bracket",
    icon: "right-to-bracket",
    is_fa: true,
  },
  {
    value: "ring",
    label: "Ring",
    icon: "ring",
    is_fa: true,
  },
  {
    value: "road",
    label: "Road",
    icon: "road",
    is_fa: true,
  },
  {
    value: "road-barrier",
    label: "Road barrier",
    icon: "road-barrier",
    is_fa: true,
  },
  {
    value: "road-bridge",
    label: "Road bridge",
    icon: "road-bridge",
    is_fa: true,
  },
  {
    value: "road-circle-check",
    label: "Road circle check",
    icon: "road-circle-check",
    is_fa: true,
  },
  {
    value: "road-circle-exclamation",
    label: "Road circle exclamation",
    icon: "road-circle-exclamation",
    is_fa: true,
  },
  {
    value: "road-circle-xmark",
    label: "Road circle xmark",
    icon: "road-circle-xmark",
    is_fa: true,
  },
  {
    value: "road-lock",
    label: "Road lock",
    icon: "road-lock",
    is_fa: true,
  },
  {
    value: "road-spikes",
    label: "Road spikes",
    icon: "road-spikes",
    is_fa: true,
  },
  {
    value: "robot",
    label: "Robot",
    icon: "robot",
    is_fa: true,
  },
  {
    value: "rocket",
    label: "Rocket",
    icon: "rocket",
    is_fa: true,
  },
  {
    value: "rotate",
    label: "Rotate",
    icon: "rotate",
    is_fa: true,
  },
  {
    value: "rotate-left",
    label: "Rotate left",
    icon: "rotate-left",
    is_fa: true,
  },
  {
    value: "rotate-right",
    label: "Rotate right",
    icon: "rotate-right",
    is_fa: true,
  },
  {
    value: "route",
    label: "Route",
    icon: "route",
    is_fa: true,
  },
  {
    value: "rss",
    label: "Rss",
    icon: "rss",
    is_fa: true,
  },
  {
    value: "ruble-sign",
    label: "Ruble sign",
    icon: "ruble-sign",
    is_fa: true,
  },
  {
    value: "rug",
    label: "Rug",
    icon: "rug",
    is_fa: true,
  },
  {
    value: "ruler",
    label: "Ruler",
    icon: "ruler",
    is_fa: true,
  },
  {
    value: "ruler-combined",
    label: "Ruler combined",
    icon: "ruler-combined",
    is_fa: true,
  },
  {
    value: "ruler-horizontal",
    label: "Ruler horizontal",
    icon: "ruler-horizontal",
    is_fa: true,
  },
  {
    value: "ruler-vertical",
    label: "Ruler vertical",
    icon: "ruler-vertical",
    is_fa: true,
  },
  {
    value: "rupee-sign",
    label: "Rupee sign",
    icon: "rupee-sign",
    is_fa: true,
  },
  {
    value: "rupiah-sign",
    label: "Rupiah sign",
    icon: "rupiah-sign",
    is_fa: true,
  },
  {
    value: "s",
    label: "S",
    icon: "s",
    is_fa: true,
  },
  {
    value: "sack-dollar",
    label: "Sack dollar",
    icon: "sack-dollar",
    is_fa: true,
  },
  {
    value: "sack-xmark",
    label: "Sack xmark",
    icon: "sack-xmark",
    is_fa: true,
  },
  {
    value: "sailboat",
    label: "Sailboat",
    icon: "sailboat",
    is_fa: true,
  },
  {
    value: "satellite",
    label: "Satellite",
    icon: "satellite",
    is_fa: true,
  },
  {
    value: "satellite-dish",
    label: "Satellite dish",
    icon: "satellite-dish",
    is_fa: true,
  },
  {
    value: "scale-balanced",
    label: "Scale balanced",
    icon: "scale-balanced",
    is_fa: true,
  },
  {
    value: "scale-unbalanced",
    label: "Scale unbalanced",
    icon: "scale-unbalanced",
    is_fa: true,
  },
  {
    value: "scale-unbalanced-flip",
    label: "Scale unbalanced flip",
    icon: "scale-unbalanced-flip",
    is_fa: true,
  },
  {
    value: "school",
    label: "School",
    icon: "school",
    is_fa: true,
  },
  {
    value: "school-circle-check",
    label: "School circle check",
    icon: "school-circle-check",
    is_fa: true,
  },
  {
    value: "school-circle-exclamation",
    label: "School circle exclamation",
    icon: "school-circle-exclamation",
    is_fa: true,
  },
  {
    value: "school-circle-xmark",
    label: "School circle xmark",
    icon: "school-circle-xmark",
    is_fa: true,
  },
  {
    value: "school-flag",
    label: "School flag",
    icon: "school-flag",
    is_fa: true,
  },
  {
    value: "school-lock",
    label: "School lock",
    icon: "school-lock",
    is_fa: true,
  },
  {
    value: "scissors",
    label: "Scissors",
    icon: "scissors",
    is_fa: true,
  },
  {
    value: "screwdriver",
    label: "Screwdriver",
    icon: "screwdriver",
    is_fa: true,
  },
  {
    value: "screwdriver-wrench",
    label: "Screwdriver wrench",
    icon: "screwdriver-wrench",
    is_fa: true,
  },
  {
    value: "scroll",
    label: "Scroll",
    icon: "scroll",
    is_fa: true,
  },
  {
    value: "scroll-torah",
    label: "Scroll torah",
    icon: "scroll-torah",
    is_fa: true,
  },
  {
    value: "sd-card",
    label: "Sd card",
    icon: "sd-card",
    is_fa: true,
  },
  {
    value: "section",
    label: "Section",
    icon: "section",
    is_fa: true,
  },
  {
    value: "seedling",
    label: "Seedling",
    icon: "seedling",
    is_fa: true,
  },
  {
    value: "server",
    label: "Server",
    icon: "server",
    is_fa: true,
  },
  {
    value: "shapes",
    label: "Shapes",
    icon: "shapes",
    is_fa: true,
  },
  {
    value: "share",
    label: "Share",
    icon: "share",
    is_fa: true,
  },
  {
    value: "share-from-square",
    label: "Share from square",
    icon: "share-from-square",
    is_fa: true,
  },
  {
    value: "share-nodes",
    label: "Share nodes",
    icon: "share-nodes",
    is_fa: true,
  },
  {
    value: "sheet-plastic",
    label: "Sheet plastic",
    icon: "sheet-plastic",
    is_fa: true,
  },
  {
    value: "shekel-sign",
    label: "Shekel sign",
    icon: "shekel-sign",
    is_fa: true,
  },
  {
    value: "shield",
    label: "Shield",
    icon: "shield",
    is_fa: true,
  },
  {
    value: "shield-cat",
    label: "Shield cat",
    icon: "shield-cat",
    is_fa: true,
  },
  {
    value: "shield-dog",
    label: "Shield dog",
    icon: "shield-dog",
    is_fa: true,
  },
  {
    value: "shield-halved",
    label: "Shield halved",
    icon: "shield-halved",
    is_fa: true,
  },
  {
    value: "shield-heart",
    label: "Shield heart",
    icon: "shield-heart",
    is_fa: true,
  },
  {
    value: "shield-virus",
    label: "Shield virus",
    icon: "shield-virus",
    is_fa: true,
  },
  {
    value: "ship",
    label: "Ship",
    icon: "ship",
    is_fa: true,
  },
  {
    value: "shirt",
    label: "Shirt",
    icon: "shirt",
    is_fa: true,
  },
  {
    value: "shoe-prints",
    label: "Shoe prints",
    icon: "shoe-prints",
    is_fa: true,
  },
  {
    value: "shop",
    label: "Shop",
    icon: "shop",
    is_fa: true,
  },
  {
    value: "shop-lock",
    label: "Shop lock",
    icon: "shop-lock",
    is_fa: true,
  },
  {
    value: "shop-slash",
    label: "Shop slash",
    icon: "shop-slash",
    is_fa: true,
  },
  {
    value: "shower",
    label: "Shower",
    icon: "shower",
    is_fa: true,
  },
  {
    value: "shrimp",
    label: "Shrimp",
    icon: "shrimp",
    is_fa: true,
  },
  {
    value: "shuffle",
    label: "Shuffle",
    icon: "shuffle",
    is_fa: true,
  },
  {
    value: "shuttle-space",
    label: "Shuttle space",
    icon: "shuttle-space",
    is_fa: true,
  },
  {
    value: "sign-hanging",
    label: "Sign hanging",
    icon: "sign-hanging",
    is_fa: true,
  },
  {
    value: "signal",
    label: "Signal",
    icon: "signal",
    is_fa: true,
  },
  {
    value: "signature",
    label: "Signature",
    icon: "signature",
    is_fa: true,
  },
  {
    value: "signs-post",
    label: "Signs post",
    icon: "signs-post",
    is_fa: true,
  },
  {
    value: "sim-card",
    label: "Sim card",
    icon: "sim-card",
    is_fa: true,
  },
  {
    value: "sink",
    label: "Sink",
    icon: "sink",
    is_fa: true,
  },
  {
    value: "sitemap",
    label: "Sitemap",
    icon: "sitemap",
    is_fa: true,
  },
  {
    value: "skull",
    label: "Skull",
    icon: "skull",
    is_fa: true,
  },
  {
    value: "skull-crossbones",
    label: "Skull crossbones",
    icon: "skull-crossbones",
    is_fa: true,
  },
  {
    value: "slash",
    label: "Slash",
    icon: "slash",
    is_fa: true,
  },
  {
    value: "sleigh",
    label: "Sleigh",
    icon: "sleigh",
    is_fa: true,
  },
  {
    value: "sliders",
    label: "Sliders",
    icon: "sliders",
    is_fa: true,
  },
  {
    value: "smog",
    label: "Smog",
    icon: "smog",
    is_fa: true,
  },
  {
    value: "smoking",
    label: "Smoking",
    icon: "smoking",
    is_fa: true,
  },
  {
    value: "snowflake",
    label: "Snowflake",
    icon: "snowflake",
    is_fa: true,
  },
  {
    value: "snowman",
    label: "Snowman",
    icon: "snowman",
    is_fa: true,
  },
  {
    value: "snowplow",
    label: "Snowplow",
    icon: "snowplow",
    is_fa: true,
  },
  {
    value: "soap",
    label: "Soap",
    icon: "soap",
    is_fa: true,
  },
  {
    value: "socks",
    label: "Socks",
    icon: "socks",
    is_fa: true,
  },
  {
    value: "solar-panel",
    label: "Solar panel",
    icon: "solar-panel",
    is_fa: true,
  },
  {
    value: "sort",
    label: "Sort",
    icon: "sort",
    is_fa: true,
  },
  {
    value: "sort-down",
    label: "Sort down",
    icon: "sort-down",
    is_fa: true,
  },
  {
    value: "sort-up",
    label: "Sort up",
    icon: "sort-up",
    is_fa: true,
  },
  {
    value: "spa",
    label: "Spa",
    icon: "spa",
    is_fa: true,
  },
  {
    value: "spaghetti-monster-flying",
    label: "Spaghetti monster flying",
    icon: "spaghetti-monster-flying",
    is_fa: true,
  },
  {
    value: "spell-check",
    label: "Spell check",
    icon: "spell-check",
    is_fa: true,
  },
  {
    value: "spider",
    label: "Spider",
    icon: "spider",
    is_fa: true,
  },
  {
    value: "spinner",
    label: "Spinner",
    icon: "spinner",
    is_fa: true,
  },
  {
    value: "splotch",
    label: "Splotch",
    icon: "splotch",
    is_fa: true,
  },
  {
    value: "spoon",
    label: "Spoon",
    icon: "spoon",
    is_fa: true,
  },
  {
    value: "spray-can",
    label: "Spray can",
    icon: "spray-can",
    is_fa: true,
  },
  {
    value: "spray-can-sparkles",
    label: "Spray can sparkles",
    icon: "spray-can-sparkles",
    is_fa: true,
  },
  {
    value: "square",
    label: "Square",
    icon: "square",
    is_fa: true,
  },
  {
    value: "square-arrow-up-right",
    label: "Square arrow up right",
    icon: "square-arrow-up-right",
    is_fa: true,
  },
  {
    value: "square-caret-down",
    label: "Square caret down",
    icon: "square-caret-down",
    is_fa: true,
  },
  {
    value: "square-caret-left",
    label: "Square caret left",
    icon: "square-caret-left",
    is_fa: true,
  },
  {
    value: "square-caret-right",
    label: "Square caret right",
    icon: "square-caret-right",
    is_fa: true,
  },
  {
    value: "square-caret-up",
    label: "Square caret up",
    icon: "square-caret-up",
    is_fa: true,
  },
  {
    value: "square-check",
    label: "Square check",
    icon: "square-check",
    is_fa: true,
  },
  {
    value: "square-envelope",
    label: "Square envelope",
    icon: "square-envelope",
    is_fa: true,
  },
  {
    value: "square-full",
    label: "Square full",
    icon: "square-full",
    is_fa: true,
  },
  {
    value: "square-h",
    label: "Square h",
    icon: "square-h",
    is_fa: true,
  },
  {
    value: "square-minus",
    label: "Square minus",
    icon: "square-minus",
    is_fa: true,
  },
  {
    value: "square-nfi",
    label: "Square nfi",
    icon: "square-nfi",
    is_fa: true,
  },
  {
    value: "square-parking",
    label: "Square parking",
    icon: "square-parking",
    is_fa: true,
  },
  {
    value: "square-pen",
    label: "Square pen",
    icon: "square-pen",
    is_fa: true,
  },
  {
    value: "square-person-confined",
    label: "Square person confined",
    icon: "square-person-confined",
    is_fa: true,
  },
  {
    value: "square-phone",
    label: "Square phone",
    icon: "square-phone",
    is_fa: true,
  },
  {
    value: "square-phone-flip",
    label: "Square phone flip",
    icon: "square-phone-flip",
    is_fa: true,
  },
  {
    value: "square-plus",
    label: "Square plus",
    icon: "square-plus",
    is_fa: true,
  },
  {
    value: "square-poll-horizontal",
    label: "Square poll horizontal",
    icon: "square-poll-horizontal",
    is_fa: true,
  },
  {
    value: "square-poll-vertical",
    label: "Square poll vertical",
    icon: "square-poll-vertical",
    is_fa: true,
  },
  {
    value: "square-root-variable",
    label: "Square root variable",
    icon: "square-root-variable",
    is_fa: true,
  },
  {
    value: "square-rss",
    label: "Square rss",
    icon: "square-rss",
    is_fa: true,
  },
  {
    value: "square-share-nodes",
    label: "Square share nodes",
    icon: "square-share-nodes",
    is_fa: true,
  },
  {
    value: "square-up-right",
    label: "Square up right",
    icon: "square-up-right",
    is_fa: true,
  },
  {
    value: "square-virus",
    label: "Square virus",
    icon: "square-virus",
    is_fa: true,
  },
  {
    value: "square-xmark",
    label: "Square xmark",
    icon: "square-xmark",
    is_fa: true,
  },
  {
    value: "staff-aesculapius",
    label: "Staff aesculapius",
    icon: "staff-aesculapius",
    is_fa: true,
  },
  {
    value: "stairs",
    label: "Stairs",
    icon: "stairs",
    is_fa: true,
  },
  {
    value: "stamp",
    label: "Stamp",
    icon: "stamp",
    is_fa: true,
  },
  {
    value: "star",
    label: "Star",
    icon: "star",
    is_fa: true,
  },
  {
    value: "star-and-crescent",
    label: "Star and crescent",
    icon: "star-and-crescent",
    is_fa: true,
  },
  {
    value: "star-half",
    label: "Star half",
    icon: "star-half",
    is_fa: true,
  },
  {
    value: "star-half-stroke",
    label: "Star half stroke",
    icon: "star-half-stroke",
    is_fa: true,
  },
  {
    value: "star-of-david",
    label: "Star of david",
    icon: "star-of-david",
    is_fa: true,
  },
  {
    value: "star-of-life",
    label: "Star of life",
    icon: "star-of-life",
    is_fa: true,
  },
  {
    value: "sterling-sign",
    label: "Sterling sign",
    icon: "sterling-sign",
    is_fa: true,
  },
  {
    value: "stethoscope",
    label: "Stethoscope",
    icon: "stethoscope",
    is_fa: true,
  },
  {
    value: "stop",
    label: "Stop",
    icon: "stop",
    is_fa: true,
  },
  {
    value: "stopwatch",
    label: "Stopwatch",
    icon: "stopwatch",
    is_fa: true,
  },
  {
    value: "stopwatch-20",
    label: "Stopwatch 20",
    icon: "stopwatch-20",
    is_fa: true,
  },
  {
    value: "store",
    label: "Store",
    icon: "store",
    is_fa: true,
  },
  {
    value: "store-slash",
    label: "Store slash",
    icon: "store-slash",
    is_fa: true,
  },
  {
    value: "street-view",
    label: "Street view",
    icon: "street-view",
    is_fa: true,
  },
  {
    value: "strikethrough",
    label: "Strikethrough",
    icon: "strikethrough",
    is_fa: true,
  },
  {
    value: "stroopwafel",
    label: "Stroopwafel",
    icon: "stroopwafel",
    is_fa: true,
  },
  {
    value: "subscript",
    label: "Subscript",
    icon: "subscript",
    is_fa: true,
  },
  {
    value: "suitcase",
    label: "Suitcase",
    icon: "suitcase",
    is_fa: true,
  },
  {
    value: "suitcase-medical",
    label: "Suitcase medical",
    icon: "suitcase-medical",
    is_fa: true,
  },
  {
    value: "suitcase-rolling",
    label: "Suitcase rolling",
    icon: "suitcase-rolling",
    is_fa: true,
  },
  {
    value: "sun",
    label: "Sun",
    icon: "sun",
    is_fa: true,
  },
  {
    value: "sun-plant-wilt",
    label: "Sun plant wilt",
    icon: "sun-plant-wilt",
    is_fa: true,
  },
  {
    value: "superscript",
    label: "Superscript",
    icon: "superscript",
    is_fa: true,
  },
  {
    value: "swatchbook",
    label: "Swatchbook",
    icon: "swatchbook",
    is_fa: true,
  },
  {
    value: "synagogue",
    label: "Synagogue",
    icon: "synagogue",
    is_fa: true,
  },
  {
    value: "syringe",
    label: "Syringe",
    icon: "syringe",
    is_fa: true,
  },
  {
    value: "t",
    label: "T",
    icon: "t",
    is_fa: true,
  },
  {
    value: "table",
    label: "Table",
    icon: "table",
    is_fa: true,
  },
  {
    value: "table-cells",
    label: "Table cells",
    icon: "table-cells",
    is_fa: true,
  },
  {
    value: "table-cells-large",
    label: "Table cells large",
    icon: "table-cells-large",
    is_fa: true,
  },
  {
    value: "table-columns",
    label: "Table columns",
    icon: "table-columns",
    is_fa: true,
  },
  {
    value: "table-list",
    label: "Table list",
    icon: "table-list",
    is_fa: true,
  },
  {
    value: "table-tennis-paddle-ball",
    label: "Table tennis paddle ball",
    icon: "table-tennis-paddle-ball",
    is_fa: true,
  },
  {
    value: "tablet",
    label: "Tablet",
    icon: "tablet",
    is_fa: true,
  },
  {
    value: "tablet-button",
    label: "Tablet button",
    icon: "tablet-button",
    is_fa: true,
  },
  {
    value: "tablet-screen-button",
    label: "Tablet screen button",
    icon: "tablet-screen-button",
    is_fa: true,
  },
  {
    value: "tablets",
    label: "Tablets",
    icon: "tablets",
    is_fa: true,
  },
  {
    value: "tachograph-digital",
    label: "Tachograph digital",
    icon: "tachograph-digital",
    is_fa: true,
  },
  {
    value: "tag",
    label: "Tag",
    icon: "tag",
    is_fa: true,
  },
  {
    value: "tags",
    label: "Tags",
    icon: "tags",
    is_fa: true,
  },
  {
    value: "tape",
    label: "Tape",
    icon: "tape",
    is_fa: true,
  },
  {
    value: "tarp",
    label: "Tarp",
    icon: "tarp",
    is_fa: true,
  },
  {
    value: "tarp-droplet",
    label: "Tarp droplet",
    icon: "tarp-droplet",
    is_fa: true,
  },
  {
    value: "taxi",
    label: "Taxi",
    icon: "taxi",
    is_fa: true,
  },
  {
    value: "teeth",
    label: "Teeth",
    icon: "teeth",
    is_fa: true,
  },
  {
    value: "teeth-open",
    label: "Teeth open",
    icon: "teeth-open",
    is_fa: true,
  },
  {
    value: "temperature-arrow-down",
    label: "Temperature arrow down",
    icon: "temperature-arrow-down",
    is_fa: true,
  },
  {
    value: "temperature-arrow-up",
    label: "Temperature arrow up",
    icon: "temperature-arrow-up",
    is_fa: true,
  },
  {
    value: "temperature-empty",
    label: "Temperature empty",
    icon: "temperature-empty",
    is_fa: true,
  },
  {
    value: "temperature-full",
    label: "Temperature full",
    icon: "temperature-full",
    is_fa: true,
  },
  {
    value: "temperature-half",
    label: "Temperature half",
    icon: "temperature-half",
    is_fa: true,
  },
  {
    value: "temperature-high",
    label: "Temperature high",
    icon: "temperature-high",
    is_fa: true,
  },
  {
    value: "temperature-low",
    label: "Temperature low",
    icon: "temperature-low",
    is_fa: true,
  },
  {
    value: "temperature-quarter",
    label: "Temperature quarter",
    icon: "temperature-quarter",
    is_fa: true,
  },
  {
    value: "temperature-three-quarters",
    label: "Temperature three quarters",
    icon: "temperature-three-quarters",
    is_fa: true,
  },
  {
    value: "tenge-sign",
    label: "Tenge sign",
    icon: "tenge-sign",
    is_fa: true,
  },
  {
    value: "tent",
    label: "Tent",
    icon: "tent",
    is_fa: true,
  },
  {
    value: "tent-arrow-down-to-line",
    label: "Tent arrow down to line",
    icon: "tent-arrow-down-to-line",
    is_fa: true,
  },
  {
    value: "tent-arrow-left-right",
    label: "Tent arrow left right",
    icon: "tent-arrow-left-right",
    is_fa: true,
  },
  {
    value: "tent-arrow-turn-left",
    label: "Tent arrow turn left",
    icon: "tent-arrow-turn-left",
    is_fa: true,
  },
  {
    value: "tent-arrows-down",
    label: "Tent arrows down",
    icon: "tent-arrows-down",
    is_fa: true,
  },
  {
    value: "tents",
    label: "Tents",
    icon: "tents",
    is_fa: true,
  },
  {
    value: "terminal",
    label: "Terminal",
    icon: "terminal",
    is_fa: true,
  },
  {
    value: "text-height",
    label: "Text height",
    icon: "text-height",
    is_fa: true,
  },
  {
    value: "text-slash",
    label: "Text slash",
    icon: "text-slash",
    is_fa: true,
  },
  {
    value: "text-width",
    label: "Text width",
    icon: "text-width",
    is_fa: true,
  },
  {
    value: "thermometer",
    label: "Thermometer",
    icon: "thermometer",
    is_fa: true,
  },
  {
    value: "thumbs-down",
    label: "Thumbs down",
    icon: "thumbs-down",
    is_fa: true,
  },
  {
    value: "thumbs-up",
    label: "Thumbs up",
    icon: "thumbs-up",
    is_fa: true,
  },
  {
    value: "thumbtack",
    label: "Thumbtack",
    icon: "thumbtack",
    is_fa: true,
  },
  {
    value: "ticket",
    label: "Ticket",
    icon: "ticket",
    is_fa: true,
  },
  {
    value: "ticket-simple",
    label: "Ticket simple",
    icon: "ticket-simple",
    is_fa: true,
  },
  {
    value: "timeline",
    label: "Timeline",
    icon: "timeline",
    is_fa: true,
  },
  {
    value: "toggle-off",
    label: "Toggle off",
    icon: "toggle-off",
    is_fa: true,
  },
  {
    value: "toggle-on",
    label: "Toggle on",
    icon: "toggle-on",
    is_fa: true,
  },
  {
    value: "toilet",
    label: "Toilet",
    icon: "toilet",
    is_fa: true,
  },
  {
    value: "toilet-paper",
    label: "Toilet paper",
    icon: "toilet-paper",
    is_fa: true,
  },
  {
    value: "toilet-paper-slash",
    label: "Toilet paper slash",
    icon: "toilet-paper-slash",
    is_fa: true,
  },
  {
    value: "toilet-portable",
    label: "Toilet portable",
    icon: "toilet-portable",
    is_fa: true,
  },
  {
    value: "toilets-portable",
    label: "Toilets portable",
    icon: "toilets-portable",
    is_fa: true,
  },
  {
    value: "toolbox",
    label: "Toolbox",
    icon: "toolbox",
    is_fa: true,
  },
  {
    value: "tooth",
    label: "Tooth",
    icon: "tooth",
    is_fa: true,
  },
  {
    value: "torii-gate",
    label: "Torii gate",
    icon: "torii-gate",
    is_fa: true,
  },
  {
    value: "tornado",
    label: "Tornado",
    icon: "tornado",
    is_fa: true,
  },
  {
    value: "tower-broadcast",
    label: "Tower broadcast",
    icon: "tower-broadcast",
    is_fa: true,
  },
  {
    value: "tower-cell",
    label: "Tower cell",
    icon: "tower-cell",
    is_fa: true,
  },
  {
    value: "tower-observation",
    label: "Tower observation",
    icon: "tower-observation",
    is_fa: true,
  },
  {
    value: "tractor",
    label: "Tractor",
    icon: "tractor",
    is_fa: true,
  },
  {
    value: "trademark",
    label: "Trademark",
    icon: "trademark",
    is_fa: true,
  },
  {
    value: "traffic-light",
    label: "Traffic light",
    icon: "traffic-light",
    is_fa: true,
  },
  {
    value: "trailer",
    label: "Trailer",
    icon: "trailer",
    is_fa: true,
  },
  {
    value: "train",
    label: "Train",
    icon: "train",
    is_fa: true,
  },
  {
    value: "train-subway",
    label: "Train subway",
    icon: "train-subway",
    is_fa: true,
  },
  {
    value: "train-tram",
    label: "Train tram",
    icon: "train-tram",
    is_fa: true,
  },
  {
    value: "transgender",
    label: "Transgender",
    icon: "transgender",
    is_fa: true,
  },
  {
    value: "trash",
    label: "Trash",
    icon: "trash",
    is_fa: true,
  },
  {
    value: "trash-arrow-up",
    label: "Trash arrow up",
    icon: "trash-arrow-up",
    is_fa: true,
  },
  {
    value: "trash-can",
    label: "Trash can",
    icon: "trash-can",
    is_fa: true,
  },
  {
    value: "trash-can-arrow-up",
    label: "Trash can arrow up",
    icon: "trash-can-arrow-up",
    is_fa: true,
  },
  {
    value: "tree",
    label: "Tree",
    icon: "tree",
    is_fa: true,
  },
  {
    value: "tree-city",
    label: "Tree city",
    icon: "tree-city",
    is_fa: true,
  },
  {
    value: "triangle-exclamation",
    label: "Triangle exclamation",
    icon: "triangle-exclamation",
    is_fa: true,
  },
  {
    value: "trophy",
    label: "Trophy",
    icon: "trophy",
    is_fa: true,
  },
  {
    value: "trowel",
    label: "Trowel",
    icon: "trowel",
    is_fa: true,
  },
  {
    value: "trowel-bricks",
    label: "Trowel bricks",
    icon: "trowel-bricks",
    is_fa: true,
  },
  {
    value: "truck",
    label: "Truck",
    icon: "truck",
    is_fa: true,
  },
  {
    value: "truck-arrow-right",
    label: "Truck arrow right",
    icon: "truck-arrow-right",
    is_fa: true,
  },
  {
    value: "truck-droplet",
    label: "Truck droplet",
    icon: "truck-droplet",
    is_fa: true,
  },
  {
    value: "truck-fast",
    label: "Truck fast",
    icon: "truck-fast",
    is_fa: true,
  },
  {
    value: "truck-field",
    label: "Truck field",
    icon: "truck-field",
    is_fa: true,
  },
  {
    value: "truck-field-un",
    label: "Truck field un",
    icon: "truck-field-un",
    is_fa: true,
  },
  {
    value: "truck-front",
    label: "Truck front",
    icon: "truck-front",
    is_fa: true,
  },
  {
    value: "truck-medical",
    label: "Truck medical",
    icon: "truck-medical",
    is_fa: true,
  },
  {
    value: "truck-monster",
    label: "Truck monster",
    icon: "truck-monster",
    is_fa: true,
  },
  {
    value: "truck-moving",
    label: "Truck moving",
    icon: "truck-moving",
    is_fa: true,
  },
  {
    value: "truck-pickup",
    label: "Truck pickup",
    icon: "truck-pickup",
    is_fa: true,
  },
  {
    value: "truck-plane",
    label: "Truck plane",
    icon: "truck-plane",
    is_fa: true,
  },
  {
    value: "truck-ramp-box",
    label: "Truck ramp box",
    icon: "truck-ramp-box",
    is_fa: true,
  },
  {
    value: "tty",
    label: "Tty",
    icon: "tty",
    is_fa: true,
  },
  {
    value: "turkish-lira-sign",
    label: "Turkish lira sign",
    icon: "turkish-lira-sign",
    is_fa: true,
  },
  {
    value: "turn-down",
    label: "Turn down",
    icon: "turn-down",
    is_fa: true,
  },
  {
    value: "turn-up",
    label: "Turn up",
    icon: "turn-up",
    is_fa: true,
  },
  {
    value: "tv",
    label: "Tv",
    icon: "tv",
    is_fa: true,
  },
  {
    value: "u",
    label: "U",
    icon: "u",
    is_fa: true,
  },
  {
    value: "umbrella",
    label: "Umbrella",
    icon: "umbrella",
    is_fa: true,
  },
  {
    value: "umbrella-beach",
    label: "Umbrella beach",
    icon: "umbrella-beach",
    is_fa: true,
  },
  {
    value: "underline",
    label: "Underline",
    icon: "underline",
    is_fa: true,
  },
  {
    value: "universal-access",
    label: "Universal access",
    icon: "universal-access",
    is_fa: true,
  },
  {
    value: "unlock",
    label: "Unlock",
    icon: "unlock",
    is_fa: true,
  },
  {
    value: "unlock-keyhole",
    label: "Unlock keyhole",
    icon: "unlock-keyhole",
    is_fa: true,
  },
  {
    value: "up-down",
    label: "Up down",
    icon: "up-down",
    is_fa: true,
  },
  {
    value: "up-down-left-right",
    label: "Up down left right",
    icon: "up-down-left-right",
    is_fa: true,
  },
  {
    value: "up-long",
    label: "Up long",
    icon: "up-long",
    is_fa: true,
  },
  {
    value: "up-right-and-down-left-from-center",
    label: "Up right and down left from center",
    icon: "up-right-and-down-left-from-center",
    is_fa: true,
  },
  {
    value: "up-right-from-square",
    label: "Up right from square",
    icon: "up-right-from-square",
    is_fa: true,
  },
  {
    value: "upload",
    label: "Upload",
    icon: "upload",
    is_fa: true,
  },
  {
    value: "user",
    label: "User",
    icon: "user",
    is_fa: true,
  },
  {
    value: "user-astronaut",
    label: "User astronaut",
    icon: "user-astronaut",
    is_fa: true,
  },
  {
    value: "user-check",
    label: "User check",
    icon: "user-check",
    is_fa: true,
  },
  {
    value: "user-clock",
    label: "User clock",
    icon: "user-clock",
    is_fa: true,
  },
  {
    value: "user-doctor",
    label: "User doctor",
    icon: "user-doctor",
    is_fa: true,
  },
  {
    value: "user-gear",
    label: "User gear",
    icon: "user-gear",
    is_fa: true,
  },
  {
    value: "user-graduate",
    label: "User graduate",
    icon: "user-graduate",
    is_fa: true,
  },
  {
    value: "user-group",
    label: "User group",
    icon: "user-group",
    is_fa: true,
  },
  {
    value: "user-injured",
    label: "User injured",
    icon: "user-injured",
    is_fa: true,
  },
  {
    value: "user-large",
    label: "User large",
    icon: "user-large",
    is_fa: true,
  },
  {
    value: "user-large-slash",
    label: "User large slash",
    icon: "user-large-slash",
    is_fa: true,
  },
  {
    value: "user-lock",
    label: "User lock",
    icon: "user-lock",
    is_fa: true,
  },
  {
    value: "user-minus",
    label: "User minus",
    icon: "user-minus",
    is_fa: true,
  },
  {
    value: "user-ninja",
    label: "User ninja",
    icon: "user-ninja",
    is_fa: true,
  },
  {
    value: "user-nurse",
    label: "User nurse",
    icon: "user-nurse",
    is_fa: true,
  },
  {
    value: "user-pen",
    label: "User pen",
    icon: "user-pen",
    is_fa: true,
  },
  {
    value: "user-plus",
    label: "User plus",
    icon: "user-plus",
    is_fa: true,
  },
  {
    value: "user-secret",
    label: "User secret",
    icon: "user-secret",
    is_fa: true,
  },
  {
    value: "user-shield",
    label: "User shield",
    icon: "user-shield",
    is_fa: true,
  },
  {
    value: "user-slash",
    label: "User slash",
    icon: "user-slash",
    is_fa: true,
  },
  {
    value: "user-tag",
    label: "User tag",
    icon: "user-tag",
    is_fa: true,
  },
  {
    value: "user-tie",
    label: "User tie",
    icon: "user-tie",
    is_fa: true,
  },
  {
    value: "user-xmark",
    label: "User xmark",
    icon: "user-xmark",
    is_fa: true,
  },
  {
    value: "users",
    label: "Users",
    icon: "users",
    is_fa: true,
  },
  {
    value: "users-between-lines",
    label: "Users between lines",
    icon: "users-between-lines",
    is_fa: true,
  },
  {
    value: "users-gear",
    label: "Users gear",
    icon: "users-gear",
    is_fa: true,
  },
  {
    value: "users-line",
    label: "Users line",
    icon: "users-line",
    is_fa: true,
  },
  {
    value: "users-rays",
    label: "Users rays",
    icon: "users-rays",
    is_fa: true,
  },
  {
    value: "users-rectangle",
    label: "Users rectangle",
    icon: "users-rectangle",
    is_fa: true,
  },
  {
    value: "users-slash",
    label: "Users slash",
    icon: "users-slash",
    is_fa: true,
  },
  {
    value: "users-viewfinder",
    label: "Users viewfinder",
    icon: "users-viewfinder",
    is_fa: true,
  },
  {
    value: "utensils",
    label: "Utensils",
    icon: "utensils",
    is_fa: true,
  },
  {
    value: "v",
    label: "V",
    icon: "v",
    is_fa: true,
  },
  {
    value: "van-shuttle",
    label: "Van shuttle",
    icon: "van-shuttle",
    is_fa: true,
  },
  {
    value: "vault",
    label: "Vault",
    icon: "vault",
    is_fa: true,
  },
  {
    value: "vector-square",
    label: "Vector square",
    icon: "vector-square",
    is_fa: true,
  },
  {
    value: "venus",
    label: "Venus",
    icon: "venus",
    is_fa: true,
  },
  {
    value: "venus-double",
    label: "Venus double",
    icon: "venus-double",
    is_fa: true,
  },
  {
    value: "venus-mars",
    label: "Venus mars",
    icon: "venus-mars",
    is_fa: true,
  },
  {
    value: "vest",
    label: "Vest",
    icon: "vest",
    is_fa: true,
  },
  {
    value: "vest-patches",
    label: "Vest patches",
    icon: "vest-patches",
    is_fa: true,
  },
  {
    value: "vial",
    label: "Vial",
    icon: "vial",
    is_fa: true,
  },
  {
    value: "vial-circle-check",
    label: "Vial circle check",
    icon: "vial-circle-check",
    is_fa: true,
  },
  {
    value: "vial-virus",
    label: "Vial virus",
    icon: "vial-virus",
    is_fa: true,
  },
  {
    value: "vials",
    label: "Vials",
    icon: "vials",
    is_fa: true,
  },
  {
    value: "video",
    label: "Video",
    icon: "video",
    is_fa: true,
  },
  {
    value: "video-slash",
    label: "Video slash",
    icon: "video-slash",
    is_fa: true,
  },
  {
    value: "vihara",
    label: "Vihara",
    icon: "vihara",
    is_fa: true,
  },
  {
    value: "virus",
    label: "Virus",
    icon: "virus",
    is_fa: true,
  },
  {
    value: "virus-covid",
    label: "Virus covid",
    icon: "virus-covid",
    is_fa: true,
  },
  {
    value: "virus-covid-slash",
    label: "Virus covid slash",
    icon: "virus-covid-slash",
    is_fa: true,
  },
  {
    value: "virus-slash",
    label: "Virus slash",
    icon: "virus-slash",
    is_fa: true,
  },
  {
    value: "viruses",
    label: "Viruses",
    icon: "viruses",
    is_fa: true,
  },
  {
    value: "voicemail",
    label: "Voicemail",
    icon: "voicemail",
    is_fa: true,
  },
  {
    value: "volcano",
    label: "Volcano",
    icon: "volcano",
    is_fa: true,
  },
  {
    value: "volleyball",
    label: "Volleyball",
    icon: "volleyball",
    is_fa: true,
  },
  {
    value: "volume-high",
    label: "Volume high",
    icon: "volume-high",
    is_fa: true,
  },
  {
    value: "volume-low",
    label: "Volume low",
    icon: "volume-low",
    is_fa: true,
  },
  {
    value: "volume-off",
    label: "Volume off",
    icon: "volume-off",
    is_fa: true,
  },
  {
    value: "volume-xmark",
    label: "Volume xmark",
    icon: "volume-xmark",
    is_fa: true,
  },
  {
    value: "vr-cardboard",
    label: "Vr cardboard",
    icon: "vr-cardboard",
    is_fa: true,
  },
  {
    value: "w",
    label: "W",
    icon: "w",
    is_fa: true,
  },
  {
    value: "walkie-talkie",
    label: "Walkie talkie",
    icon: "walkie-talkie",
    is_fa: true,
  },
  {
    value: "wallet",
    label: "Wallet",
    icon: "wallet",
    is_fa: true,
  },
  {
    value: "wand-magic",
    label: "Wand magic",
    icon: "wand-magic",
    is_fa: true,
  },
  {
    value: "wand-magic-sparkles",
    label: "Wand magic sparkles",
    icon: "wand-magic-sparkles",
    is_fa: true,
  },
  {
    value: "wand-sparkles",
    label: "Wand sparkles",
    icon: "wand-sparkles",
    is_fa: true,
  },
  {
    value: "warehouse",
    label: "Warehouse",
    icon: "warehouse",
    is_fa: true,
  },
  {
    value: "water",
    label: "Water",
    icon: "water",
    is_fa: true,
  },
  {
    value: "water-ladder",
    label: "Water ladder",
    icon: "water-ladder",
    is_fa: true,
  },
  {
    value: "wave-square",
    label: "Wave square",
    icon: "wave-square",
    is_fa: true,
  },
  {
    value: "weight-hanging",
    label: "Weight hanging",
    icon: "weight-hanging",
    is_fa: true,
  },
  {
    value: "weight-scale",
    label: "Weight scale",
    icon: "weight-scale",
    is_fa: true,
  },
  {
    value: "wheat-awn",
    label: "Wheat awn",
    icon: "wheat-awn",
    is_fa: true,
  },
  {
    value: "wheat-awn-circle-exclamation",
    label: "Wheat awn circle exclamation",
    icon: "wheat-awn-circle-exclamation",
    is_fa: true,
  },
  {
    value: "wheelchair",
    label: "Wheelchair",
    icon: "wheelchair",
    is_fa: true,
  },
  {
    value: "wheelchair-move",
    label: "Wheelchair move",
    icon: "wheelchair-move",
    is_fa: true,
  },
  {
    value: "whiskey-glass",
    label: "Whiskey glass",
    icon: "whiskey-glass",
    is_fa: true,
  },
  {
    value: "wifi",
    label: "Wifi",
    icon: "wifi",
    is_fa: true,
  },
  {
    value: "wind",
    label: "Wind",
    icon: "wind",
    is_fa: true,
  },
  {
    value: "window-maximize",
    label: "Window maximize",
    icon: "window-maximize",
    is_fa: true,
  },
  {
    value: "window-minimize",
    label: "Window minimize",
    icon: "window-minimize",
    is_fa: true,
  },
  {
    value: "window-restore",
    label: "Window restore",
    icon: "window-restore",
    is_fa: true,
  },
  {
    value: "wine-bottle",
    label: "Wine bottle",
    icon: "wine-bottle",
    is_fa: true,
  },
  {
    value: "wine-glass",
    label: "Wine glass",
    icon: "wine-glass",
    is_fa: true,
  },
  {
    value: "wine-glass-empty",
    label: "Wine glass empty",
    icon: "wine-glass-empty",
    is_fa: true,
  },
  {
    value: "won-sign",
    label: "Won sign",
    icon: "won-sign",
    is_fa: true,
  },
  {
    value: "worm",
    label: "Worm",
    icon: "worm",
    is_fa: true,
  },
  {
    value: "wrench",
    label: "Wrench",
    icon: "wrench",
    is_fa: true,
  },
  {
    value: "x",
    label: "X",
    icon: "x",
    is_fa: true,
  },
  {
    value: "x-ray",
    label: "X ray",
    icon: "x-ray",
    is_fa: true,
  },
  {
    value: "xmark",
    label: "Xmark",
    icon: "xmark",
    is_fa: true,
  },
  {
    value: "xmarks-lines",
    label: "Xmarks lines",
    icon: "xmarks-lines",
    is_fa: true,
  },
  {
    value: "y",
    label: "Y",
    icon: "y",
    is_fa: true,
  },
  {
    value: "yen-sign",
    label: "Yen sign",
    icon: "yen-sign",
    is_fa: true,
  },
  {
    value: "yin-yang",
    label: "Yin yang",
    icon: "yin-yang",
    is_fa: true,
  },
  {
    value: "z",
    label: "Z",
    icon: "z",
    is_fa: true,
  },
];
